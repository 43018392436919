import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../common/constants/constants";
import { UrnConstants } from "../common/constants/urn.constants";
import { EnvService } from "./env.service";
import { ToastService } from "./toast.service";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _envService: EnvService,
    private readonly _toastService: ToastService,
    private readonly _translateService: TranslateService
  ) {}

  public getAccountingReport(startDate: string, endDate: string) {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetBuySellCryptoByPeriodReport}`;

    this.getReportResult(
      uri,
      {
        startDate: startDate,
        endDate: endDate,
      },
      `accounting_report_${startDate}_${endDate}.xlsx`
    );
  }

  public getRegisteredUsersReportForPeriodReport(startDate: string, endDate: string) {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetUsersRegisteredByPeriodReport}`;

    this.getReportResult(
      uri,
      {
        startDate: startDate,
        endDate: endDate,
      },
      `users_registration_report_${startDate}_${endDate}.xlsx`
    );
  }

  public getUserPersonalDataReport(userId: number, firstName: string, lastName: string) {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetUserPersonalDataReport}`;

    this.getReportResult(uri, { userId: userId }, `users_registration_report_${lastName}_${firstName}.xlsx`);
  }

  public getUsersPersonalDataReport() {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetUsersPersonalDataReport}`;

    this.getReportResult(uri, {}, `users_registration_report_${new Date().toISOString()}.xlsx`);
  }

  public getTerroristsDataReport() {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetTerroristsDataReport}`;

    this.getReportResult(uri, {}, `terrorists_data_report_${new Date().toISOString()}.xlsx`);
  }

  private getReportResult(uri: string, parameters: object, resultFileName: string): void {
    this._httpClient
      .post(uri, JSON.stringify(parameters), {
        headers: Constants.JsonContentTypeHeader,
        responseType: "blob",
      })
      .subscribe({
        next: file => {
          this.downloadReport(file, resultFileName);
        },
        error: error => {
          if (error.status === 404) {
            this._toastService.show(this._translateService.instant("Admin.Reports.Download_not_found"), {
              variant: "danger",
            });
          } else {
            this._toastService.show(this._translateService.instant("Common.Unknown_error"), {
              variant: "danger",
            });
          }
        },
        complete: () => {},
      });
  }

  private downloadReport(file: Blob, fileName: string): void {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = fileName;
    link.click();
    link.remove();
  }
}
