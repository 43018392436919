<div class="box px-3">
  <div class="flex items-center justify-between border-b border-gray-100 pb-5 dark:border-slate-700">
    <h3 class="altitle">{{ "Admin.Home.Limit" | translate }}</h3>
    <button
      *ngIf="hasInteractionPermissions"
      class="btn-flat w-6"
      (click)="toggleEditing(selectedCurrency)"
      [disabled]="isPending"
    >
      <app-svg-icon [name]="isEditing ? 'check' : 'edit'" />
    </button>
  </div>

  <form class="mt-5 space-y-4" [formGroup]="limitsForm" (ngSubmit)="handleSaveLimit()">
    <div class="admin-value-row-wrapper">
      {{ "Admin.Home.Day_limit" | translate }}:
      <div *ngIf="isEditing">
        <input
          formControlName="maxWithdrawalPerDay"
          type="number"
          placeholder="100"
          class="admin-value-row"
        />
        <span class="text-xs text-red-400">{{ getFieldError("maxWithdrawalPerDay") }}</span>
      </div>
      <span *ngIf="!isEditing" class="admin-value-row">{{
        getLimits(selectedCurrency)?.maxWithdrawalPerDay | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </div>

    <div class="admin-value-row-wrapper">
      {{ "Admin.Home.Operation_limit" | translate }}:
      <div *ngIf="isEditing">
        <input formControlName="maxWithdrawalPerTx" type="number" placeholder="100" class="admin-value-row" />
        <span class="text-xs text-red-400">{{ getFieldError("maxWithdrawalPerTx") }}</span>
      </div>
      <span *ngIf="!isEditing" class="admin-value-row">{{
        getLimits(selectedCurrency)?.maxWithdrawalPerTx | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </div>

    <div class="admin-value-row-wrapper">
      {{ "Admin.Home.Min_withdrawal_limit" | translate }}:
      <div *ngIf="isEditing">
        <input
          *ngIf="isEditing"
          formControlName="minWithdrawalPerTx"
          type="number"
          placeholder="100"
          class="admin-value-row"
        />
        <span class="text-xs text-red-400">{{ getFieldError("minWithdrawalPerTx") }}</span>
      </div>
      <span *ngIf="!isEditing" class="admin-value-row">{{
        getLimits(selectedCurrency)?.minWithdrawalPerTx | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </div>

    <div class="admin-value-row-wrapper">
      {{ "Admin.Home.With_verification_limit" | translate }}:
      <div *ngIf="isEditing">
        <input
          formControlName="maxTxAmountWithoutConfirm"
          type="number"
          placeholder="100"
          class="admin-value-row"
        />
        <span class="text-xs text-red-400">{{ getFieldError("maxTxAmountWithoutConfirm") }}</span>
      </div>
      <span *ngIf="!isEditing" class="admin-value-row">{{
        getLimits(selectedCurrency)?.maxTxAmountWithoutConfirm | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </div>

    <div class="admin-value-row-wrapper">
      {{ "Admin.Home.Max_buy_limit" | translate }}:
      <div *ngIf="isEditing">
        <input formControlName="maxBuyAmount" type="number" placeholder="100" class="admin-value-row" />
        <span class="text-xs text-red-400">{{ getFieldError("maxBuyAmount") }}</span>
      </div>
      <span *ngIf="!isEditing" class="admin-value-row">{{
        getLimits(selectedCurrency)?.maxBuyAmount | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </div>

    <div class="admin-value-row-wrapper">
      {{ "Admin.Home.Max_sell_limit" | translate }}:
      <div *ngIf="isEditing">
        <input formControlName="maxSellAmount" type="number" placeholder="100" class="admin-value-row" />
        <span class="text-xs text-red-400">{{ getFieldError("maxSellAmount") }}</span>
      </div>
      <span *ngIf="!isEditing" class="admin-value-row">{{
        getLimits(selectedCurrency)?.maxSellAmount | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </div>

    <div class="admin-value-row-wrapper">
      {{ "Admin.Home.Min_sell_limit" | translate }}:
      <div *ngIf="isEditing">
        <input formControlName="minSellAmount" type="number" placeholder="100" class="admin-value-row" />
        <span class="text-xs text-red-400">{{ getFieldError("minSellAmount") }}</span>
      </div>
      <span *ngIf="!isEditing" class="admin-value-row">{{
        getLimits(selectedCurrency)?.minSellAmount | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </div>

    <div class="admin-value-row-wrapper">
      {{ "Admin.Home.Min_buy_limit" | translate }}:
      <div *ngIf="isEditing">
        <input formControlName="minBuyAmount" type="number" placeholder="100" class="admin-value-row" />
        <span class="text-xs text-red-400">{{ getFieldError("minBuyAmount") }}</span>
      </div>
      <span *ngIf="!isEditing" class="admin-value-row">{{
        getLimits(selectedCurrency)?.minBuyAmount | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </div>

    <div class="admin-value-row-wrapper">
      {{ "Admin.Home.Without_confirm_limit" | translate }}:
      <div *ngIf="isEditing">
        <input
          formControlName="maxAcquiringAmountWithoutConfirm"
          type="number"
          placeholder="100"
          class="admin-value-row"
        />
        <span class="text-xs text-red-400">{{ getFieldError("maxAcquiringAmountWithoutConfirm") }}</span>
      </div>
      <span *ngIf="!isEditing" class="admin-value-row">{{
        getLimits(selectedCurrency)?.maxAcquiringAmountWithoutConfirm
          | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </div>

    <div class="admin-value-row-wrapper">
      {{ "Admin.Home.Deposit_limit" | translate }}:
      <div *ngIf="isEditing">
        <input formControlName="depositLimit" type="number" placeholder="100" class="admin-value-row" />
        <span class="text-xs text-red-400">{{ getFieldError("depositLimit") }}</span>
      </div>
      <span *ngIf="!isEditing" class="admin-value-row">{{
        getDepositLimit(selectedCurrency)?.amount | mfeCustom: { currency: selectedCurrency }
      }}</span>
    </div>
  </form>
</div>
