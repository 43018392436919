import { Network } from "src/app/common/enums/network.enum";

export function getNetworkName(network?: Network | null) {
  switch (network) {
    case Network.Tron:
      return "Tron";
    case Network.Polygon:
      return "Polygon";
    case Network.Ton:
      return "Ton";
    case Network.Bitcoin:
      return "Bitcoin";
    default:
      return "";
  }
}
