<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
>
  <div
    class="mx-auto mb-2 flex w-fit min-w-[13.375rem] items-center justify-center gap-2.5 rounded-xl border-min border-[#767e7a] bg-[#d1d1d1] p-3 text-xs font-semibold text-text-primary"
    [ngClass]="{
      '!border-[#9cfcdf] !bg-[#e6fef7] ': toast.variant === 'success',
      '!border-[#e8b0b0] !bg-[#f9ebeb] ': toast.variant === 'danger',
      '!border-gold-400': toast.variant === 'warning'
    }"
  >
    <img
      *ngIf="toast.variant === 'success'"
      src="assets/images/notification/success.png"
      alt="success"
      class="h-6 w-6"
    />
    <img
      *ngIf="toast.variant === 'danger'"
      src="assets/images/notification/error.png"
      alt="danger"
      class="h-6 w-6"
    />

    {{ toast.body }}
  </div>
</ngb-toast>
