<app-client-layout>
  <ng-container *ngIf="!isLoading; else loader">
    <app-back-button></app-back-button>

    <section class="box mt-5 grid grid-rows-1 gap-3">
      <div class="flex items-center justify-between text-sm">
        <span>{{ "Transaction.Type" | translate }}</span>
        <span>{{ txDto.type }}</span>
      </div>
      <hr class="divider" />
      <div class="flex items-center justify-between text-sm">
        <span>{{ "Transaction.Init_date" | translate }}</span>
        <span>{{ txDate }}</span>
      </div>
      <hr class="divider" />
      <div class="flex items-center justify-between text-sm">
        <span>{{ "Transaction.Status" | translate }}</span>
        <span>{{ txStatus | translate }}</span>
      </div>
      <hr class="divider" />
      <div class="flex items-center justify-between text-sm">
        <span>{{ "Transaction.Usdt_amount" | translate }}</span>
        <span>{{ txDto.amount }} {{ tokenName }}</span>
      </div>
      <hr class="divider" />
      <div class="flex items-center justify-between text-sm">
        <span>{{ "Transaction.Commission" | translate }}</span>
        <span>{{ txFee }} TRX</span>
      </div>
      <hr class="divider" />
      <div class="flex items-center justify-between text-sm">
        <span>{{ "Transaction.Hash" | translate }}</span>
        <span class="flex items-center max-md:max-w-[9.375rem]">
          <a [href]="'https://tronscan.io/#/transaction/' + txDto.hash" class="truncate" target="_blank">{{
            txDto.hash
          }}</a>
          <app-copy-btn [value]="txDto.hash" *ngIf="txDto.hash != ''"> </app-copy-btn>
        </span>
      </div>
      <hr class="divider" />
      <div class="flex items-center justify-between text-sm">
        <span>{{ "Transaction.Receiver_address" | translate }}</span>
        <span class="flex items-center">
          <a
            [href]="'https://tronscan.io/#/address/' + txDto.to"
            class="truncate max-md:max-w-[9.375rem]"
            target="_blank"
            >{{ txDto.to }}</a
          >
          <app-copy-btn [value]="txDto.to"></app-copy-btn>
        </span>
      </div>
      <hr class="divider" />
      <div class="flex items-center justify-between text-sm">
        <span>{{ "Transaction.Sender_address" | translate }}</span>
        <span class="flex items-center">
          <a
            [href]="'https://tronscan.io/#/address/' + txDto.from"
            class="truncate max-md:max-w-[9.375rem]"
            target="_blank"
            >{{ txDto.from }}</a
          >
          <app-copy-btn [value]="txDto.from"></app-copy-btn>
        </span>
      </div>
      <hr class="divider" />
      <a
        *ngIf="txDto.hash != ''"
        [href]="explorerUrl"
        target="_blank"
        rel="noreferrer"
        class="flex items-center space-x-3 justify-self-end"
      >
        <img class="w-[6.25rem]" src="assets/icons/tron-logo.png" alt="Tronscan" />
        <img class="w-4" src="assets/icons/link.svg" alt="Tronscan" />
      </a>
    </section>
  </ng-container>

  <ng-template #loader>
    <app-loader></app-loader>
  </ng-template>
</app-client-layout>
