<div class="block">
  <div
    *ngFor="let item of faqList; index as i"
    class="mb-5 cursor-pointer rounded-2xl border border-[#323232] bg-[#141414] p-5 last:mb-0"
    (click)="toggleAccordion(i)"
  >
    <div class="flex flex-col gap-y-3 md:flex-row md:items-center md:gap-x-13 md:gap-y-0">
      <!-- <span class="w-[3.625rem] shrink-0 text-5xl font-bold opacity-50">
        {{ (i + 1).toString().padStart(2, "0") }}
      </span> -->

      <div class="flex grow items-center gap-x-4">
        <span class="flex-1 text-left text-xl font-medium">{{ item.question }}</span>
        <div class="ml-auto flex h-6 w-6 items-center justify-center rounded-full text-white transition-all">
          <span class="text-3xl leading-none">
            {{ item.opened ? "-" : "+" }}
          </span>
        </div>
      </div>
    </div>

    <div
      [@openClose]="item.opened"
      class="cursor-text overflow-hidden transition-all"
      [ngClass]="{ 'mt-0': !item.opened, 'mt-5': item.opened }"
      (click)="$event.stopPropagation()"
    >
      <p class="border-t border-t-white/10 pt-5 text-text-secondary">{{ item.answer }}</p>
    </div>
  </div>
</div>
