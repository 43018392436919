import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import {
  DepositControlPanelDto,
  UpdateDepositControlPanelDto,
} from "src/app/common/DTO/deposit-control-panel";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { PagedListDto } from "src/app/common/DTO/paged-list.dto";
import { RequestOnDepositDto, SetVerdictForRequestOnDepositDto } from "src/app/common/DTO/request-on-deposit";
import { DepositControlPanelErrorCode } from "src/app/common/enums/deposit-control-panel-error-code";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class AmlDepositsService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async getDepositControlPanels() {
    const uri = `${this._envService.serverUrl}${UrnConstants.DepositControlPanels}`;
    const result = new HttpResultDto<DepositControlPanelErrorCode, DepositControlPanelDto>(false);

    try {
      const res = (await firstValueFrom(
        this._http.get(uri, this._baseReqOpts)
      )) as ApiResponseDto<DepositControlPanelDto>;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async updateDepositControlPanel(dto: UpdateDepositControlPanelDto) {
    const uri = `${this._envService.serverUrl}${UrnConstants.DepositControlPanels}`;
    const result = new HttpResultDto<DepositControlPanelErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.put(uri, dto, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getAllRequestsOnDeposit(page: number, size: number) {
    const query = `?index=${page}&size=${size}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.RequestOnDepositsAll}${query}`;
    const result = new HttpResultDto<DepositControlPanelErrorCode, PagedListDto<RequestOnDepositDto>>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri, this._baseReqOpts))) as ApiResponseDto<
        PagedListDto<RequestOnDepositDto>
      >;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async setVerdictForRequestOnDeposit(dto: SetVerdictForRequestOnDepositDto) {
    const uri = `${this._envService.serverUrl}${UrnConstants.RequestOnDepositsVerdict}`;
    const result = new HttpResultDto<DepositControlPanelErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.post(uri, dto, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): DepositControlPanelErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return DepositControlPanelErrorCode.InternalError;
      case Constants.Unauthorized:
        return DepositControlPanelErrorCode.Unauthorized;
      default:
        return DepositControlPanelErrorCode.InternalError;
    }
  }
}
