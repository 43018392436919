type TonEventType = "TonTransfer" | "JettonTransfer";

export class TonApiEventDto {
  event_id = "";
  account = {
    address: "",
    is_scam: false,
    is_wallet: true,
  };
  timestamp = 0;
  actions = [
    {
      type: "JettonTransfer" as TonEventType,
      status: "ok",
      JettonTransfer: new JettonTransferDto(),
      TonTransfer: new TonTransferDto(),
      simple_preview: {
        name: "Jetton Transfer",
        description: "",
        value: "",
        value_image:
          "https://cache.tonapi.io/imgproxy/T3PB4s7oprNVaJkwqbGg54nexKE0zzKhcrPv8jcWYzU/rs:fill:200:200:1/g:no/aHR0cHM6Ly90ZXRoZXIudG8vaW1hZ2VzL2xvZ29DaXJjbGUucG5n.webp",
        accounts: [
          {
            address: "",
            is_scam: false,
            is_wallet: true,
          },
          {
            address: "",
            name: "",
            is_scam: false,
            is_wallet: false,
          },
        ],
      },
      base_transactions: [""],
    },
  ];
  is_scam = false;
  lt = 0;
  in_progress = false;
  extra = 0;
}

class JettonTransferDto {
  sender = {
    address: "",
    is_scam: false,
    is_wallet: true,
  };
  recipient = {
    address: "",
    is_scam: false,
    is_wallet: true,
  };
  senders_wallet = "";
  recipients_wallet = "";
  amount = "0" as `${number}`;
  jetton = {
    address: "",
    name: "Tether USD",
    symbol: "USD₮",
    decimals: 0,
    image: "",
    verification: "whitelist",
  };
}

class TonTransferDto {
  sender = {
    address: "",
    is_scam: false,
    is_wallet: true,
  };
  recipient = {
    address: "",
    is_scam: false,
    is_wallet: true,
  };
  amount = "0" as `${number}`;
}
