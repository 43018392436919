<div class="relative w-[32rem] max-md:w-full">
  <div class="flex items-center p-3 max-md:px-0">
    <button *ngIf="step === 'otp'" class="btn-flat mr-3" (click)="onBack()">
      <app-svg-icon name="actions/back" class="h-8 w-8" />
    </button>
    <h2 class="title">
      <ng-container *ngIf="step === 'input'">
        {{ "Login.Enter" | translate }}
      </ng-container>
      <ng-container *ngIf="step === 'otp'">
        {{ "Login.Enter_code" | translate }}
      </ng-container>
    </h2>
    <button (click)="onClose()" class="btn-flat ml-auto">
      <app-svg-icon name="close" class="h-6 w-6" />
    </button>
  </div>

  <ng-container [ngSwitch]="step">
    <ng-template ngSwitchCase="input">
      <form
        class="mt-6 flex flex-col gap-y-4 max-md:gap-y-3"
        [formGroup]="inputForm"
        (ngSubmit)="submitCredentials()"
      >
        <app-input
          id="phoneNumber"
          type="phone"
          formControlName="phoneNumber"
          [title]="'Login.Phone_number' | translate"
          placeholder="11 222 33 44"
          mask="00 000 00 00"
          [errorMsg]="phoneNumberError"
          [autofocus]="true"
        />

        <app-input
          id="psw"
          type="password"
          formControlName="psw"
          [title]="'Login.Password' | translate"
          placeholder="********"
          [errorMsg]="pswError"
          [withPasswordToggle]="true"
        />

        <p *ngIf="businessError" class="text-sm font-medium text-red-500 dark:text-red-200">
          {{ businessError }}
        </p>

        <button
          type="submit"
          class="btn-primary btn-large w-full"
          [disabled]="inputForm.invalid || isPending"
        >
          {{ "Login.Enter" | translate }}
        </button>
      </form>
    </ng-template>

    <ng-template ngSwitchCase="otp">
      <p class="mt-4 text-center text-text-secondary max-md:mt-3 max-md:text-sm">
        {{ "Login.Phone_code_sent" | translate }}<br />+998{{ phoneNumber }}
      </p>

      <form [formGroup]="otpForm" (ngSubmit)="submitOtp()" class="mt-8 max-md:mt-6">
        <app-otp-input
          formControlName="code"
          [initialTimerValue]="otpTimer"
          [errorMsg]="codeError || businessError"
          (resendCode)="sendConfirmCode()"
          (otpComplete)="submitOtp()"
        />

        <re-captcha
          *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
          (resolved)="captchaResolved($event)"
        ></re-captcha>

        <button
          type="submit"
          class="btn-primary btn-large mt-4 w-full max-md:mt-3"
          [disabled]="otpForm.invalid || isPending"
        >
          {{ "Login.Enter" | translate }}
        </button>
      </form>
    </ng-template>
  </ng-container>

  <div *ngIf="step === 'input'" class="mt-4 space-y-4 max-md:mt-3 max-md:space-y-2">
    <button class="btn-text mx-auto" (click)="showSignUpModal()">
      {{ "Login.Sign_up" | translate }}
    </button>
    <button class="btn-text mx-auto" (click)="showResetPasswordModal()">
      {{ "Login.Forgot_password" | translate }}
    </button>
  </div>
</div>
