<div class="relative overflow-hidden bg-landingBg text-gray-25">
  <app-header [transparent]="true" [withAuthState]="false" />

  <!-- Shine effect -->
  <div
    class="pointer-events-none absolute right-[-5rem] top-[-2rem] z-[1] w-[39.5rem] max-md:right-[-14rem] max-md:top-[2rem] max-md:w-[27rem] max-md:-rotate-6"
  >
    <img src="assets/images/landing/hero/hero-effect-desktop.png" alt="" class="w-full max-md:hidden" />
    <img src="assets/images/landing/hero/hero-effect-mobile.png" alt="" class="w-full md:hidden" />
  </div>

  <main>
    <!-- HERO -->
    <section
      class="relative bg-landing bg-cover bg-center px-5 pt-6 max-md:bg-landingMobile md:px-0 md:pt-16"
    >
      <app-carousel-indicator
        [slideIds]="slideIds"
        [activeSlideId]="activeSlideId"
        class="flex justify-center gap-x-2"
        (select)="handleSelectSlide($event)"
      />

      <div class="mt-6 overflow-hidden max-md:mt-5">
        <div
          class="flex w-max gap-x-12 transition-transform duration-300 md:gap-x-[21.5rem] md:px-[10.75rem]"
          [style.transform]="slidesTranslateStyle"
        >
          <app-carousel-slide
            #carouselSlide
            *ngFor="let slide of slides"
            [slide]="slide"
            (buttonClick)="openSignUpModal()"
          />
        </div>
      </div>

      <button
        class="btn-primary btn-medium mx-auto mt-12 w-fit px-16 max-md:mt-5"
        (click)="openSignUpModal()"
      >
        {{ "Login.Sign_up" | translate }}
      </button>

      <div class="relative z-[2] mx-auto mt-5 w-80 max-md:mt-12 md:w-[79.375rem]">
        <img
          [src]="
            language === 'uz'
              ? 'assets/images/landing/hero/hero-screen-desktop-uz.png'
              : 'assets/images/landing/hero/hero-screen-desktop-ru.png'
          "
          alt=""
          class="relative z-10 w-full max-md:hidden"
        />
        <img
          [src]="
            language === 'uz'
              ? 'assets/images/landing/hero/hero-screen-mobile-uz.png'
              : 'assets/images/landing/hero/hero-screen-mobile-ru.png'
          "
          alt=""
          class="relative z-10 w-full md:hidden"
        />
        <!-- Side effect -->
        <img
          src="assets/images/landing/hero/hero-screen-accent-desktop.png"
          alt=""
          class="pointer-events-none absolute right-[-9rem] top-[-11rem] z-0 w-[55rem] max-md:hidden"
        />
        <img
          src="assets/images/landing/hero/hero-screen-accent-mobile.png"
          alt=""
          class="pointer-events-none absolute right-[-9.4rem] top-[-6rem] z-0 w-[18.75rem] md:hidden"
        />
      </div>

      <!-- Bottom gradient -->
      <div
        class="pointer-events-none absolute bottom-0 left-0 right-0 z-10 h-14 bg-gradient-to-t from-landingBg to-transparent max-md:h-36"
      ></div>
    </section>

    <!-- CRYPTO CURRENCIES -->
    <section
      class="flex flex-col items-center gap-y-12 py-12 max-md:pb-8 md:flex-row md:justify-center md:gap-x-20 md:gap-y-0 md:px-[7.5rem] md:py-12"
    >
      <h2 class="text-lg font-semibold max-md:text-center max-md:text-2xl">
        {{ "Landing.Supported" | translate }} <br />
        {{ "Landing.Cryptocurrencies" | translate }}
      </h2>

      <div class="relative w-full overflow-hidden">
        <div class="flex w-max items-center gap-x-8 max-md:animate-marquee md:w-full md:justify-between">
          <div *ngFor="let item of supportedCurrencies" class="flex items-center gap-x-2 whitespace-nowrap">
            <app-currency-icon [currency]="item" />
            <span class="text-lg">{{ item | currencyName }}</span>
          </div>
          <!-- Duplicate items for seamless looping -->
          <div
            *ngFor="let item of supportedCurrencies"
            aria-hidden="true"
            class="flex items-center gap-x-2 whitespace-nowrap md:hidden"
          >
            <app-currency-icon [currency]="item" />
            <span class="text-lg">{{ item | currencyName }}</span>
          </div>
        </div>
      </div>
    </section>

    <!-- CALCULATOR -->
    <section class="px-4 py-12 md:px-28 md:py-16">
      <div class="calculator-border rounded-3xl bg-[#111111] p-8">
        <div class="flex flex-col gap-y-4 text-center">
          <h2 class="text-3xl max-md:text-2xl">{{ "Landing.Calculator.Hero_title" | translate }}</h2>
          <p class="text-xl text-text-secondary max-md:text-lg">
            {{ "Landing.Calculator.Subtitle" | translate }}
          </p>
        </div>

        <div class="mt-8 flex flex-col gap-4 md:mt-12 md:flex-row md:items-center">
          <app-currency-calculator class="md:grow" />
          <button
            class="btn-primary btn-large relative w-full md:top-[0.125rem] md:w-[17rem] md:py-[1rem]"
            (click)="openSignInModal()"
          >
            {{ "Swap.Swap" | translate }}
          </button>
        </div>

        <p class="mt-6 text-text-secondary max-md:text-sm">
          {{ "Landing.Calculator.Note" | translate }}
        </p>
      </div>
    </section>

    <!-- HOW IT WORKS -->
    <section class="relative py-12 max-md:pt-6 md:px-28">
      <div class="mx-auto">
        <div class="flex flex-col items-center gap-y-2">
          <h2 class="text-5xl max-md:text-2xl">{{ "Landing.HIW.Title" | translate }}</h2>
          <p class="text-2xl text-text-secondary max-md:text-base">
            {{ "Landing.HIW.Subtitle" | translate }}
          </p>
        </div>

        <div
          class="mt-8 grid grid-cols-3 justify-between gap-x-16 max-md:grid-cols-1 max-md:gap-y-12 max-md:px-5 md:mt-12"
        >
          <div>
            <div class="mx-auto mb-6 h-48 w-48 md:mb-8 md:h-60 md:w-60">
              <img src="assets/images/landing/instructions/step-1.png" alt="" class="w-full" />
            </div>
            <div class="space-y-3 text-center">
              <h3 class="text-2xl max-md:text-xl">{{ "Landing.HIW.Step_1" | translate }}</h3>
              <p class="text-text-secondary max-md:text-sm md:whitespace-pre-line">
                {{ "Landing.HIW.Step_1_text" | translate }}
              </p>
            </div>
          </div>

          <div>
            <div class="mx-auto mb-6 h-48 w-48 md:mb-8 md:h-60 md:w-60">
              <img src="assets/images/landing/instructions/step-2.png" alt="" class="w-full" />
            </div>
            <div class="space-y-3 text-center">
              <h3 class="text-2xl max-md:text-xl">{{ "Landing.HIW.Step_2" | translate }}</h3>
              <p class="text-text-secondary max-md:text-sm md:whitespace-pre-line">
                {{ "Landing.HIW.Step_2_text" | translate }}
              </p>
            </div>
          </div>

          <div>
            <div class="mx-auto mb-6 h-48 w-48 md:mb-8 md:h-60 md:w-60">
              <img src="assets/images/landing/instructions/step-3.png" alt="" class="w-full" />
            </div>
            <div class="space-y-3 text-center">
              <h3 class="text-2xl max-md:text-xl">{{ "Landing.HIW.Step_3" | translate }}</h3>
              <p class="text-text-secondary max-md:text-sm md:whitespace-pre-line">
                {{ "Landing.HIW.Step_3_text" | translate }}
              </p>
            </div>
          </div>
        </div>

        <!-- Shine effect -->
        <div
          class="pointer-events-none absolute right-[-20rem] top-[6.75rem] h-[20.875rem] w-[52.125rem] skew-x-12 bg-green-400/10 blur-3xl max-md:hidden"
        ></div>
      </div>
    </section>

    <!-- FAQ -->
    <section
      class="relative flex flex-col items-start px-6 pb-18 pt-6 md:flex-row md:gap-x-6 md:px-40 md:pb-20 md:pt-16"
    >
      <h2
        class="w-full max-w-[29.25rem] shrink-0 text-[2.75rem] font-medium leading-tight max-md:mb-8 max-md:text-center max-md:text-2xl"
      >
        {{ "Landing.Faq" | translate }}
      </h2>
      <app-faq-accordion />

      <!-- Shine effect -->
      <div
        class="pointer-events-none absolute right-[-20rem] top-[14rem] h-[17.625rem] w-[53.25rem] skew-x-12 bg-green-400/10 blur-3xl max-md:hidden"
      ></div>
    </section>
  </main>

  <app-footer [isLarge]="true" />
</div>
