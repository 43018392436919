import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Network } from "src/app/common/enums/network.enum";
import { getNetworkName } from "src/app/common/utils/network-name-helper";

@Component({
  selector: "app-network-dropdown",
  templateUrl: "./network-dropdown.component.html",
  styleUrls: ["./network-dropdown.component.css"],
})
export class NetworkDropdownComponent {
  @Input() items: Network[] = [];
  @Input() disabled = false;
  @Input() isFullWidthList = false;

  @Output() onSelect = new EventEmitter<Network>();

  public getNetworkName = getNetworkName;

  pickItem(item: Network) {
    if (!this.disabled) {
      this.onSelect.emit(item);
    }
  }
}
