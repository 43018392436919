import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { Network } from "src/app/common/enums/network.enum";

export function getCurrencyNetwork(currency?: CryptoSymbol | null): Network | null {
  switch (currency) {
    case CryptoSymbol.Trx:
    case CryptoSymbol.Usdt:
      return Network.Tron;
    case CryptoSymbol.Matic:
    case CryptoSymbol.PolygonUsdt:
      return Network.Polygon;
    case CryptoSymbol.Ton:
    case CryptoSymbol.TonUsdt:
    case CryptoSymbol.Not:
      return Network.Ton;
    case CryptoSymbol.Bitcoin:
      return Network.Bitcoin;
    case CryptoSymbol.AbstractUsdt:
      return null;
    default:
      return null;
  }
}
