import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SystemScheduleDto, UpdateSystemScheduleDto } from "src/app/common/DTO/system-schedule";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { SchedulePanelService } from "src/app/services/schedule-panel.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-schedule-block",
  templateUrl: "./schedule-block.component.html",
  styleUrls: ["./schedule-block.component.css"],
})
export class ScheduleBlockComponent implements OnInit {
  @Input() public selectedCurrency: CryptoSymbol = CryptoSymbol.Trx;

  public systemSchedule: SystemScheduleDto[] = [];
  public hasInteractionPermissions: boolean = false;

  public editScheduleForm: FormGroup;
  public isEditing = false;
  public isPending = false;

  constructor(
    private _schedulePanelService: SchedulePanelService,
    private _localStorage: LocalStorageService,
    private _modalService: NgbModal,
    private _translateService: TranslateService,
    private _toastService: ToastService
  ) {
    this.editScheduleForm = new FormGroup({
      collectFundsInterval: new FormControl(null, [Validators.required, Validators.min(0)]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.TransactionInteraction
    );
    await this.getSystemSchedule();
  }

  public get currencySchedule() {
    return this.systemSchedule.find(s => s.currency === this.selectedCurrency);
  }

  public get parsedInterval() {
    if (!this.currencySchedule?.collectFundsInterval) {
      return "-";
    }

    const [firstPart, secondPart] = this.currencySchedule?.collectFundsInterval.split(".") ?? [];
    if (!secondPart) {
      return firstPart;
    }

    return `${firstPart}д ${secondPart}`;
  }

  public toggleEditing() {
    if (this.isEditing) {
      this.handleSaveSchedule();
    } else {
      this.handleSetIntervalToForm();
      this.isEditing = true;
    }
  }

  public handleSetIntervalToForm() {
    let totalSeconds = 0;

    const [firstPart, secondPart] = this.currencySchedule?.collectFundsInterval.split(".") ?? [];
    let timePart;

    if (secondPart) {
      totalSeconds += +firstPart * 86400; // + days
      timePart = secondPart;
    } else {
      timePart = firstPart;
    }

    const [hours, minutes, seconds] = timePart?.split(":").map(Number) ?? [];
    totalSeconds += hours * 3600 + minutes * 60 + seconds;

    this.editScheduleForm.get("collectFundsInterval")?.setValue(totalSeconds);
  }

  public handleCloseModal() {
    this._modalService.dismissAll();
  }

  public async handleSaveSchedule() {
    this.isPending = true;

    const seconds = this.editScheduleForm.value.collectFundsInterval;
    const dto: UpdateSystemScheduleDto = {
      collectFundsIntervalInSec: seconds,
      currency: this.selectedCurrency,
    };
    const result = await this._schedulePanelService.updateSystemSchedule(dto);

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.error(errorMsg);
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.success(successMsg);
      this.getSystemSchedule();
      this.isEditing = false;
    }

    this.isPending = false;
  }

  public getFieldError(field: string) {
    const control = this.editScheduleForm.get(field);

    if (control?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (control?.hasError("min") || control?.hasError("max")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
  }

  private async getSystemSchedule() {
    const result = await this._schedulePanelService.getSystemSchedule();
    if (!result.withError) {
      this.systemSchedule = result.params ?? [];
    }
  }
}
