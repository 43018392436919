<div
  class="grid grid-cols-[25%_1fr_20%] items-center justify-between gap-x-8 px-3 py-4 max-md:grid-cols-[35%_1fr_25%] max-md:px-2 max-md:py-4 max-sm:gap-x-2"
>
  <div class="flex items-center gap-x-5 max-md:gap-x-3">
    <app-currency-icon size="sm" [currency]="balance.currency" />
    <div class="overflow-hidden">
      <p class="truncate text-base font-semibold">{{ balance.currency | currencyName }}</p>
      <p class="text-xs font-bold text-text-secondary">{{ getCurrencyNetworkName(balance.currency) }}</p>
    </div>
  </div>

  <div
    *ngIf="!isLoading; else skeleton"
    class="pointer-events-none relative h-12 w-full max-w-[21.25rem] max-md:h-auto max-md:pb-[calc(100%/5)]"
  >
    <canvas
      baseChart
      type="line"
      [data]="chartData"
      [options]="chartOptions"
      class="absolute left-0 top-0 h-full w-full"
    ></canvas>
  </div>

  <div class="text-end">
    <p class="text-base font-semibold">${{ currentRate?.usd | mfeCustom: { fiat: true } }}</p>
    <p
      class="flex items-center justify-end text-sm font-semibold"
      [ngClass]="{
        'text-green-500 dark:text-green-200': ratePercentChange > 0,
        'text-red-500 dark:text-red-200': ratePercentChange < 0
      }"
    >
      <app-svg-icon
        *ngIf="ratePercentChange !== 0"
        name="ticker-up"
        class="w-6 shrink-0"
        [ngClass]="{ 'rotate-180': ratePercentChange < 0 }"
      />
      {{ ratePercentChange | number: "1.0-2" }}%
    </p>
  </div>
</div>

<ng-template #skeleton>
  <app-skeleton class="h-12 w-full"></app-skeleton>
</ng-template>
