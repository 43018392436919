import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[appPositiveDecimals]",
})
export class PositiveDecimalsDirective {
  @Input() maxLength = 2;

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,}$/g);

  private specialKeys: Array<string> = [
    "Backspace",
    "Tab",
    "End",
    "Home",
    "ArrowLeft",
    "ArrowRight",
    "Del",
    "Delete",
    "Meta",
  ];

  @HostListener("input", ["$event"])
  onInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    // Remove leading zeros
    if (value.match(/^0\d/) && !value.includes(".")) {
      input.value = value.replace(/^0+/, "");
    }
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const input = event.target as HTMLInputElement;
    const value = input.value + event.key;

    if (value && !String(value).match(this.regex)) {
      event.preventDefault();
    }

    const floatingPart = value.split(".")?.[1] ?? "";
    if (floatingPart.length > this.maxLength) {
      event.preventDefault();
    }
  }

  @HostListener("keyup", ["$event"])
  onKeyUp(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    let shouldDispatchEvent = false;

    if (value.includes(" ")) {
      value = value.replaceAll(" ", "");
      shouldDispatchEvent = true;
    }

    if (value.includes("-")) {
      value = value.replaceAll("-", "");
      shouldDispatchEvent = true;
    }

    if (value.includes(",")) {
      value = value.replaceAll(",", ".");
      shouldDispatchEvent = true;
    }

    if (value.match(/\.+/)) {
      value = value.replaceAll(/\.+/g, ".");
      shouldDispatchEvent = true;
    }

    if (value.match(/^00+\./)) {
      value = value.replace(/^00+\./, "0.");
      shouldDispatchEvent = true;
    }

    if (value.startsWith(".")) {
      value = `0${value}`;
      shouldDispatchEvent = true;
    }

    const splittedValue = value.split(".");
    if (splittedValue.length > 2) {
      const [integerPart = "0", floatingPart = ""] = splittedValue;
      value = `${integerPart}.${floatingPart}`;
      shouldDispatchEvent = true;
    }

    const [integerPart = "0", floatingPart = ""] = value.split(".");
    if (floatingPart.length > this.maxLength) {
      value = `${integerPart}.${floatingPart.slice(0, this.maxLength)}`;
      shouldDispatchEvent = true;
    }

    if (shouldDispatchEvent) {
      input.value = value;
      const customInputEvent = new InputEvent("input");
      input.dispatchEvent(customInputEvent);
    }
  }
}
