import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ScreenSizeService {
  private isMobileSubject = new BehaviorSubject<boolean>(this.checkIsMobile());

  constructor() {
    window.addEventListener("resize", () => {
      this.isMobileSubject.next(this.checkIsMobile());
    });
  }

  private checkIsMobile(): boolean {
    return window.innerWidth < 768;
  }

  get isMobile(): boolean {
    return this.checkIsMobile();
  }

  get isMobile$(): Observable<boolean> {
    return this.isMobileSubject.asObservable();
  }
}
