import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { BalanceDto } from "src/app/common/DTO/balances/balance.dto";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { Network } from "src/app/common/enums/network.enum";
import { PlatformType } from "src/app/common/enums/platform-type.enum";
import { EventData } from "src/app/common/models/event-data";
import { Transaction } from "src/app/common/models/transaction";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { getWalletLink } from "src/app/common/utils/wallet-link-helper.util";
import { ReceiveModalComponent } from "src/app/components/receive-modal/receive-modal.component";
import { SendModalComponent } from "src/app/components/send-modal/send-modal.component";
import { SwapModalComponent } from "src/app/components/swap-modal/swap-modal.component";
import { BalancesService } from "src/app/services/balances.service";
import { EventBusService } from "src/app/services/event-bus.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { RatesService } from "src/app/services/rates.service";
import { ScreenSizeService } from "src/app/services/screen-size.service";

@Component({
  templateUrl: "./wallet.component.html",
  styleUrls: ["./wallet.component.css"],
})
export class WalletComponent implements OnInit {
  public internalError = false;
  public platformType = PlatformType;
  public rates: RateDto[] = [];
  public balances: BalanceDto[] = [];
  public isLoading = true;

  public readonly TxCount = 4;

  private trxTxs: Transaction[] = [];
  private usdtTxs: Transaction[] = [];
  private maticTxs: Transaction[] = [];
  private polygonUsdtTxs: Transaction[] = [];
  private tonTxs: Transaction[] = [];
  private tonUsdtTxs: Transaction[] = [];
  private notTxs: Transaction[] = [];
  private bitcoinTxs: Transaction[] = [];
  public isTxsLoading = true;
  public buySellTxs: CryptoTxDto[] = [];

  public currentPlatformType = PlatformType.DEPOSITARY;
  public CryptoSymbol = CryptoSymbol;
  public RouteConstants = RouteConstants;
  public isMobile = false;
  public mobileBalancesToLoad = 5;
  public isBalanceVisible = true;

  public mobileBalanceCurrency: CryptoSymbol = CryptoSymbol.AbstractUsdt;
  public readonly mobileCurrencyOptions = [
    {
      value: CryptoSymbol.AbstractUsdt,
      label: "Wallet.In_usdt",
    },
    {
      value: CryptoSymbol.Uzs,
      label: "Wallet.In_uzs",
    },
  ];

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _ratesService: RatesService,
    private readonly _modalService: NgbModal,
    private readonly _eventBusService: EventBusService,
    private readonly _balancesService: BalancesService,
    private readonly _screenSizeService: ScreenSizeService
  ) {
    this._eventBusService.handle(EventTypeConstants.UpdateBalances, async () => {
      this.balances = (await this._balancesService.getBalances()).params ?? [];
    });
    this._screenSizeService.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    });
    this._eventBusService.handle(EventTypeConstants.ToggleBalanceVisibility, (isVisible: boolean) => {
      this.isBalanceVisible = isVisible;
    });
  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.balances = (await this._balancesService.getBalances()).params ?? [];
    this.rates = (await this._ratesService.getRates()).params ?? [];
    this.currentPlatformType = await this._localStorage.getPlatformType();
    const showBalances = await this._localStorage.getBalanceVisibility();
    this._eventBusService.dispatch(new EventData(EventTypeConstants.ToggleBalanceVisibility, showBalances));
    this.isLoading = false;
  }

  public get totalUzsBalance(): number {
    let sum = 0;
    if (this.rates.length === 0 || this.balances.length === 0) {
      return sum;
    }

    for (const balance of this.balances) {
      sum += ConvertCurrencyHelper.convertToUzs(balance.availableAmount, balance.currency, this.rates);
    }

    return sum;
  }

  public get totalUsdBalance(): number | null {
    if (this.rates.length === 0 || this.balances.length === 0) {
      return null;
    }

    let sum = 0;
    for (const balance of this.balances) {
      sum += ConvertCurrencyHelper.convertToUsd(balance.availableAmount, balance.currency, this.rates);
    }
    return sum;
  }

  public get visibleBalances() {
    const balancesInStock = this.balances.filter(x => x.availableAmount > 0);
    return balancesInStock;
    // if (this.isMobile) {
    //   return balancesInStock.slice(0, this.mobileBalancesToLoad);
    // } else {
    //   return balancesInStock;
    // }
  }

  public get balancesForAssets() {
    return this.balances.slice(0, 5);
  }

  public showMoreBalances() {
    this.mobileBalancesToLoad = this.balances.length;
  }

  public get txs() {
    const groupedTxs = [
      ...this.trxTxs,
      ...this.usdtTxs,
      ...this.maticTxs,
      ...this.polygonUsdtTxs,
      ...this.tonTxs,
      ...this.tonUsdtTxs,
      ...this.notTxs,
      ...this.bitcoinTxs,
    ];
    return groupedTxs.sort((a, b) => b.timestamp - a.timestamp).slice(0, this.TxCount);
  }

  public get mobileCurrencyOptionsNames() {
    return this.mobileCurrencyOptions.map(x => x.label);
  }

  public get selectedMobileBalanceCurrency() {
    return this.mobileCurrencyOptions.find(x => x.value === this.mobileBalanceCurrency)?.label;
  }

  public onMobileCurrencyChange(currency: string) {
    this.mobileBalanceCurrency =
      this.mobileCurrencyOptions.find(x => x.label === currency)?.value ?? CryptoSymbol.AbstractUsdt;
  }

  public returnNetwork(network: Network) {
    return getNetworkName(network);
  }

  public returnWalletLink(network: Network, address: string) {
    return getWalletLink(network, address);
  }

  public openReceiveModal(preselectedBalance?: BalanceDto) {
    const modalRef = this._modalService.open(ReceiveModalComponent);
    if (preselectedBalance) {
      modalRef.componentInstance.walletBalance = preselectedBalance;
    }
  }

  public openSendModal(preselectedBalance?: BalanceDto) {
    const modalRef = this._modalService.open(SendModalComponent);
    if (preselectedBalance) {
      modalRef.componentInstance.walletBalance = preselectedBalance;
    }
  }

  public openSwapModal(preselectedBalance?: BalanceDto) {
    const modalRef = this._modalService.open(SwapModalComponent, {
      modalDialogClass: "modal-dialog_full-content",
    });
    if (preselectedBalance) {
      modalRef.componentInstance.walletBalance = preselectedBalance;
    }
  }
}
