import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Address } from "@ton/core";
import { Big } from "big.js";
import { Subject } from "rxjs";
import { BalanceDto } from "src/app/common/DTO/balances/balance.dto";
import { CheckWalletExistsDto, SendCryptoDto, WalletDto } from "src/app/common/DTO/wallets";
import { WithdrawCommissionDto } from "src/app/common/DTO/withdraw-commissions/withdraw-commission.dto";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { Network } from "src/app/common/enums/network.enum";
import { WalletErrorCode } from "src/app/common/enums/wallet-error-code.enum";
import { EventData } from "src/app/common/models/event-data";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { getNetworkInfo } from "src/app/common/utils/network-info-helper";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { TelegramMiniAppHelper } from "src/app/common/utils/telegram-mini-app-helper.util";
import { lengthEqualValidator } from "src/app/common/validators/length-equal.validator";
import { BalancesService } from "src/app/services/balances.service";
import { CommissionService } from "src/app/services/commission.service";
import { EventBusService } from "src/app/services/event-bus.service";
import { LimitPanelService } from "src/app/services/limit-panel.service";
import { PolygonService } from "src/app/services/polygon.service";
import { TonConnectService } from "src/app/services/ton-connect.service";
import { WalletService } from "src/app/services/wallet.service";
import { WithdrawCommissionsService } from "src/app/services/withdraw-commissions.service";

enum Step {
  Input,
  NetworkSelect,
  Confirm,
  Success,
}

@Component({
  selector: "app-send-modal",
  templateUrl: "./send-modal.component.html",
  styleUrls: ["./send-modal.component.css"],
})
export class SendModalComponent implements OnInit, OnDestroy {
  @Input() walletBalance: BalanceDto | null = null;

  step: Step = Step.Input;
  Step = Step;
  businessError: string | null = null;
  wallets: WalletDto[] = [];
  balances: BalanceDto[] = [];
  selectedWalletBalance = this.walletBalance;
  selectedCurrency = this.selectedWalletBalance?.currency;
  confirmationTime = 0;
  isPending = false;
  networkFee = 0;
  serviceFee = 0;
  minSendAmount = 0;
  isCalculating = false;
  Network = Network;
  CryptoSymbol = CryptoSymbol;
  isTelegramMiniApp = TelegramMiniAppHelper.isMiniApp();
  tonWalletAddress = "";
  networkSearch = "";
  isInternalTransfer = false;
  RouteConstants = RouteConstants;

  sendForm: FormGroup;

  private _timer: any;
  private polygonGasPrice = 0;
  private withdrawCommissions: WithdrawCommissionDto[] = [];
  private feeCallSubject = new Subject<void>();

  private cleanUpFunctionsList: (VoidFunction | undefined)[] = [];
  private readonly MAX_INPUT_VALUE = 100_000_000_000;

  constructor(
    private _translateService: TranslateService,
    private _walletService: WalletService,
    private _activeModal: NgbActiveModal,
    private _polygonService: PolygonService,
    private _commissionService: CommissionService,
    private _withdrawCommissionsService: WithdrawCommissionsService,
    private _tonConnectService: TonConnectService,
    private _eventBusService: EventBusService,
    private _balancesService: BalancesService,
    private _limitPanelService: LimitPanelService
  ) {
    this.sendForm = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(Number.MIN_VALUE)]),
      to: new FormControl(null, [
        Validators.required,
        lengthEqualValidator(ValidateConstants.TrxAddressLength),
        Validators.pattern(ValidateConstants.TrxAddressPattern),
      ]),
    });

    // Call the fee calculation function when the amount changes
    this.sendForm.controls["amount"].valueChanges.subscribe(async () => {
      this.calculateServiceFee();
      // if (this.sendForm.valid) {
      //   await this.calculateNetworkFee();
      // }
    });

    // Check if the wallet is internal
    this.sendForm.controls["to"].valueChanges.subscribe(async to => {
      if (this.sendForm.controls["to"].valid) {
        this.isCalculating = true;
        await this.checkWalletIsInternal(to, this.selectedNetwork);
        this.calculateServiceFee();
        this.isCalculating = false;
      }
    });

    // Call the fee calculation function every 10 seconds after the last call
    // this.feeCallSubject.pipe(debounceTime(10000)).subscribe(async () => {
    //   await this.getGasPrice();
    //   await this.calculateNetworkFee();
    // });

    this.tonWalletAddress = this._tonConnectService?.tonConnectUi?.account?.address || "";
    const tonStatusUnsubscribe = this._tonConnectService?.tonConnectUi?.onStatusChange?.(wallet => {
      this.tonWalletAddress = wallet?.account?.address || "";
    });
    this.cleanUpFunctionsList.push(tonStatusUnsubscribe);
  }

  async ngOnInit() {
    const wallets = await this._walletService.getMy();
    this.wallets = wallets?.params || [];
    this.balances = (await this._balancesService.getBalances()).params ?? [];
    if (this.walletBalance) {
      if (this.walletBalance.currency === CryptoSymbol.AbstractUsdt) {
        const balance = { ...this.walletBalance, wallet: { address: "", network: Network.Tron } };
        this.selectedWalletBalance = balance;
      } else {
        this.selectedWalletBalance = this.walletBalance;
      }
    } else {
      this.selectedWalletBalance = this.balances[1];
    }
    this.selectedCurrency = this.selectedWalletBalance?.currency;
    await this.getGasPrice();
    await this.getWithdrawCommissions();
    await this.getMinSendAmount();
    // await this.calculateNetworkFee();
    this.calculateServiceFee();
    this.updateAmountValidator();
    this.updateWalletValidator();
  }

  public getNetworkInfo = getNetworkInfo;

  onBack() {
    if (this.step === Step.NetworkSelect) {
      this.step = Step.Input;
    }
    if (this.step === Step.Confirm) {
      this.step = Step.Input;
    }
    clearInterval(this._timer);
    this.businessError = null;
  }

  onClose() {
    this._activeModal.close();
  }

  handleAmountChange(event: any) {
    let { value } = event.target;

    if (Number(value) > this.MAX_INPUT_VALUE) {
      value = value.toString().slice(0, this.MAX_INPUT_VALUE.toString().length);
      if (value !== event.target.value) {
        event.target.value = value;
        event.target.dispatchEvent(new Event("input"));
      }
    }
  }

  async submitInput() {
    if (this.sendForm.valid) {
      this.step = Step.Confirm;
    }
  }

  getUsdtCurrencyByNetwork(network: Network) {
    switch (network) {
      case Network.Tron:
        return CryptoSymbol.Usdt;
      case Network.Polygon:
        return CryptoSymbol.PolygonUsdt;
      case Network.Ton:
        return CryptoSymbol.TonUsdt;
      default:
        return CryptoSymbol.AbstractUsdt;
    }
  }

  async onConfirm() {
    this.isPending = true;
    this.businessError = null;
    const balance = this.selectedWalletBalance!;
    const amount = Number(this.sendForm.controls["amount"].value);
    const to = this.sendForm.controls["to"].value;
    let currency = balance.currency;

    if (balance.currency === CryptoSymbol.AbstractUsdt) {
      currency = this.getUsdtCurrencyByNetwork(balance.wallet?.network!);
    }

    const dto: SendCryptoDto = {
      to,
      amount,
      currency,
    };

    if (balance.currency === CryptoSymbol.Bitcoin) {
      const decimals = 10 ** 8;
      const amountWithDecimals = Number(dto.amount) * decimals;
      dto.amount = Math.floor(amountWithDecimals);
    }

    const response = await this._walletService.send(dto);
    this.isPending = false;

    if (response.withError) {
      switch (response.errorCode) {
        case WalletErrorCode.SendLimitReached:
          this.businessError = this._translateService.instant("Send.Send_limit_reached_error");
          break;
        case WalletErrorCode.BlockchainError:
          this.businessError = this._translateService.instant("Send.Blockchain_error");
          break;
        case WalletErrorCode.SendDisabled:
          this.businessError = this._translateService.instant("Send.Send_disabled_error");
          break;
        case WalletErrorCode.InvalidAddress:
          this.businessError = this._translateService.instant("Send.Invalid_address_error");
          break;
        case WalletErrorCode.NotEnoughCurrency:
          this.businessError = this._translateService.instant("Send.Not_enough_currency_error");
          break;
        case WalletErrorCode.LessThanMinSendAmount:
          this.businessError = this._translateService.instant("Send.Less_than_min_send_amount_error");
          break;
        default:
          this.businessError = this._translateService.instant("Common.Unknown_error");
          break;
      }
      return;
    } else {
      this.step = Step.Success;
      this.businessError = null;
      this._eventBusService.dispatch(new EventData(EventTypeConstants.UpdateBalances));
    }
  }

  selectNetwork(network: Network): void {
    let balance: BalanceDto | null;
    // For preselected abstract usdt balance, we need to set network to selected network
    if (this.walletBalance?.currency === CryptoSymbol.AbstractUsdt) {
      balance = { ...this.walletBalance, wallet: { address: "", network: network } };
    } else {
      balance = this.balances.find(b => b.wallet?.network === network) ?? null;
    }
    if (!balance) {
      return;
    }
    this.selectedWalletBalance = balance;
    this.selectedCurrency = this.selectedWalletBalance?.currency;
    this.updateWalletValidator();
    this.updateAmountValidator();
    this.step = Step.Input;
    this.networkSearch = "";
  }

  selectWalletBalanceByCurrency(currency: CryptoSymbol) {
    this.selectedCurrency = currency;

    const currencyMapping: any = {
      [CryptoSymbol.Usdt]: CryptoSymbol.AbstractUsdt,
      [CryptoSymbol.TonUsdt]: CryptoSymbol.AbstractUsdt,
      [CryptoSymbol.PolygonUsdt]: CryptoSymbol.AbstractUsdt,
    };

    const targetBalanceCurrency = currencyMapping[currency] || currency;
    const balance = this.balances.find(b => b.currency === targetBalanceCurrency) ?? null;
    // Set network to abstract usdt as selected wallet balance
    if (balance && targetBalanceCurrency === CryptoSymbol.AbstractUsdt) {
      balance.wallet = { address: "", network: this.selectedWalletBalance!.wallet!.network! };
    }
    this.selectedWalletBalance = balance;
    this.updateWalletValidator();
    this.updateAmountValidator();
  }

  get networks() {
    let nets = this.wallets.map(w => w.network);
    if (this.walletBalance?.currency === CryptoSymbol.AbstractUsdt) {
      nets = nets.filter(n => n !== Network.Bitcoin);
    }
    if (this.networkSearch) {
      nets = nets.filter(n => getNetworkName(n).toLowerCase().includes(this.networkSearch.toLowerCase()));
    }
    return nets;
  }

  get currencies() {
    const currencies = this.balances
      .filter(
        balance =>
          balance.wallet?.network === this.selectedNetwork && balance.currency !== CryptoSymbol.AbstractUsdt
      )
      .map(balance => balance.currency);

    const networkToUsdtMap = new Map<Network, CryptoSymbol>([
      [Network.Tron, CryptoSymbol.Usdt],
      [Network.Ton, CryptoSymbol.TonUsdt],
      [Network.Polygon, CryptoSymbol.PolygonUsdt],
    ]);

    const selectedNetwork = this.selectedWalletBalance?.wallet?.network;

    if (selectedNetwork && networkToUsdtMap.has(selectedNetwork)) {
      currencies.push(networkToUsdtMap.get(selectedNetwork)!);
    }

    return currencies;
  }

  public get selectedCurrencyName() {
    if (this.selectedCurrency) {
      return getCurrencyName(this.selectedCurrency);
    } else {
      return "-";
    }
  }

  public get nativeCurrencyName() {
    switch (this.selectedNetwork) {
      case Network.Tron:
        return "TRX";
      case Network.Polygon:
        return "MATIC";
      case Network.Ton:
        return "TON";
      case Network.Bitcoin:
        return "BTC";
      default:
        return "";
    }
  }

  public get minimumAmount() {
    switch (this.selectedCurrency) {
      case CryptoSymbol.Trx:
        return 5;
      case CryptoSymbol.Usdt:
        return 50;
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
      case CryptoSymbol.Ton:
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
        return 1;
      case CryptoSymbol.Bitcoin:
        return 0.00005;
      default:
        return 50;
    }
  }

  public get selectedNetwork() {
    return this.selectedWalletBalance?.wallet?.network;
  }

  public get networkName() {
    if (this.balances.length === 0) {
      return "";
    }
    return getNetworkName(this.selectedNetwork!);
  }

  public get receiveAmount() {
    return Number(this.sendForm.controls["amount"].value || 0);
  }

  public get totalAmount() {
    return new Big(this.receiveAmount).plus(this.serviceFee).toNumber();
  }

  public get amountError(): string | null {
    const amount = this.sendForm.controls["amount"];
    const balanceCurrency = this.selectedWalletBalance?.currency;

    if (amount.value == null) {
      return null;
    }

    if (amount?.hasError("required")) {
      return this._translateService.instant("Send.Amount_required_error");
    }

    if (amount?.hasError("min")) {
      return this._translateService.instant("Send.Amount_min_error");
    }

    if (amount?.hasError("max")) {
      return this._translateService.instant("Send.Amount_insuf_error");
    }

    const nativeBalance = this.balances.find(b => b.currency === balanceCurrency)?.availableAmount ?? 0;
    if (this.totalAmount > nativeBalance) {
      return this._translateService.instant("Send.Amount_commission_error");
    }

    if (amount.value < this.minSendAmount) {
      return this._translateService.instant("Send.Less_than_min_send_amount_error");
    }

    return null;
  }

  public get toError(): string | null {
    const to = this.sendForm.controls["to"];
    if (to?.value == null) {
      return null;
    }
    if (to?.hasError("required")) {
      return this._translateService.instant("Send.Receiver_required_error");
    }
    if (to?.hasError("lengthEqual")) {
      return this._translateService.instant("Send.Receiver_incorrect_error");
    }
    if (to?.hasError("pattern")) {
      return this._translateService.instant("Send.Receiver_incorrect_error");
    }
    return null;
  }

  public useTonWalletAddressAsReceiver() {
    if (!this.tonWalletAddress) {
      return;
    }
    const userFriendlyTonAddress = Address.parseRaw(this.tonWalletAddress).toString();
    this.sendForm.controls["to"].setValue(userFriendlyTonAddress);
    this.sendForm.controls["to"].updateValueAndValidity();
  }

  private async calculateNetworkFee() {
    this.isCalculating = true;
    const currency = this.selectedWalletBalance?.currency!;
    this.networkFee = await this._commissionService.calculateNetworkCommission({
      currency,
      gasPrices: { polygonGasPrice: this.polygonGasPrice },
      amount: this.sendForm.controls["amount"].value,
      toAddress: this.sendForm.controls["to"].value,
      fromAddress: this.selectedWalletBalance?.wallet?.address,
    });
    this.isCalculating = false;
    this.feeCallSubject.next();
  }

  private calculateServiceFee() {
    this.isCalculating = true;
    const amount = Number(this.sendForm.controls["amount"].value);
    let targetCurrency: CryptoSymbol;

    if (this.selectedWalletBalance?.currency === CryptoSymbol.AbstractUsdt) {
      targetCurrency = this.getUsdtCurrencyByNetwork(this.selectedNetwork!);
    } else {
      targetCurrency = this.selectedWalletBalance?.currency!;
    }

    const commission = this.withdrawCommissions.find(c => c.currency === targetCurrency);

    if (commission) {
      const percent = this.isInternalTransfer ? commission.internalAmount : commission.amount;
      this.serviceFee = new Big(amount).times(percent).div(100).toNumber();
    } else {
      this.serviceFee = 0;
    }
    this.isCalculating = false;
  }

  private updateAmountValidator() {
    const amount = this.sendForm.controls["amount"];
    const balanceAmount = this.selectedWalletBalance?.availableAmount || 0;
    let maxValue = 0;

    switch (this.selectedWalletBalance?.currency) {
      case CryptoSymbol.Trx:
      case CryptoSymbol.Matic:
      case CryptoSymbol.Ton:
      case CryptoSymbol.Bitcoin:
        maxValue = balanceAmount - this.serviceFee;
        break;
      case CryptoSymbol.Usdt:
      case CryptoSymbol.PolygonUsdt:
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
      case CryptoSymbol.AbstractUsdt:
        maxValue = balanceAmount;
        break;
      default:
        break;
    }

    amount.setValidators([Validators.required, Validators.min(Number.MIN_VALUE), Validators.max(maxValue)]);
    amount.updateValueAndValidity();
  }

  private updateWalletValidator() {
    const to = this.sendForm.controls["to"];
    if (this.selectedNetwork === Network.Tron) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.TrxAddressLength),
        Validators.pattern(ValidateConstants.TrxAddressPattern),
      ]);
    } else if (this.selectedNetwork === Network.Polygon) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.PolygonAddressLength),
        Validators.pattern(ValidateConstants.PolygonAddressPattern),
      ]);
    } else if (this.selectedNetwork === Network.Ton) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.TonAddressLength),
        Validators.pattern(ValidateConstants.TonAddressPattern),
      ]);
    } else if (this.selectedNetwork === Network.Bitcoin) {
      to.setValidators([
        Validators.required,
        lengthEqualValidator(ValidateConstants.BitcoinAddressLength),
        Validators.pattern(ValidateConstants.BitcoinAddressPattern),
      ]);
    }
    to.updateValueAndValidity();
  }

  private async getGasPrice() {
    const polygonRes = await this._polygonService.getGasPrice();
    this.polygonGasPrice = polygonRes?.SafeGasPrice ? +polygonRes.SafeGasPrice : 0;
  }

  private async getWithdrawCommissions() {
    const res = await this._withdrawCommissionsService.getAllWithdrawCommissions();
    this.withdrawCommissions = res.params ?? [];
  }

  private async getMinSendAmount() {
    const res = await this._limitPanelService.getLimits();
    if (res.withError) {
      return;
    }
    const currencyLimits = res.params?.find(l => l.currency === this.selectedCurrency);
    this.minSendAmount = currencyLimits?.minWithdrawalPerTx ?? 0;
  }

  private async checkWalletIsInternal(address?: string, network?: Network) {
    if (!address || !network) {
      return;
    }

    const dto: CheckWalletExistsDto = {
      address,
      network,
    };
    const res = await this._walletService.checkWalletExists(dto);
    this.isInternalTransfer = res.params ?? false;
  }

  ngOnDestroy() {
    clearInterval(this._timer);
    this.feeCallSubject?.unsubscribe();
    this.cleanUpFunctionsList.map(cleanUp => cleanUp?.());
  }
}
