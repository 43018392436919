import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { BalanceDto } from "src/app/common/DTO/balances/balance.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";

@Component({
  selector: "app-balance-card",
  templateUrl: "./balance-card.component.html",
  styleUrls: ["./balance-card.component.css"],
})
export class BalanceCardComponent implements OnChanges {
  @Input() balanceItem: BalanceDto = new BalanceDto();
  @Input() rates: RateDto[] = [];
  @Input() isLoading: boolean = false;
  @Input() isShort: boolean = false;
  @Input() withSelect: boolean = false;
  @Input() selected: boolean = false;
  @Input() showRate: boolean = false;

  @Output() onSelect = new EventEmitter<BalanceDto>();

  public CryptoSymbol = CryptoSymbol;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["rates"]) {
      this.rates = changes["rates"].currentValue;
    }
  }

  getCurrencyCaption(): string {
    switch (this.balanceItem.currency) {
      case CryptoSymbol.Usdt:
      case CryptoSymbol.PolygonUsdt:
      case CryptoSymbol.TonUsdt:
        return "USDT";
      case CryptoSymbol.Trx:
        return "Tron";
      case CryptoSymbol.Matic:
        return "Polygon";
      case CryptoSymbol.Ton:
        return "Ton";
      case CryptoSymbol.Not:
        return "Not";
      case CryptoSymbol.Bitcoin:
        return "Bitcoin";
      default:
        return "-";
    }
  }

  public get currencyName(): string {
    return getCurrencyName(this.balanceItem.currency);
  }

  getNetworkName(): string {
    switch (this.balanceItem.currency) {
      case CryptoSymbol.Usdt:
      case CryptoSymbol.Trx:
        return "Tron";
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "Polygon";
      case CryptoSymbol.Ton:
      case CryptoSymbol.Not:
      case CryptoSymbol.TonUsdt:
        return "Ton";
      case CryptoSymbol.Bitcoin:
        return "Bitcoin";
      default:
        return "";
    }
  }

  onClickSelect() {
    this.onSelect.emit(this.balanceItem);
  }

  public get uzsValue() {
    return this.getUzsValue(this.balanceItem.availableAmount);
  }

  private getUzsValue(amount: number): number {
    if (!this.rates || this.rates.length === 0) {
      return 0;
    }

    const hasRate = this.rates.find(rate => rate.symbol === this.balanceItem.currency);
    if (!hasRate) {
      return 0;
    }

    let calculated = 0;
    calculated = ConvertCurrencyHelper.convertToUzs(amount, this.balanceItem.currency, this.rates);
    return calculated;
  }
}
