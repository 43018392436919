<div class="absolute top-8">
  <h1 class="title font-semibold">AML</h1>
</div>

<section class="mt-6">
  <div class="box">
    <div class="flex items-center justify-between">
      <h3 class="altitle">{{ "Admin.Aml.Settings" | translate }}</h3>
      <button class="btn-flat" (click)="toggleEditing('AutoBlock')" [disabled]="isPending">
        <app-svg-icon [name]="isEditingAutoBlock ? 'check' : 'edit'" class="w-6" />
      </button>
    </div>
    <form [formGroup]="panelForm" class="mt-8">
      <div
        class="flex items-center justify-between rounded-xl border border-gray-100 px-4 py-2.5 dark:border-slate-700"
      >
        <p class="text-sm font-medium">{{ "Admin.Aml.Auto_check" | translate }}</p>
        <app-switch formControlName="autoBlock" [disabled]="!isEditingAutoBlock" />
      </div>
    </form>
  </div>
</section>

<section class="mt-6 grid grid-cols-2 gap-x-6">
  <form [formGroup]="panelForm" class="box space-y-5">
    <div class="flex items-center justify-between border-b border-gray-100 pb-5 dark:border-slate-700">
      <h3 class="altitle">{{ "Admin.Aml.Incomes" | translate }}</h3>
      <button
        class="btn-flat"
        (click)="toggleEditing('InSignal')"
        [disabled]="isPending || panelForm.invalid"
      >
        <app-svg-icon [name]="isEditingInSignal ? 'check' : 'edit'" class="w-6" />
      </button>
    </div>

    <app-input
      type="search"
      [placeholder]="'Admin.Aml.Search' | translate"
      class="w-full [&_input]:py-2.5"
      [(ngModel)]="incomeSignalsSearch"
      [ngModelOptions]="{ standalone: true }"
    />

    <div class="max-h-[16rem] space-y-4 overflow-y-auto" formGroupName="inSignal">
      <div *ngFor="let key of getSignalKeys('inSignal')">
        <div *ngIf="shouldDisplaySignal(key, 'inSignal')" class="admin-value-row-wrapper">
          <p>{{ "Admin.Aml." + key | translate }}</p>
          <div *ngIf="isEditingInSignal">
            <input
              type="number"
              [formControlName]="key"
              placeholder="0"
              class="admin-value-row focus:outline-none"
            />
            <small class="text-xs text-red-500 dark:text-red-200">
              {{ getSignalControlError(key, "inSignal") }}
            </small>
          </div>
          <div *ngIf="!isEditingInSignal" class="admin-value-row">
            {{ panelForm.get("inSignal." + key)?.value | mfeCustom }}%
          </div>
        </div>
      </div>
    </div>
  </form>

  <form [formGroup]="panelForm" class="box space-y-5">
    <div class="flex items-center justify-between border-b border-gray-100 pb-5 dark:border-slate-700">
      <h3 class="altitle">{{ "Admin.Aml.Outcomes" | translate }}</h3>
      <button
        class="btn-flat"
        (click)="toggleEditing('OutSignal')"
        [disabled]="isPending || panelForm.invalid"
      >
        <app-svg-icon [name]="isEditingOutSignal ? 'check' : 'edit'" class="w-6" />
      </button>
    </div>

    <app-input
      type="search"
      [placeholder]="'Admin.Aml.Search' | translate"
      class="w-full [&_input]:py-2.5"
      [(ngModel)]="outcomeSignalsSearch"
      [ngModelOptions]="{ standalone: true }"
    />

    <div class="max-h-[16rem] space-y-4 overflow-y-auto" formGroupName="outSignal">
      <div *ngFor="let key of getSignalKeys('outSignal')">
        <div *ngIf="shouldDisplaySignal(key, 'outSignal')" class="admin-value-row-wrapper">
          <p>{{ "Admin.Aml." + key | translate }}</p>
          <div *ngIf="isEditingOutSignal">
            <input
              type="number"
              [formControlName]="key"
              placeholder="0"
              class="admin-value-row focus:outline-none"
            />
            <small class="text-xs text-red-500 dark:text-red-200">
              {{ getSignalControlError(key, "outSignal") }}
            </small>
          </div>
          <div *ngIf="!isEditingOutSignal" class="admin-value-row">
            {{ panelForm.get("outSignal." + key)?.value | mfeCustom }}%
          </div>
        </div>
      </div>
    </div>
  </form>
</section>

<section class="mt-5 rounded-2xl bg-gray-25 py-6 dark:bg-slate-800">
  <table class="adminTable">
    <thead>
      <tr>
        <th>ID</th>
        <th>{{ "Admin.Aml.Amount" | translate }}</th>
        <th>{{ "Admin.Aml.Date" | translate }}</th>
        <th>{{ "Admin.Aml.Sender_address" | translate }}</th>
        <th>{{ "Admin.Aml.Receiver_address" | translate }}</th>
        <th>{{ "Admin.Aml.Signals" | translate }}</th>
        <th>{{ "Admin.Aml.Options" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let request of requestsOnDeposit">
        <td>{{ request.id }}</td>
        <td>
          {{ request.amount | mfeCustom: { currency: request.currency } }}
          {{ request.currency | currencyName }}
        </td>
        <td class="whitespace-nowrap">{{ request.createdAt | customDate: "full" }}</td>
        <td>
          <div class="max-w-[8rem] overflow-hidden">
            <span class="truncate" [title]="request.from">{{ request.from }}</span>
            <app-copy-btn [value]="request.from"></app-copy-btn>
          </div>
        </td>
        <td>
          <div class="max-w-[8rem] overflow-hidden">
            <span class="truncate" [title]="request.toWallet.address">{{ request.toWallet.address }}</span>
            <app-copy-btn [value]="request.toWallet.address"></app-copy-btn>
          </div>
        </td>
        <td>
          <div class="flex max-w-[14rem] flex-wrap !gap-1 overflow-hidden">
            <span
              *ngFor="let signal of getTriggeredSignals(request)"
              [title]="signal.name + ': ' + signal.value"
              class="block truncate rounded-lg bg-gray-100 px-2 py-1 text-xs font-medium text-text-secondary dark:bg-slate-700"
            >
              {{ signal.name }}: {{ signal.value }}
            </span>
          </div>
        </td>
        <td>
          <div class="flex items-center gap-x-2">
            <button
              class="btn-flat h-6 w-6 rounded bg-green-100 text-green-500 disabled:opacity-50"
              (click)="setVerdictForRequestOnDeposit(request.id, true)"
              [disabled]="isVerdictPending"
            >
              <app-svg-icon name="check" class="h-4 w-4" />
            </button>
            <button
              class="btn-flat h-6 w-6 rounded bg-red-100 text-red-500 disabled:opacity-50"
              (click)="setVerdictForRequestOnDeposit(request.id, false)"
              [disabled]="isVerdictPending"
            >
              <app-svg-icon name="close" class="h-4 w-4" />
            </button>
          </div>
        </td>
      </tr>

      <tr *ngIf="requestsOnDeposit.length === 0">
        <td colspan="7">
          <p class="text-center text-xs text-text-secondary">
            {{ "Admin.Aml.No_requests" | translate }}
          </p>
        </td>
      </tr>
    </tbody>
  </table>

  <ngb-pagination
    [collectionSize]="totalCount"
    [pageSize]="pageSize"
    [(page)]="currentPage"
    [maxSize]="5"
    (pageChange)="onRequestsPageChange()"
    class="mt-6 block"
  ></ngb-pagination>
</section>
