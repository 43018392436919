import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { TxDirection } from "src/app/common/enums/tx-direction.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";

export class TxDto {
  public id: number = 0;
  public createdAt: string = "";
  public hash: string = "";
  public amount: number = 0;
  public to: string = "";
  public fee: number = 0;
  public from: string = "";
  public currency: CryptoSymbol = CryptoSymbol.Usdt;
  public type: TxType = TxType.Chain;
  public fromCurrency: CryptoSymbol = CryptoSymbol.Usdt;
  public fromAmount: number = 0;
  public sendAt: string = "";
  public status: TxStatus = TxStatus.OnConfirm;
  public direction: TxDirection = TxDirection.Sending;
}
