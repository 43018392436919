import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";

@Component({
  selector: "app-currency-dropdown",
  templateUrl: "./currency-dropdown.component.html",
  styleUrls: ["./currency-dropdown.component.css"],
})
export class CurrencyDropdownComponent {
  @HostListener("window:click", ["$event"])
  handleClick(event: KeyboardEvent) {
    this.hideOnClick(event);
  }

  @Input() items: CryptoSymbol[] = [];
  @Input() disabled = false;
  @Input() isFullWidthList = false;

  @Output() onSelect = new EventEmitter<CryptoSymbol>();

  isOpen = false;

  public getCurrencyName(currency: CryptoSymbol): string {
    return getCurrencyName(currency);
  }

  public getCurrencyNetworkName(currency: CryptoSymbol): string {
    switch (currency) {
      case CryptoSymbol.Trx:
      case CryptoSymbol.Usdt:
        return "Tron";
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "Polygon";
      case CryptoSymbol.Ton:
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Not:
        return "Ton";
      case CryptoSymbol.Bitcoin:
        return "Bitcoin";
      default:
        return "";
    }
  }

  toggleSelect() {
    this.isOpen = !this.isOpen;
  }

  pickItem(item: CryptoSymbol) {
    if (!this.disabled) {
      this.onSelect.emit(item);
    }
  }

  hideOnClick(e: any) {
    e.stopPropagation();
    if (!e.target.classList.contains("dropdown__list") && e.target.parentNode?.nodeName !== "BUTTON") {
      this.isOpen = false;
    }
  }
}
