import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { interval, Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Component({
  selector: "app-otp-input",
  templateUrl: "./otp-input.component.html",
  styleUrls: ["./otp-input.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OtpInputComponent),
      multi: true,
    },
  ],
})
export class OtpInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() title: string = "";
  @Input() errorMsg: string | null = null;
  @Input() comment: string[] = [];
  @Input() initialTimerValue: number | null = 60; // Timer in seconds
  @Input() autofocus: boolean = true;
  @Input() digits: number = 6;

  @Output() resendCode = new EventEmitter<void>();
  @Output() otpComplete = new EventEmitter<string>();

  timeCounter: number = 0;
  otpDigits: FormControl[] = [];
  private timerSubscription?: Subscription;

  public control = new FormControl();
  private onChange: any = (_: any) => {};
  private onTouch: any = () => {};

  ngOnInit(): void {
    this.control.valueChanges.subscribe(x => {
      if (this.onChange != null) {
        this.onChange(x);
        this.onTouch();
      }
    });

    // Initialize 6 separate controls for each digit
    this.otpDigits = Array(this.digits)
      .fill(0)
      .map(() => new FormControl(""));

    // Start timer
    this.startTimer();

    // Subscribe to changes in individual digit controls
    this.otpDigits.forEach((digitControl, index) => {
      digitControl.valueChanges.subscribe(value => {
        this.handleDigitChange(value, index);
      });
    });
  }

  public writeValue(obj: any) {
    this.control.setValue(obj);
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  startTimer(): void {
    if (this.initialTimerValue === null) {
      return;
    }

    this.timeCounter = this.initialTimerValue;

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timerSubscription = interval(1000)
      .pipe(take(this.initialTimerValue + 1))
      .subscribe(() => {
        if (this.timeCounter > 0) {
          this.timeCounter--;
        }
      });
  }

  onResendClick(): void {
    this.resendCode.emit();
    this.startTimer();
  }

  handleDigitChange(value: string, index: number): void {
    // Only allow numbers
    if (value && !/^\d*$/.test(value)) {
      this.otpDigits[index].setValue(value.replace(/\D/g, ""));
      return;
    }

    // Auto-limit to single digit
    if (value && value.length > 1) {
      this.otpDigits[index].setValue(value.charAt(0));
      return;
    }

    // Move focus to next input if value is entered
    if (value && index < 5) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }

    // Update the main control with all digits
    this.updateMainControl();
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    // Handle backspace
    if (event.key === "Backspace") {
      if (!this.otpDigits[index].value && index > 0) {
        // If current input is empty and backspace is pressed, move to previous input
        this.otpDigits[index - 1].setValue("");
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData("text");
      const digits = pastedText.replace(/\D/g, "").substring(0, 6);

      for (let i = 0; i < digits.length; i++) {
        if (i < 6) {
          this.otpDigits[i].setValue(digits.charAt(i));
        }
      }

      // Focus the next empty input or the last one
      for (let i = 0; i < 6; i++) {
        if (!this.otpDigits[i].value) {
          document.getElementById(`otp-input-${i}`)?.focus();
          break;
        } else if (i === 5) {
          document.getElementById(`otp-input-5`)?.focus();
        }
      }
    }
  }

  private updateMainControl(): void {
    const combinedValue = this.otpDigits.map(control => control.value || "").join("");
    this.control.setValue(combinedValue);

    // Emit event when OTP is complete
    if (combinedValue.length === this.digits) {
      this.otpComplete.emit(combinedValue);
    }
  }
}
