export enum TxPageType {
  Trx = 1,
  Usdt = 2,
  Uzs = 3,
  Tiyins = 4,
  Matic = 5,
  PolygonUsdt = 6,
  Ton = 7,
  Not = 8,
  TonUsdt = 9,
  Bitcoin = 10,
  AbstractUsdt = 11,
  BuySell = 12,
  WaitingConfirm = 13,
}
