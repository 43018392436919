<!-- Full size version -->
<div
  *ngIf="fullSize"
  class="grid grid-cols-[17%_10%_25%_12%_auto] items-center gap-x-12 px-6 py-3 max-md:flex max-md:flex-wrap max-md:justify-between max-md:gap-x-4 max-md:gap-y-6 max-md:p-3"
>
  <div class="flex items-center gap-x-4">
    <app-skeleton class="h-10 w-10 shrink-0 max-md:h-12"></app-skeleton>
    <app-skeleton class="h-6 w-full max-md:w-20"></app-skeleton>
  </div>
  <div class="flex flex-col gap-y-1 max-md:items-end">
    <app-skeleton class="h-6 w-full max-md:w-16"></app-skeleton>
    <app-skeleton class="h-6 w-full max-md:w-28"></app-skeleton>
  </div>
  <app-skeleton class="h-6 w-full"></app-skeleton>
  <app-skeleton class="h-6 w-full max-md:w-24"></app-skeleton>
  <app-skeleton class="ml-auto h-6 w-full max-md:w-24"></app-skeleton>
</div>

<!-- Short version -->
<div *ngIf="!fullSize" class="flex items-center justify-between px-3 py-4 max-md:px-2">
  <div class="flex items-center gap-x-4">
    <app-skeleton class="h-12 w-10"></app-skeleton>
    <div class="flex flex-col gap-y-1">
      <app-skeleton class="h-6 w-52 max-md:w-32"></app-skeleton>
      <app-skeleton class="h-6 w-28 max-md:w-16"></app-skeleton>
    </div>
  </div>
  <div class="flex flex-col items-end gap-y-1">
    <app-skeleton class="h-6 w-32 max-md:w-20"></app-skeleton>
    <app-skeleton class="h-6 w-28 max-md:w-16"></app-skeleton>
  </div>
</div>
