import { AfterViewInit, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Constants } from "src/app/common/constants/constants";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { Transaction } from "src/app/common/models/transaction";
import { TxParserUtil } from "src/app/common/utils/tx-parser.util";
import { EnvService } from "src/app/services/env.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { TronService } from "src/app/services/tron.service";
import { TxService } from "src/app/services/tx.service";

@Component({
  templateUrl: "./transaction-page.component.html",
  styleUrls: ["./transaction-page.component.css"],
})
export class TransactionPageComponent implements AfterViewInit {
  public txDto = new Transaction();
  public isLoading: boolean = true;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _envService: EnvService,
    private readonly _tronService: TronService,
    private readonly _txService: TxService,
    private readonly _localStorage: LocalStorageService
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    this.isLoading = true;
    const hash = this._route.snapshot.paramMap.get("id");

    if (hash == null) {
      this._router.navigate([RouteConstants.wallet]);
      return;
    }

    await this.loadTx(hash);
    this.isLoading = false;
  }

  public get txFee(): number {
    return this.txDto.fee / Constants.Mega;
  }

  public get txStatus(): string {
    return TxParserUtil.getTxStatus(this.txDto.status);
  }

  public get txDate(): string {
    return TxParserUtil.getFormattedDate(this.txDto.createdAt);
  }

  public get explorerUrl(): string {
    return `${this._envService.tronScanUrl}#/transaction/${this.txDto.hash}`;
  }

  public get tokenName(): string {
    return this.txDto.token == CryptoToken.Usdt ? "USDT" : "TRX";
  }

  private async loadTx(hash: string) {
    let tx: Transaction | null;
    if (Number.isFinite(+hash)) {
      tx = await this.loadTxFromApi(+hash);
    } else {
      tx = await this.loadTxFromChain(hash);
    }

    if (tx == null) {
      //this._router.navigate([RouteConstants.wallet]);
      return;
    }
    this.txDto = tx;
  }

  private async loadTxFromChain(hash: string) {
    const wallet = (await this._localStorage.getUserData())?.walletAddress;
    return await this._tronService.getTxByHash(hash, wallet);
  }

  private async loadTxFromApi(id: number) {
    const res = await this._txService.getById(id);
    if (res.withError) {
      return null;
    }

    return TxParserUtil.mapToTransaction(res.params!);
  }
}
