import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appDateInputMask]",
})
export class DateInputMaskDirective {
  constructor(private el: ElementRef) {}

  @HostListener("input", ["$event"])
  onInput(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    value = value.replace(/[^0-9.]/g, "");

    const dots = value.split(".").length - 1;
    if (dots > 2) {
      value = value.slice(0, -1);
    }

    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    if (value.length >= 3 && value[2] !== ".") {
      value = value.slice(0, 2) + "." + value.slice(2);
    }
    if (value.length >= 6 && value[5] !== ".") {
      value = value.slice(0, 5) + "." + value.slice(5);
    }

    if (input.value !== value) {
      input.value = value;
      input.dispatchEvent(new Event("input"));
    }
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;

    if (
      !/[0-9.]/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "Tab" &&
      !event.key.startsWith("Arrow")
    ) {
      event.preventDefault();
    }

    if (event.key === ".") {
      const cursorPosition = input.selectionStart;
      if (
        cursorPosition === 0 ||
        cursorPosition === 1 ||
        cursorPosition === 3 ||
        cursorPosition === 4 ||
        cursorPosition === 6
      ) {
        event.preventDefault();
      }
    }
  }

  @HostListener("blur", ["$event"])
  onBlur(event: FocusEvent): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (value.length === 10) {
      const [day, month, year] = value.split(".").map(Number);

      if (isNaN(day) || isNaN(month) || isNaN(year) || month < 1 || month > 12 || day < 1 || day > 31) {
        input.value = "";
        alert("Некорректная дата. Введите дату в формате дд.мм.гггг.");
      }
    }
  }
}
