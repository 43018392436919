import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Big } from "big.js";
import { debounceTime, Subject, switchMap } from "rxjs";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { SwapCommissionDto } from "src/app/common/DTO/swap-commissions";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { CommissionService } from "./commission.service";
import { PolygonService } from "./polygon.service";
import { RatesService } from "./rates.service";
import { SwapCommissionsService } from "./swap-commissions.service";

type CalculationInput = "from" | "to";

@Injectable({
  providedIn: "root",
})
export class CalculatorService {
  public fromCurrency = CryptoSymbol.Uzs;
  public toCurrency = CryptoSymbol.Usdt;
  public networkFee = 0;
  public serviceFeeFactor = 0;
  public isCalculationsPending = false;

  public form: FormGroup;

  private rates: RateDto[] = [];
  private swapCommissions: SwapCommissionDto[] = [];
  private polygonGasPrice = 0;

  private $calculations = new Subject<{ amount: number; input: CalculationInput }>();

  constructor(
    private readonly _ratesService: RatesService,
    private readonly _commissionService: CommissionService,
    private readonly _swapCommissionsService: SwapCommissionsService,
    private readonly _polygonService: PolygonService
  ) {
    this.form = new FormGroup({
      fromAmount: new FormControl(null, [Validators.required, Validators.min(Number.MIN_VALUE)]),
      toAmount: new FormControl(null, [Validators.required, Validators.min(Number.MIN_VALUE)]),
    });

    this.$calculations
      .pipe(
        debounceTime(300),
        switchMap(({ amount, input }) =>
          Promise.all([this.calculateServiceFeeFactor(amount), this.calculateNetworkFee(amount), input])
        )
      )
      .subscribe(([serviceFeeFactor, networkFee, input]) => {
        this.serviceFeeFactor = serviceFeeFactor;
        this.networkFee = networkFee;
        if (input === "from") {
          this.calculateAndSetToAmount();
        } else {
          this.calculateAndSetFromAmount();
        }
        this.isCalculationsPending = false;
      });
  }

  private get selectedCryptoCurrency() {
    if (this.fromCurrency === CryptoSymbol.Uzs) {
      return this.toCurrency;
    }
    return this.fromCurrency;
  }

  private async calculateServiceFeeFactor(amount: number): Promise<number> {
    let cryptoAmount = amount;
    if (this.fromCurrency === CryptoSymbol.Uzs) {
      cryptoAmount = this.convertAmount(amount, this.fromCurrency, this.toCurrency);
    }
    if (!cryptoAmount || cryptoAmount === 0) {
      return 0;
    }

    let percents = 0;

    if (this.fromCurrency === CryptoSymbol.Uzs || this.toCurrency === CryptoSymbol.Uzs) {
      const response = await this._commissionService.getCommissionWithParams(
        cryptoAmount,
        this.selectedCryptoCurrency
      );
      if (response.withError || response.params === null) {
        return 0;
      }
      percents = response.params?.percents ?? 0;
    } else if (this.fromCurrency === CryptoSymbol.AbstractUsdt) {
      const commission = this.swapCommissions.find(c => c.currency === this.toCurrency);
      percents = commission?.usdtPercent ?? 0;
    } else if (this.toCurrency === CryptoSymbol.AbstractUsdt) {
      const commission = this.swapCommissions.find(c => c.currency === this.fromCurrency);
      percents = commission?.percent ?? 0;
    }

    return new Big(percents).div(100).toNumber();
  }

  private calculateNetworkFee(amount: number) {
    return this._commissionService.calculateNetworkCommission({
      currency: this.selectedCryptoCurrency,
      gasPrices: { polygonGasPrice: this.polygonGasPrice },
      amount: amount?.toString() ?? "0",
    });
  }

  private calculateAndSetToAmount() {
    if (!this.fromAmount) {
      this.setToAmount(null);
      return;
    }
    const convertedAmount = this.convertAmount(this.fromAmount, this.fromCurrency, this.toCurrency);
    const serviceFee = this.calculateServiceFee(convertedAmount);
    // const finalAmountDecimals = this.getCurrencyDecimals(this.toCurrency);
    // const finalAmount = Number((convertedAmount - serviceFee).toFixed(finalAmountDecimals));
    const finalAmount = new Big(convertedAmount).minus(serviceFee).toNumber();
    this.setToAmount(finalAmount);
  }

  private calculateAndSetFromAmount() {
    if (!this.toAmount) {
      this.setFromAmount(null);
      return;
    }
    const convertedAmount = this.convertAmount(this.toAmount, this.toCurrency, this.fromCurrency);
    const serviceFee = this.calculateServiceFee(convertedAmount);
    const finalAmount = new Big(convertedAmount).plus(serviceFee).toNumber();
    this.setFromAmount(finalAmount);
  }

  public convertAmount(amount: number, fromCurrency: CryptoSymbol, toCurrency: CryptoSymbol): number {
    if (fromCurrency === toCurrency) {
      return amount;
    }
    return ConvertCurrencyHelper.convertToCurrency(amount, fromCurrency, toCurrency, this.rates);
  }

  private calculateServiceFee(amount: number) {
    return new Big(amount).times(this.serviceFeeFactor).toNumber();
  }

  private getCurrencyDecimals(currency: CryptoSymbol) {
    switch (currency) {
      case CryptoSymbol.Uzs: {
        return 0;
      }
      case CryptoSymbol.Bitcoin: {
        return 6;
      }
      default: {
        return 2;
      }
    }
  }

  public get fromAmount() {
    return this.form.get("fromAmount")?.value ?? 0;
  }

  public get toAmount() {
    return this.form.get("toAmount")?.value ?? 0;
  }

  private setToAmount(amount: number | null) {
    this.form.get("toAmount")?.setValue?.(amount?.toString?.() ?? null);
  }

  private setFromAmount(amount: number | null) {
    this.form.get("fromAmount")?.setValue?.(amount?.toString?.() ?? null);
  }

  public setFromCurrency(currency: CryptoSymbol) {
    this.fromCurrency = currency;
  }

  public setToCurrency(currency: CryptoSymbol) {
    this.toCurrency = currency;
  }

  public triggerCalculations(amount: number | null, input: CalculationInput = "from") {
    this.isCalculationsPending = true;
    this.$calculations.next({ amount: amount ?? 0, input });
  }

  public async init() {
    this.form.reset();

    await Promise.all([this.setupRates(), this.setupGasPrice(), this.setupSwapCommissions()]);
  }

  private async setupRates() {
    const res = await this._ratesService.getRates();
    if (res.withError || !res.params) {
      return;
    }
    this.rates = res.params;
  }

  private async setupGasPrice() {
    if (this.fromCurrency === CryptoSymbol.Matic || this.fromCurrency === CryptoSymbol.PolygonUsdt) {
      const polygonRes = await this._polygonService.getGasPrice();
      this.polygonGasPrice = polygonRes?.SafeGasPrice ? +polygonRes.SafeGasPrice : 0;
    }
  }

  private async setupSwapCommissions() {
    const res = await this._swapCommissionsService.getAllSwapCommissions();
    this.swapCommissions = res.params ?? [];
  }
}
