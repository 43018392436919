import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { getRoleName } from "src/app/common/utils/role-name-helper.util";
import { AuthService } from "src/app/services/auth.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ThemeService } from "src/app/services/theme.service";

@Component({
  selector: "app-admin-sidebar",
  templateUrl: "./admin-sidebar.component.html",
  styleUrls: ["./admin-sidebar.component.css"],
})
export class AdminSidebarComponent implements OnInit {
  public user = new UserDto();
  public theme: "light" | "dark" = "light";

  private readonly _sidebarLinks = [
    {
      title: "Admin.Sidebar.Home",
      link: "/admin/home",
      icon: "admin/home",
      permission: AdminPermission.PlatformInteraction,
    },
    {
      title: "Admin.Sidebar.Transactions",
      link: "/admin/transactions",
      icon: "admin/transactions",
      permission: AdminPermission.TransactionInteraction,
    },
    {
      title: "Admin.Sidebar.Users",
      link: "/admin/users",
      icon: "admin/users",
      permission: AdminPermission.UserInteraction,
    },
    {
      title: "Admin.Sidebar.Admins",
      link: "/admin/admins",
      icon: "admin/admins",
      permission: AdminPermission.AdminInteraction,
    },
    {
      title: "Admin.Sidebar.Reports",
      link: "/admin/reports",
      icon: "admin/document",
      permission: AdminPermission.PlatformInteraction,
    },
    {
      title: "Admin.Sidebar.Blacklist",
      link: "/admin/security",
      icon: "admin/lock",
      permission: AdminPermission.PlatformInteraction,
    },
    {
      title: "AML",
      link: "/admin/aml",
      icon: "admin/aml",
      permission: AdminPermission.PlatformInteraction,
    },
    {
      title: "Admin.Sidebar.Revenue",
      link: "/admin/revenue",
      icon: "admin/currency-dollar",
      permission: AdminPermission.PlatformInteraction,
    },
  ];

  private _visibleLinks: typeof this._sidebarLinks = [];

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _authService: AuthService,
    private readonly _router: Router,
    private readonly _themeService: ThemeService
  ) {
    this._themeService.theme$.subscribe(theme => {
      this.theme = theme;
    });
  }

  get sidebarLinks() {
    return this._visibleLinks;
  }

  async ngOnInit() {
    const user = await this._localStorage.getUserData();
    if (user) this.user = user;
    await this.filterLinksByPermissions();
  }

  private async filterLinksByPermissions(): Promise<void> {
    const permissionChecks = await Promise.all([
      this._localStorage.adminHasPermission(AdminPermission.TransactionInteraction),
      this._localStorage.adminHasPermission(AdminPermission.UserInteraction),
      this._localStorage.adminHasPermission(AdminPermission.AdminInteraction),
      this._localStorage.adminHasPermission(AdminPermission.PlatformInteraction),
    ]);

    const [canViewTransactions, canViewUsers, canViewAdmins, canViewPlatform] = permissionChecks;

    this._visibleLinks = this._sidebarLinks.filter(link => {
      if (!link.permission) return true;

      switch (link.permission) {
        case AdminPermission.TransactionInteraction:
          return canViewTransactions;
        case AdminPermission.UserInteraction:
          return canViewUsers;
        case AdminPermission.AdminInteraction:
          return canViewAdmins;
        case AdminPermission.PlatformInteraction:
          return canViewPlatform;
        default:
          return false;
      }
    });
  }

  public get userRoleName() {
    return getRoleName(this.user.role.name);
  }

  public async onLogout(): Promise<void> {
    await this._authService.logout();
    this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
    await this._localStorage.clearTokens();
    await this._localStorage.removeUserData();
  }
}
