<div class="relative flex items-center justify-center gap-x-1 rounded-xl bg-gray-50 p-1 dark:bg-slate-900">
  <button
    class="toggle"
    (click)="setTheme('light')"
    [ngClass]="theme === 'light' ? 'text-text-primary' : 'text-text-secondary'"
  >
    <app-svg-icon name="sun" class="block aspect-square w-6 max-md:w-5" />
  </button>
  <button
    class="toggle"
    (click)="setTheme('dark')"
    [ngClass]="theme === 'dark' ? 'text-white' : 'text-text-secondary'"
  >
    <app-svg-icon name="moon" class="block aspect-square w-6 max-md:w-5" />
  </button>

  <span
    class="slider"
    [ngClass]="{ 'translate-x-0': theme === 'light', 'translate-x-full': theme === 'dark' }"
  ></span>
</div>
