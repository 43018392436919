<div class="absolute top-8">
  <h1 class="title">{{ "Admin.Home.Title" | translate }}</h1>
</div>

<section class="mt-6">
  <app-dropdown [items]="currenciesNames" (onSelect)="onSelectCurrency($event)" class="block w-[15.75rem]">
    <button class="dropdown-base-toggle w-full">
      <span class="text-sm font-bold text-text-secondary">{{ selectedCurrencyName }}</span>
      <app-svg-icon name="chevron" class="h-4 w-4"></app-svg-icon>
    </button>
  </app-dropdown>

  <div class="mt-5 grid grid-cols-3 gap-6">
    <div class="flex flex-col gap-5">
      <app-control-panel-block [selectedCurrency]="selectedCurrency" class="h-full"></app-control-panel-block>

      <app-tron-energy-block *ngIf="selectedCurrency === CryptoSymbol.Usdt"></app-tron-energy-block>

      <app-rate-block [selectedCurrency]="selectedCurrency"></app-rate-block>

      <app-schedule-block [selectedCurrency]="selectedCurrency"></app-schedule-block>
    </div>

    <div class="flex flex-col gap-5">
      <app-commission-block [selectedCurrency]="selectedCurrency" class="h-full"></app-commission-block>

      <app-min-balance-block [selectedCurrency]="selectedCurrency"></app-min-balance-block>
    </div>

    <app-limits-block [selectedCurrency]="selectedCurrency"></app-limits-block>
  </div>
</section>
