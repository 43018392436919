import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CardDto } from "src/app/common/DTO/cards/card.dto";
import { CardService } from "src/app/services/card.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-delete-bank-card-modal",
  templateUrl: "./delete-bank-card-modal.component.html",
  styleUrls: ["./delete-bank-card-modal.component.css"],
})
export class DeleteBankCardModalComponent {
  @Input() card: CardDto = new CardDto();
  @Input() cardIndex: number = 0;

  @Output() onDelete = new EventEmitter();

  constructor(
    private readonly _cardService: CardService,
    private readonly _translateService: TranslateService,
    private readonly _toastService: ToastService,
    private readonly _modalRef: NgbActiveModal
  ) {}

  public async onDeleteCard() {
    const res = await this._cardService.deleteCard(this.card.id);

    if (res.withError) {
      this._toastService.show(this._translateService.instant("Common.Unknown_error"));
      return;
    }

    this.onDelete.emit();
    const successMessage = this._translateService.instant("Profile.Bank_card_deleted");
    this._toastService.show(successMessage);
  }

  public closeModal() {
    this._modalRef.close();
  }
}
