import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { SignInModalComponent } from "./modals/sign-in-modal/sign-in-modal.component";
import { SignUpModalComponent } from "./modals/sign-up-modal/sign-up-modal.component";

interface LandingSlide {
  id: number;
  imgSrc: string;
  title: {
    common: string;
    highlighted: string;
  };
}

@Component({
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.css"],
})
export class LandingComponent implements OnInit, OnDestroy {
  public slides: LandingSlide[] = [
    {
      id: 0,
      imgSrc: "assets/images/landing/hero-slides/slide-1.png",
      title: { common: "Landing.Title_1_common", highlighted: "Landing.Title_1_highlighted" },
    },
    {
      id: 1,
      imgSrc: "assets/images/landing/hero-slides/slide-2.png",
      title: { common: "Landing.Title_2_common", highlighted: "Landing.Title_2_highlighted" },
    },
  ];
  public activeSlideId = this.slides[0].id;
  public language: string = "";

  private slidesIntervalId: NodeJS.Timer | null = null;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _translateService: TranslateService
  ) {
    this.language = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(l => {
      this.language = l.lang;
    });
  }

  async ngOnInit() {
    this.slidesIntervalId = setInterval(() => {
      const activeSlideIndex = this.slideIds.findIndex(slideId => slideId === this.activeSlideId);
      const nextSlideIndex = activeSlideIndex + 1;
      if (nextSlideIndex === this.slides.length) {
        this.activeSlideId = this.slides[0].id;
        return;
      }
      this.activeSlideId = nextSlideIndex;
    }, 4000);
  }

  public get slideIds() {
    return this.slides.map(slide => slide.id);
  }

  public get slidesTranslateStyle() {
    const activeSlideIndex = this.slideIds.findIndex(slideId => slideId === this.activeSlideId);
    return `translateX(-${activeSlideIndex * 100}vw)`;
  }

  public get supportedCurrencies() {
    return [
      CryptoSymbol.AbstractUsdt,
      CryptoSymbol.Trx,
      CryptoSymbol.Matic,
      CryptoSymbol.Bitcoin,
      CryptoSymbol.Ton,
      CryptoSymbol.Not,
    ];
  }

  public openSignInModal() {
    this._modalService.open(SignInModalComponent);
  }

  public openSignUpModal() {
    this._modalService.open(SignUpModalComponent);
  }

  public handleSelectSlide(slideId: number) {
    this.activeSlideId = slideId;
  }

  ngOnDestroy() {
    if (this.slidesIntervalId) {
      clearInterval(this.slidesIntervalId);
    }
  }
}
