<div *ngIf="internalError" class="bg-invalid-color text-center">
  <div class="text-text-secondary">
    {{ "Common.Unknown_error" | translate }}
  </div>
</div>

<div
  class="max-md:my-2 max-md:rounded-2xl max-md:bg-gray-25 max-md:p-4 max-md:dark:bg-slate-800 md:absolute md:top-8"
>
  <h1 class="title">{{ "Wallet.Title" | translate }}</h1>
</div>

<app-balance-chart [totalUzsBalance]="totalUzsBalance" [totalUsdBalance]="totalUsdBalance" />

<section class="mt-2 rounded-2xl bg-gray-25 pb-6 dark:bg-slate-800 max-md:pb-4">
  <div
    class="flex items-center justify-between px-6 py-7 max-md:flex-col max-md:items-start max-md:gap-y-7 max-md:p-6"
  >
    <h2 class="subtitle">{{ "Wallet.My_assets" | translate }}</h2>
    <div class="grid grid-cols-2 items-center gap-x-4 max-md:w-full">
      <button
        class="btn-primary btn-medium max-md:px-0"
        (click)="openSwapModal()"
        [disabled]="visibleBalances.length === 0"
      >
        {{ "Swap.Swap" | translate }}
      </button>
      <a [routerLink]="RouteConstants.assets" class="btn-primary btn-medium text-center max-md:px-0">
        {{ "Wallet.Add_asset" | translate }}
      </a>
    </div>
  </div>

  <div>
    <div
      *ngIf="isLoading || visibleBalances.length > 0"
      class="grid grid-cols-wallet-balances-table items-center gap-x-1 px-6 py-3 text-xs font-medium text-text-secondary max-md:grid-cols-[auto_auto] max-md:gap-x-2"
    >
      <span>{{ "Wallet.Currency_name" | translate }}</span>
      <span class="max-md:hidden">{{ "Wallet.Total" | translate }}</span>
      <span class="max-md:hidden">{{ "Wallet.In_usdt" | translate }}</span>
      <span class="max-md:hidden">{{ "Wallet.In_uzs" | translate }}</span>
      <app-dropdown
        class="md:hidden"
        [items]="mobileCurrencyOptionsNames"
        (onSelect)="onMobileCurrencyChange($event)"
      >
        <div class="flex items-center justify-end gap-x-2">
          <span>{{ selectedMobileBalanceCurrency ?? "" | translate }}</span>
          <app-svg-icon name="chevron" class="w-3" />
        </div>
      </app-dropdown>
      <span></span>
    </div>

    <div>
      <ng-container *ngIf="!isLoading; else balancesSkeleton">
        <ng-container *ngIf="visibleBalances.length > 0; else noBalances">
          <app-balance-row
            *ngFor="let balance of visibleBalances"
            [inStock]="true"
            [balanceItem]="balance"
            [rates]="rates"
            [isBalanceVisible]="isBalanceVisible"
            [mobileBalanceCurrency]="mobileBalanceCurrency"
            [asLink]="true"
            (receiveClick)="openReceiveModal(balance)"
            (sendClick)="openSendModal(balance)"
            (swapClick)="openSwapModal(balance)"
          />
        </ng-container>
      </ng-container>

      <ng-template #balancesSkeleton>
        <app-balance-row [isLoading]="true" [inStock]="true" />
        <app-balance-row [isLoading]="true" [inStock]="true" />
        <app-balance-row [isLoading]="true" [inStock]="true" />
      </ng-template>
      <ng-template #noBalances>
        <div class="text-center text-sm font-semibold text-text-secondary">
          {{ "Wallet.No_assets" | translate }}
        </div>
      </ng-template>
    </div>

    <!-- <button
      *ngIf="visibleBalances.length && balances.length > visibleBalances.length"
      class="btn-primary btn-medium mx-6 mt-3 w-fill md:hidden"
      (click)="showMoreBalances()"
    >
      {{ "Wallet.Show_all" | translate }}
    </button> -->
  </div>
</section>

<div class="mt-2 grid grid-cols-2 gap-2 max-md:grid-cols-1">
  <app-available-assets
    *ngIf="!isMobile"
    [balances]="balancesForAssets"
    [rates]="rates"
    class="h-full [&>*]:h-full"
  ></app-available-assets>
  <app-latest-transactions [txCount]="7" class="h-full [&>*]:h-full"></app-latest-transactions>
</div>
