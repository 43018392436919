import { Component, Input } from "@angular/core";

@Component({
  selector: "app-transaction-list-item-skeleton",
  templateUrl: "./transaction-list-item-skeleton.component.html",
  styleUrls: ["./transaction-list-item-skeleton.component.css"],
})
export class TransactionListItemSkeletonComponent {
  @Input() public fullSize: boolean = false;
}
