<div class="box h-full space-y-4 px-3">
  <div class="flex items-center justify-between border-b border-gray-100 pb-5 dark:border-slate-700">
    <h3 class="altitle">{{ "Admin.Home.Commission" | translate }}</h3>
    <button
      *ngIf="
        hasInteractionPermissions &&
        (selectedCurrency === CryptoSymbol.Usdt ||
          selectedCurrency === CryptoSymbol.PolygonUsdt ||
          selectedCurrency === CryptoSymbol.TonUsdt)
      "
      class="btn-flat w-5"
      (click)="openCreateModal(selectedCurrency, createCommissionModal)"
    >
      <app-svg-icon name="plus" />
    </button>
  </div>

  <div
    class="admin-value-row-wrapper"
    *ngIf="
      selectedCurrency === CryptoSymbol.Usdt ||
      selectedCurrency === CryptoSymbol.PolygonUsdt ||
      selectedCurrency === CryptoSymbol.TonUsdt
    "
  >
    <p>{{ "Admin.Home.Sell_commission" | translate }}:</p>
    <ng-container *ngFor="let item of sellCommissions">
      <div class="admin-value-row">
        <p class="text-sm font-medium">
          <span>
            {{
              "Admin.Home.Up_to"
                | translate
                  : {
                      value: item.maxAmount | mfeCustom: { currency: selectedCurrency },
                      ticker: currencyName
                    }
            }}
          </span>
          <span class="text-text-secondary">{{ item.percents }}%</span>
        </p>
        <button
          *ngIf="hasInteractionPermissions"
          class="btn-flat w-6 hover:text-red-400"
          (click)="openDeleteModal(item, deleteCommissionModal)"
        >
          <app-svg-icon name="settings/trash" />
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="sellCommissions.length === 0">
      <p class="text-xs font-medium text-text-secondary">
        {{ "Admin.Home.Commission_not_set" | translate }}
      </p>
    </ng-container>
  </div>

  <div class="admin-value-row-wrapper">
    <p>{{ "Admin.Home.Withdraw_commission" | translate }}:</p>
    <ng-container *ngFor="let item of getWithdrawCommissions(selectedCurrency)">
      <div class="admin-value-row">
        <span class="text-sm font-medium">{{ item.amount }}%</span>
        <button
          *ngIf="hasInteractionPermissions"
          class="btn-flat w-6"
          (click)="openEditWithdrawCommissionModal(item.id, item.currency, editWithdrawCommissionModal)"
        >
          <app-svg-icon name="edit" />
        </button>
      </div>
    </ng-container>
  </div>

  <div class="admin-value-row-wrapper">
    <p>{{ "Admin.Home.Internal_withdraw_commission" | translate }}:</p>
    <ng-container *ngFor="let item of getWithdrawCommissions(selectedCurrency)">
      <div class="admin-value-row">
        <span class="text-sm font-medium">{{ item.internalAmount }}%</span>
        <button
          *ngIf="hasInteractionPermissions"
          class="btn-flat w-6"
          (click)="openEditWithdrawCommissionModal(item.id, item.currency, editWithdrawCommissionModal)"
        >
          <app-svg-icon name="edit" />
        </button>
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="
      selectedCurrency !== CryptoSymbol.Usdt &&
      selectedCurrency !== CryptoSymbol.PolygonUsdt &&
      selectedCurrency !== CryptoSymbol.TonUsdt
    "
    class="admin-value-row-wrapper"
  >
    <p>{{ "Admin.Home.Swap_usdt_commission" | translate }}:</p>
    <ng-container *ngFor="let item of getSwapCommissions(selectedCurrency)">
      <div class="admin-value-row">
        <span class="text-sm font-medium">{{ item.percent }}%</span>
        <button
          *ngIf="hasInteractionPermissions"
          class="btn-flat w-6"
          (click)="openEditSwapCommissionModal(item, editSwapCommissionModal)"
        >
          <app-svg-icon name="edit" />
        </button>
      </div>
    </ng-container>
  </div>

  <!-- TODO: clarify if this is correct -->
  <div
    *ngIf="
      selectedCurrency !== CryptoSymbol.Usdt &&
      selectedCurrency !== CryptoSymbol.PolygonUsdt &&
      selectedCurrency !== CryptoSymbol.TonUsdt
    "
    class="admin-value-row-wrapper"
  >
    <p>{{ "Admin.Home.Asset_swap_commission" | translate }}:</p>
    <ng-container *ngFor="let item of getSwapCommissions(selectedCurrency)">
      <div class="admin-value-row">
        <span class="text-sm font-medium">{{ item.usdtPercent }}%</span>
        <button
          *ngIf="hasInteractionPermissions"
          class="btn-flat w-6"
          (click)="openEditSwapCommissionModal(item, editSwapCommissionModal)"
        >
          <app-svg-icon name="edit" />
        </button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template let-modal #createCommissionModal>
  <div class="flex items-center justify-between p-3">
    <h4 class="title">{{ "Admin.Home.Commission" | translate }}</h4>
    <button class="btn-flat w-6" (click)="modal.close()">
      <app-svg-icon name="close" />
    </button>
  </div>
  <form class="mt-6 flex flex-col gap-y-4" [formGroup]="form" (ngSubmit)="onCreateCommissionSubmit()">
    <app-input
      [title]="('Admin.Home.Check_sum' | translate) + ' ' + currencyName"
      type="number"
      placeholder="0"
      formControlName="maxAmount"
      [errorMsg]="amountError"
    />
    <app-input
      [title]="('Admin.Home.Commission' | translate) + ' %'"
      type="number"
      placeholder="0"
      formControlName="percent"
      [errorMsg]="percentError"
    />
    <button
      type="submit"
      class="btn-primary btn-large mt-2"
      [disabled]="form.invalid || isFormPending || !!amountError"
    >
      {{ "Admin.Home.Add" | translate }}
    </button>
  </form>
</ng-template>

<ng-template let-modal #editWithdrawCommissionModal>
  <div class="flex items-center justify-between p-3">
    <h4 class="title">{{ "Admin.Home.Withdraw_commission_for" | translate }} {{ currencyName }}</h4>
    <button class="btn-flat w-6" (click)="modal.close()">
      <app-svg-icon name="close" />
    </button>
  </div>
  <form class="mt-6 flex flex-col gap-y-4" [formGroup]="editWithdrawForm" (ngSubmit)="onEditWithdrawSubmit()">
    <app-input
      [title]="('Admin.Home.Commission' | translate) + ' %'"
      type="number"
      placeholder="0"
      formControlName="percent"
      [errorMsg]="editWithdrawError('percent')"
    />
    <app-input
      [title]="('Admin.Home.Internal_withdraw_commission' | translate) + ' %'"
      type="number"
      placeholder="0"
      formControlName="internalPercent"
      [errorMsg]="editWithdrawError('internalPercent')"
    />
    <button
      type="submit"
      class="btn-primary btn-large mt-2"
      [disabled]="editWithdrawForm.invalid || isEditWithdrawPending"
    >
      {{ "Common.Confirm" | translate }}
    </button>
  </form>
</ng-template>

<ng-template #deleteCommissionModal let-modal>
  <div class="flex items-center justify-between p-3">
    <h4 class="title">{{ "Admin.Home.Commission_deletion" | translate }}</h4>
    <button class="btn-flat w-6" (click)="modal.close()">
      <app-svg-icon name="close" />
    </button>
  </div>
  <div class="mt-8 flex flex-col text-center">
    <app-status-mark variant="error" icon="settings/trash" />
    <p class="mt-4 font-medium">{{ "Admin.Home.Delete_commission_confirmation" | translate }}</p>
    <p class="font-bold">
      {{
        "Admin.Home.Up_to"
          | translate
            : {
                value: commissionToDelete?.maxAmount | mfeCustom: { currency: commissionToDelete?.currency },
                ticker: currencyName
              }
      }}
      <span class="text-text-secondary">{{ commissionToDelete?.percents }}%</span>
    </p>
    <button class="btn-primary-danger btn-large mt-8" (click)="deleteCommission(commissionToDelete?.id!)">
      {{ "Admin.Home.Delete_commission" | translate }}
    </button>
  </div>
</ng-template>

<ng-template let-modal #editSwapCommissionModal>
  <div class="flex items-center justify-between p-3">
    <h4 class="title">{{ "Admin.Home.Usdt_swap_commission" | translate: { currency: currencyName } }}</h4>
    <button class="btn-flat w-6" (click)="modal.close()">
      <app-svg-icon name="close" />
    </button>
  </div>
  <form class="mt-6 flex flex-col gap-y-4" [formGroup]="editSwapForm" (ngSubmit)="onEditSwapSubmit()">
    <app-input
      [title]="('Admin.Home.Swap_usdt_commission' | translate) + ' %'"
      type="number"
      placeholder="0"
      formControlName="percent"
      [errorMsg]="getEditSwapFieldError('percent')"
    />
    <app-input
      [title]="('Admin.Home.Asset_swap_commission' | translate) + ' %'"
      type="number"
      placeholder="0"
      formControlName="usdtPercent"
      [errorMsg]="getEditSwapFieldError('usdtPercent')"
    />
    <button
      type="submit"
      class="btn-primary btn-large mt-2"
      [disabled]="editSwapForm.invalid || isEditSwapPending"
    >
      {{ "Common.Confirm" | translate }}
    </button>
  </form>
</ng-template>
