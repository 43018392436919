import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { BalanceDto } from "src/app/common/DTO/balances/balance.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { MfeCustomPipe } from "src/app/common/pipes/mfe-custom.pipe";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { ScreenSizeService } from "src/app/services/screen-size.service";

@Component({
  selector: "app-balance-row",
  templateUrl: "./balance-row.component.html",
  styleUrls: ["./balance-row.component.css"],
})
export class BalanceRowComponent {
  @Input() balanceItem: BalanceDto = new BalanceDto();
  @Input() rates: RateDto[] = [];
  @Input() ratePercentChange: number | null = 0;
  @Input() isLoading: boolean = false;
  @Input() inStock: boolean = false;
  @Input() isBalanceVisible: boolean = true;
  @Input() mobileBalanceCurrency: CryptoSymbol = CryptoSymbol.AbstractUsdt;
  @Input() asLink: boolean = false;

  @Output() receiveClick = new EventEmitter<BalanceDto>();
  @Output() sendClick = new EventEmitter<BalanceDto>();
  @Output() swapClick = new EventEmitter<BalanceDto>();

  public CryptoSymbol = CryptoSymbol;
  public isMobile: boolean = false;

  constructor(
    private router: Router,
    private _screenSizeService: ScreenSizeService
  ) {
    this._screenSizeService.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  public getUzsValue(amount: number) {
    if (!this.rates || this.rates.length === 0) {
      return 0;
    }
    const uzs = ConvertCurrencyHelper.convertToUzs(amount, this.balanceItem.currency, this.rates);
    const formatted = new MfeCustomPipe().transform(uzs, {
      currency: CryptoSymbol.Uzs,
    });
    return formatted;
  }

  public getUsdValue(amount: number) {
    if (!this.rates || this.rates.length === 0) {
      return 0;
    }
    const usd = ConvertCurrencyHelper.convertToUsd(amount, this.balanceItem.currency, this.rates);
    const formatted = new MfeCustomPipe().transform(usd, {
      fiat: true,
    });
    return formatted;
  }

  handleClick() {
    if (this.asLink && this.isMobile) {
      this.router.navigate([RouteConstants.depositary, RouteConstants.assets, this.balanceItem.currency]);
    }
  }

  handleReceiveClick(event: any) {
    event.stopPropagation();
    this.receiveClick.emit(this.balanceItem);
  }

  handleSendClick(event: any) {
    event.stopPropagation();
    this.sendClick.emit(this.balanceItem);
  }

  handleSwapClick(event: any) {
    event.stopPropagation();
    this.swapClick.emit(this.balanceItem);
  }

  public formatAmount(amount: string | number, prefix: string = ""): string {
    let value = "0";

    if (amount === "0") return value;

    const str = amount.toString();
    if (str.length > 11) {
      value = str.slice(0, 11);
    } else {
      value = str;
    }

    const withPrefix = `${prefix}${value}`;
    return withPrefix;
  }
}
