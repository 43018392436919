<div class="relative w-[32rem] max-md:w-full">
  <div class="flex items-center p-3 max-md:px-0">
    <h2 class="title">{{ "Kyc.Verification" | translate }}</h2>
    <button (click)="onClose()" class="btn-flat ml-auto">
      <app-svg-icon name="close" class="h-6 w-6" />
    </button>
  </div>

  <div *ngIf="isCameraAccessPrompting || hasCameraAccess" class="mt-8 max-md:mt-6">
    <app-status-mark variant="warning" icon="user" />
    <p class="mt-4 text-center">{{ "Kyc.Verification_needed" | translate }}</p>
  </div>
  <div *ngIf="!isCameraAccessPrompting && !hasCameraAccess" class="mt-8 max-md:mt-6">
    <app-status-mark variant="error" icon="camera-off" />
    <p class="mt-4 text-center">{{ "Kyc.Camera_not_enabled" | translate }}</p>
  </div>

  <div class="mt-8 space-y-4 max-md:mt-6 max-md:space-y-3">
    <a *ngIf="!isMiniApp" [href]="myIdLink" target="_blank" class="btn-primary btn-large w-full">
      {{ "Kyc.Proceed_with_mobile_app" | translate }}</a
    >
    <button
      *ngIf="isMiniApp"
      class="btn-primary btn-large w-full"
      (click)="handleRedirectFromTelegramMiniApp()"
    >
      {{ "Kyc.Proceed_with_mobile_app" | translate }}
    </button>

    <a
      *ngIf="isCameraAccessPrompting || hasCameraAccess"
      [routerLink]="'/' + RouteConstants.newMyIdVerification"
      class="btn-primary btn-large w-full"
      [ngClass]="{ 'pointer-events-none opacity-50': isCameraAccessPrompting }"
      (click)="onClose()"
    >
      {{ "Kyc.Proceed_on_website" | translate }}
    </a>
    <button
      *ngIf="!isCameraAccessPrompting && !hasCameraAccess"
      (click)="onClose()"
      class="btn-primary-dark btn-large w-full"
    >
      {{ "Common.Close" | translate }}
    </button>
  </div>
</div>
