<div class="relative w-[32rem] max-md:w-full">
  <div class="flex items-center justify-between p-3">
    <h4 class="title">
      <ng-container *ngIf="step === Step.Input">
        {{ "Bank.Adding_card" | translate }}
      </ng-container>
      <ng-container *ngIf="step === Step.Otp">
        {{ "Bank.Enter_code" | translate }}
      </ng-container>
      <ng-container *ngIf="step === Step.Success">
        {{ "Bank.Success" | translate }}
      </ng-container>
    </h4>
    <button (click)="onClose()" class="btn-flat">
      <app-svg-icon name="close" class="h-6 w-6" />
    </button>
  </div>

  <ng-container [ngSwitch]="step">
    <!-- Input -->
    <ng-template [ngSwitchCase]="Step.Input">
      <form [formGroup]="addCardForm" (ngSubmit)="onCardSubmit()" class="mt-6 flex flex-col gap-1">
        <p class="flex items-center gap-x-2 text-sm font-semibold text-text-secondary md:hidden">
          <span class="h-3 w-3 shrink-0 rounded bg-green-400 dark:bg-green-400"></span>
          {{ "Bank.Card_number" | translate }}
        </p>
        <app-bullet-row
          name="{{ 'Bank.Card_number' | translate }}"
          class="rounded-2xl border border-gray-100 px-4 py-5 dark:border-slate-700"
          bulletClass="bg-green-400 dark:!bg-green-400"
          rowClass="!grid-cols-[10rem_auto] max-md:[&>div]:hidden"
        >
          <app-input
            [placeholder]="'Bank.Card_number_placeholder' | translate"
            formControlName="number"
            id="number"
            mask="0000 0000 0000 0000"
            class="[&_input]:rounded-none [&_input]:border-none [&_input]:bg-transparent [&_input]:p-0 [&_input]:dark:bg-transparent"
          />
        </app-bullet-row>
        <p *ngIf="getNumberErrMsg" class="text-xs font-semibold text-red-400">
          {{ getNumberErrMsg }}
        </p>

        <p class="flex items-center gap-x-2 text-sm font-semibold text-text-secondary md:hidden">
          <span class="h-3 w-3 shrink-0 rounded bg-yellow-500 dark:bg-yellow-500"></span>
          {{ "Bank.Card_expiration_date" | translate }}
        </p>
        <app-bullet-row
          name="{{ 'Bank.Card_expiration_date' | translate }}"
          class="rounded-2xl border border-gray-100 px-4 py-5 dark:border-slate-700"
          bulletClass="bg-yellow-500 dark:!bg-yellow-500"
          rowClass="!grid-cols-[10rem_auto] max-md:[&>div]:hidden"
        >
          <app-input
            type="text"
            formControlName="expiryAt"
            id="expiryAt"
            mask="00/00"
            placeholder="мм/гг"
            [dropSpecialCharacters]="false"
            class="[&_input]:rounded-none [&_input]:border-none [&_input]:bg-transparent [&_input]:p-0 [&_input]:dark:bg-transparent"
          />
        </app-bullet-row>
        <p *ngIf="getExpiryAtErrMsg" class="text-xs font-semibold text-red-400">
          {{ getExpiryAtErrMsg }}
        </p>

        <button
          type="submit"
          class="btn-primary btn-large mt-4 w-full"
          [disabled]="addCardForm.invalid || isPending"
        >
          {{ "Common.Add" | translate }}
        </button>
      </form>

      <p *ngIf="businessError" class="mt-4 text-sm text-red-400">{{ businessError }}</p>
    </ng-template>

    <!-- Otp -->
    <ng-template [ngSwitchCase]="Step.Otp">
      <p class="mt-4 text-center text-text-secondary">
        {{ "Bank.Otp_code_sent" | translate }}
        <br />
        {{ maskedPhoneNumber }}
      </p>

      <form [formGroup]="addCardOtpForm" (ngSubmit)="onCodeSubmit()" class="mt-8">
        <app-otp-input
          [initialTimerValue]="otpTimer"
          formControlName="cardVerificationCode"
          (resendCode)="sendCodeToPhone()"
        />
        <re-captcha
          *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
          (resolved)="captchaResolved($event)"
        ></re-captcha>

        <p *ngIf="businessError" class="mt-4 text-sm font-semibold text-red-400 dark:text-red-200">
          {{ businessError }}
        </p>

        <button
          type="submit"
          class="btn-primary btn-large mt-4 w-full"
          [disabled]="addCardOtpForm.invalid || isPending"
        >
          {{ "Bank.Add_card" | translate }}
        </button>
      </form>
    </ng-template>

    <!-- Success -->
    <ng-template [ngSwitchCase]="Step.Success">
      <div class="mt-8 max-md:mt-6">
        <app-status-mark variant="success" icon="check" />
        <p class="mt-4 text-center text-xl font-bold text-green-500 dark:text-green-200">
          {{ "Bank.Card_added" | translate }}
        </p>
      </div>
      <button class="btn-primary-dark btn-large mt-8 w-full max-md:mt-6" (click)="onClose()">
        {{ "Common.Close" | translate }}
      </button>
    </ng-template>
  </ng-container>
</div>
