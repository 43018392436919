import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { EnvService } from "src/app/services/env.service";

@Injectable({
  providedIn: "root",
})
export class RatesService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _env: EnvService
  ) {}

  public async getRates(): Promise<HttpResultDto<null, RateDto[]>> {
    const uri = `${this._env.serverUrl}${UrnConstants.GetRates}`;
    const result = new HttpResultDto<null, RateDto[]>(false);
    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<RateDto[]>;
      const tronUsdtRate = res.data.find(x => x.symbol === CryptoSymbol.Usdt);
      const abstractUsdtRate: RateDto = {
        symbol: CryptoSymbol.AbstractUsdt,
        usd: tronUsdtRate?.usd ?? 1,
        autoupdates: false,
      };
      result.params = [...res.data, abstractUsdtRate];
    } catch (e) {
      result.withError = true;
    }

    return result;
  }
}
