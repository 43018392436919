<button
  class="flex items-center justify-center rounded-full transition-transform hover:scale-105"
  [ngClass]="{ 'h-8 w-8': size === 'md', 'h-7 w-7': size === 'sm' }"
  (click)="toggleLanguage()"
>
  <img
    class="h-full w-full"
    [src]="
      currentLanguage === LanguageConstants.UZBEK
        ? 'assets/images/language/uz.png'
        : 'assets/images/language/ru.png'
    "
    [alt]="currentLanguage"
  />
</button>
