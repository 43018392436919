<div class="box space-y-2 px-3">
  <form class="admin-value-row-wrapper" [formGroup]="editNetworkFeeForm">
    <p>{{ "Admin.Home.Network_fee_commission" | translate }}:</p>
    <div class="admin-value-row">
      <input
        *ngIf="isEditingNetworkFee; else networkFeeValue"
        type="number"
        formControlName="amount"
        placeholder="0"
        class="w-full bg-transparent text-sm font-medium"
      />
      <ng-template #networkFeeValue>
        <p class="text-sm font-medium">{{ currentNetworkFee?.amount }}</p>
      </ng-template>

      <button
        *ngIf="hasInteractionPermissions"
        class="btn-flat h-6 w-6"
        (click)="toggleEditNetworkFee()"
        [disabled]="isEditNetworkFeePending"
      >
        <app-svg-icon [name]="isEditingNetworkFee ? 'check' : 'edit'" />
      </button>
    </div>
    <span *ngIf="networkFeeAmountError" class="text-xs text-red-400">
      {{ networkFeeAmountError }}
    </span>
  </form>

  <form class="admin-value-row-wrapper" [formGroup]="editMinBalanceForm">
    <p>{{ "Admin.Home.Min_balance_for_withdraw" | translate }}:</p>
    <div class="admin-value-row">
      <input
        *ngIf="isEditingMinBalance; else minBalanceValue"
        type="number"
        formControlName="amount"
        placeholder="0"
        class="w-full bg-transparent text-sm font-medium"
      />
      <ng-template #minBalanceValue>
        <p class="text-sm font-medium">{{ currentMinBalance?.amount }}</p>
      </ng-template>

      <button
        *ngIf="hasInteractionPermissions"
        class="btn-flat w-6"
        (click)="toggleEditMinBalance()"
        [disabled]="isEditMinBalancePending"
      >
        <app-svg-icon [name]="isEditingMinBalance ? 'check' : 'edit'" />
      </button>
    </div>

    <span *ngIf="minBalanceAmountError" class="text-xs text-red-400">
      {{ minBalanceAmountError }}
    </span>
  </form>
</div>
