import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

type Theme = "dark" | "light";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private themeSubject = new BehaviorSubject<Theme>("light");
  public theme$ = this.themeSubject.asObservable();

  private readonly LS_THEME_KEY = "theme";

  constructor() {
    this.loadTheme();
  }

  public toggleTheme() {
    const newTheme = this.themeSubject.value === "light" ? "dark" : "light";
    this.themeSubject.next(newTheme);
    this.saveTheme(newTheme);
    this.applyTheme(newTheme);
  }

  public setTheme(theme: Theme) {
    this.themeSubject.next(theme);
    this.saveTheme(theme);
    this.applyTheme(theme);
  }

  private saveTheme(theme: Theme) {
    localStorage.setItem(this.LS_THEME_KEY, theme);
  }

  private loadTheme() {
    const themeFromLocalStorage = localStorage.getItem(this.LS_THEME_KEY) as Theme;
    if (themeFromLocalStorage) {
      this.themeSubject.next(themeFromLocalStorage);
      this.applyTheme(themeFromLocalStorage);
    }
  }

  private applyTheme(theme: Theme) {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }
}
