export class RiskSignalDto {
  public exchange: number = 0;
  public riskyExchange: number = 0;
  public p2PExchange: number = 0;
  public p2PExchangeMlRiskHigh: number = 0;
  public exchangeMlRiskHigh: number = 0;
  public exchangeMlRiskLow: number = 0;
  public exchangeMlRiskModerate: number = 0;
  public exchangeMlRiskVeryHigh: number = 0;
  public p2PExchangeMlRiskLow: number = 0;
  public enforcementAction: number = 0;
  public atm: number = 0;
  public childExploitation: number = 0;
  public darkMarket: number = 0;
  public darkService: number = 0;
  public exchangeFraudulent: number = 0;
  public gambling: number = 0;
  public illegalService: number = 0;
  public liquidityPools: number = 0;
  public marketplace: number = 0;
  public miner: number = 0;
  public mixer: number = 0;
  public other: number = 0;
  public payment: number = 0;
  public ransom: number = 0;
  public sanctions: number = 0;
  public scam: number = 0;
  public seizedAssets: number = 0;
  public stolenCoins: number = 0;
  public terrorismFinancing: number = 0;
  public wallet: number = 0;
  public fraudShop: number = 0;
  public illicitActorOrg: number = 0;
  public highRiskJurisdiction: number = 0;
  public infrastructureAsAService: number = 0;
  public lendingContract: number = 0;
  public decentralizedExchangeContract: number = 0;
  public smartContract: number = 0;
  public tokenSmartContract: number = 0;
  public protocolPrivacy: number = 0;
  public merchantServices: number = 0;
  public ico: number = 0;
  public unnamedService: number = 0;
  public specialMeasures: number = 0;
  public onlinePharmacy: number = 0;
  public bridge: number = 0;
  public malware: number = 0;
  public nftPlatformCollection: number = 0;
}
