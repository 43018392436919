import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { TimeHelperUtil } from "src/app/common/utils/time-helper.util";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard {
  constructor(
    private readonly _localStorage: LocalStorageService,
    private _router: Router
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const token = await this._localStorage.accessToken();
    const tokenLifetime = await this._localStorage.accessTokenLifetime();
    const refresh = await this._localStorage.refreshToken();
    const refreshLifetime = await this._localStorage.refreshTokenLifetime();

    if ((token == null || tokenLifetime == null) && (refresh == null || refreshLifetime == null)) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
      return false;
    }

    if (
      !TimeHelperUtil.checkLifetimeIsValid(tokenLifetime) &&
      !TimeHelperUtil.checkLifetimeIsValid(refreshLifetime)
    ) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
      return false;
    }

    const userData = await this._localStorage.getUserData();
    if (!userData?.role.isAdmin) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
      return false;
    }

    return true;
  }
}
