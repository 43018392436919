<main class="flex h-screen items-center justify-center bg-gray-50 dark:bg-slate-900">
  <section
    class="mx-auto w-[32rem] rounded-[2rem] border border-gray-100 bg-gray-25 p-8 shadow-xl dark:border-slate-700 dark:bg-slate-800"
  >
    <div class="flex items-center justify-between p-3">
      <h1 class="title">{{ "Login.Enter" | translate }}</h1>
      <a routerLink="/" class="btn-flat">
        <app-svg-icon name="actions/close" class="h-6 w-6"></app-svg-icon>
      </a>
    </div>

    <form class="mt-6" [formGroup]="adminForm" (ngSubmit)="handleLogin()">
      <app-input
        [title]="'Login.Login' | translate"
        [placeholder]="'Login.Enter_login' | translate"
        type="text"
        id="login"
        formControlName="login"
        autocomplete="off"
      />
      <app-input
        [title]="'Login.Password' | translate"
        [placeholder]="'Login.Enter_password' | translate"
        type="password"
        id="psw"
        formControlName="psw"
        [withPasswordToggle]="true"
        class="mt-4 block"
      />

      <small *ngIf="authError" class="mt-2 text-sm text-invalid-color">{{ authError }}</small>

      <button
        type="submit"
        class="btn-primary btn-large mt-6 w-full"
        [disabled]="adminForm.invalid || isPending"
      >
        {{ "Login.Enter" | translate }}
      </button>
    </form>
  </section>
</main>
