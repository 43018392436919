import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { ToastService } from "src/app/services/toast.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-delete-user-kyc-modal",
  templateUrl: "./delete-user-kyc-modal.component.html",
  styleUrls: ["./delete-user-kyc-modal.component.css"],
})
export class DeleteUserKycModalComponent {
  @Input() user: UserDto | null = null;

  @Output() onSuccess: EventEmitter<void> = new EventEmitter<void>();

  public isPending: boolean = false;

  constructor(
    private readonly _modalRef: NgbActiveModal,
    private readonly _userService: UserService,
    private readonly _toastService: ToastService,
    private readonly _translateService: TranslateService
  ) {}

  public onClose() {
    this._modalRef.close();
  }

  public get userFullName() {
    if (!this.user) {
      return null;
    }
    return this.user.kyc?.firstName + " " + this.user.kyc?.lastName;
  }

  public async onDelete() {
    if (!this.user) {
      return;
    }
    this.isPending = true;
    await this.handleDeleteIdentificationConfirm(this.user.id);
    this.onSuccess.emit();
    this.isPending = false;
    this.onClose();
  }

  private async handleDeleteIdentificationConfirm(userId: number) {
    const response = await this._userService.deleteUserIdentification(userId);

    if (response.withError) {
      this._toastService.show(this._translateService.instant("Common.Unknown_error"));
    } else {
      this._toastService.show(this._translateService.instant("Admin.Users.Success_delete_identification"));
    }
  }
}
