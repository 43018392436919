<div class="box px-3">
  <div class="flex items-center justify-between border-b border-gray-100 pb-5 dark:border-slate-700">
    <h3 class="altitle">{{ "Admin.Home.Tron_energy" | translate }}</h3>
    <button class="btn-flat" (click)="toggleEditing()" [disabled]="isPending">
      <app-svg-icon [name]="isEditing ? 'check' : 'edit'" class="block h-6 w-6" />
    </button>
  </div>
  <form [formGroup]="tronEnergyForm" class="mt-5 space-y-2">
    <div class="admin-value-row-wrapper">
      <p>{{ "Admin.Home.Master_energy_count" | translate }}:</p>
      <div *ngIf="isEditing; else masterEnergyCount">
        <input type="number" formControlName="masterEnergyCount" class="admin-value-row" />
        <small *ngIf="getFieldError('masterEnergyCount')" class="text-xs text-red-400">
          {{ getFieldError("masterEnergyCount") }}
        </small>
      </div>
      <ng-template #masterEnergyCount>
        <div class="admin-value-row">{{ energyCount.masterEnergyCount | mfeCustom }}</div>
      </ng-template>
    </div>

    <div class="admin-value-row-wrapper">
      <p>{{ "Admin.Home.User_energy_count" | translate }}:</p>
      <div *ngIf="isEditing; else userEnergyCount">
        <input type="number" formControlName="userEnergyCount" class="admin-value-row" />
        <small *ngIf="getFieldError('userEnergyCount')" class="text-xs text-red-400">
          {{ getFieldError("userEnergyCount") }}
        </small>
      </div>
      <ng-template #userEnergyCount>
        <div class="admin-value-row">{{ energyCount.userEnergyCount | mfeCustom }}</div>
      </ng-template>
    </div>
  </form>
</div>
