<div class="flex flex-col max-md:h-full md:w-[28rem]">
  <!-- Header -->
  <div class="flex items-center px-3 py-4 max-md:px-0 max-md:py-3">
    <button
      *ngIf="step === Step.CurrencySelect || step === Step.Confirm"
      class="btn-flat mr-3"
      (click)="onBack()"
    >
      <app-svg-icon name="actions/back" class="h-8 w-8" />
    </button>
    <h2 class="title max-md:text-xl">
      <ng-template [ngIf]="step === Step.Input">{{ "Swap.Title" | translate }}</ng-template>
      <ng-template [ngIf]="step === Step.CurrencySelect">{{ "Swap.Choose_asset" | translate }}</ng-template>
      <ng-template [ngIf]="step === Step.Confirm">{{ "Swap.Confirmation" | translate }}</ng-template>
      <ng-template [ngIf]="step === Step.Success">{{ "Swap.Success_transaction" | translate }}</ng-template>
      <ng-template [ngIf]="step === Step.Error">{{ "Swap.Error_transaction" | translate }}</ng-template>
    </h2>
    <button (click)="onClose()" class="btn-flat ml-auto h-6 w-6">
      <app-svg-icon name="close" />
    </button>
  </div>

  <ng-container [ngSwitch]="step">
    <!-- Input -->
    <ng-template [ngSwitchCase]="Step.Input">
      <form
        class="mt-6 rounded-2xl max-md:mt-3 max-md:flex max-md:flex-col"
        [ngClass]="{ 'pointer-events-none animate-pulse opacity-75': isPending }"
        [formGroup]="calculatorForm"
        (ngSubmit)="onInputSubmit()"
      >
        <!-- From currency -->
        <div class="rounded-2xl md:border md:border-gray-100 md:p-4 md:dark:border-slate-700">
          <div class="grid grid-cols-2 gap-x-4 max-md:grid-cols-1 max-md:gap-y-3">
            <!-- From currency selector -->
            <label class="flex flex-col gap-y-2">
              <p class="font-medium">{{ "Swap.From" | translate }}</p>
              <button
                (click)="openCurrencySelect('from')"
                class="flex w-full items-center rounded-2xl border border-gray-300 px-3 py-2.5 dark:border-slate-500 max-md:hidden"
              >
                <app-currency-icon [currency]="fromCurrency" size="sm" />
                <span class="ml-2 font-medium">
                  {{ fromCurrencyName }}
                </span>
                <app-svg-icon name="round-next" class="ml-auto h-6 w-6 text-gray-100 dark:text-slate-700" />
              </button>
            </label>

            <!-- From currency input -->
            <label class="flex flex-col gap-y-2">
              <p class="font-medium max-md:hidden">{{ "Swap.Sum" | translate }}</p>
              <div class="relative grow">
                <input
                  type="number"
                  id="fromAmount"
                  name="fromAmount"
                  formControlName="fromAmount"
                  appPositiveDecimals
                  placeholder="0"
                  class="h-full w-full rounded-2xl border border-gray-300 bg-transparent px-3 py-2.5 font-medium placeholder:text-text-tertiary focus:outline-none focus:placeholder:text-transparent dark:border-slate-500 max-md:rounded-xl max-md:py-3 max-md:pr-28 max-md:text-2xl"
                  [autofocus]="true"
                  autocomplete="off"
                  [maxLength]="16"
                  (input)="handleAmountChange($event, 'from')"
                />
                <!-- Max button, hidden on mobile -->
                <button
                  *ngIf="!fromAmount"
                  type="button"
                  (click)="setMaxFromAmount()"
                  class="btn-primary btn-small absolute right-3 top-1/2 -translate-y-1/2 rounded px-2 py-1 text-xs max-md:hidden"
                >
                  MAX
                </button>
                <!-- Currency select button, visible on mobile -->
                <button
                  type="button"
                  (click)="openCurrencySelect('from')"
                  class="absolute right-3 top-1/2 flex w-fit -translate-y-1/2 items-center md:hidden"
                >
                  <app-currency-icon [currency]="fromCurrency" size="sm" class="[&>div]:h-5 [&>div]:w-5" />
                  <span class="ml-2 text-xs font-medium">
                    {{ fromCurrencyName }}
                  </span>
                  <app-svg-icon name="round-next" class="ml-3 h-4 w-4 text-gray-100 dark:text-slate-700" />
                </button>
              </div>
            </label>
          </div>

          <p *ngIf="isCalculationsPending" class="mt-2 text-sm font-medium text-text-secondary">
            {{ "Swap.Calculating" | translate }}
          </p>
          <p
            *ngIf="fromAmountError && !isCalculationsPending"
            class="mt-2 text-sm font-medium text-red-500 dark:text-red-200"
          >
            {{ fromAmountError }}
          </p>

          <p *ngIf="fromCurrency !== CryptoSymbol.Uzs" class="mt-3 text-sm font-medium text-text-secondary">
            {{ "Swap.Available" | translate }}:
            <button
              type="button"
              class="max-md:text-green-400 max-md:dark:text-green-200"
              (click)="setMaxFromAmount()"
            >
              {{ fromWalletBalance?.availableAmount | mfeCustom: { currency: fromWalletBalance?.currency } }}
              {{ fromCurrencyName }}
            </button>
          </p>
        </div>

        <!-- Swap button -->
        <div class="relative my-3">
          <button
            type="button"
            (click)="swapDirection()"
            title="{{ 'Swap.Swap_assets' | translate }}"
            class="relative z-10 mx-auto block h-12 w-12 transition-all hover:scale-105"
          >
            <app-svg-icon name="actions/swap" class="block" />
          </button>
          <hr
            class="absolute left-1/2 top-1/2 z-0 w-full -translate-x-1/2 -translate-y-1/2 border-gray-300 dark:border-slate-700 md:hidden"
          />
        </div>

        <!-- To currency -->
        <div class="rounded-2xl md:border md:border-gray-100 md:p-4 md:dark:border-slate-700">
          <div class="grid grid-cols-2 gap-x-4 max-md:grid-cols-1 max-md:gap-y-3">
            <!-- To currency selector -->
            <label class="flex flex-col gap-y-2">
              <p class="font-medium">{{ "Swap.To" | translate }}</p>
              <button
                type="button"
                (click)="openCurrencySelect('to')"
                class="flex w-full items-center rounded-2xl border border-gray-300 px-3 py-2.5 dark:border-slate-500 max-md:hidden"
              >
                <app-currency-icon [currency]="toCurrency" size="sm" />
                <span class="ml-2 font-medium">
                  {{ toCurrencyName }}
                </span>
                <app-svg-icon name="round-next" class="ml-auto h-6 w-6 text-gray-100 dark:text-slate-700" />
              </button>
            </label>

            <!-- To currency input -->
            <label class="flex flex-col gap-y-2">
              <p class="font-medium max-md:hidden">{{ "Swap.Sum" | translate }}</p>
              <div class="relative grow">
                <input
                  type="number"
                  name="toAmount"
                  formControlName="toAmount"
                  appPositiveDecimals
                  placeholder="0"
                  class="h-full w-full rounded-2xl border border-gray-300 bg-transparent px-3 py-2.5 font-medium placeholder:text-text-tertiary focus:outline-none focus:placeholder:text-transparent dark:border-slate-500 max-md:rounded-xl max-md:py-3 max-md:pr-28 max-md:text-2xl"
                  [autofocus]="true"
                  autocomplete="off"
                  [maxLength]="16"
                  (input)="handleAmountChange($event, 'to')"
                />
                <!-- Currency select button, visible on mobile -->
                <button
                  type="button"
                  (click)="openCurrencySelect('to')"
                  class="absolute right-3 top-1/2 flex w-fit -translate-y-1/2 items-center md:hidden"
                >
                  <app-currency-icon [currency]="toCurrency" size="sm" class="[&>div]:h-5 [&>div]:w-5" />
                  <span class="ml-2 text-xs font-medium">
                    {{ toCurrencyName }}
                  </span>
                  <app-svg-icon name="round-next" class="ml-3 h-4 w-4 text-gray-100 dark:text-slate-700" />
                </button>
              </div>
            </label>
          </div>

          <p *ngIf="toCurrency !== CryptoSymbol.Uzs" class="mt-3 text-sm font-medium text-text-secondary">
            <span>{{ "Swap.Available" | translate }}:</span>
            <span class="max-md:text-green-400 max-md:dark:text-green-200">
              {{ toWalletBalance?.availableAmount | mfeCustom: { currency: toWalletBalance?.currency } }}
              {{ toCurrencyName }}
            </span>
          </p>
        </div>

        <div class="max-md:mt-auto">
          <p class="mt-4 text-sm font-semibold text-text-secondary">
            {{ "Swap.Commission_size" | translate }}:
            <span class="text-green-400 dark:text-green-200">
              {{ serviceFee | mfeCustom: { currency: toCurrency } }} {{ toCurrencyName }}
            </span>
          </p>

          <p class="mt-2 text-sm font-semibold text-text-secondary md:hidden">
            1 {{ oneCurrencyRate.fromCurrencyName }} = {{ oneCurrencyRate.rate }}
            {{ oneCurrencyRate.toCurrencyName }}
          </p>

          <button
            type="submit"
            class="btn-primary btn-large mt-4 w-full max-md:mt-3"
            [disabled]="calculatorForm.invalid || fromAmountError || toAmountError"
          >
            <ng-container
              *ngIf="fromCurrency == CryptoSymbol.Uzs || toCurrency == CryptoSymbol.Uzs; else swapCaption"
            >
              {{ fromCurrency === CryptoSymbol.Uzs ? ("Swap.Buy" | translate) : ("Swap.Sell" | translate) }}
            </ng-container>
            <ng-template #swapCaption>
              {{ "Swap.Swap" | translate }}
            </ng-template>
          </button>
        </div>
      </form>
    </ng-template>

    <!-- Currency select -->
    <ng-template [ngSwitchCase]="Step.CurrencySelect">
      <app-input
        type="search"
        class="mt-6 w-full"
        placeholder="{{ 'Swap.Search_asset' | translate }}"
        [(ngModel)]="currencySearch"
      />
      <div class="mt-4 flex flex-col gap-y-1 overflow-y-auto md:max-h-96">
        <button
          *ngFor="let c of currencies"
          (click)="onSelectCurrency(c.currency)"
          class="flex items-center gap-x-3 rounded-2xl border border-transparent p-3 transition-colors hover:border-gray-100 dark:hover:border-slate-700"
          [ngClass]="{
            'bg-gray-50 dark:bg-slate-900':
              currencyToSelect === 'from' ? fromCurrency === c.currency : toCurrency === c.currency
          }"
        >
          <app-currency-icon [currency]="c.currency" size="xs" />
          <p class="text-start font-semibold">
            {{ getCurrencyName(c.currency) }}
            <span class="block text-sm text-text-tertiary">{{ getNetworkName(c.network) }}</span>
          </p>
          <app-svg-icon
            *ngIf="currencyToSelect === 'from' ? fromCurrency === c.currency : toCurrency === c.currency"
            name="check"
            class="ml-auto h-4 w-4"
          />
        </button>
      </div>
    </ng-template>

    <!-- Confirmation -->
    <ng-template [ngSwitchCase]="Step.Confirm">
      <div class="mt-6">
        <app-bullet-row
          name="{{ 'Swap.Give' | translate }}"
          bulletClass="!bg-yellow-500"
          class="bordered-row"
        >
          <div class="flex items-center gap-x-2 text-sm font-semibold">
            <app-currency-icon [currency]="fromCurrency" size="xs" />
            <span>{{ fromCurrencyName }}</span>
            <span *ngIf="fromCurrency !== CryptoSymbol.Uzs" class="text-text-tertiary">
              {{ getCurrencyNetwork(fromCurrency) }}
            </span>
            <span class="ml-auto">{{ fromAmount | mfeCustom: { currency: fromCurrency } }}</span>
          </div>
        </app-bullet-row>

        <app-bullet-row
          name="{{ 'Swap.Receive' | translate }}"
          bulletClass="!bg-green-400"
          class="bordered-row"
        >
          <div class="flex items-center gap-x-2 text-sm font-semibold">
            <app-currency-icon [currency]="toCurrency" size="xs" />
            <span>{{ toCurrencyName }}</span>
            <span *ngIf="toCurrency !== CryptoSymbol.Uzs" class="text-text-tertiary">
              {{ getCurrencyNetwork(toCurrency) }}
            </span>
            <span class="ml-auto">{{ toAmount | mfeCustom: { currency: toCurrency } }}</span>
          </div>
        </app-bullet-row>

        <app-bullet-row
          name="{{ 'Swap.Commission' | translate }}"
          bulletClass="!bg-purple-500"
          class="bordered-row"
        >
          <div class="text-sm font-semibold">
            {{ serviceFee | mfeCustom: { currency: toCurrency } }} {{ toCurrencyName }}
          </div>
        </app-bullet-row>
      </div>

      <button class="btn-primary btn-large mt-4 w-full" (click)="onCheckout()" [disabled]="isPending">
        {{ "Common.Confirm" | translate }}
      </button>
    </ng-template>

    <!-- Success -->
    <ng-template [ngSwitchCase]="Step.Success">
      <app-status-mark variant="success" icon="check" class="mt-6 block" />
      <p class="mt-8 text-center text-xl font-bold text-green-500 dark:text-green-200 max-md:mt-8">
        {{ "Swap.Success" | translate }}
      </p>
      <div
        class="mt-4 grid grid-cols-[1fr_1.5rem_1fr] items-center gap-x-2 max-md:grid-cols-1 max-md:gap-y-1"
      >
        <div
          class="rounded-2xl bg-gray-100 p-4 text-center text-xl font-medium dark:bg-slate-700 max-md:text-base"
        >
          {{ fromAmount | mfeCustom: { currency: fromCurrency } }} {{ fromCurrencyName }}
        </div>
        <app-svg-icon
          name="actions/back"
          class="h-6 w-6 rotate-180 text-text-secondary max-md:mx-auto max-md:-rotate-90"
        />
        <div
          class="rounded-2xl bg-gray-100 p-4 text-center text-xl font-medium dark:bg-slate-700 max-md:text-base"
        >
          {{ toAmount | mfeCustom: { currency: toCurrency } }} {{ toCurrencyName }}
        </div>
      </div>
      <a
        [routerLink]="['/', RouteConstants.depositary, RouteConstants.transactions]"
        class="btn-primary btn-large mt-6 w-full text-center"
        (click)="onClose()"
      >
        <span class="max-md:hidden">{{ "Send.View_transaction_history" | translate }}</span>
        <span class="md:hidden">{{ "Send.View_transactions" | translate }}</span>
      </a>
    </ng-template>

    <!-- Error -->
    <ng-template [ngSwitchCase]="Step.Error">
      <app-status-mark variant="error" icon="trash-bin" class="mt-6 block" />
      <p class="mt-8 text-center text-xl font-bold text-red-500 dark:text-red-200 max-md:mt-8">
        {{ "Swap.Error" | translate }}
      </p>
      <div
        class="mt-4 rounded-2xl bg-gray-100 p-4 text-center text-sm font-medium text-text-secondary dark:bg-slate-700"
      >
        {{ swapError || ("Common.Unknown_error" | translate) }}
      </div>
      <a
        [routerLink]="['/', RouteConstants.depositary, RouteConstants.transactions]"
        class="btn-primary btn-large mt-8 w-full text-center"
        (click)="onClose()"
      >
        <span class="max-md:hidden">{{ "Send.View_transaction_history" | translate }}</span>
        <span class="md:hidden">{{ "Send.View_transactions" | translate }}</span>
      </a>
    </ng-template>
  </ng-container>
</div>
