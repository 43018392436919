import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { debounceTime, distinctUntilChanged, Subject } from "rxjs";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { UserActivationFilterOptions } from "src/app/common/enums/user-activation-filter-option.enum";
import { UserIdentificationFilterOptions } from "src/app/common/enums/user-identification-filter-options.enum";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { DeactivateUserModalComponent } from "src/app/components/_admin/deactivate-user-modal/deactivate-user-modal.component";
import { DeleteUserKycModalComponent } from "src/app/components/_admin/delete-user-kyc-modal/delete-user-kyc-modal.component";
import { BlackListService } from "src/app/services/black-list.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { RatesService } from "src/app/services/rates.service";
import { ReportsService } from "src/app/services/reports.service";
import { UserService } from "src/app/services/user.service";

interface IUserIdentificationFilter {
  name: string;
  value: UserIdentificationFilterOptions;
}

interface IUserActivationFilter {
  name: string;
  value: UserActivationFilterOptions;
}

@Component({
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.css"],
})
export class AdminUsersComponent implements OnInit {
  public users: UserDto[] = [];
  public page = 1;
  public pageSize = 10;
  public totalCount = 0;

  public userIdentificationFilter: IUserIdentificationFilter[] = [
    {
      name: this._translateService.instant("Admin.Users.Select_all"),
      value: UserIdentificationFilterOptions.All,
    },
    {
      name: this._translateService.instant("Admin.Users.Select_verified"),
      value: UserIdentificationFilterOptions.Verified,
    },
    {
      name: this._translateService.instant("Admin.Users.Select_not_verified"),
      value: UserIdentificationFilterOptions.NotVerified,
    },
  ];

  public userActivationFilter: IUserActivationFilter[] = [
    {
      name: this._translateService.instant("Admin.Users.Select_all"),
      value: UserActivationFilterOptions.All,
    },
    {
      name: this._translateService.instant("Admin.Users.Select_active"),
      value: UserActivationFilterOptions.Active,
    },
    {
      name: this._translateService.instant("Admin.Users.Select_not_active"),
      value: UserActivationFilterOptions.NotActive,
    },
  ];

  public selectedIdentificationFilter: UserIdentificationFilterOptions =
    this.userIdentificationFilter[0].value;
  public selectedActivationFilter: UserActivationFilterOptions = this.userActivationFilter[0].value;

  public search: string = "";
  public $search = new Subject<string>();

  public isLoading: boolean = true;
  public isLoadingMore: boolean = false;
  public hasMoreUsers: boolean = true;

  public isUserActionsInProgress: boolean = false;
  public hasInteractionPermissions: boolean = false;

  public rates: RateDto[] = [];

  constructor(
    private readonly _userService: UserService,
    private readonly _translateService: TranslateService,
    private readonly _blackListService: BlackListService,
    private readonly _reportService: ReportsService,
    private readonly _modalService: NgbModal,
    private readonly _localStorage: LocalStorageService,
    private readonly _ratesService: RatesService
  ) {
    this.$search.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async search => {
      await this.onSearch();
    });
  }

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.UserInteraction
    );
    this.rates = (await this._ratesService.getRates()).params ?? [];
    await this.requestAllUsers();
  }

  public async onSearch() {
    this.resetPagination();
    await this.requestAllUsers();
  }

  public resetPagination() {
    this.page = 1;
    this.users = [];
    this.totalCount = 0;
    this.hasMoreUsers = true;
  }

  public async loadMoreUsers() {
    if (this.isLoadingMore || !this.hasMoreUsers) {
      return;
    }

    this.isLoadingMore = true;
    this.page++;
    const res = await this._userService.getAllUsers(
      this.search,
      this.pageSize,
      this.page,
      this.selectedIdentificationFilter,
      this.selectedActivationFilter
    );
    if (res.params) {
      this.users = [...this.users, ...res.params.items];
      this.totalCount = res.params.totalCount;
      this.hasMoreUsers = this.users.length < this.totalCount;
    }
    this.isLoadingMore = false;
  }

  public renderStatus(user: UserDto) {
    if (user.blackList?.isForever) {
      return "Admin.Kyc.Black_list";
    }
    if (user.blackList?.isForever === false) {
      return "Admin.Users.Status_blocked";
    }
    if (!user.kyc) {
      return "Admin.Users.Status_no_kyc";
    }
    if (user.kyc) {
      return "Admin.Users.Status_verified";
    }

    return "-";
  }

  public isActiveCaption(user: UserDto): string {
    return user.isActive
      ? this._translateService.instant("Admin.Users.Active")
      : this._translateService.instant("Admin.Users.Not_active");
  }

  public getUsdtBalanceByUser(user: UserDto) {
    if (this.rates.length === 0 || user.balances.length === 0) {
      return 0;
    }

    let sum = 0;
    for (const balance of user.balances) {
      sum += ConvertCurrencyHelper.convertToUsd(balance.availableAmount, balance.currency, this.rates);
    }

    return sum;
  }

  public get userIdentificationFilterNames(): string[] {
    return this.userIdentificationFilter.map(filter => this._translateService.instant(filter.name));
  }

  public get userActivationFilterNames(): string[] {
    return this.userActivationFilter.map(filter => this._translateService.instant(filter.name));
  }

  public userFilterNameByIdentificationOption(option: UserIdentificationFilterOptions): string {
    return this._translateService.instant(
      this.userIdentificationFilter.filter(x => x.value == option)[0].name
    );
  }

  public userFilterNameByActivationOption(option: UserActivationFilterOptions): string {
    return this._translateService.instant(this.userActivationFilter.filter(x => x.value == option)[0].name);
  }

  public async onActivationFilterSelect(selectedItem: string) {
    this.selectedActivationFilter = this.userActivationFilter.filter(
      x => this._translateService.instant(x.name) == selectedItem
    )[0].value;
    this.resetPagination();
    await this.requestAllUsers();
  }

  public async onIdentificationFilterSelect(selectedItem: string) {
    this.selectedIdentificationFilter = this.userIdentificationFilter.filter(
      x => this._translateService.instant(x.name) == selectedItem
    )[0].value;
    this.resetPagination();
    await this.requestAllUsers();
  }

  public get selectedIdentificationFilterName() {
    const name =
      this.userIdentificationFilter.find(f => f.value === this.selectedIdentificationFilter)?.name ?? "";
    const translated = this._translateService.instant(name);
    return translated;
  }

  public handleDownloadReport(user: UserDto) {
    this._reportService.getUserPersonalDataReport(
      user.id,
      user.kyc?.firstName ?? "",
      user.kyc?.lastName ?? ""
    );
  }

  public async handleBlockUnblock(user: UserDto) {
    this.isUserActionsInProgress = true;

    if (user.blackList) {
      await this.unblockUser(user.id);
    } else {
      await this.blockUser(user.id);
    }

    await this.requestAllUsers();
    this.isUserActionsInProgress = false;
  }

  public openDeleteKycConfirmation(user: UserDto) {
    const modalRef = this._modalService.open(DeleteUserKycModalComponent);
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.onSuccess.subscribe(() => {
      this.requestAllUsers();
    });
  }

  public openDeactivateUserConfirmation(user: UserDto) {
    const modalRef = this._modalService.open(DeactivateUserModalComponent);
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.onSuccess.subscribe(() => {
      this.requestAllUsers();
    });
  }

  private async blockUser(userId: number) {
    await this._blackListService.blockUser(userId, false);
  }

  private async unblockUser(userId: number) {
    await this._blackListService.unblockUser(userId);
  }

  private async requestAllUsers() {
    this.isLoading = true;
    this.hasMoreUsers = true;

    const res = await this._userService.getAllUsers(
      this.search,
      this.pageSize,
      this.page,
      this.selectedIdentificationFilter,
      this.selectedActivationFilter
    );
    if (res.params) {
      this.users = res.params.items;
      this.totalCount = res.params.totalCount;
    }
    this.isLoading = false;
  }
}
