import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { TxDto } from "src/app/common/DTO/txs/tx.dto";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { Network } from "src/app/common/enums/network.enum";
import { TxDirection } from "src/app/common/enums/tx-direction.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { UserRiskLevel } from "src/app/common/enums/user-risk-level.enum";
import { MfeCustomPipe } from "src/app/common/pipes/mfe-custom.pipe";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { TxParserUtil } from "src/app/common/utils/tx-parser.util";
import { getWalletLink } from "src/app/common/utils/wallet-link-helper.util";
import { DeactivateUserModalComponent } from "src/app/components/_admin/deactivate-user-modal/deactivate-user-modal.component";
import { DeleteUserKycModalComponent } from "src/app/components/_admin/delete-user-kyc-modal/delete-user-kyc-modal.component";
import { BlackListService } from "src/app/services/black-list.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { RatesService } from "src/app/services/rates.service";
import { ReportsService } from "src/app/services/reports.service";
import { ToastService } from "src/app/services/toast.service";
import { TxService } from "src/app/services/tx.service";
import { UserService } from "src/app/services/user.service";

@Component({
  templateUrl: "./user-page.component.html",
  styleUrls: ["./user-page.component.css"],
})
export class UserPageComponent implements OnInit {
  public userRiskLevelsList = [
    {
      name: "Admin.Users.Risk_level_none",
      value: UserRiskLevel.None,
      color: "text-slate-400",
    },
    {
      name: "Admin.Users.Risk_level_low",
      value: UserRiskLevel.Low,
      color: "!text-green-500",
    },
    {
      name: "Admin.Users.Risk_level_medium",
      value: UserRiskLevel.Medium,
      color: "!text-gold-500",
    },
    {
      name: "Admin.Users.Risk_level_high",
      value: UserRiskLevel.High,
      color: "!text-red-500",
    },
  ];

  public isLoading: boolean = true;
  public userData: UserDto;

  public currencies: CryptoSymbol[] = [];
  public transactions: TxDto[] = [];
  public isTxsLoading: boolean = true;

  public rates: RateDto[] = [];

  public blockInProgress: boolean = false;
  public hasInteractionPermissions: boolean = false;

  public UserRiskLevel = UserRiskLevel;

  private userId: number = 0;
  private txsPage = 1;
  private txsPageSize = 10;
  private totalTxsCount = 0;
  private hasMoreTxs = true;
  private isTxsLoadingMore = false;

  public TxStatus = TxStatus;

  private readonly currenciesList = [
    CryptoSymbol.AbstractUsdt,
    CryptoSymbol.Trx,
    CryptoSymbol.Matic,
    CryptoSymbol.Ton,
    CryptoSymbol.Not,
    CryptoSymbol.Bitcoin,
  ];

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _blackListService: BlackListService,
    private readonly _router: Router,
    private readonly _userService: UserService,
    private readonly _translateService: TranslateService,
    private readonly _toastService: ToastService,
    private readonly _reportService: ReportsService,
    private readonly _modalService: NgbModal,
    private readonly _ratesService: RatesService,
    private readonly _txService: TxService
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.userId = params["id"];
    });
    this.userData = new UserDto();
  }

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.UserInteraction
    );
    await this.requestUserData();
    await this.loadRates();
    await this.loadTxs();
    this.isLoading = false;
  }

  public get currencyNames(): string[] {
    return this.currenciesList.map(c => getCurrencyName(c));
  }

  public get selectedCurrencyNames(): string[] {
    return this.currencies.map(c => getCurrencyName(c));
  }

  public onSelectCurrency(currencyName: string) {
    const currency = this.currenciesList.find(c => getCurrencyName(c) === currencyName);
    if (currency && !this.currencies.includes(currency)) {
      this.currencies.push(currency);
    } else {
      this.currencies = this.currencies.filter(c => c !== currency);
    }
    this.resetTxsPagination();
    this.loadTxs();
  }

  public removeCurrency(currency: CryptoSymbol, event: Event) {
    event.stopPropagation();
    this.currencies = this.currencies.filter(c => c !== currency);
    this.resetTxsPagination();
    this.loadTxs();
  }

  public get appliedFiltersCount(): number {
    return this.currencies.length;
  }

  public resetTxsPagination() {
    this.txsPage = 1;
    this.transactions = [];
  }

  public resetTxsFilters() {
    this.currencies = [];
    this.resetTxsPagination();
    this.loadTxs();
  }

  public handleDownloadReport() {
    this._reportService.getUserPersonalDataReport(
      this.userId,
      this.userData.kyc?.firstName ?? "",
      this.userData.kyc?.lastName ?? ""
    );
  }

  public get isUserBlocked() {
    return this.userData.blackList;
  }

  public get isUserBlockedForever() {
    return this.userData.blackList?.isForever;
  }

  public get isUserActive() {
    return this.userData.isActive;
  }

  public async handleBlockUnblock() {
    this.blockInProgress = true;

    if (this.isUserBlocked) {
      await this.unblockUser();
    } else {
      await this.blockUser();
    }

    this.blockInProgress = false;
  }

  public get hasTxs() {
    return this.transactions.length > 0;
  }

  public get userRiskLevel() {
    return this.userRiskLevelsList.find(x => x.value == this.userData.riskLevel);
  }

  public get userFullName() {
    if (!this.userData.kyc || !this.userData.kyc.firstName) {
      return null;
    }
    return this.userData.kyc?.firstName + " " + this.userData.kyc?.lastName;
  }

  public get userBalance() {
    return this.userData.balances.find(b => b.currency === CryptoSymbol.AbstractUsdt)?.availableAmount;
  }

  public getWalletLink(network: Network, wallet: string) {
    return getWalletLink(network, wallet);
  }

  public getNetworkName(network: Network) {
    return getNetworkName(network);
  }

  public onRiskLevelSelect(selectedRisk: UserRiskLevel) {
    this._userService
      .updateUserRiskLevel({
        userId: this.userData.id,
        userRiskLevel: selectedRisk,
      })
      .then(res => {
        if (res.withError) {
          this._toastService.error(this._translateService.instant("Admin.Users.Risk_level_update_fail"));
        } else {
          this.userData.riskLevel = selectedRisk;
          this._toastService.success(this._translateService.instant("Admin.Users.Risk_level_update_success"));
        }
      });
  }

  public handleDeleteIdentification() {
    const modalRef = this._modalService.open(DeleteUserKycModalComponent);
    modalRef.componentInstance.user = this.userData;
    modalRef.componentInstance.onSuccess.subscribe(() => {
      this.requestUserData();
    });
  }

  public handleDeactivate() {
    const modalRef = this._modalService.open(DeactivateUserModalComponent);
    modalRef.componentInstance.user = this.userData;
    modalRef.componentInstance.onSuccess.subscribe(() => {
      this.requestUserData();
    });
  }

  public getAmountInUsd(amount: number, currency: CryptoSymbol): string {
    if (this.rates.length === 0) return "0";

    const converted = ConvertCurrencyHelper.convertToUsd(+amount, currency, this.rates);
    const formatted = new MfeCustomPipe().transform(converted, {
      fiat: true,
    });
    return formatted;
  }

  public getTxDestination(tx: TxDto): string {
    if (tx.direction === TxDirection.Swap) {
      return this._translateService.instant("Transaction.Type_swap", {
        fromCurrency: getCurrencyName(tx.fromCurrency),
        toCurrency: getCurrencyName(tx.currency),
      });
    }

    let destinationTag = "";
    switch (tx.direction) {
      case TxDirection.Receiving:
        destinationTag = "Transaction.Receiving";
        break;
      case TxDirection.Sending:
        destinationTag = "Transaction.Sending";
        break;
      default:
        destinationTag = "Transaction.Sending";
        break;
    }
    return this._translateService.instant(destinationTag);
  }

  public getTxStatus(status: TxStatus): string {
    const statusTag = TxParserUtil.getTxStatus(status);
    if (!statusTag) {
      return "";
    }
    return this._translateService.instant(statusTag);
  }

  public async loadMoreTransactions() {
    if (this.isTxsLoadingMore || !this.hasMoreTxs) {
      return;
    }

    this.isTxsLoadingMore = true;
    this.txsPage++;
    const res = await this._txService.getAllTxs({
      size: this.txsPageSize,
      page: this.txsPage,
      userId: this.userId,
      currencies: this.currencies,
    });
    if (!res.withError) {
      const newTxs = res.params?.items ?? [];
      this.transactions = [...this.transactions, ...newTxs];
      this.totalTxsCount = res.params?.totalCount ?? 0;
      this.hasMoreTxs = this.transactions.length < this.totalTxsCount;
    }

    this.isTxsLoadingMore = false;
  }

  private async requestUserData() {
    const res = await this._userService.getUserById(this.userId);
    this.userData = res.params!;
  }

  private async blockUser() {
    await this._blackListService.blockUser(this.userId, false);
    this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminUsers}`);
  }

  private async unblockUser() {
    await this._blackListService.unblockUser(this.userId);
    this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminUsers}`);
  }

  private async loadTxs() {
    this.isTxsLoading = true;
    const res = await this._txService.getAllTxs({
      size: this.txsPageSize,
      page: this.txsPage,
      userId: this.userId,
      currencies: this.currencies,
    });
    this.transactions = res.params?.items ?? [];
    this.totalTxsCount = res.params?.totalCount ?? 0;
    this.isTxsLoading = false;
  }

  private async loadRates() {
    const rates = await this._ratesService.getRates();
    this.rates = rates.params ?? [];
  }
}
