<a
  *ngIf="type === 'link'"
  [routerLink]="item.link.split('?')[0]"
  class="menu-item"
  [ngClass]="{ 'menu-item_active': isCurrent }"
  (click)="onItemClick($event)"
>
  <app-svg-icon [name]="item.icon" class="menu-item-icon" />
  <span class="menu-item-title">
    {{ item.title | translate }}
  </span>
</a>

<button *ngIf="type === 'button'" class="menu-item w-full" (click)="onItemClick($event)">
  <app-svg-icon [name]="item.icon" class="menu-item-icon" />
  <span class="menu-item-title">
    {{ item.title | translate }}
  </span>
</button>
