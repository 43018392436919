<form [formGroup]="calculatorForm" class="flex flex-col gap-4 md:flex-row md:items-center">
  <div class="grow space-y-2">
    <span class="font-medium">{{ "Landing.Calculator.From" | translate }}</span>
    <div class="flex items-center rounded-xl border-2 border-slate-700 bg-transparent">
      <input
        type="number"
        formControlName="fromAmount"
        placeholder="0"
        appPositiveDecimals
        [maxLength]="16"
        autocomplete="off"
        class="w-[70%] flex-1 rounded-l-xl bg-transparent p-4 text-sm font-semibold text-white placeholder:text-text-secondary focus-visible:outline-none md:w-auto md:max-w-none"
        (input)="handleOutputAmountChange($event)"
      />
      <app-currency-dropdown [items]="currencies" (onSelect)="onSelectInputCurrency($event)">
        <div
          class="flex items-center justify-end gap-x-3 p-4 text-sm font-semibold text-text-secondary md:w-24"
        >
          <span>{{ fromCurrency | currencyName }}</span>
          <app-svg-icon name="chevron" class="w-3 shrink-0" />
        </div>
      </app-currency-dropdown>
    </div>
  </div>

  <button (click)="swapDirection()" class="mx-auto h-6 w-6 md:mt-8">
    <img src="assets/icons/transfer.svg" alt="swap" class="w-full max-md:rotate-90" />
  </button>

  <div class="grow space-y-2">
    <span class="font-medium">{{ "Landing.Calculator.To" | translate }}</span>
    <div class="flex items-center rounded-xl border-2 border-slate-700 bg-transparent">
      <input
        type="number"
        formControlName="toAmount"
        placeholder="0"
        appOnlyNumbers
        class="w-[70%] flex-1 rounded-l-xl bg-transparent p-4 text-sm font-semibold text-white placeholder:text-text-secondary focus-visible:outline-none md:w-auto md:max-w-none"
      />
      <app-currency-dropdown [items]="currencies" (onSelect)="onSelectOutputCurrency($event)">
        <div
          class="flex items-center justify-end gap-x-3 p-4 text-sm font-semibold text-text-secondary md:w-24"
        >
          <span>{{ toCurrency | currencyName }}</span>
          <app-svg-icon name="chevron" class="w-3 shrink-0" />
        </div>
      </app-currency-dropdown>
    </div>
  </div>
</form>

<div class="mt-2 flex items-center gap-x-4 text-sm text-text-secondary max-md:flex-col max-md:items-start">
  <p>
    {{ "Landing.Calculator.Result" | translate }}:
    <span>{{ pureToAmount | mfeCustom: { currency: toCurrency } }} {{ toCurrency | currencyName }}</span>
  </p>
  <p>
    {{ "Landing.Calculator.Commission" | translate }}:
    <span>{{ serviceFee | mfeCustom: { currency: toCurrency } }} {{ toCurrency | currencyName }}</span>
  </p>
</div>
