<div class="relative w-[32rem] max-md:w-full">
  <div class="flex items-center p-3 max-md:px-0">
    <button *ngIf="step === 'otp'" class="btn-flat mr-3" (click)="onBack()">
      <app-svg-icon name="actions/back" class="h-8 w-8" />
    </button>
    <h2 class="title">
      <ng-container *ngIf="step === 'phone'">
        {{ "Login.Sign_up" | translate }}
      </ng-container>
      <ng-container *ngIf="step === 'otp'">
        {{ "Login.Enter_code" | translate }}
      </ng-container>
    </h2>
    <button (click)="onClose()" class="btn-flat ml-auto">
      <app-svg-icon name="close" class="h-6 w-6" />
    </button>
  </div>

  <ng-container [ngSwitch]="step">
    <!-- Enter credentials -->
    <ng-template ngSwitchCase="phone">
      <form
        [formGroup]="phoneForm"
        (ngSubmit)="submitPassword()"
        class="mt-6 flex flex-col gap-y-4 max-md:gap-y-3"
      >
        <app-input
          id="phoneNumber"
          type="phone"
          formControlName="phoneNumber"
          [title]="'Login.Phone_number' | translate"
          placeholder="11 222 33 44"
          mask="00 000 00 00"
          [errorMsg]="phoneNumberError"
          [autofocus]="true"
        />

        <app-input
          type="password"
          [title]="'Login.Password' | translate"
          placeholder="********"
          formControlName="psw"
          id="psw"
          [autocomplete]="'off'"
          [autofocus]="true"
          [withPasswordToggle]="true"
        />

        <app-password-checker [password]="phoneForm.get('psw')?.value" />

        <input
          id="checkDepositary"
          type="checkbox"
          formControlName="checkDepositary"
          class="customCheckbox"
        />
        <label for="checkDepositary">
          <span>
            {{ "Login.Agree_1" | translate }}
            <a
              [href]="'assets/public-offers/' + language + '/offer-dep-' + language + '.pdf'"
              target="_blank"
              class="underline underline-offset-2"
            >
              {{ "Login.Agree_2" | translate }}
            </a>
          </span>
        </label>

        <p *ngIf="businessError" class="text-sm font-semibold text-red-500 dark:text-red-200">
          {{ businessError }}
        </p>

        <div class="mt-2">
          <button
            type="submit"
            class="btn-primary btn-large w-full"
            [disabled]="phoneForm.invalid || isPending"
          >
            {{ "Login.Register" | translate }}
          </button>
          <button type="button" class="btn-text mx-auto mt-4" (click)="openSignInModal()">
            {{ "Login.Enter" | translate }}
          </button>
        </div>
      </form>
    </ng-template>

    <!-- Enter OTP -->
    <ng-template ngSwitchCase="otp">
      <p class="mt-4 text-center text-text-secondary max-md:mt-3 max-md:text-sm">
        {{ "Login.Phone_code_sent" | translate }}<br />+998{{ phoneNumber }}
      </p>

      <form [formGroup]="otpForm" (ngSubmit)="submitOtp()" class="mt-8 max-md:mt-6">
        <app-otp-input
          formControlName="code"
          [initialTimerValue]="otpTimer"
          (resendCode)="sendConfirmCode()"
          (otpComplete)="submitOtp()"
          [errorMsg]="codeError || businessError"
        />

        <re-captcha
          *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
          (resolved)="captchaResolved($event)"
        ></re-captcha>

        <button
          type="submit"
          class="btn-primary btn-large mt-4 w-full"
          [disabled]="otpForm.invalid || isPending"
        >
          {{ "Common.Continue" | translate }}
        </button>
      </form>
    </ng-template>
  </ng-container>
</div>
