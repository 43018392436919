import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { AdminGuard } from "src/app/common/guards/admin.guard";
import { AuthGuard } from "src/app/common/guards/auth.guard";
import { BlackListGuard } from "src/app/common/guards/black-list.guard";
import { KycGuard } from "src/app/common/guards/kyc.guard";
import { LogoutGuard } from "src/app/common/guards/logout.guard";
import { UnauthorizedGuard } from "src/app/common/guards/unauthorized.guard";
import { UserGuard } from "src/app/common/guards/user.guard";
import { UserResolver } from "src/app/common/resolvers/user.resolver";
import { AdminAdminsComponent } from "src/app/pages/admin/admin-admins/admin-admins.component";
import { AdminAmlComponent } from "src/app/pages/admin/admin-aml/admin-aml.component";
import { AdminHomeComponent } from "src/app/pages/admin/admin-home/admin-home.component";
import { AdminKycComponent } from "src/app/pages/admin/admin-kyc/admin-kyc.component";
import { KycRecordComponent } from "src/app/pages/admin/admin-kyc/kyc-record/kyc-record.component";
import { AdminLoginComponent } from "src/app/pages/admin/admin-login/admin-login.component";
import { ReportsComponent } from "src/app/pages/admin/admin-reports/reports.component";
import { AdminRevenueComponent } from "src/app/pages/admin/admin-revenue/admin-revenue.component";
import { SecurityComponent } from "src/app/pages/admin/admin-security/security.component";
import { AdminTransactionsComponent } from "src/app/pages/admin/admin-transactions/admin-transactions.component";
import { AdminUsersComponent } from "src/app/pages/admin/admin-users/admin-users.component";
import { UserPageComponent } from "src/app/pages/admin/admin-users/user-page/user-page.component";
import { UserTransactionsComponent } from "src/app/pages/admin/admin-users/user-transactions/user-transactions.component";
import { AdminComponent } from "src/app/pages/admin/admin.component";
import { AssetPageComponent } from "src/app/pages/assets/asset-page/asset-page.component";
import { AssetsComponent } from "src/app/pages/assets/assets.component";
import { LogoutComponent } from "src/app/pages/auth/logout/logout.component";
import { MyidComponent } from "src/app/pages/auth/myid/myid.component";
import { BlackListComponent } from "src/app/pages/black-list/black-list.component";
import { CardsComponent } from "src/app/pages/cards/cards.component";
import { DepositaryComponent } from "src/app/pages/depositary/depositary.component";
import { KycVerificationComponent } from "src/app/pages/kyc-verification/kyc-verification.component";
import { LandingComponent } from "src/app/pages/landing/landing.component";
import { MyidRedirectComponent } from "src/app/pages/myid-redirect/myid-redirect.component";
import { NewMyIdVerificationComponent } from "src/app/pages/new-my-id-verification/new-my-id-verification.component";
import { PageNotFoundComponent } from "src/app/pages/page-not-found/page-not-found.component";
import { TmaToMyIdRedirectComponent } from "src/app/pages/tma-to-my-id-redirect/tma-to-my-id-redirect.component";
import { VerificationComponent } from "src/app/pages/verification/verification.component";
import { TransactionsComponent } from "src/app/pages/wallet/transactions/transactions.component";
import { WalletComponent } from "src/app/pages/wallet/wallet.component";

const depositaryChildren: Routes = [
  { path: "", component: WalletComponent },
  {
    path: RouteConstants.cards,
    component: CardsComponent,
    runGuardsAndResolvers: "always",
    resolve: { user: UserResolver },
  },
  // {
  //   path: RouteConstants.buySell,
  //   component: BuySellComponent,
  //   runGuardsAndResolvers: "always",
  //   resolve: { user: UserResolver },
  // },
  {
    path: RouteConstants.transactions,
    runGuardsAndResolvers: "always",
    component: TransactionsComponent,
    resolve: { user: UserResolver },
  },
  {
    path: RouteConstants.assets,
    runGuardsAndResolvers: "always",
    component: AssetsComponent,
    resolve: { user: UserResolver },
  },
  {
    path: RouteConstants.assets + "/:currency",
    runGuardsAndResolvers: "always",
    component: AssetPageComponent,
    resolve: { user: UserResolver },
  },
  // {
  //   path: RouteConstants.commissions,
  //   component: CommissionsComponent,
  // },
  // {
  //   path: RouteConstants.calculator,
  //   component: CalculatorComponent,
  // },
  // {
  //   path: RouteConstants.faq,
  //   component: FaqComponent,
  // },
];

const adminChildren: Routes = [
  { path: "", redirectTo: RouteConstants.adminHome, pathMatch: "full" },
  {
    path: RouteConstants.adminHome,
    component: AdminHomeComponent,
  },
  {
    path: RouteConstants.adminTransactions,
    component: AdminTransactionsComponent,
  },
  { path: RouteConstants.adminUsers, component: AdminUsersComponent },
  { path: `${RouteConstants.adminUsers}/:id`, component: UserPageComponent },
  { path: `${RouteConstants.adminUsers}/:id/transactions`, component: UserTransactionsComponent },
  { path: RouteConstants.adminAdmins, component: AdminAdminsComponent },
  { path: RouteConstants.adminKyc, component: AdminKycComponent },
  { path: `${RouteConstants.adminKyc}/:id`, component: KycRecordComponent },
  { path: RouteConstants.reports, component: ReportsComponent },
  { path: RouteConstants.adminSecurity, component: SecurityComponent },
  { path: RouteConstants.adminAml, component: AdminAmlComponent },
  { path: RouteConstants.adminRevenue, component: AdminRevenueComponent },
];

const routes: Routes = [
  {
    path: RouteConstants.logout,
    component: LogoutComponent,
    canActivate: [LogoutGuard],
  },
  {
    path: RouteConstants.myid,
    component: MyidComponent,
    canActivate: [KycGuard, UserGuard],
  },
  // {
  //   path: RouteConstants.kyc,
  //   component: KycComponent,
  //   canActivate: [KycGuard, UserGuard],
  // },
  {
    path: "depositary",
    component: DepositaryComponent,
    children: depositaryChildren,
    canActivate: [AuthGuard, UserGuard, BlackListGuard],
  },
  {
    path: RouteConstants.admin,
    component: AdminComponent,
    children: adminChildren,
    canActivate: [AdminGuard],
  },
  {
    path: `${RouteConstants.admin}/${RouteConstants.login}`,
    component: AdminLoginComponent,
    pathMatch: "full",
    canActivate: [UnauthorizedGuard],
  },
  {
    path: `${RouteConstants.verification}`,
    component: VerificationComponent,
  },
  {
    path: `${RouteConstants.kycVerification}`,
    component: KycVerificationComponent,
  },
  {
    path: `${RouteConstants.blackList}`,
    component: BlackListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${RouteConstants.myIdRedirect}`,
    component: MyidRedirectComponent,
  },
  {
    path: `${RouteConstants.tmaToMyIdRedirect}`,
    component: TmaToMyIdRedirectComponent,
  },
  {
    path: RouteConstants.eventsListener,
    loadChildren: () =>
      import("./event-listener-module/event-listener.module").then(m => m.EventListenerModule),
  },
  {
    path: RouteConstants.newMyIdVerification,
    component: NewMyIdVerificationComponent,
  },
  {
    path: "",
    component: LandingComponent,
  },
  { path: "**", pathMatch: "full", component: PageNotFoundComponent },
];

// const subdomainRoutes: Routes = [
//   {
//     path: "",
//     component: BuySellComponent,
//     runGuardsAndResolvers: "always",
//     resolve: { user: UserResolver },
//     canActivate: [SubdomainGuard, AuthGuard, UserGuard, BlackListGuard],
//   },
//   {
//     path: RouteConstants.eventsListener,
//     loadChildren: () =>
//       import("./event-listener-module/event-listener.module").then(m => m.EventListenerModule),
//   },
//   { path: "**", pathMatch: "full", component: PageNotFoundComponent },
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
