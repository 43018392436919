import { IEnv } from "../app/common/interfaces/ienv";

export const environment: IEnv = {
  // domain: "https://paynet-dev.unistory.app/",
  // isProduction: false,
  // // serverUrl: "/api/",
  // serverUrl: "https://paynet-dev.unistory.app/api/",
  // tronScanApiUrl: "https://apilist.tronscanapi.com/api/",
  // usdtAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  // tronGridUrl: "https://api.trongrid.io/",
  // recaptchaKey: "6LclAicpAAAAACe4lcxzUzvlV6ocnPOCBqW4rgHv",
  // polygonScanApiUrl: "https://api.polygonscan.com/api",
  // polygonScanApiKey: "CX1NW8C9WTE9D73T9514EBNY58IAQREI81",
  // subdomain: "https://paynet-shop.unistory.app/",
  // // tmaUrl: "https://t.me/paynet_dev_bot/paynet_dev_app",
  // tmaUrl: "tg://resolve?domain=paynet_dev_bot&appname=paynet_dev_app",
  // // tonCenterApiUrl: "https://testnet.toncenter.com/api/v3",
  // // tonCenterApiKey: "0e5e7f2b30cf9e99f2639280a397cd0e827784d811995608734395c43843e18e",
  // tonCenterApiUrl: "https://toncenter.com/api/v3",
  // tonCenterApiKey: "0e5e7f2b30cf9e99f2639280a397cd0e827784d811995608734395c43843e18e",
  // tonApiUrl: "https://tonapi.io/v2",
  // tonApiKey: "AGWF3NLM5EVSBWYAAAABPUFFPAIBFAX5LL4TKNFAW5R6UEN27O2KEQOUMM7JAPCQR5UYGBI",
  // tonRpcUrl: "https://testnet.toncenter.com/api/v2/jsonRPC",
  // bitcoinApiUrl: "https://mempool.space/api",

  // Temp
  domain: "https://paynet-dev.unistory.app/",
  isProduction: true,
  serverUrl: "https://paynet-dev.unistory.app/api/",
  tronScanApiUrl: "https://apilist.tronscanapi.com/api/",
  usdtAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  tronGridUrl: "https://api.trongrid.io/",
  recaptchaKey: "6LclAicpAAAAACe4lcxzUzvlV6ocnPOCBqW4rgHv",
  polygonScanApiUrl: "https://api.polygonscan.com/api/",
  polygonScanApiKey: "EX5YJMWAQWVWA174GZ8JBTS5KC7KVTHWC3",
  subdomain: "https://paynet-dev.unistory.app/",
  tmaUrl: "https://t.me/paynet_stage_bot/paynet_stage_app",
  tonCenterApiUrl: "https://toncenter.com/api/v3",
  tonCenterApiKey: "5f21004ecc7bd3285a6fab71df9b7533a9c17146ea77f7ce573a24464c6946fe",
  tonApiUrl: "https://tonapi.io/v2",
  tonApiKey: "AGWF3NLM5EVSBWYAAAABPUFFPAIBFAX5LL4TKNFAW5R6UEN27O2KEQOUMM7JAPCQR5UYGBI",
  tonRpcUrl: "https://toncenter.com/api/v2/jsonRPC",
  bitcoinApiUrl: "https://mempool.space/api",
};
