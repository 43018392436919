<ng-container *ngIf="!isLoading; else loader">
  <div class="absolute top-8 flex items-center gap-x-3">
    <a routerLink="/admin/users" class="btn-flat">
      <app-svg-icon name="actions/back" class="block h-8 w-8" />
    </a>
    <h1 class="title">
      {{ "Admin.Users.User" | translate }}
      {{ userFullName }}
    </h1>
  </div>

  <div class="mt-6 space-y-6">
    <section class="flex items-center justify-between">
      <!-- <div>
        <h3 class="half-title mt-5 uppercase">
          {{ userFullName ? userFullName : ("Admin.Users.User" | translate) }}
        </h3>
        <div class="mt-2 text-lg font-medium text-slate-400">{{ userBalance | mfeCustom }} USDT</div>
      </div> -->

      <div class="ml-auto flex items-center gap-x-6">
        <button
          class="btn-flat text-sm"
          [class.btn-flat_danger]="isUserBlocked"
          (click)="handleDownloadReport()"
          [disabled]="!hasInteractionPermissions || !userData.kyc"
        >
          <app-svg-icon name="admin/download" class="aspect-square w-5" />
          {{ "Admin.Users.Download_user_data" | translate }}
        </button>
        <button
          class="btn-flat btn-flat_danger text-sm"
          (click)="handleDeleteIdentification()"
          [disabled]="!hasInteractionPermissions || !userData.kyc || !isUserActive"
        >
          <app-svg-icon name="trash-bin" class="aspect-square w-5" />
          {{ "Admin.Users.Delete_identification" | translate }}
        </button>
        <button
          class="btn-flat btn-flat_danger text-sm"
          (click)="handleDeactivate()"
          *ngIf="!isUserBlockedForever"
          [disabled]="!isUserActive || !hasInteractionPermissions"
        >
          <app-svg-icon name="admin/close-circle" class="aspect-square w-5" />
          {{ "Admin.Users.Deactivate_account" | translate }}
        </button>
        <button
          class="btn-flat btn-flat_danger text-sm"
          (click)="handleBlockUnblock()"
          *ngIf="!isUserBlockedForever"
          [disabled]="blockInProgress || !hasInteractionPermissions"
        >
          <app-svg-icon name="admin/lock" class="aspect-square w-5" />
          {{ isUserBlocked ? ("Admin.Users.Unblock" | translate) : ("Admin.Users.Block" | translate) }}
        </button>
      </div>
    </section>

    <div class="grid grid-cols-4 gap-2">
      <app-balance-card *ngFor="let balance of userData.balances" [balanceItem]="balance" [rates]="rates" />
    </div>

    <section class="grid grid-cols-[20.5rem_auto] gap-x-6">
      <div class="box">
        <div class="border-b border-gray-100 pb-5 dark:border-slate-700">
          <h3 class="altitle">{{ "Admin.Users.Information" | translate }}</h3>
        </div>

        <div class="mt-5 space-y-6">
          <div class="admin-value-row-wrapper">
            <p>
              {{ "Admin.Users.Full_name" | translate }}
            </p>
            <p *ngIf="userFullName; else userNotVerified" class="admin-value-row">
              {{ userData.kyc?.firstName + " " + userData.kyc?.lastName }}
            </p>
          </div>

          <div class="admin-value-row-wrapper">
            <p>
              {{ "Admin.Users.Phone_number" | translate }}
            </p>
            <p class="admin-value-row">
              {{ userData.phoneNumber | phoneDisplayer }}
            </p>
          </div>

          <div class="admin-value-row-wrapper">
            <p>
              {{ "Admin.Users.Bank_cards" | translate }}
            </p>
            <ng-container *ngIf="userData.cards?.length; else noCardsAdded">
              <div *ngFor="let card of userData.cards" class="space-y-2">
                <p class="admin-value-row">{{ card }}</p>
              </div>
            </ng-container>
          </div>

          <div class="admin-value-row-wrapper">
            <p>
              {{ "Admin.Users.Risk_level" | translate }}
            </p>
            <div ngbDropdown class="dropdown">
              <div class="admin-value-row">
                <p [ngClass]="userRiskLevel?.color">
                  {{ userRiskLevel?.name || "" | translate }}
                </p>
                <button *ngIf="hasInteractionPermissions" ngbDropdownToggle class="btn-flat w-6">
                  <app-svg-icon name="edit" class="block" />
                </button>
              </div>
              <div ngbDropdownMenu>
                <button
                  ngbDropdownItem
                  *ngFor="let item of userRiskLevelsList"
                  (click)="onRiskLevelSelect(item.value)"
                  [ngClass]="item.color"
                >
                  <p>{{ item.name | translate }}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="userData?.wallets?.length" class="box">
        <div class="border-b border-gray-100 pb-5 dark:border-slate-700">
          <h4 class="altitle">{{ "Admin.Users.Wallets" | translate }}</h4>
        </div>

        <div class="mt-5 space-y-6">
          <div *ngFor="let wallet of userData.wallets" class="admin-value-row-wrapper">
            <p>
              {{ getNetworkName(wallet.network) }}
            </p>
            <div *ngIf="userData.wallets" class="admin-value-row">
              <a
                target="_blank"
                [href]="getWalletLink(wallet.network, wallet.address)"
                class="truncate text-sm font-medium text-green-500 dark:text-green-200"
              >
                {{ wallet.address }}
              </a>
              <app-copy-btn [value]="wallet.address" class="h-5 w-5" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="rounded-2xl bg-gray-25 py-6 dark:bg-slate-800">
      <div class="flex items-center justify-between px-6">
        <app-dropdown
          [items]="currencyNames"
          (onSelect)="onSelectCurrency($event)"
          [value]="selectedCurrencyNames"
          class="w-[17rem]"
        >
          <div class="dropdown-base-toggle w-full gap-x-2" [ngClass]="{ 'py-1.5': currencies.length > 0 }">
            <div
              *ngIf="currencies.length > 0; else currencyPlaceholder"
              class="flex flex-wrap items-center gap-1"
            >
              <div
                *ngFor="let currency of currencies"
                class="flex items-center gap-x-2 rounded-lg bg-gray-100 px-2 py-1 text-sm font-medium text-text-secondary dark:bg-slate-700"
              >
                {{ currency | currencyName }}
                <button class="btn-flat" (click)="removeCurrency(currency, $event)">
                  <app-svg-icon name="actions/close" class="block w-3" />
                </button>
              </div>
            </div>

            <ng-template #currencyPlaceholder>
              {{ "Transaction.Asset" | translate }}
            </ng-template>

            <app-svg-icon name="chevron" class="block w-3 shrink-0" />
          </div>
        </app-dropdown>

        <button
          class="btn-primary-danger px-4 py-2.5"
          (click)="resetTxsFilters()"
          [disabled]="currencies.length === 0"
        >
          {{ "Common.Reset" | translate }}
        </button>
      </div>

      <div
        class="mt-6"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="loadMoreTransactions()"
      >
        <table class="adminTable">
          <thead>
            <tr>
              <th>{{ "Transaction.Sum" | translate }}</th>
              <th>{{ "Transaction.Date" | translate }}</th>
              <th>{{ "Transaction.Type" | translate }}</th>
              <th>{{ "Transaction.Wallet" | translate }}</th>
              <th>Id</th>
              <th class="text-end">{{ "Transaction.Status" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="!isTxsLoading; else txLoader">
              <tr *ngFor="let tx of transactions">
                <td class="whitespace-nowrap">
                  {{ tx.amount | mfeCustom: { currency: tx.currency } }} {{ tx.currency | currencyName }}
                  <span class="mt-1 block text-text-secondary">
                    {{ getAmountInUsd(tx.amount, tx.currency) }} USD
                  </span>
                </td>
                <td>{{ tx.createdAt | customDate: "full" }}</td>
                <td>{{ getTxDestination(tx) }}</td>
                <td class="max-w-[10rem] overflow-hidden" [title]="tx.from">
                  <span class="block truncate">{{ tx.from || "-" }}</span>
                </td>
                <td class="max-w-[10rem] overflow-hidden" [title]="tx.hash">
                  <span class="block truncate">{{ tx.hash }}</span>
                </td>
                <td
                  class="text-end"
                  [ngClass]="{
                    'text-green-500': tx.status === TxStatus.Approved,
                    'text-red-500': tx.status === TxStatus.Error,
                    'text-yellow-500': tx.status === TxStatus.OnConfirm
                  }"
                >
                  {{ getTxStatus(tx.status) }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <!-- <ng-container *ngIf="!isTxsLoading; else txLoader">
        <div *ngIf="hasTxs; else noTrans" class="grid grid-rows-1">
          <app-transaction-list-item
            *ngFor="let item of transactions"
            [txDto]="item"
            [fullSize]="true"
            [rates]="rates"
            class="block border-t border-gray-100 dark:border-slate-700 max-md:border-none"
          />
        </div>
        <ng-template #noTrans>
          <div class="mt-3 text-center text-base">{{ "Transaction.No_transactions" | translate }}</div>
        </ng-template>
      </ng-container> -->

      <ng-template #txLoader>
        <app-transaction-list-item-skeleton [fullSize]="true" />
      </ng-template>
    </section>
  </div>
</ng-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #userNotVerified>
  <p class="admin-value-row">
    {{ "Admin.Users.User_not_verified" | translate }}
  </p>
</ng-template>

<ng-template #noCardsAdded>
  <p class="admin-value-row">
    {{ "Admin.Users.No_bank_cards" | translate }}
  </p>
</ng-template>
