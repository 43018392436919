<div class="relative grid min-h-screen grid-cols-[21.25rem_auto]">
  <app-admin-sidebar class="bg-gray-25 dark:bg-slate-800"></app-admin-sidebar>

  <main class="bg-gray-50 px-10 pb-10 dark:bg-slate-900">
    <app-header [fullSize]="false"></app-header>

    <ng-content></ng-content>

    <!-- <app-footer></app-footer> -->
  </main>
</div>
