<ng-template #landing>
  <router-outlet></router-outlet>
</ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>

<div
  *ngIf="showFullscreenLoader"
  class="fixed right-0 top-0 z-[1000] flex h-screen w-full items-center justify-center bg-[#fff] dark:bg-slate-900"
>
  <app-loader />
</div>

<div
  *ngIf="shouldRenderTelegramMiniAppPlaceholder; else landing"
  class="flex h-screen w-screen items-center justify-center bg-gray-25 p-5"
>
  <span class="text-center text-lg font-medium text-slate-400">
    Данное приложение доступно только на мобильных устройствах
  </span>
</div>
