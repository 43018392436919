<div class="relative">
  <div class="flex items-center justify-between p-3">
    <h4 class="title">
      {{ "Bank.Approval" | translate }}
    </h4>
    <button (click)="closeModal()" class="btn-flat">
      <app-svg-icon name="close" class="h-6 w-6" />
    </button>
  </div>

  <div class="mt-8 max-md:mt-6">
    <app-status-mark variant="error" icon="trash-bin" />

    <p class="mt-4 text-center">
      {{ "Bank.Delete_card_confirmation" | translate }}
      <span class="block font-bold">{{ card.maskedPan }}</span>
    </p>
  </div>

  <button class="btn-primary-danger btn-large mt-8 w-full max-md:mt-6" (click)="onDeleteCard()">
    {{ "Bank.Delete_card" | translate }}
  </button>
</div>
