import { Component } from "@angular/core";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";

@Component({
  templateUrl: "./admin-home.component.html",
  styleUrls: ["./admin-home.component.css"],
})
export class AdminHomeComponent {
  public selectedCurrency: CryptoSymbol = CryptoSymbol.Trx;
  public CryptoSymbol = CryptoSymbol;

  private readonly currenciesList = [
    {
      currency: CryptoSymbol.Trx,
      name: "TRX",
    },
    {
      currency: CryptoSymbol.Matic,
      name: "MATIC",
    },
    {
      currency: CryptoSymbol.Ton,
      name: "Ton",
    },
    {
      currency: CryptoSymbol.Not,
      name: "Not",
    },
    {
      currency: CryptoSymbol.Bitcoin,
      name: "Bitcoin",
    },
    {
      currency: CryptoSymbol.Usdt,
      name: "Tron USDT",
    },
    {
      currency: CryptoSymbol.PolygonUsdt,
      name: "Polygon USDT",
    },
    {
      currency: CryptoSymbol.TonUsdt,
      name: "Ton USDT",
    },
  ];

  constructor() {}

  public get currenciesNames() {
    return this.currenciesList.map(currency => currency.name);
  }

  public get selectedCurrencyName() {
    return this.currenciesList.find(currency => currency.currency === this.selectedCurrency)?.name!;
  }

  public onSelectCurrency(currencyName: string) {
    this.selectedCurrency = this.currenciesList.find(currency => currency.name === currencyName)?.currency!;
  }
}
