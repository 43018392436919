import { Component, EventEmitter, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { UpdatePinCodeDto } from "src/app/common/DTO/users/update-pin-code.dto";
import { UserErrorCode } from "src/app/common/enums/user-error-code.enum";
import { matchValidator } from "src/app/common/validators/match.validator";
import { ToastService } from "src/app/services/toast.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-change-pincode",
  templateUrl: "./change-pincode.component.html",
  styleUrls: ["./change-pincode.component.css"],
})
export class ChangePincodeComponent {
  @Output() onSuccess = new EventEmitter<void>();
  @Output() onBack = new EventEmitter<void>();

  @ViewChild("successModal") successModal!: TemplateRef<any>;

  changePinCodeForm: FormGroup;
  isPending = false;
  businessError: string | null = null;

  constructor(
    private _modalService: NgbModal,
    private _translateService: TranslateService,
    private _userService: UserService,
    private _toastService: ToastService
  ) {
    this.changePinCodeForm = new FormGroup(
      {
        oldPinCode: new FormControl(null, [
          Validators.required,
          Validators.minLength(ValidateConstants.PinCodeLength),
          Validators.maxLength(ValidateConstants.PinCodeLength),
        ]),
        newPinCode: new FormControl(null, [
          Validators.required,
          Validators.minLength(ValidateConstants.PinCodeLength),
          Validators.maxLength(ValidateConstants.PinCodeLength),
        ]),
        repeatNewPinCode: new FormControl(null, [Validators.required]),
      },
      { validators: matchValidator("newPinCode", "repeatNewPinCode") }
    );
  }

  getFieldHasErrors(fieldName: string) {
    const field = this.changePinCodeForm.get(fieldName);

    if (field?.value === null) {
      return null;
    }

    if (field?.errors?.["match"]) {
      return this._translateService.instant("Pincode.Pin_not_match");
    }

    if (field?.errors?.["minlength"] || field?.errors?.["maxlength"]) {
      return this._translateService.instant("Pincode.Pin_length_error", {
        length: ValidateConstants.PinCodeLength,
      });
    }

    if (field?.invalid) {
      return this._translateService.instant("Pincode.Pin_invalid");
    } else {
      return null;
    }
  }

  openSuccessModal() {
    const modal = this._modalService.open(this.successModal, {
      backdropClass: "!bg-transparent",
    });
    this.onSuccess.emit();

    // Send event when modal is closed
    modal.result.then(
      () => {
        this.onBack.emit();
      },
      () => {
        this.onBack.emit();
      }
    );
  }

  async onChangePinCode() {
    if (this.changePinCodeForm.invalid) {
      return;
    }

    const dto: UpdatePinCodeDto = {
      oldPinCode: this.changePinCodeForm.get("oldPinCode")?.value,
      newPinCode: this.changePinCodeForm.get("newPinCode")?.value,
    };

    this.isPending = true;
    const res = await this._userService.updatePinCode(dto);
    this.isPending = false;

    if (res && res.withError) {
      if (res.errorCode === UserErrorCode.IncorrectOldPinCode) {
        const errorMsg = this._translateService.instant("Pincode.Incorrect_old_pin");
        this._toastService.error(errorMsg);
      } else {
        const errorMsg = this._translateService.instant("Common.Unknown_error");
        this._toastService.error(errorMsg);
      }
      return;
    }

    this.businessError = null;
    this.changePinCodeForm.reset();
    this.openSuccessModal();
  }
}
