<div class="relative grid min-h-screen grid-cols-[21.25rem_auto] max-md:block">
  <app-client-sidebar class="bg-gray-25 dark:bg-slate-800 max-md:hidden"></app-client-sidebar>

  <div
    *ngIf="!resolverLoading; else loader"
    class="bg-gray-50 dark:bg-slate-900 max-md:min-h-screen max-md:p-4"
  >
    <app-header [fullSize]="false"></app-header>

    <main class="px-10 pb-10 max-md:p-0">
      <ng-content></ng-content>
    </main>

    <!-- TODO: no footer? -->
    <!-- <app-footer></app-footer> -->
  </div>
</div>

<ng-template #loader>
  <app-loader class="m-10 block" />
</ng-template>
