import { Component, OnInit } from "@angular/core";
import { NgbCalendar, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ReportsService } from "src/app/services/reports.service";

@Component({
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit {
  hasInteractionPermissions: boolean = false;

  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  startDate: NgbDate | null = null;
  maxDate: NgbDate | null = null;

  showDownloadButton = false;

  constructor(
    private readonly _reportsService: ReportsService,
    private readonly _localStorage: LocalStorageService,
    private readonly _calendar: NgbCalendar
  ) {
    const oneMonthEarlier = this._calendar.getToday();
    oneMonthEarlier.month -= 1;
    oneMonthEarlier.day = 1;
    this.startDate = oneMonthEarlier;
    this.maxDate = this._calendar.getToday();
  }

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.PlatformInteraction
    );
  }

  public handleExportClick() {
    if (this.fromDate && this.toDate) {
      this.showDownloadButton = true;
    }
  }

  public handleDownloadReports(report: "accounting" | "registered-users") {
    if (!this.fromDate || !this.toDate) {
      return;
    }

    const startDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    const endDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);

    switch (report) {
      case "accounting":
        this._reportsService.getAccountingReport(startDate.toISOString(), endDate.toISOString());
        break;
      case "registered-users":
        this._reportsService.getRegisteredUsersReportForPeriodReport(
          startDate.toISOString(),
          endDate.toISOString()
        );
        break;
      default:
        break;
    }
  }

  public handleDownloadUsersData() {
    this._reportsService.getUsersPersonalDataReport();
  }

  public onSelectDates(date: [NgbDate | null, NgbDate | null] | NgbDate | null) {
    if (Array.isArray(date)) {
      this.fromDate = date[0];
      this.toDate = date[1];
    } else {
      this.fromDate = date;
    }
  }

  public formatNgbDate(date?: NgbDate | null) {
    if (!date) {
      return "";
    }
    return `${String(date.day).padStart(2, "0")}.${String(date.month).padStart(2, "0")}.${date.year}`;
  }

  public get dateRange() {
    if (!this.fromDate || !this.toDate) {
      return "";
    }
    return `${this.formatNgbDate(this.fromDate)} - ${this.formatNgbDate(this.toDate)}`;
  }
}
