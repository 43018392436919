<aside class="sticky top-6 flex h-[calc(100vh-1.5rem)] flex-col p-6 pt-0">
  <app-logo [theme]="theme" class="px-4 py-3" />

  <div class="mt-6 grid grid-rows-1 gap-y-1">
    <app-menu-link-button *ngFor="let item of menuItems" [item]="item" />

    <hr class="border-gray-100 dark:border-slate-700" />

    <app-menu-link-button
      type="button"
      [item]="{ link: '', title: 'Header.Settings', icon: 'menu/settings' }"
      (onClick)="openSettingsModal()"
    />
  </div>

  <hr class="mb-6 mt-auto border-gray-100 dark:border-slate-700" />
  <app-theme-selector class="mx-auto w-full" />
  <button
    class="mt-1 flex items-center gap-x-4 rounded-xl bg-gray-100 px-4 py-3 font-semibold text-text-primary hover:text-red-400 dark:bg-slate-700 dark:text-white dark:hover:text-red-500"
    (click)="onLogout()"
  >
    <app-svg-icon name="menu/exit" class="h-6 w-6 shrink-0" />
    {{ "Header.Logout" | translate }}
  </button>
</aside>
