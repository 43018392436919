import { Injectable } from "@angular/core";

type ToastVariant = "success" | "danger" | "warning" | "info";

export interface ToastInfo {
  body: string;
  delay?: number;
  variant?: ToastVariant;
}

interface ToastOptions {
  delay?: number;
  variant?: ToastVariant;
}

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor() {}

  toasts: ToastInfo[] = [];

  show(body: string, options?: ToastOptions) {
    this.toasts.push({ body, ...options });
  }

  success(body: string, options?: ToastOptions) {
    this.show(body, { ...options, variant: "success" });
  }

  error(body: string, options?: ToastOptions) {
    this.show(body, { ...options, variant: "danger" });
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter(t => t != toast);
  }
}
