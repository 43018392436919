import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DepositLimitDto } from "src/app/common/DTO/deposit-limit";
import { LimitPanelSettingsDto } from "src/app/common/DTO/limit-panel/limit-panel-settings.dto";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { DepositLimitsService } from "src/app/services/deposit-limits.service";
import { LimitPanelService } from "src/app/services/limit-panel.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-limits-block",
  templateUrl: "./limits-block.component.html",
  styleUrls: ["./limits-block.component.css"],
})
export class LimitsBlockComponent implements OnInit {
  @Input() public selectedCurrency: CryptoSymbol = CryptoSymbol.Trx;

  public ModalConstants = ModalConstants;
  public CryptoSymbol = CryptoSymbol;
  public limits: LimitPanelSettingsDto[] = [];
  public depositLimits: DepositLimitDto[] = [];
  public isPending = false;
  public currencies: CryptoSymbol[] = [];
  public isEditing = false;
  public hasInteractionPermissions: boolean = false;

  public limitsForm: FormGroup;

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _translateService: TranslateService,
    private readonly _toastService: ToastService,
    private readonly _limitService: LimitPanelService,
    private readonly _depositLimitsService: DepositLimitsService
  ) {
    this.limitsForm = new FormGroup({
      currency: new FormControl(null),
      maxBuyAmount: new FormControl(null, [Validators.required, Validators.min(0)]),
      minBuyAmount: new FormControl(null, [Validators.required, Validators.min(0)]),
      maxSellAmount: new FormControl(null, [Validators.required, Validators.min(0)]),
      minSellAmount: new FormControl(null, [Validators.required, Validators.min(0)]),
      maxAcquiringAmountWithoutConfirm: new FormControl(null, [Validators.required, Validators.min(0)]),
      maxWithdrawalPerDay: new FormControl(null, [Validators.required, Validators.min(0)]),
      maxWithdrawalPerTx: new FormControl(null, [Validators.required, Validators.min(0)]),
      minWithdrawalPerTx: new FormControl(null, [Validators.required, Validators.min(0)]),
      maxTxAmountWithoutConfirm: new FormControl(null, [Validators.required, Validators.min(0)]),
      depositLimit: new FormControl(null, [Validators.required, Validators.min(0)]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.PlatformInteraction
    );
    await this.requestLimits();
    await this.requestDepositLimits();
  }

  public getCurrencyName(currency: CryptoSymbol) {
    switch (currency) {
      case CryptoSymbol.Trx:
        return "TRX";
      case CryptoSymbol.Usdt:
        return "USDT (TRX)";
      case CryptoSymbol.Matic:
        return "MATIC";
      case CryptoSymbol.PolygonUsdt:
        return "USDT (Polygon)";
      case CryptoSymbol.Ton:
        return "TON";
      case CryptoSymbol.TonUsdt:
        return "USDT (TON)";
      case CryptoSymbol.Not:
        return "NOT";
      case CryptoSymbol.Bitcoin:
        return "BTC";
      default:
        return "";
    }
  }

  public getLimits(currency: CryptoSymbol) {
    return this.limits.find(x => x.currency === currency);
  }

  public getDepositLimit(currency: CryptoSymbol) {
    return this.depositLimits.find(x => x.currency === currency);
  }

  public async toggleEditing(currency: CryptoSymbol) {
    if (this.isEditing) {
      await this.handleSaveLimit();
    } else {
      const currentLimit = this.limits.find(x => x.currency === currency);
      const currentDepositLimit = this.depositLimits.find(x => x.currency === currency);
      this.limitsForm.setValue({
        ...currentLimit,
        depositLimit: currentDepositLimit?.amount,
      });
      this.isEditing = true;
    }
  }

  public async handleSaveLimit() {
    if (this.limitsForm.invalid) {
      return;
    }

    this.isPending = true;

    const isUsdtCurrency = [CryptoSymbol.Usdt, CryptoSymbol.PolygonUsdt, CryptoSymbol.TonUsdt].includes(
      this.selectedCurrency
    );

    const result = await this.updateLimits(isUsdtCurrency);
    this.isPending = false;

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.error(errorMsg);
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.success(successMsg);
      this.requestLimits();
      this.requestDepositLimits();
      this.isEditing = false;
    }
  }

  public getFieldError(field: string) {
    const formField = this.limitsForm.get(field);

    if (formField?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (formField?.hasError("min")) {
      return this._translateService.instant("Admin.Home.Min_value_error", {
        value: formField?.errors?.["min"]?.min,
      });
    }
    return null;
  }

  private async requestLimits() {
    const res = await this._limitService.getLimits();
    if (res.withError) {
      return;
    }
    this.limits = res.params!;
    this.currencies = res.params!.map(x => x.currency);
  }

  private async updateLimits(isUsdtCurrency: boolean): Promise<{ withError: boolean }> {
    const depositLimitAmount = this.limitsForm.value.depositLimit;

    if (!isUsdtCurrency) {
      const dto: LimitPanelSettingsDto = {
        ...this.limitsForm.value,
      };
      await this._limitService.updateLimits(dto);
      await this._depositLimitsService.updateDepositLimit(depositLimitAmount, dto.currency);
      return { withError: false };
    }

    const usdtVariants = [
      { ...this.limitsForm.value, currency: CryptoSymbol.Usdt },
      { ...this.limitsForm.value, currency: CryptoSymbol.PolygonUsdt },
      { ...this.limitsForm.value, currency: CryptoSymbol.TonUsdt },
    ];

    const results = await Promise.all(
      usdtVariants.map(async dto => {
        try {
          await this._limitService.updateLimits(dto);
          await this._depositLimitsService.updateDepositLimit(depositLimitAmount, dto.currency);
          return { withError: false };
        } catch (error) {
          return { withError: true };
        }
      })
    );

    return {
      withError: results.some(r => r.withError),
    };
  }

  private async requestDepositLimits() {
    const res = await this._depositLimitsService.getDepositLimits();
    if (res.withError) {
      return;
    }
    this.depositLimits = res.params ?? [];
  }
}
