import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Big } from "big.js";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { TxDto } from "src/app/common/DTO/txs/tx.dto";
import { Network } from "src/app/common/enums/network.enum";
import { TxDirection } from "src/app/common/enums/tx-direction.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { MfeCustomPipe } from "src/app/common/pipes/mfe-custom.pipe";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { getTransactionLink } from "src/app/common/utils/transaction-link-helper.util";
import { TxParserUtil } from "src/app/common/utils/tx-parser.util";
import { getWalletLink } from "src/app/common/utils/wallet-link-helper.util";
import { validate as uuidValidate } from "uuid";

@Component({
  selector: "app-transaction-list-item",
  templateUrl: "./transaction-list-item.component.html",
  styleUrls: ["./transaction-list-item.component.css"],
})
export class TransactionListItemComponent {
  @Input() txDto = new TxDto();
  @Input() fullSize = false;
  @Input() rates: RateDto[] = [];
  @Input() isBalanceVisible = true;

  public TxType = TxType;
  public TxDirection = TxDirection;

  constructor(private readonly _translateService: TranslateService) {}

  public get balanceChange(): string {
    const { amount, fee, currency } = this.txDto;
    const finalAmount = new Big(amount).minus(fee || 0).toString();
    return new MfeCustomPipe().transform(finalAmount, { currency });
  }

  public get usdBalanceChange(): string {
    if (!this.rates.length) return "0";

    const { amount, fee, currency } = this.txDto;
    const finalAmount = new Big(amount).minus(fee || 0).toNumber();
    const converted = ConvertCurrencyHelper.convertToUsd(finalAmount, currency, this.rates);
    return new MfeCustomPipe().transform(converted, { fiat: true });
  }

  public get txDestination(): string {
    if (this.txDto.direction === TxDirection.Swap) {
      return this._translateService.instant("Transaction.Type_swap", {
        fromCurrency: getCurrencyName(this.txDto.fromCurrency),
        toCurrency: getCurrencyName(this.txDto.currency),
      });
    }

    let destinationTag = "";
    switch (this.txDto.direction) {
      case TxDirection.Receiving:
        destinationTag = "Transaction.Receiving";
        break;
      case TxDirection.Sending:
        destinationTag = "Transaction.Sending";
        break;
      default:
        destinationTag = "Transaction.Sending";
        break;
    }
    return this._translateService.instant(destinationTag);
  }

  public get txStatus(): string {
    const statusTag = TxParserUtil.getTxStatus(this.txDto?.status);
    if (!statusTag) {
      return "";
    }
    return this._translateService.instant(statusTag);
  }

  public get txDate(): string {
    return this.txDto.createdAt.toString();
  }

  public get statusColor(): string {
    if (this.txDto.status === TxStatus.Approved) {
      return "text-green-500 dark:text-green-200";
    } else if (
      this.txDto.status === TxStatus.OnConfirm ||
      this.txDto.status === TxStatus.WaitingApproveByBlockchain ||
      this.txDto.status === TxStatus.Processing
    ) {
      return "text-yellow-500 dark:text-yellow-200";
    } else {
      return "text-red-500 dark:text-red-200";
    }
  }

  public get txNetwork(): Network {
    return TxParserUtil.getTxNetwork(this.txDto.currency);
  }

  public get shouldShowLinkToTx(): boolean {
    const isUuid = uuidValidate(this.txDto.hash);
    return isUuid ? false : true;
  }

  public returnWalletLink(address: string): string {
    return getWalletLink(this.txNetwork, address);
  }

  public get txLink(): string {
    return getTransactionLink(this.txNetwork, this.txDto.hash);
  }

  public get otherWalletAddress(): string {
    if (this.txDto.direction === TxDirection.Receiving) {
      return this.txDto.from;
    } else {
      return this.txDto.to;
    }
  }

  public formatAddress(address: string): string {
    return address.slice(0, 2) + "..." + address.slice(-4);
  }

  public formatAmount(amount: string | number, prefix: string = ""): string {
    let value = "0";

    if (amount === "0") return value;

    const str = amount.toString();
    if (str.length > 11) {
      value = str.slice(0, 11);
    } else {
      value = str;
    }

    const withPrefix = `${prefix}${value}`;

    return this.txDto.direction === TxDirection.Receiving
      ? `+${withPrefix}`
      : this.txDto.direction === TxDirection.Sending
        ? `-${withPrefix}`
        : withPrefix;
  }
}
