<section class="rounded-2xl bg-gray-25 px-3 py-6 dark:bg-slate-800 max-md:px-2 max-md:py-4">
  <div class="flex items-center justify-between px-3">
    <h2 class="subtitle py-1">{{ "Assets.Assets_catalog" | translate }}</h2>
    <a
      [routerLink]="['/' + RouteConstants.depositary, RouteConstants.assets]"
      class="flex items-center gap-x-2 font-bold text-green-400"
    >
      {{ "Assets.Show_all" | translate }}
      <app-svg-icon name="chevron" class="w-4 -rotate-90"></app-svg-icon>
    </a>
  </div>

  <div class="mt-6 flex flex-col gap-y-1">
    <div
      class="grid grid-cols-[25%_1fr_20%] items-center justify-between gap-x-8 p-3 text-xs font-semibold text-text-secondary max-md:grid-cols-[35%_1fr_25%] max-sm:gap-x-2"
    >
      <span>{{ "Wallet.Currency_name" | translate }}</span>

      <app-dropdown [items]="periodOptionsNames" (onSelect)="onPeriodChange($event)">
        <button class="flex items-center gap-x-2">
          <span>{{ "Assets.Time" | translate }} ({{ selectedPeriod.label | translate }})</span>
          <app-svg-icon name="chevron" class="w-3" />
        </button>
      </app-dropdown>

      <span class="text-end">{{ "Assets.Total" | translate }}</span>
    </div>

    <app-asset-chart-item
      *ngFor="let balance of balances"
      [balance]="balance"
      [currentRate]="getRateByCurrency(balance.currency)"
      [period]="selectedPeriod.days"
    ></app-asset-chart-item>
  </div>
</section>
