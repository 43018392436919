import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { RateHistoryDto } from "src/app/common/DTO/rate-history";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class RateHistoriesService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _env: EnvService
  ) {}

  public async getRateHistory(
    currency: CryptoSymbol,
    from: string,
    to: string
  ): Promise<HttpResultDto<null, RateHistoryDto[]>> {
    if (currency === CryptoSymbol.AbstractUsdt) {
      currency = CryptoSymbol.Usdt;
    }

    const params = `?Currency=${currency}&From=${from}&To=${to}`;
    const uri = `${this._env.serverUrl}${UrnConstants.RateHistoriesAll}${params}`;
    const result = new HttpResultDto<null, RateHistoryDto[]>(false);
    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<RateHistoryDto[]>;
      result.params = res.data;
    } catch (e) {
      result.withError = true;
    }

    return result;
  }
}
