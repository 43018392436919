<div class="relative w-[32rem] max-md:w-full">
  <div class="flex items-center p-3 max-md:px-0">
    <button *ngIf="step === 'password' || step === 'otp'" class="btn-flat mr-3" (click)="onBack()">
      <app-svg-icon name="actions/back" class="h-8 w-8" />
    </button>
    <h2 class="title">
      <ng-container *ngIf="step === 'phone'">
        {{ "Login.Password_reset" | translate }}
      </ng-container>
      <ng-container *ngIf="step === 'otp'">
        {{ "Login.Enter_code" | translate }}
      </ng-container>
      <ng-container *ngIf="step === 'password'">
        {{ "Login.Password_reset" | translate }}
      </ng-container>
      <ng-container *ngIf="step === 'success'">
        {{ "Password_change.Success" | translate }}
      </ng-container>
    </h2>
    <button (click)="onClose()" class="btn-flat ml-auto">
      <app-svg-icon name="close" class="h-6 w-6" />
    </button>
  </div>

  <ng-container [ngSwitch]="step">
    <!-- Enter phone -->
    <ng-template ngSwitchCase="phone">
      <form [formGroup]="phoneForm" (ngSubmit)="onPhoneSubmit()" class="mt-6">
        <app-input
          [title]="'Login.Phone_number' | translate"
          type="phone"
          placeholder="11 222 33 44"
          id="phoneNumber"
          formControlName="phoneNumber"
          [errorMsg]="phoneNumberError"
          mask="00 000 00 00"
          [autocomplete]="'off'"
        />

        <p *ngIf="businessError" class="mt-2 text-sm font-medium text-red-500 dark:text-red-200">
          {{ businessError }}
        </p>

        <button
          type="submit"
          class="btn-primary btn-large mt-6 w-full"
          [disabled]="phoneForm.invalid || isPending"
        >
          {{ "Common.Continue" | translate }}
        </button>
      </form>
    </ng-template>

    <!-- Enter code -->
    <ng-template ngSwitchCase="otp">
      <p class="mt-4 text-center font-medium text-text-secondary max-md:mt-3 max-md:text-sm">
        {{ "Login.Phone_code_sent" | translate }}<br />+998{{ phoneForm.get("phoneNumber")?.value }}
      </p>
      <form [formGroup]="passwordForm" class="mt-6">
        <app-otp-input
          formControlName="code"
          [initialTimerValue]="otpTimer"
          (onRefresh)="sendCode()"
          (otpComplete)="onOtpSubmit()"
        />
      </form>
      <button type="button" class="btn-primary btn-large mt-4 w-full max-md:mt-3" (click)="onOtpSubmit()">
        {{ "Common.Continue" | translate }}
      </button>
    </ng-template>

    <!-- Set new password -->
    <ng-template ngSwitchCase="password">
      <form
        [formGroup]="passwordForm"
        (ngSubmit)="onPasswordSubmit()"
        class="mt-6 flex flex-col space-y-4 max-md:space-y-3"
      >
        <app-input
          type="password"
          [title]="'Profile.New_password' | translate"
          [placeholder]="'Password_change.Enter_new_password' | translate"
          formControlName="newPsw"
          id="newPsw"
          autocomplete="off"
          [withPasswordToggle]="true"
          [errorMsg]="getPasswordHasErrors('newPsw')"
        />
        <app-input
          type="password"
          [title]="'Profile.Repeat_new_password' | translate"
          [placeholder]="'Password_change.Enter_new_password' | translate"
          formControlName="pswRepeat"
          id="pswRepeat"
          autocomplete="off"
          [withPasswordToggle]="true"
          [errorMsg]="getPasswordHasErrors('pswRepeat')"
        />
        <app-password-checker
          [password]="passwordForm.get('newPsw')?.value"
          [confirmPassword]="passwordForm.get('pswRepeat')?.value"
        />

        <re-captcha
          *ngIf="otpInputCount >= validateConstants.MaxOtpTries"
          (resolved)="captchaResolved($event)"
        ></re-captcha>

        <p *ngIf="businessError" class="text-sm font-medium text-red-500 dark:text-red-200">
          {{ businessError }}
        </p>

        <button
          type="submit"
          class="btn-primary btn-large mt-4 w-full"
          [disabled]="passwordForm.invalid || isPending || otpInputCount >= validateConstants.MaxOtpTries"
        >
          {{ "Login.Change_password" | translate }}
        </button>
      </form>
    </ng-template>

    <!-- Success -->
    <ng-template ngSwitchCase="success">
      <div class="mt-8 max-md:mt-6">
        <app-status-mark variant="success" icon="check" />
        <p class="mt-4 text-center text-xl font-bold text-green-500 dark:text-green-200">
          {{ "Password_change.Password_changed" | translate }}
        </p>
      </div>
      <button class="btn-primary-dark btn-large mt-8 w-full max-md:mt-6" (click)="onClose()">
        {{ "Common.Close" | translate }}
      </button>
    </ng-template>
  </ng-container>
</div>
