import { Component, Input } from "@angular/core";

@Component({
  selector: "app-bullet-row",
  templateUrl: "./bullet-row.component.html",
  styleUrls: ["./bullet-row.component.css"],
})
export class BulletRowComponent {
  @Input() name: string = "";
  @Input() rowClass: string = "";
  @Input() bulletClass: string = "";
}
