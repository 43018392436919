<div *ngIf="!isLoading; else skeleton">
  <div
    class="grid grid-cols-wallet-balances-table items-center gap-x-1 border-t border-solid border-gray-100 px-6 py-3 text-base font-semibold dark:border-slate-700 dark:text-white max-md:gap-x-2"
    [ngClass]="{
      'cursor-pointer': asLink && isMobile,
      'max-md:grid-cols-[auto_auto]': inStock,
      'max-md:grid-cols-wallet-balances-table-mobile': !inStock
    }"
    (click)="handleClick()"
  >
    <div class="flex items-center gap-x-4 max-md:gap-x-3">
      <app-currency-icon [currency]="balanceItem.currency" size="sm" />
      <div class="space-y-2">
        <span class="block">{{ balanceItem.currency | currencyName }}</span>
      </div>
    </div>

    <div class="flex flex-col gap-y-1 max-md:text-end" *ngIf="inStock">
      <div class="break-words" [title]="balanceItem.availableAmount">
        {{
          isBalanceVisible
            ? (balanceItem.availableAmount | mfeCustom: { currency: balanceItem.currency })
            : "***"
        }}
        <span class="md:hidden"> {{ balanceItem.currency | currencyName }}</span>
      </div>

      <div class="text-xs font-semibold text-text-secondary md:hidden">
        <span *ngIf="mobileBalanceCurrency === CryptoSymbol.AbstractUsdt">
          {{ isBalanceVisible ? formatAmount(getUsdValue(balanceItem.availableAmount), "$") : "***" }}
        </span>
        <span *ngIf="mobileBalanceCurrency === CryptoSymbol.Uzs">
          {{ isBalanceVisible ? formatAmount(getUzsValue(balanceItem.availableAmount)) + " UZS" : "***" }}
        </span>
      </div>
    </div>

    <span
      class="break-words"
      [ngClass]="{ 'max-md:hidden': mobileBalanceCurrency === CryptoSymbol.Uzs || inStock }"
      [title]="getUsdValue(inStock ? balanceItem.availableAmount : 1)"
    >
      {{
        isBalanceVisible ? formatAmount(getUsdValue(inStock ? balanceItem.availableAmount : 1), "$") : "***"
      }}
    </span>

    <span
      class="break-words"
      [ngClass]="{ 'max-md:hidden': mobileBalanceCurrency === CryptoSymbol.AbstractUsdt || inStock }"
      [title]="getUzsValue(inStock ? balanceItem.availableAmount : 1)"
    >
      {{ isBalanceVisible ? formatAmount(getUzsValue(inStock ? balanceItem.availableAmount : 1)) : "***" }}
    </span>

    <span
      *ngIf="!inStock"
      class="flex items-center gap-x-1 max-md:hidden"
      [ngClass]="{
        'text-green-500 dark:text-green-200': ratePercentChange !== null && ratePercentChange >= 0,
        'text-red-500 dark:text-red-200': ratePercentChange !== null && ratePercentChange < 0
      }"
    >
      <app-svg-icon
        *ngIf="ratePercentChange !== 0"
        name="ticker-up"
        class="h-6 w-6"
        [ngClass]="{ 'rotate-180': ratePercentChange !== null && ratePercentChange < 0 }"
      />
      {{ ratePercentChange | number: "1.0-2" }}%
    </span>

    <div
      class="grid grid-cols-2 items-center gap-x-2 max-md:ml-auto max-md:w-fit"
      [ngClass]="{ 'max-md:hidden': inStock }"
    >
      <button
        class="btn-secondary btn-small max-md:rounded-full max-md:p-2"
        (click)="handleReceiveClick($event)"
      >
        <span class="max-md:hidden">{{ "Receive.Receive" | translate }}</span>
        <app-svg-icon name="actions/plus" class="h-6 w-6 md:hidden" />
      </button>

      <button
        *ngIf="inStock"
        class="btn-secondary btn-small max-md:rounded-full max-md:p-2"
        (click)="handleSendClick($event)"
      >
        <span class="max-md:hidden">{{ "Send.Send" | translate }}</span>
        <app-svg-icon name="arrow-up" class="h-6 w-6 md:hidden" />
      </button>

      <button
        *ngIf="!inStock"
        class="btn-secondary btn-small max-md:rounded-full max-md:p-2"
        (click)="handleSwapClick($event)"
      >
        <span class="max-md:hidden">{{ "Swap.Swap" | translate }}</span>
        <app-svg-icon name="actions/exchange" class="h-6 w-6 md:hidden" />
      </button>
    </div>
  </div>
</div>

<ng-template #skeleton>
  <div
    class="grid grid-cols-wallet-balances-table items-center gap-x-1 px-6 py-3"
    [ngClass]="inStock ? 'max-md:grid-cols-[auto_auto]' : 'max-md:grid-cols-wallet-balances-table-mobile'"
  >
    <div class="flex items-center gap-x-4 max-md:gap-x-3">
      <app-skeleton class="h-10 w-10 shrink-0" />
      <app-skeleton class="h-5 w-20 max-md:w-fill" />
    </div>
    <app-skeleton class="h-5 w-18 max-md:!hidden" />
    <app-skeleton class="h-5 w-18 max-md:!hidden" />
    <app-skeleton class="h-5 w-18 max-md:!hidden" />

    <div class="grid grid-cols-2 gap-x-2 max-md:hidden">
      <button class="btn-secondary btn-small" disabled>{{ "Receive.Receive" | translate }}</button>
      <button *ngIf="inStock" class="btn-secondary btn-small" disabled>{{ "Send.Send" | translate }}</button>
      <button *ngIf="!inStock" class="btn-secondary btn-small" disabled>{{ "Swap.Swap" | translate }}</button>
    </div>
    <div class="ml-auto flex w-fit flex-col items-end gap-y-1 md:hidden">
      <app-skeleton class="h-5 w-40" />
      <app-skeleton class="h-5 w-18" />
    </div>
  </div>
</ng-template>
