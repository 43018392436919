<div class="dropdown" ngbDropdown container="body">
  <div id="custom-dropdown" ngbDropdownToggle #toggle>
    <ng-content></ng-content>
  </div>

  <ul
    *ngIf="!disabled"
    aria-labelledby="custom-dropdown"
    ngbDropdownMenu
    [matchWidth]="widthAsToggle ? toggle : null"
  >
    <li
      *ngFor="let item of items"
      (click)="pickItem(item)"
      ngbDropdownItem
      [class.selected]="isSelected(item)"
    >
      {{ item | translate }}

      <svg
        *ngIf="isSelected(item)"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        class="ml-auto h-4 w-4 text-text-secondary"
      >
        <path
          d="M12.3334 1L5.00008 8.33333L1.66675 5"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </li>
  </ul>
</div>
