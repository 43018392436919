import { KycDto } from "src/app/common/DTO/kyc/kyc.dto";
import { UserRiskLevel } from "../../enums/user-risk-level.enum";
import { BalanceDto } from "../balances/balance.dto";
import { RoleDto } from "../roles";
import { WalletDto } from "../wallets/wallet.dto";

interface IBlackList {
  isForever: boolean;
}

export class UserDto {
  public id = 0;
  public avatar: string | null = null;
  public phoneNumber = "";
  public createdAt: Date = new Date();
  public isActive: boolean = true;
  public role: RoleDto = new RoleDto();
  public kyc: KycDto | null = null;
  public blackList: IBlackList | null = null;
  public walletAddress: string | null = null;
  public riskLevel: UserRiskLevel = UserRiskLevel.None;
  public cards: string[] = [];
  public wallets: WalletDto[] = [];
  public balances: BalanceDto[] = [];
  public havePinCode: boolean = false;
}
