import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  DepositControlPanelDto,
  RiskSignalDto,
  UpdateDepositControlPanelDto,
} from "src/app/common/DTO/deposit-control-panel";
import { RequestOnDepositDto, SetVerdictForRequestOnDepositDto } from "src/app/common/DTO/request-on-deposit";
import { AmlDepositsService } from "src/app/services/aml-deposits.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  templateUrl: "./admin-aml.component.html",
  styleUrls: ["./admin-aml.component.css"],
})
export class AdminAmlComponent {
  public depositControlPanel: DepositControlPanelDto = new DepositControlPanelDto();
  public isEditingAutoBlock: boolean = false;
  public isEditingInSignal: boolean = false;
  public isEditingOutSignal: boolean = false;
  public isPending: boolean = false;
  public panelForm: FormGroup;

  public incomeSignalsSearch: string = "";
  public outcomeSignalsSearch: string = "";

  public requestsOnDeposit: RequestOnDepositDto[] = [];
  public currentPage: number = 1;
  public totalCount: number = 0;
  public pageSize: number = 10;
  public isVerdictPending: boolean = false;

  constructor(
    private readonly _amlDepositsService: AmlDepositsService,
    private readonly _toastService: ToastService,
    private readonly _translateService: TranslateService
  ) {
    const createRiskSignalFormGroup = (): FormGroup => {
      const controls: { [key in keyof RiskSignalDto]: FormControl } = {} as any;
      const riskSignalKeys = Object.keys(new RiskSignalDto()) as Array<keyof RiskSignalDto>;
      riskSignalKeys.forEach(key => {
        controls[key] = new FormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]);
      });
      return new FormGroup(controls);
    };

    this.panelForm = new FormGroup({
      autoBlock: new FormControl(false),
      inSignal: createRiskSignalFormGroup(),
      outSignal: createRiskSignalFormGroup(),
    });
  }

  async ngOnInit() {
    await this.getDepositControlPanel();
    await this.getAllRequestsOnDeposit();
  }

  public async toggleEditing(editSection: "AutoBlock" | "InSignal" | "OutSignal") {
    if (this[`isEditing${editSection}`]) {
      await this.updateDepositControlPanel();
      this[`isEditing${editSection}`] = false;
    } else {
      this[`isEditing${editSection}`] = true;
    }
  }

  public getSignalKeys(signalType: "inSignal" | "outSignal"): Array<keyof RiskSignalDto> {
    const formGroup = this.panelForm.get(signalType) as FormGroup;
    return formGroup ? (Object.keys(formGroup.controls) as Array<keyof RiskSignalDto>) : [];
  }

  public getSignalControlError(key: keyof RiskSignalDto, signalType: "inSignal" | "outSignal"): string {
    const formGroup = this.panelForm.get(signalType) as FormGroup;
    const control = formGroup.get(key) as FormControl;

    if (!control.touched) {
      return "";
    }

    if (control.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (control.hasError("max") || control.hasError("min")) {
      return this._translateService.instant("Common.Field_not_correct");
    }

    return "";
  }

  public shouldDisplaySignal(key: keyof RiskSignalDto, signalType: "inSignal" | "outSignal"): boolean {
    const searchTerm = signalType === "inSignal" ? this.incomeSignalsSearch : this.outcomeSignalsSearch;

    if (!searchTerm) {
      return true;
    }

    const translationKey = `Admin.Aml.${key}`;
    const translatedText = this._translateService.instant(translationKey);
    return translatedText.toLowerCase().includes(searchTerm.toLowerCase());
  }

  public onRequestsPageChange() {
    this.getAllRequestsOnDeposit();
  }

  public async setVerdictForRequestOnDeposit(requestId: number, approve: boolean) {
    const dto: SetVerdictForRequestOnDepositDto = {
      requestId,
      approve,
    };
    this.isVerdictPending = true;
    const result = await this._amlDepositsService.setVerdictForRequestOnDeposit(dto);
    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.error(errorMsg);
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.success(successMsg);
      await this.getAllRequestsOnDeposit();
    }
    this.isVerdictPending = false;
  }

  public getTriggeredSignals(request: RequestOnDepositDto): Array<{ name: string; value: number }> {
    const triggered = [];
    const signalKeys = Object.keys(new RiskSignalDto()) as Array<keyof RiskSignalDto>;

    for (const key of signalKeys) {
      const inSignalValue = request.signal?.in?.[key] ?? 0;
      const outSignalValue = request.signal?.out?.[key] ?? 0;

      const inThreshold = this.depositControlPanel.inSignal[key];
      const outThreshold = this.depositControlPanel.outSignal[key];

      const translationKey = `Admin.Aml.${key}`;
      const translatedName = this._translateService.instant(translationKey);

      // Check IN signal
      if (inSignalValue > 0 && inSignalValue > inThreshold) {
        triggered.push({ name: `${translatedName} (In)`, value: inSignalValue });
      }

      // Check OUT signal
      if (outSignalValue > 0 && outSignalValue > outThreshold) {
        triggered.push({ name: `${translatedName} (Out)`, value: outSignalValue });
      }
    }
    return triggered;
  }

  private async getDepositControlPanel() {
    const result = await this._amlDepositsService.getDepositControlPanels();
    if (result.params) {
      this.depositControlPanel = result.params;
      this.panelForm.patchValue(this.depositControlPanel);
    }
  }

  private async updateDepositControlPanel() {
    if (this.panelForm.invalid) {
      return;
    }

    const formValue = this.panelForm.value;
    const dto: UpdateDepositControlPanelDto = {
      autoBlock: formValue.autoBlock,
      inSignal: { ...formValue.inSignal },
      outSignal: { ...formValue.outSignal },
    };

    this.isPending = true;
    const result = await this._amlDepositsService.updateDepositControlPanel(dto);

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.error(errorMsg);
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.success(successMsg);
      await this.getDepositControlPanel();
    }

    this.isPending = false;
  }

  private async getAllRequestsOnDeposit() {
    const result = await this._amlDepositsService.getAllRequestsOnDeposit(this.currentPage, this.pageSize);
    if (result.params) {
      this.requestsOnDeposit = result.params.items;
      this.totalCount = result.params.totalCount;
    }
  }
}
