<div class="relative md:min-h-[29rem] md:w-[40.625rem]" [ngClass]="{ hidden: hideWindow }">
  <!-- Only on mobile -->
  <div
    class="flex items-center justify-between gap-x-4 rounded-2xl bg-gray-25 p-4 dark:bg-slate-800 md:hidden"
  >
    <app-logo [theme]="mode" />
    <app-language-selector size="sm" class="ml-auto" />
    <button (click)="onClose()" class="h-6 w-6">
      <app-svg-icon name="close" class="block" />
    </button>
  </div>

  <div
    class="flex items-center justify-between p-3 max-md:mt-2 max-md:rounded-2xl max-md:bg-gray-25 max-md:p-4 max-md:dark:bg-slate-800"
  >
    <h2 class="text-2xl font-semibold">{{ "Settings.Title" | translate }}</h2>
    <button (click)="onClose()" class="btn-flat h-6 w-6 max-md:hidden">
      <app-svg-icon name="close" class="block" />
    </button>
  </div>

  <section
    class="relative mt-6 grid grid-cols-[11.875rem_auto] gap-x-6 max-md:mt-2 max-md:block max-md:h-fill max-md:rounded-2xl max-md:bg-gray-25 max-md:p-4 max-md:dark:bg-slate-800"
  >
    <aside>
      <ul class="flex gap-3 max-md:items-center max-md:overflow-x-auto md:flex-col">
        <li *ngFor="let item of SETTINGS_TABS">
          <button
            (click)="onTabSelect(item)"
            class="flex w-full items-center gap-x-2 rounded-xl border border-gray-100 px-5 py-3 text-text-secondary transition-colors hover:text-black dark:border-slate-700 dark:hover:text-white"
            [ngClass]="{
              '!border-green-400 !text-black dark:!border-green-400 dark:!text-white':
                item.id === currentTab.id
            }"
          >
            <app-svg-icon [name]="item.icon" class="h-6 w-6 shrink-0" />
            <span class="whitespace-nowrap text-sm font-semibold">{{ item.title | translate }}</span>
          </button>
        </li>
      </ul>
    </aside>

    <div class="overflow-y-auto max-md:mt-6 max-md:h-fill">
      <ng-container [ngSwitch]="currentTab.id">
        <app-change-password-modal
          *ngSwitchCase="'password'"
          [userId]="user?.id || null"
          (onSuccess)="hideWindow = true"
          (onBack)="hideWindow = false"
        />
        <app-delete-account
          *ngSwitchCase="'delete'"
          [userId]="user?.id || null"
          (onSuccess)="hideWindow = true"
          (onBack)="hideWindow = false"
        />
        <app-change-pincode
          *ngSwitchCase="'pin'"
          (onSuccess)="hideWindow = true"
          (onBack)="hideWindow = false"
        />
      </ng-container>
    </div>
  </section>
</div>
