<section class="rounded-2xl bg-gray-25 px-3 py-6 dark:bg-slate-800">
  <div class="flex items-center justify-between px-3">
    <h2 class="subtitle py-1">
      <span class="max-md:hidden">{{ "Transaction.Your_transactions" | translate }}</span>
      <span class="md:hidden">{{ "Transaction.Title" | translate }} </span>
    </h2>
    <a
      [routerLink]="['/', RouteConstants.depositary, RouteConstants.transactions]"
      class="flex items-center gap-x-2 font-bold text-green-400"
    >
      {{ "Transaction.Show_all" | translate }}
      <app-svg-icon name="chevron" class="w-4 -rotate-90"></app-svg-icon>
    </a>
  </div>

  <div #scrollContainerRef class="relative mt-6 max-h-[28.5rem] overflow-y-auto">
    <ng-container *ngIf="!isTxsLoading; else loader">
      <ng-container *ngIf="transactions.length > 0; else noTxs">
        <div
          class="flex flex-col gap-y-1 max-md:gap-y-1"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          [infiniteScrollContainer]="scrollContainerRef"
          (scrolled)="loadMoreTransactions()"
        >
          <app-transaction-list-item
            *ngFor="let tx of transactions"
            [txDto]="tx"
            [rates]="rates"
            [isBalanceVisible]="isBalanceVisible"
          />

          <ng-container *ngIf="isLoadingMore">
            <app-transaction-list-item-skeleton />
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #loader>
      <app-transaction-list-item-skeleton />
      <app-transaction-list-item-skeleton />
      <app-transaction-list-item-skeleton />
    </ng-template>

    <ng-template #noTxs>
      <div class="text-center font-semibold text-text-secondary">
        {{ "Transaction.No_transactions" | translate }}
      </div>
    </ng-template>
  </div>
</section>
