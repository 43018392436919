import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MinBalanceForWithdrawDto } from "src/app/common/DTO/min-balance-for-withdraws";
import { NetworkFeeDto } from "src/app/common/DTO/network-fee";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { MinBalanceForWithdrawsErrorCode } from "src/app/common/enums/min-balance-error-code.enum";
import { NetworkFeesErrorCode } from "src/app/common/enums/network-fees-error-code.enum";
import { Network } from "src/app/common/enums/network.enum";
import { getCurrencyNetwork } from "src/app/common/utils/currency-network-helper.util";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { MinBalanceForWithdrawsService } from "src/app/services/min-balance-for-withdraws.service";
import { NetworkFeesService } from "src/app/services/network-fees.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-min-balance-block",
  templateUrl: "./min-balance-block.component.html",
  styleUrls: ["./min-balance-block.component.css"],
})
export class MinBalanceBlockComponent implements OnInit {
  @Input() public selectedCurrency: CryptoSymbol = CryptoSymbol.Trx;

  public networkFees: NetworkFeeDto[] = [];
  public editNetworkFeeForm: FormGroup;
  public isEditingNetworkFee = false;
  public isEditNetworkFeePending = false;

  public minBalanceForWithdraws: MinBalanceForWithdrawDto[] = [];
  public editMinBalanceForm: FormGroup;
  public isEditingMinBalance = false;
  public isEditMinBalancePending = false;
  public hasInteractionPermissions = false;

  constructor(
    private _minBalanceForWithdrawsService: MinBalanceForWithdrawsService,
    private _localStorage: LocalStorageService,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    private _networkFeesService: NetworkFeesService
  ) {
    this.editNetworkFeeForm = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(0)]),
    });
    this.editMinBalanceForm = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(0)]),
    });
  }

  public async ngOnInit() {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.CommissionInteraction
    );
    await this.requestNetworkFees();
    await this.requestMinBalanceForWithdraws();
  }

  public get currentNetworkFee() {
    return this.networkFees.find(x => x.network === getCurrencyNetwork(this.selectedCurrency));
  }

  public get currentMinBalance() {
    return this.minBalanceForWithdraws.find(item => item.currency === this.selectedCurrency);
  }

  public toggleEditNetworkFee() {
    if (this.isEditingNetworkFee) {
      this.onEditNetworkFeeSubmit();
    } else {
      this.isEditingNetworkFee = true;
      this.editNetworkFeeForm.get("amount")?.patchValue(this.currentNetworkFee?.amount);
    }
  }

  public toggleEditMinBalance() {
    if (this.isEditingMinBalance) {
      this.onEditMinBalanceSubmit();
    } else {
      this.isEditingMinBalance = true;
      this.editMinBalanceForm.get("amount")?.patchValue(this.currentMinBalance?.amount);
    }
  }

  public get networkFeeAmountError() {
    const field = this.editNetworkFeeForm.get("amount");
    if (field?.touched == false) {
      return null;
    }
    if (field?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (field?.hasError("min") || field?.hasError("max")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
  }

  public get minBalanceAmountError() {
    const field = this.editMinBalanceForm.get("amount");
    if (field?.touched == false) {
      return null;
    }
    if (field?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (field?.hasError("min") || field?.hasError("max")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
  }

  public async onEditNetworkFeeSubmit() {
    if (this.editNetworkFeeForm.invalid) {
      return;
    }

    this.isEditNetworkFeePending = true;
    const amount = this.editNetworkFeeForm.get("amount")?.value;
    const network = this.currentNetworkFee?.network!;
    const result = await this.editNetworkFee(amount, network);

    this.isEditNetworkFeePending = false;
    if (result.withError) {
      return;
    }

    this.isEditNetworkFeePending = false;
    await this.requestNetworkFees();
    this.editNetworkFeeForm.reset();
    this.isEditingNetworkFee = false;
  }

  public async onEditMinBalanceSubmit() {
    if (this.editMinBalanceForm.invalid) {
      return;
    }

    this.isEditMinBalancePending = true;
    const amount = this.editMinBalanceForm.get("amount")?.value;
    const result = await this.editMinBalance(amount, this.selectedCurrency);

    this.isEditMinBalancePending = false;
    if (result.withError) {
      return;
    }

    await this.requestMinBalanceForWithdraws();
    this.editMinBalanceForm.reset();
    this.isEditingMinBalance = false;
  }

  private async requestMinBalanceForWithdraws() {
    const result = await this._minBalanceForWithdrawsService.getMinBalanceForWithdraws();
    this.minBalanceForWithdraws = result.params ?? [];
  }

  private async editMinBalance(amount: number, currency: CryptoSymbol) {
    const result = await this._minBalanceForWithdrawsService.updateMinBalanceForWithdraws(currency, amount);

    if (result.withError) {
      switch (result.errorCode) {
        case MinBalanceForWithdrawsErrorCode.FeeGreaterThanMinBalance:
          this._toastService.error(this._translateService.instant("Admin.Home.Fee_greater_than_min_balance"));
          break;
        default:
          this._toastService.error(this._translateService.instant("Common.Unknown_error"));
          break;
      }
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.success(successMsg);
    }

    return result;
  }

  private async requestNetworkFees() {
    const result = await this._networkFeesService.getNetworkFees();
    this.networkFees = result.params ?? [];
  }

  private async editNetworkFee(amount: number, network: Network) {
    const result = await this._networkFeesService.updateNetworkFees(amount, network);

    if (result.withError) {
      switch (result.errorCode) {
        case NetworkFeesErrorCode.FeeGreaterThanMinBalance:
          this._toastService.error(this._translateService.instant("Admin.Home.Fee_greater_than_min_balance"));
          break;
        default:
          this._toastService.error(this._translateService.instant("Common.Unknown_error"));
          break;
      }
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.success(successMsg);
    }

    return result;
  }
}
