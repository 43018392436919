<div class="box px-3">
  <div class="flex items-center justify-between border-b border-gray-100 pb-5 dark:border-slate-700">
    <h3 class="altitle">
      {{ "Admin.Home.System_schedule" | translate }}
    </h3>
  </div>

  <div class="mt-6 space-y-2">
    <form class="admin-value-row-wrapper" [formGroup]="editScheduleForm">
      <p>{{ "Admin.Home.Schedule_interval" | translate }}:</p>
      <div class="admin-value-row">
        <div *ngIf="isEditing" class="flex-grow">
          <input
            type="number"
            formControlName="collectFundsInterval"
            placeholder="0"
            appPositiveDecimals
            class="w-full bg-transparent text-sm font-medium"
          />
        </div>
        <span *ngIf="!isEditing" class="text-sm text-text-secondary">{{ parsedInterval }}</span>

        <button
          *ngIf="hasInteractionPermissions"
          class="btn-flat w-6"
          (click)="toggleEditing()"
          [disabled]="isPending || (isEditing && editScheduleForm.invalid)"
        >
          <app-svg-icon [name]="isEditing ? 'check' : 'edit'" />
        </button>
      </div>
      <span *ngIf="isEditing" class="text-xs text-red-400">{{ getFieldError("collectFundsInterval") }}</span>
    </form>

    <div class="admin-value-row-wrapper">
      <p>{{ "Admin.Home.Last_funds_collection" | translate }}:</p>
      <div class="admin-value-row">
        <span class="text-sm text-text-secondary">{{
          currencySchedule?.lastFundsCollectionAt
            ? (currencySchedule?.lastFundsCollectionAt! | customDate: "full")
            : "-"
        }}</span>
      </div>
    </div>
  </div>
</div>
