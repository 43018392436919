import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { Network } from "src/app/common/enums/network.enum";
import { getCurrencyName } from "./currency-name-helper.util";
import { getNetworkName } from "./network-name-helper";

export function getNetworkInfo(network: Network) {
  const name = getNetworkName(network);

  let icon, currency;

  switch (network) {
    case Network.Tron:
      icon = "assets/icons/currency/tron.png";
      currency = CryptoSymbol.Trx;
      break;
    case Network.Polygon:
      icon = "assets/icons/currency/polygon.png";
      currency = CryptoSymbol.Matic;
      break;
    case Network.Ton:
      icon = "assets/icons/currency/ton.png";
      currency = CryptoSymbol.Ton;
      break;
    case Network.Bitcoin:
      icon = "assets/icons/currency/bitcoin.png";
      currency = CryptoSymbol.Bitcoin;
      break;
    default:
      break;
  }

  const currencyName = getCurrencyName(currency ?? CryptoSymbol.Usdt);

  return { name, icon, currency, currencyName };
}
