import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IMenuItem } from "src/app/common/constants/menu-items.constant";

@Component({
  selector: "app-menu-link-button",
  templateUrl: "./menu-link-button.component.html",
  styleUrls: ["./menu-link-button.component.css"],
})
export class MenuLinkButtonComponent {
  @Input() item: IMenuItem = { link: "", icon: "", title: "" };
  @Input() type: "button" | "link" = "link";

  @Output() onClick = new EventEmitter();

  constructor() {}

  public get isCurrent(): boolean {
    return window.location.pathname == this.item.link.split("?")[0].split("#")[0];
  }

  public onItemClick(event: MouseEvent) {
    if (this.isCurrent) {
      event.preventDefault();
    }
    this.onClick.emit();
  }
}
