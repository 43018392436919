import { AccountRole } from "src/app/common/enums/account-role.enum";

export function getRoleName(role?: AccountRole | string) {
  switch (role) {
    case AccountRole.Admin:
      return "Админ";
    case AccountRole.User:
      return "Пользователь";
    case AccountRole.ObserverAmin:
      return "Обозреватель";
    case AccountRole.SuperAdmin:
      return "Суперадмин";
    default:
      return "Неизвестный";
  }
}
