<div class="dropdown" ngbDropdown container="body" display="dynamic" placement="bottom-end top-end">
  <div id="custom-dropdown" ngbDropdownToggle class="dropdown__toggle">
    <ng-content></ng-content>
  </div>

  <ul
    *ngIf="!disabled"
    aria-labelledby="custom-dropdown"
    class="dropdown__list"
    [ngClass]="{ 'dropdown__list_full-width': isFullWidthList }"
    ngbDropdownMenu
  >
    <li *ngFor="let item of items" class="dropdown__item" (click)="pickItem(item)" ngbDropdownItem>
      <app-currency-icon [currency]="item" size="sm" />
      <span>{{ getCurrencyNetworkName(item) }}</span>
      <span class="text-xs text-slate-400 dark:text-slate-200">{{ getCurrencyName(item) }}</span>
    </li>
  </ul>
</div>
