<div
  class="flex w-[calc(100vw-2.5rem)] flex-col items-center justify-center text-center md:w-[calc(100vw-21.5rem)]"
>
  <h1 class="whitespace-pre-line text-3xl font-semibold md:text-7xl md:leading-tight">
    {{ slide?.title?.common! | translate }}
    <br />
    <span class="text-green-400">{{ slide?.title?.highlighted! | translate }}</span>
  </h1>

  <div
    class="mt-5 flex items-center gap-x-3 text-lg font-semibold text-text-secondary md:gap-x-4 md:text-2xl"
  >
    <span>{{ "Landing.Sub_1" | translate }}</span>
    <div class="h-1.5 w-1.5 rounded-full bg-text-secondary"></div>
    <span>{{ "Landing.Sub_2" | translate }}</span>
    <div class="h-1.5 w-1.5 rounded-full bg-text-secondary"></div>
    <span>{{ "Landing.Sub_3" | translate }}</span>
  </div>
</div>
