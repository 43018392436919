<div
  class="max-md:my-2 max-md:rounded-2xl max-md:bg-gray-25 max-md:p-4 max-md:dark:bg-slate-800 md:absolute md:top-8"
>
  <h1 class="title">{{ "Assets.Title" | translate }}</h1>
</div>

<section class="rounded-2xl bg-gray-25 py-6 dark:bg-slate-800 max-md:pb-4">
  <h2 class="subtitle px-6 py-3 max-md:py-1">{{ "Assets.Assets_catalog" | translate }}</h2>

  <div class="mt-6">
    <div
      class="grid grid-cols-wallet-balances-table items-center gap-x-1 px-6 py-3 text-xs font-medium text-text-secondary max-md:grid-cols-wallet-balances-table-mobile max-md:gap-x-2"
    >
      <span>{{ "Wallet.Currency_name" | translate }}</span>
      <span class="max-md:hidden">{{ "Wallet.In_usdt" | translate }}</span>
      <span class="max-md:hidden">{{ "Wallet.In_uzs" | translate }}</span>
      <app-dropdown
        class="md:hidden"
        [items]="mobileCurrencyOptionsNames"
        (onSelect)="onMobileCurrencyChange($event)"
      >
        <div class="flex items-center gap-x-2">
          <span>{{ selectedMobileBalanceCurrency ?? "" | translate }}</span>
          <app-svg-icon name="chevron" class="w-3" />
        </div>
      </app-dropdown>
      <span class="max-md:hidden">{{ "Wallet.Price_dynamics" | translate }}</span>
      <span></span>
    </div>

    <div>
      <ng-container *ngIf="!isLoading; else balancesSkeleton">
        <app-balance-row
          *ngFor="let balance of balances"
          [inStock]="false"
          [balanceItem]="balance"
          [rates]="rates"
          [ratePercentChange]="getRatePercentChange(balance.currency)"
          [mobileBalanceCurrency]="mobileBalanceCurrency"
          (receiveClick)="openReceiveModal(balance)"
          (swapClick)="openSwapModal(balance)"
        />
      </ng-container>

      <ng-template #balancesSkeleton>
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" />
        <app-balance-row [isLoading]="true" class="max-md:hidden" />
      </ng-template>
    </div>
  </div>
</section>
