import { Network } from "src/app/common/enums/network.enum";

export function getTransactionLink(network: Network, hash: string) {
  switch (network) {
    case Network.Tron:
      return `https://tronscan.org/#/transaction/${hash}`;
    case Network.Polygon:
      return `https://polygonscan.com/tx/${hash}`;
    case Network.Ton:
      return `https://tonscan.org/tx/${hash}`;
    case Network.Bitcoin:
      return `https://btcscan.org/tx/${hash}`;
    default:
      return "";
  }
}
