import { Pipe, PipeTransform } from "@angular/core";
import { CryptoSymbol } from "../enums/crypto-symbol.enum";

type MfeCustomPipeOptions = {
  currency?: CryptoSymbol;
  fiat?: boolean;
};

@Pipe({
  name: "mfeCustom",
})
export class MfeCustomPipe implements PipeTransform {
  transform(value: string | number | undefined, options?: MfeCustomPipeOptions): string {
    if (value === undefined) {
      return "0";
    }

    let transformed = Number(value);

    if (Number.isNaN(transformed) || transformed <= 0) {
      return "0";
    }

    // if (options?.currency === CryptoSymbol.AbstractUsdt && Math.abs(transformed) < 1e-2) {
    //   return this.convertScientificToDecimal(transformed);
    // }

    let maxDecimals = 2;
    switch (options?.currency) {
      case CryptoSymbol.Uzs:
        maxDecimals = 0;
        break;
      case CryptoSymbol.AbstractUsdt:
        maxDecimals = 4;
        break;
      case CryptoSymbol.Trx:
        maxDecimals = 6;
        break;
      case CryptoSymbol.Bitcoin:
        maxDecimals = 8;
        break;
      case CryptoSymbol.Ton:
        maxDecimals = 6;
        break;
      case CryptoSymbol.Not:
        maxDecimals = 4;
        break;
      case CryptoSymbol.Matic:
        maxDecimals = 6;
        break;
      default:
        maxDecimals = 16;
        break;
    }

    if (options?.fiat) {
      if (transformed < 1e-2) {
        return this.convertScientificToDecimal(transformed);
      }
      maxDecimals = 2;
    }

    if (options?.currency === CryptoSymbol.Uzs && transformed > 10_000_000) {
      return this.uzsMilFormatter(transformed);
    }

    // if (Math.abs(transformed) < 1e-6) {
    //   return transformed.toFixed(16).replace(/\.?0+$/, "");
    // }

    return new Intl.NumberFormat("mfe", {
      minimumFractionDigits: 0,
      maximumFractionDigits: maxDecimals,
    }).format(this.roundDownSignificantDigits(transformed, maxDecimals + 1));
  }

  private convertScientificToDecimal(number: number) {
    const decimalPlaces = -Math.floor(Math.log10(Math.abs(number)));
    return number.toFixed(decimalPlaces);
  }

  private roundDownSignificantDigits(number: number, decimals: number) {
    let significantDigits = parseInt(number.toExponential().split("e-")[1]) || 0;
    let decimalsUpdated = (decimals || 0) + significantDigits - 1;
    decimals = Math.min(decimalsUpdated, number.toString().length);

    return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }

  private uzsMilFormatter(num: number) {
    if (num > 10_000_000 && num < 999_999_999) {
      return (num / 1_000_000).toFixed(1) + " млн.";
    }

    if (num > 1_000_000_000 && num < 999_999_999_999) {
      return (num / 1_000_000_000).toFixed(1) + " млрд.";
    }

    return num.toFixed(0);
  }
}
