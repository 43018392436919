import { Pipe, PipeTransform } from "@angular/core";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";

@Pipe({
  name: "currencyName",
})
export class CurrencyNamePipe implements PipeTransform {
  transform(value: CryptoSymbol): string {
    return getCurrencyName(value);
  }
}
