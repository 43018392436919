<h4 *ngIf="step !== 3" class="text-center text-2xl font-bold">
  {{ variant === "buy" ? ("Buy.Title" | translate) : ("Sell.Title" | translate) }}
  {{ currencySymbol }}
  ({{ currencyNetwork }})
</h4>
<h4 *ngIf="step === 3" class="text-center text-2xl font-bold">{{ "Operation.In_progress" | translate }}</h4>
<app-stepper class="mb-5 mt-2 block" [totalSteps]="3" [currentStep]="step" />

<ng-container [ngSwitch]="step">
  <ng-template ngSwitchCase="1">
    <div class="space-y-5">
      <app-input
        type="number"
        [title]="variant === 'buy' ? ('Buy.You_receive' | translate) : ('Sell.You_give' | translate)"
        [(ngModel)]="amount"
        placeholder="100"
        (input)="calculateUzs()"
        appPositiveDecimals
        [errorMsg]="amountError"
      />
      <div class="space-y-2">
        <p *ngIf="variant === 'buy'" class="flex items-center justify-between">
          {{ "Buy.Min_amount" | translate }}:
          <span>1 {{ currencySymbol }}</span>
        </p>
        <p class="flex items-center justify-between">
          {{ "Sell.Service_commission" | translate }}:
          <span>
            {{ serviceFeePercents | mfeCustom }}% =
            {{ feeAmount | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS
          </span>
        </p>
        <p class="flex items-center justify-between">
          {{ "Sell.Network_commission" | translate }}:
          <span>
            {{ networkFee | mfeCustom: { currency: selectedBalance.currency } }} {{ nativeCurrencyName }} =
            {{ networkFeeUzs | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS
          </span>
        </p>
      </div>
      <p class="flex items-center justify-between">
        {{ variant === "buy" ? ("Buy.You_give" | translate) : ("Buy.You_receive" | translate) }}:
        <span class="text-2xl font-bold"
          >{{ totalAmount | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS</span
        >
      </p>
      <button class="btn w-full" (click)="onInputSubmit()">{{ "Common.Next" | translate }}</button>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="2">
    <div class="space-y-5">
      <p class="flex items-center justify-between">
        {{ variant === "buy" ? ("Sell.You_get" | translate) : ("Sell.You_sell" | translate) }}:
        <span class="text-2xl font-bold"> {{ amount! | mfeCustom }} {{ currencySymbol }}</span>
      </p>
      <p class="flex items-center justify-between">
        {{ "Sell.Service_commission" | translate }}:
        <span>
          {{ serviceFeePercents | mfeCustom }}% =
          {{ feeAmount | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS
        </span>
      </p>
      <p class="flex items-center justify-between">
        {{ "Sell.Network_commission" | translate }}:
        <span>
          {{ networkFee | mfeCustom: { currency: selectedBalance.currency } }} {{ nativeCurrencyName }} =
          {{ networkFeeUzs | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS
        </span>
      </p>
      <p class="flex items-center justify-between">
        {{ variant === "buy" ? ("Buy.You_give" | translate) : ("Buy.You_receive" | translate) }}:
        <span class="text-2xl font-bold">
          {{ totalAmount | mfeCustom: { currency: CryptoSymbol.Uzs } }} UZS
        </span>
      </p>

      <p>{{ variant === "buy" ? ("Buy.Select_card" | translate) : ("Sell.Select_card" | translate) }}:</p>
      <div *ngIf="bankCards.length > 0; else noCards" class="flex flex-col gap-5">
        <app-bank-card
          *ngFor="let card of bankCards"
          [card]="card"
          [withSelect]="true"
          [selected]="selectedBankCard!.id === card.id"
          (onSelect)="selectCard($event)"
        />
      </div>
      <ng-template #noCards>
        <button
          class="flex w-full items-center justify-between rounded-main border border-accent px-7 py-8 font-bold"
          (click)="goToBankCards()"
        >
          {{ "Bank.Add_card" | translate }}
          <app-svg-icon name="arrow" class="w-7 -rotate-90" />
        </button>
      </ng-template>

      <div class="mt-5 flex items-stretch gap-x-5">
        <button
          type="button"
          class="flex w-16 items-center justify-center rounded-xl bg-select-bg px-5"
          (click)="onBack()"
        >
          <img src="assets/icons/arrow-back-outline.svg" alt="back" />
        </button>
        <button class="btn flex-grow" (click)="onCheckout()" [disabled]="isPending || !selectedBankCard">
          {{ variant === "buy" ? ("Buy.Title" | translate) : ("Sell.Title" | translate) }}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="3">
    <app-svg-icon name="hourglass" class="mx-auto block w-10" />
    <p class="mt-5 text-xl">
      {{ "Operation.Awaiting" | translate }}
    </p>
    <button class="btn mt-5 w-full" (click)="goToWallet()">{{ "Common.Continue" | translate }}</button>
  </ng-template>
</ng-container>
