import { Component } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { SignInModalComponent } from "src/app/pages/landing/modals/sign-in-modal/sign-in-modal.component";
import { AuthService } from "src/app/services/auth.service";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-enter-pin-modal",
  templateUrl: "./enter-pin-modal.component.html",
  styleUrls: ["./enter-pin-modal.component.css"],
})
export class EnterPinModalComponent {
  public pinCode: string = "";
  public isPending = false;
  public businessError: string | null = null;
  public readonly pinCodeLength = ValidateConstants.PinCodeLength;

  constructor(
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _modalRef: NgbActiveModal,
    private _translateService: TranslateService,
    private _localStorage: LocalStorageService
  ) {}

  public async onSubmit() {
    if (this.pinCode.length !== this.pinCodeLength) {
      return;
    }

    this.isPending = true;
    try {
      const res = await firstValueFrom(await this._authService.refreshTokens(this.pinCode));
      await this._localStorage.saveTokens(res.data);
      this.onClose();
      window.location.reload();
    } catch (error) {
      console.log(error);
      this.businessError = this._translateService.instant("Pincode.Pin_invalid");
    } finally {
      this.isPending = false;
    }
  }

  public onClose() {
    this._modalRef.dismiss();
  }

  public onEnterWithCred() {
    this.onClose();
    this._modalService.open(SignInModalComponent);
  }
}
