import { Component, OnInit } from "@angular/core";
import { NgbDate, NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { TxDto } from "src/app/common/DTO/txs";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { TxDirection } from "src/app/common/enums/tx-direction.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { UserResolver } from "src/app/common/resolvers/user.resolver";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { RatesService } from "src/app/services/rates.service";
import { TxService } from "src/app/services/tx.service";

@Component({
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.css"],
})
export class TransactionsComponent implements OnInit {
  public internalError = false;
  public user: UserDto = new UserDto();
  public rates: RateDto[] = [];

  public transactions: TxDto[] = [];
  public page = 1;
  public statuses: TxStatus[] = [];
  public types: TxType[] = [];
  public currencies: CryptoSymbol[] = [];
  public directions: TxDirection[] = [];
  public totalCount = 0;
  public isLoading: boolean = true;

  public startDate: NgbDate | null = null;
  public endDate: NgbDate | null = null;

  private pageSize = 10;

  public isLoadingMore: boolean = false;
  public hasMoreTransactions: boolean = true;

  private readonly currenciesList = [
    CryptoSymbol.AbstractUsdt,
    CryptoSymbol.Trx,
    CryptoSymbol.Matic,
    CryptoSymbol.Ton,
    CryptoSymbol.Not,
    CryptoSymbol.Bitcoin,
  ];

  public readonly trxTypeFilter = [
    { value: TxType.Chain, label: "Transaction.Type_chain" },
    { value: TxType.Internal, label: "Transaction.Type_internal" },
    { value: TxType.Swap, label: "Transaction.Type_swap" },
    { value: TxType.Deposit, label: "Transaction.Type_deposit" },
  ];

  public readonly trxStatusFilter = [
    { value: TxStatus.OnConfirm, label: "Transaction.Status_on_confirm" },
    { value: TxStatus.Approved, label: "Transaction.Status_approved" },
    { value: TxStatus.Error, label: "Transaction.Status_error" },
    { value: TxStatus.Processing, label: "Transaction.Status_processing" },
    { value: TxStatus.WaitingApproveByBlockchain, label: "Transaction.Status_waiting_approve_by_blockchain" },
  ];

  public readonly trxDirectionFilter = [
    { value: TxDirection.Sending, label: "Transaction.Sending" },
    { value: TxDirection.Receiving, label: "Transaction.Receiving" },
    { value: TxDirection.Swap, label: "Transaction.Swap" },
  ];

  constructor(
    private _modalService: NgbModal,
    private _userResolver: UserResolver,
    private _txService: TxService,
    private _ratesService: RatesService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.user = (await this._userResolver.resolve()) ?? new UserDto();

    await this.loadTransactions();
    await this.loadRates();
  }

  public get currencyNames(): string[] {
    return this.currenciesList.map(c => getCurrencyName(c));
  }

  public get selectedCurrencyNames(): string[] {
    return this.currencies.map(c => getCurrencyName(c));
  }

  public onSelectCurrency(currencyName: string) {
    const currency = this.currenciesList.find(x => getCurrencyName(x) === currencyName);
    if (currency) {
      if (this.currencies.includes(currency)) {
        this.currencies = this.currencies.filter(x => x !== currency);
      } else {
        this.currencies = [...this.currencies, currency];
      }
    }
  }

  public removeCurrency(currency: CryptoSymbol, event: Event) {
    event.stopPropagation();
    this.currencies = this.currencies.filter(x => x !== currency);
  }

  public onSelectStatus(statusName: string) {
    const status = this.trxStatusFilter.find(x => x.label === statusName)?.value;
    if (status) {
      const isSelected = this.statuses.includes(status);
      if (isSelected) {
        this.statuses = this.statuses.filter(x => x !== status);
      } else {
        this.statuses.push(status);
      }
    }
  }

  public removeStatus(status: number, event: Event) {
    event.stopPropagation();
    this.statuses = this.statuses.filter(x => x !== status);
  }

  public getStatusName(status: number): string {
    return this.trxStatusFilter.find(x => x.value === status)?.label ?? "";
  }

  public get trxStatusFilterNames(): string[] {
    return this.trxStatusFilter.map(x => x.label);
  }

  public get selectedTrxStatusFilterNames(): string[] {
    return this.statuses.map(x => this.getStatusName(x));
  }

  public get trxTypeFilterNames(): string[] {
    return this.trxTypeFilter.map(x => x.label);
  }

  public getTypeName(type: number): string {
    return this.trxTypeFilter.find(x => x.value === type)?.label ?? "";
  }

  public onSelectType(typeName: string) {
    const type = this.trxTypeFilter.find(x => x.label === typeName)?.value;
    if (type) {
      const isSelected = this.types.includes(type);
      if (isSelected) {
        this.types = this.types.filter(x => x !== type);
      } else {
        this.types.push(type);
      }
    }
  }

  public removeType(type: number, event: Event) {
    event.stopPropagation();
    this.types = this.types.filter(x => x !== type);
  }

  public get trxDirectionFilterNames(): string[] {
    return this.trxDirectionFilter.map(x => x.label);
  }

  public get selectedDirectionFilterNames(): string[] {
    return this.directions.map(x => this.getDirectionName(x));
  }

  public getDirectionName(direction: number): string {
    return this.trxDirectionFilter.find(x => x.value === direction)?.label ?? "";
  }

  public onSelectDirection(directionName: string) {
    const direction = this.trxDirectionFilter.find(x => x.label === directionName)?.value;
    if (direction) {
      if (this.directions.includes(direction)) {
        this.directions = this.directions.filter(x => x !== direction);
      } else {
        this.directions.push(direction);
      }
    }
  }

  public removeDirection(direction: number, event: Event) {
    event.stopPropagation();
    this.directions = this.directions.filter(x => x !== direction);
  }

  public get minEndDate(): NgbDateStruct {
    if (this.startDate) {
      return this.startDate as NgbDateStruct;
    }
    const nowDate = new Date();
    return { year: nowDate.getFullYear() - 10, month: nowDate.getMonth() + 1, day: nowDate.getDate() };
  }

  public handleFilterChange() {
    this.resetPagination();
    this.loadTransactions();
  }

  public onSelectDates(date: [NgbDate | null, NgbDate | null] | NgbDate | null, field?: "start" | "end") {
    if (Array.isArray(date)) {
      this.startDate = date[0];
      this.endDate = date[1];
    } else if (field === "end") {
      this.endDate = date;
    } else {
      this.startDate = date;
    }
  }

  public resetDates(field?: "start" | "end") {
    if (!field) {
      this.startDate = null;
      this.endDate = null;
    }
    if (field === "end") {
      this.endDate = null;
    } else {
      this.startDate = null;
    }
  }

  public resetFilters() {
    this.resetDates();
    this.statuses = [];
    this.types = [];
    this.currencies = [];
    this.directions = [];
    this.resetPagination();
    this.loadTransactions();
  }

  public openModal(content: any) {
    this._modalService.open(content);
  }

  public closeModalAndResetFilters(modal: any) {
    this.resetFilters();
    modal?.close?.();
  }

  public closeModalAndApplyFilters(modal: any) {
    this.handleFilterChange();
    modal?.close?.();
  }

  public get appliedFiltersCount(): number {
    let count = 0;
    if (this.startDate) count++;
    if (this.endDate) count++;
    if (this.statuses.length > 0) count += this.statuses.length;
    if (this.types.length > 0) count += this.types.length;
    if (this.currencies.length > 0) count += this.currencies.length;
    if (this.directions.length > 0) count += this.directions.length;
    return count;
  }

  public onPageChange() {
    this.loadTransactions();
  }

  private prepareDate(date?: NgbDate | string | null): string | undefined {
    if (!date || typeof date === "string") {
      return undefined;
    }
    const dateObj = new Date(date.year, date.month - 1, date.day);
    dateObj.setHours(0, 0, 0, 0);
    return dateObj.toISOString();
  }

  public async loadMoreTransactions(): Promise<void> {
    if (this.isLoadingMore || !this.hasMoreTransactions) {
      return;
    }

    this.isLoadingMore = true;
    this.page++;

    const startDate = this.prepareDate(this.startDate);
    const endDate = this.prepareDate(this.endDate);
    const res = await this._txService.getAllMy({
      page: this.page,
      size: this.pageSize,
      currencies: this.currencies,
      statuses: this.statuses,
      types: this.types,
      txDirections: this.directions,
      from: startDate,
      to: endDate,
    });

    if (res.withError) {
      this.internalError = true;
    } else {
      const newTransactions = res.params?.items ?? [];
      this.transactions = [...this.transactions, ...newTransactions];
      this.totalCount = res.params?.totalCount ?? 0;
      this.hasMoreTransactions = this.transactions.length < this.totalCount;
    }

    this.isLoadingMore = false;
  }

  private resetPagination() {
    this.page = 1;
    this.hasMoreTransactions = true;
    this.transactions = [];
  }

  private async loadTransactions() {
    this.isLoading = true;
    this.hasMoreTransactions = true;

    const startDate = this.prepareDate(this.startDate);
    const endDate = this.prepareDate(this.endDate);

    const res = await this._txService.getAllMy({
      page: this.page,
      size: this.pageSize,
      currencies: this.currencies,
      statuses: this.statuses,
      types: this.types,
      txDirections: this.directions,
      from: startDate,
      to: endDate,
    });

    if (res.withError) {
      this.internalError = true;
    } else {
      this.transactions = res.params?.items ?? [];
      this.totalCount = res.params?.totalCount ?? 0;
      this.hasMoreTransactions = this.transactions.length < this.totalCount;
    }
    this.isLoading = false;
  }

  private async loadRates() {
    const res = await this._ratesService.getRates();
    if (res.withError) {
      this.internalError = true;
    } else {
      this.rates = res.params ?? [];
    }
  }
}
