export class EventTypeConstants {
  public static readonly LoadUser = "LoadUser";
  public static readonly ChangeAvatar = "ChangeAvatar";
  public static readonly Logout = "Logout";
  public static readonly ToggleFullscreenLoader = "ToggleFullscreenLoader";
  public static readonly UpdateVerificationStatus = "UpdateVerificationStatus";
  public static readonly ChangeLanguage = "ChangeLanguage";
  public static readonly UpdateBalances = "UpdateBalances";
  public static readonly ToggleBalanceVisibility = "ToggleBalanceVisibility";
  public static readonly ShowEnterPinModal = "ShowEnterPinModal";
}
