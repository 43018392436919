import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgbDate, NgbDatepicker, NgbDropdown } from "@ng-bootstrap/ng-bootstrap";

type DatepickerValue = NgbDate | [NgbDate | null, NgbDate | null] | null;

interface DateRange {
  start: NgbDate | null;
  end: NgbDate | null;
}

@Component({
  selector: "app-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.css"],
})
export class DatepickerComponent {
  @Input() public value: DatepickerValue = null;
  @Input() public range: boolean = false;
  @Input() public withActionButtons: boolean = false;
  @Input() public placeholder: string = "Дата";

  public hoveredDate: NgbDate | null = null;
  public maxDate: NgbDate = new NgbDate(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    new Date().getDate()
  );
  public readonly weekdays = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];
  public Array = Array;

  @Output() onSelect = new EventEmitter<DatepickerValue>();
  @Output() onReset = new EventEmitter<void>();

  @ViewChild(NgbDropdown) public dropdown: NgbDropdown | null = null;

  constructor() {}

  public get selectedDates(): string {
    return this.formatSelectedDates();
  }

  public navigateToDate(datepicker: NgbDatepicker, monthOffset: number): void {
    const { state, calendar } = datepicker;
    datepicker.navigateTo(calendar.getNext(state.firstDate, "m", monthOffset));
  }

  public onDateSelection(date: NgbDate): void {
    if (this.range) {
      this.handleRangeSelection(date);
    } else {
      this.handleSingleDateSelection(date);
    }
  }

  public isHovered(date: NgbDate): boolean {
    if (!this.range || !Array.isArray(this.value)) return false;

    const [startDate, endDate] = this.value;
    return !!(
      startDate &&
      !endDate &&
      this.hoveredDate &&
      date.after(startDate) &&
      date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate): boolean {
    if (!this.range || !Array.isArray(this.value)) return false;

    const [startDate, endDate] = this.value;
    return !!(endDate && date.after(startDate) && date.before(endDate));
  }

  public isRange(date: NgbDate): boolean {
    if (!this.range || !Array.isArray(this.value)) return false;

    const [startDate, endDate] = this.value;
    return !!(
      date.equals(startDate) ||
      (endDate && date.equals(endDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public onSelectDates(): void {
    this.onSelect.emit(this.value);
  }

  public onResetDates(event?: Event): void {
    event?.stopPropagation();
    this.value = this.range ? [null, null] : null;
    this.hoveredDate = null;
    this.onReset.emit();
  }

  private formatDate(date: NgbDate | null): string {
    if (!date) return "";
    return `${this.padNumber(date.day)}.${this.padNumber(date.month)}.${date.year}`;
  }

  private padNumber(num: number): string {
    return num.toString().padStart(2, "0");
  }

  private formatSelectedDates(): string {
    if (!this.value) return "";

    if (Array.isArray(this.value)) {
      const [start, end] = this.value;
      const startDate = this.formatDate(start);
      const endDate = this.formatDate(end);

      return startDate && endDate ? `${startDate} - ${endDate}` : startDate || endDate;
    }

    return this.formatDate(this.value);
  }

  private handleRangeSelection(date: NgbDate): void {
    if (!Array.isArray(this.value)) return;

    const [startDate, endDate] = this.value;

    if (!startDate && !endDate) {
      this.value = [date, null];
    } else if (startDate && !endDate && date.after(startDate)) {
      this.value = [startDate, date];
      this.emitSelectionIfNoButtons();
    } else {
      this.value = [date, null];
    }
  }

  private handleSingleDateSelection(date: NgbDate): void {
    this.value = date;
    this.emitSelectionIfNoButtons();
  }

  private emitSelectionIfNoButtons(): void {
    if (!this.withActionButtons) {
      this.dropdown?.close();
      this.onSelectDates();
    }
  }
}
