<form [formGroup]="changePinCodeForm" class="flex flex-col gap-y-4" (ngSubmit)="onChangePinCode()">
  <app-input
    type="number"
    [title]="'Pincode.Old_pin' | translate"
    [placeholder]="'Pincode.Enter_old_pin' | translate"
    formControlName="oldPinCode"
    id="oldPinCode"
    [errorMsg]="getFieldHasErrors('oldPinCode')"
  />
  <app-input
    type="number"
    [title]="'Pincode.New_pin' | translate"
    [placeholder]="'Pincode.Enter_new_pin' | translate"
    formControlName="newPinCode"
    id="newPinCode"
    [errorMsg]="getFieldHasErrors('newPinCode')"
  />
  <app-input
    type="number"
    [title]="'Pincode.Repeat_new_pin' | translate"
    [placeholder]="'Pincode.Enter_repeat_new_pin' | translate"
    formControlName="repeatNewPinCode"
    id="repeatNewPinCode"
    [errorMsg]="getFieldHasErrors('repeatNewPinCode')"
  />
  <button
    type="submit"
    class="btn-primary btn-large w-full"
    [disabled]="changePinCodeForm.invalid || isPending"
  >
    {{ "Pincode.Change_pincode" | translate }}
  </button>
</form>

<ng-template #successModal let-modal>
  <div class="space-y-8">
    <div class="flex items-center justify-between p-3">
      <h3 class="title">{{ "Pincode.Success" | translate }}</h3>
      <button class="btn-flat h-6 w-6" (click)="modal.dismiss()">
        <app-svg-icon name="close" class="block" />
      </button>
    </div>
    <div>
      <app-status-mark variant="success" icon="check" class="mx-auto block" />
      <p class="mt-4 text-center text-xl font-semibold text-green-500 dark:text-green-200">
        {{ "Pincode.Pincode_changed" | translate }}
      </p>
    </div>
    <button class="btn-primary-dark btn-large w-full" (click)="modal.dismiss()">
      {{ "Pincode.Back" | translate }}
    </button>
  </div>
</ng-template>
