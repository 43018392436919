import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BalanceDto } from "src/app/common/DTO/balances/balance.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { MfeCustomPipe } from "src/app/common/pipes/mfe-custom.pipe";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { ReceiveModalComponent } from "src/app/components/receive-modal/receive-modal.component";
import { SendModalComponent } from "src/app/components/send-modal/send-modal.component";
import { BalancesService } from "src/app/services/balances.service";
import { RatesService } from "src/app/services/rates.service";
@Component({
  templateUrl: "./asset-page.component.html",
  styleUrls: ["./asset-page.component.css"],
})
export class AssetPageComponent {
  public currency: CryptoSymbol = CryptoSymbol.AbstractUsdt;

  public balances: BalanceDto[] = [];
  public rates: RateDto[] = [];

  constructor(
    private route: ActivatedRoute,
    private _balancesService: BalancesService,
    private _ratesService: RatesService,
    private _modalService: NgbModal
  ) {
    this.route.params.subscribe(params => {
      this.currency = Number(params["currency"]) as CryptoSymbol;
    });
  }

  async ngOnInit() {
    this.balances = (await this._balancesService.getBalances()).params ?? [];
    this.rates = (await this._ratesService.getRates()).params ?? [];
  }

  public get currentBalance() {
    return this.balances.find(b => b.currency === this.currency);
  }

  public getUsdValue(amount: number) {
    if (!this.rates || this.rates.length === 0) {
      return 0;
    }
    const usd = ConvertCurrencyHelper.convertToUsd(amount, this.currency, this.rates);
    const formatted = new MfeCustomPipe().transform(usd, {
      fiat: true,
    });
    return formatted;
  }

  public openReceiveModal(preselectedBalance?: BalanceDto) {
    const modalRef = this._modalService.open(ReceiveModalComponent);
    if (preselectedBalance) {
      modalRef.componentInstance.walletBalance = preselectedBalance;
    }
  }

  public openSendModal(preselectedBalance?: BalanceDto) {
    const modalRef = this._modalService.open(SendModalComponent);
    if (preselectedBalance) {
      modalRef.componentInstance.walletBalance = preselectedBalance;
    }
  }
}
