<div class="absolute top-8">
  <h1 class="title">{{ "Admin.Admins.Title" | translate }}</h1>
</div>

<section class="rounded-2xl bg-gray-25 py-6 dark:bg-slate-800">
  <div class="mb-6 flex items-center justify-between px-6">
    <app-tabs variant="buttons" [tabs]="tabs" (onSelect)="onTabSelect($event)" />
    <button class="btn-primary btn-medium" (click)="onClickAdd()" [disabled]="!hasInteractionPermissions">
      <ng-container *ngIf="currentTab.id === 1">
        {{ "Admin.Admins.Add_admin" | translate }}
      </ng-container>
      <ng-container *ngIf="currentTab.id === 2">
        {{ "Admin.Roles.Add_role" | translate }}
      </ng-container>
    </button>
  </div>

  <!-- Admins -->
  <table class="adminTable" *ngIf="currentTab.id === 1">
    <thead>
      <tr>
        <th scope="col">{{ "Admin.Admins.Login" | translate }}</th>
        <th scope="col">{{ "Admin.Admins.Password" | translate }}</th>
        <th scope="col">{{ "Admin.Admins.Phone_number" | translate }}</th>
        <th scope="col">{{ "Admin.Admins.Role" | translate }}</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let admin of admins">
        <td>{{ admin.login }}</td>
        <td>********</td>
        <td>{{ admin.phoneNumber | phoneDisplayer }}</td>
        <td>
          <app-dropdown
            *ngIf="canRoleBeChanged(admin); else roleRenderer"
            [items]="roleNames"
            (onSelect)="handleChangeRole(admin.id, $event)"
          >
            <button class="flex items-center gap-x-2" [disabled]="isPending">
              {{ admin.role }}
              <app-svg-icon name="chevron" class="block h-3 w-3" />
            </button>
          </app-dropdown>
          <ng-template #roleRenderer>
            {{ admin.role }}
          </ng-template>
        </td>
        <td class="flex justify-end">
          <button
            class="flex h-6 w-6 items-center justify-center rounded bg-gray-300 text-white hover:text-red-400 disabled:cursor-not-allowed disabled:opacity-50 dark:bg-slate-400"
            title="{{ 'Admin.Admins.Delete_admin' | translate }}"
            (click)="handleDeleteAdmin(admin.id)"
            [disabled]="!canDeleteAdmin(admin) || isPending"
          >
            <app-svg-icon name="admin/trash" class="block h-4 w-4" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Roles -->
  <app-roles-block
    *ngIf="currentTab.id === 2"
    [roles]="roles"
    (onRolesChange)="onRolesChange()"
    [onClickAddRole]="onClickAddRole.asObservable()"
  />
</section>

<ng-template #addAdminModal let-modal>
  <div class="flex items-center justify-between p-3">
    <h4 class="title">{{ "Admin.Admins.Add_admin" | translate }}</h4>
    <button class="btn-flat" (click)="closeAddAdminModal()">
      <app-svg-icon name="close" class="block h-6 w-6" />
    </button>
  </div>

  <form
    *ngIf="!showSuperConfirmation; else superConfirmation"
    class="mt-6 flex flex-col gap-y-4"
    [formGroup]="addAdminForm"
    (ngSubmit)="openConfirmation()"
  >
    <app-input
      type="text"
      id="newAdminName"
      [title]="'Admin.Admins.Name_surname' | translate"
      placeholder="Степан"
      formControlName="name"
      [errorMsg]="formControlError('name')"
    />
    <app-input
      type="phone"
      id="newAdminSurname"
      [title]="'Admin.Admins.Phone_number' | translate"
      placeholder="11 222 33 44"
      mask="00 000 00 00"
      formControlName="phoneNumber"
      [errorMsg]="formControlError('phoneNumber')"
    />
    <div>
      <p class="mb-2 text-sm font-medium">{{ "Admin.Admins.Role" | translate }}</p>
      <app-dropdown [items]="roleNames" (onSelect)="handleChangeRoleOnAdd($event)">
        <button type="button" class="dropdown-base-toggle w-full">
          {{ selectedRoleNameOnAdd ?? "Admin.Admins.Select_role" | translate }}
          <app-svg-icon name="chevron" class="block h-4 w-4" />
        </button>
      </app-dropdown>
    </div>
    <app-input
      [title]="'Admin.Admins.Login' | translate"
      type="text"
      placeholder="admin"
      formControlName="login"
      id="newAdminLogin"
      [errorMsg]="formControlError('login')"
    />
    <app-input
      [title]="'Admin.Admins.Password' | translate"
      type="password"
      placeholder="********"
      formControlName="psw"
      id="newAdminPsw"
      [errorMsg]="formControlError('psw')"
    />

    <button type="submit" class="btn-primary btn-large mt-2" [disabled]="addAdminForm.invalid">
      {{ "Common.Add" | translate }}
    </button>
  </form>
</ng-template>

<ng-template #superConfirmation>
  <div class="mt-6 flex flex-col gap-y-4">
    <app-input
      [title]="'Admin.Admins.Superadmin_password' | translate"
      type="password"
      placeholder="******"
      id="newAdminConfirm"
      [formControl]="adminPsw"
    />
    <button
      type="submit"
      class="btn-primary btn-large mt-2"
      (click)="handleAddAdmin()"
      [disabled]="adminPsw.invalid || isPending"
    >
      {{ "Common.Approve" | translate }}
    </button>
    <small class="block text-sm text-invalid-color" *ngIf="addErrorMessage">
      {{ addErrorMessage | translate }}
    </small>
  </div>
</ng-template>
