import { Component } from "@angular/core";
import { ThemeService } from "src/app/services/theme.service";

@Component({
  selector: "app-theme-selector",
  templateUrl: "./theme-selector.component.html",
  styleUrls: ["./theme-selector.component.css"],
})
export class ThemeSelectorComponent {
  public theme = "light";

  constructor(private readonly _themeService: ThemeService) {
    this._themeService.theme$.subscribe(theme => {
      this.theme = theme;
    });
  }

  public setTheme(theme: "light" | "dark") {
    this._themeService.setTheme(theme);
  }
}
