import { Address } from "@ton/core";

export class TonAddressUtils {
  /**
   * Converts a bounceable TON address to non-bounceable format
   * @param address The bounceable address to convert
   * @returns The non-bounceable address
   */
  static bounceableToNonBounceable(address: string): string {
    try {
      const addr = Address.parse(address);

      return addr.toString({ bounceable: false });
    } catch (error) {
      console.error("Error converting TON address:", error);
      return address;
    }
  }

  /**
   * Converts a non-bounceable TON address to bounceable format
   * @param address The non-bounceable address to convert
   * @returns The bounceable address
   */
  static nonBounceableToBounceable(address: string): string {
    try {
      const addr = Address.parse(address);

      return addr.toString({ bounceable: true });
    } catch (error) {
      console.error("Error converting TON address:", error);
      return address;
    }
  }

  /**
   * Checks if a string is a valid TON address
   * @param address The address to validate
   * @returns True if the address is valid
   */
  static isValidAddress(address: string): boolean {
    try {
      Address.parse(address);
      return true;
    } catch (error) {
      return false;
    }
  }
}
