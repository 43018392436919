import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.css"],
})
export class DropdownComponent {
  @Input() items: string[] = [];
  @Input() value: string | string[] = "";
  @Input() disabled: boolean = false;
  @Input() widthAsToggle: boolean = true;

  @Output() onSelect = new EventEmitter<string>();

  constructor() {}

  pickItem(item: string) {
    if (!this.disabled) {
      this.onSelect.emit(item);
    }
  }

  isSelected(item: string) {
    return this.value === item || (Array.isArray(this.value) && this.value.includes(item));
  }
}
