import { Component, OnInit } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { TxDto } from "src/app/common/DTO/txs/tx.dto";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { BankingTransactionInternalStatus } from "src/app/common/enums/banking-transaction-internal-status.enum";
import { BankingTransactionType } from "src/app/common/enums/banking-transaction-type.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { MfeCustomPipe } from "src/app/common/pipes/mfe-custom.pipe";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { CryptoService } from "src/app/services/crypto.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { RatesService } from "src/app/services/rates.service";
import { TxService } from "src/app/services/tx.service";

type TransactionTab = "active" | "all";

@Component({
  templateUrl: "./admin-transactions.component.html",
  styleUrls: ["./admin-transactions.component.css"],
})
export class AdminTransactionsComponent implements OnInit {
  public tabs: { value: TransactionTab; label: string }[] = [
    {
      value: "all",
      label: "Admin.Trans.All",
    },
    {
      value: "active",
      label: "Admin.Trans.Active",
    },
  ];
  public currentTab: TransactionTab = "all";

  public rates: RateDto[] = [];

  public sendTxs: TxDto[] = [];
  public sendPage: number = 1;
  public sendTotalCount: number = 0;

  public cryptoTxs: CryptoTxDto[] = [];
  public cryptoPage: number = 1;
  public cryptoTotalCount: number = 0;

  public pageSize: number = 10;
  public isVerdictPending: boolean = false;
  public isCryptoVerdictPending: boolean = false;
  public hasInteractionPermissions: boolean = false;

  public startDate: NgbDate | null = null;
  public endDate: NgbDate | null = null;

  public isLoading: boolean = true;
  public isLoadingMore: boolean = false;
  public hasMoreTransactions: boolean = true;

  public TxStatus = TxStatus;

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _txService: TxService,
    private readonly _cryptoService: CryptoService,
    private readonly _translateService: TranslateService,
    private readonly _ratesService: RatesService
  ) {}

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.TransactionInteraction
    );
    await this.requestRates();
    await this.requestSendTxs();
    // await this.requestCryptoTxs();
  }

  public get tabNames() {
    return this.tabs.map(t => t.label);
  }

  public get currentTabLabel() {
    return this.tabs.find(t => t.value === this.currentTab)?.label ?? "";
  }

  public onTabChange(tabName: string) {
    this.currentTab = this.tabs.find(t => t.label === tabName)?.value ?? "all";
    this.resetPagination();
    this.requestSendTxs();
  }

  public resetPagination() {
    this.sendPage = 1;
    this.sendTxs = [];
    this.sendTotalCount = 0;
    this.hasMoreTransactions = true;
  }

  public handleFilterChange() {
    this.resetPagination();
    this.requestSendTxs();
  }

  public onSelectDates(date: [NgbDate | null, NgbDate | null] | NgbDate | null, field?: "start" | "end") {
    if (Array.isArray(date)) {
      this.startDate = date[0];
      this.endDate = date[1];
    } else if (field === "end") {
      this.endDate = date;
    } else {
      this.startDate = date;
    }
  }

  public resetDates(field?: "start" | "end") {
    if (!field) {
      this.startDate = null;
      this.endDate = null;
    }
    if (field === "end") {
      this.endDate = null;
    } else {
      this.startDate = null;
    }
  }

  public resetFilters() {
    this.resetDates();
    this.resetPagination();
    this.requestSendTxs();
  }

  public onSendTxsPageChange() {
    this.requestSendTxs();
  }

  public get appliedFiltersCount(): number {
    let count = 0;
    if (this.startDate) count++;
    if (this.endDate) count++;
    return count;
  }

  public getTxType(type: TxType) {
    switch (type) {
      case TxType.Deposit:
        return "Transaction.Type_deposit";
      case TxType.Chain:
        return "Transaction.Type_chain";
      case TxType.Internal:
        return "Transaction.Type_internal";
      case TxType.Swap:
        return "Transaction.Type_swap";
      default:
        return "";
    }
  }

  public getTxStatus(status: TxStatus) {
    switch (status) {
      case TxStatus.Approved:
        return "Transaction.Status_approved";
      case TxStatus.Error:
        return "Transaction.Status_error";
      case TxStatus.OnConfirm:
        return "Transaction.Status_on_confirm";
      case TxStatus.Processing:
        return "Transaction.Status_processing";
      case TxStatus.WaitingApproveByBlockchain:
        return "Transaction.Status_waiting_approve_by_blockchain";
      default:
        return "";
    }
  }

  public getAmountInUsd(amount: number, currency: CryptoSymbol): string {
    if (this.rates.length === 0) return "0";

    const converted = ConvertCurrencyHelper.convertToUsd(+amount, currency, this.rates);
    const formatted = new MfeCustomPipe().transform(converted, {
      fiat: true,
    });
    return formatted;
  }

  public get transactionsHasActions() {
    return this.currentTab === "active";
  }

  public async loadMoreTransactions() {
    if (this.isLoadingMore || !this.hasMoreTransactions) {
      return;
    }

    this.isLoadingMore = true;
    this.sendPage++;

    const startDate = this.prepareDate(this.startDate);
    const endDate = this.prepareDate(this.endDate);
    const statuses = this.currentTab === "active" ? [TxStatus.OnConfirm] : undefined;

    const res = await this._txService.getAllTxs({
      size: this.pageSize,
      page: this.sendPage,
      statuses,
      from: startDate,
      to: endDate,
    });

    if (res.params) {
      const newTransactions = res.params?.items ?? [];
      this.sendTxs = [...this.sendTxs, ...newTransactions];
      this.sendTotalCount = res.params.totalCount;
      this.hasMoreTransactions = this.sendTxs.length < this.sendTotalCount;
    }

    this.isLoadingMore = false;
  }

  public async handleVerdictSendTx(txId: number, verdict: boolean) {
    this.isVerdictPending = true;

    try {
      await this._txService.verdictWaitingTx(txId, verdict);
    } catch (error) {
      return;
    } finally {
      this.isVerdictPending = false;
    }

    await this.requestSendTxs();
  }

  public async handleRevertWithdrawTx(txId: number) {
    await this._txService.revertWithdraw(txId);

    await this.requestCryptoTxs();
  }

  public async requestCryptoTxs() {
    const res = await this._cryptoService.getTransactions(this.cryptoPage, this.pageSize);
    if (res.params) {
      this.cryptoTxs = res.params.items;
      this.cryptoTotalCount = res.params.totalCount;
    }
  }

  public getCryptoTxType(type: BankingTransactionType) {
    // ?? TODO: check if it is correct
    if (type === BankingTransactionType.Deposit) {
      return this._translateService.instant("Crypto_transaction.Withdraw");
    } else if (type === BankingTransactionType.Withdraw) {
      return this._translateService.instant("Crypto_transaction.Deposit");
    } else {
      return "";
    }
  }

  public getCryptoCurrencyName(currency: CryptoSymbol) {
    if (currency === CryptoSymbol.Tiyins) {
      return "UZS";
    }

    switch (currency) {
      case CryptoSymbol.Uzs:
        return "UZS";
      case CryptoSymbol.Usdt:
        return "USDT";
      case CryptoSymbol.Trx:
        return "TRX";
      case CryptoSymbol.Matic:
        return "MATIC";
      case CryptoSymbol.PolygonUsdt:
        return "Polygon USDT";
      default:
        return "";
    }
  }

  public getCryptoCurrencyValue(amount: number, currency: CryptoSymbol) {
    if (currency === CryptoSymbol.Tiyins) {
      return amount / 100;
    } else {
      return amount;
    }
  }

  public getCryptoTxStatus(status: BankingTransactionInternalStatus) {
    switch (status) {
      case BankingTransactionInternalStatus.Pending:
        return this._translateService.instant("Crypto_transaction.Internal_pending");
      case BankingTransactionInternalStatus.Complete:
        return this._translateService.instant("Crypto_transaction.Internal_complete");
      case BankingTransactionInternalStatus.Error:
        return this._translateService.instant("Crypto_transaction.Internal_error");
      case BankingTransactionInternalStatus.WaitingForApproval:
        return this._translateService.instant("Crypto_transaction.Internal_waiting");
      case BankingTransactionInternalStatus.RejectedByAdmin:
        return this._translateService.instant("Crypto_transaction.Internal_rejected");
      default:
        return "";
    }
  }

  public isCryptoVerdictEnabled(status: BankingTransactionInternalStatus) {
    return status === BankingTransactionInternalStatus.WaitingForApproval;
  }

  public async handleVerdictCryptoTx(txId: number, verdict: boolean) {
    this.isCryptoVerdictPending = true;

    try {
      if (verdict) {
        await this._cryptoService.approveTx(txId);
      } else {
        await this._cryptoService.rejectTx(txId);
      }
    } catch (error) {
      return;
    } finally {
      this.isCryptoVerdictPending = false;
    }

    await this.requestCryptoTxs();
  }

  public getAddressOnExplorer(item: CryptoTxDto): string {
    return this.isPolygonNetwork(item)
      ? "https://polygonscan.com/address/" + item.walletPublicKey
      : "https://tronscan.io/#/address/" + item.walletPublicKey;
  }

  public getTransactionOnExplorer(item: CryptoTxDto): string {
    return this.isPolygonNetwork(item)
      ? "https://polygonscan.com/tx/" + item.cryptoTxHash
      : "https://tronscan.io/#/transaction/" + item.cryptoTxHash;
  }

  private isPolygonNetwork(item: CryptoTxDto): boolean {
    return (
      item.fromCurrency == CryptoSymbol.PolygonUsdt ||
      item.fromCurrency == CryptoSymbol.Matic ||
      item.toCurrency == CryptoSymbol.PolygonUsdt ||
      item.toCurrency == CryptoSymbol.Matic
    );
  }

  private prepareDate(date?: NgbDate | string | null): string | undefined {
    if (!date || typeof date === "string") {
      return undefined;
    }
    const dateObj = new Date(date.year, date.month - 1, date.day);
    dateObj.setHours(0, 0, 0, 0);
    return dateObj.toISOString();
  }

  private async requestSendTxs() {
    this.isLoading = true;
    this.hasMoreTransactions = true;

    const statuses = this.currentTab === "active" ? [TxStatus.OnConfirm] : undefined;
    const startDate = this.prepareDate(this.startDate);
    const endDate = this.prepareDate(this.endDate);

    const res = await this._txService.getAllTxs({
      size: this.pageSize,
      page: this.sendPage,
      statuses,
      from: startDate,
      to: endDate,
    });

    if (res.params) {
      this.sendTxs = res.params.items;
      this.sendTotalCount = res.params.totalCount;
    }

    this.isLoading = false;
  }

  public async requestRates() {
    const res = await this._ratesService.getRates();
    if (res.params) {
      this.rates = res.params;
    }
  }
}
