import { Component, Input } from "@angular/core";

type StatusMarkVariant = "success" | "error" | "warning";

@Component({
  selector: "app-status-mark",
  templateUrl: "./status-mark.component.html",
  styleUrls: ["./status-mark.component.css"],
})
export class StatusMarkComponent {
  @Input() variant: StatusMarkVariant = "success";
  @Input() icon: string = "check";
}
