<div class="md:w-[28rem]">
  <!-- Header -->
  <div class="flex items-center">
    <button
      *ngIf="step === Step.NetworkSelect || step === Step.Confirm"
      class="btn-flat mr-3"
      (click)="onBack()"
    >
      <app-svg-icon name="actions/back" class="h-8 w-8" />
    </button>
    <h2 class="title max-md:text-xl">
      <ng-template [ngIf]="step === Step.Input">{{ "Send.Send" | translate }}</ng-template>
      <ng-template [ngIf]="step === Step.NetworkSelect">{{ "Send.Network_select" | translate }}</ng-template>
      <ng-template [ngIf]="step === Step.Confirm">{{ "Send.Confirmation" | translate }}</ng-template>
      <ng-template [ngIf]="step === Step.Success">{{ "Send.Success" | translate }}</ng-template>
    </h2>
    <button (click)="onClose()" class="btn-flat ml-auto h-6 w-6">
      <app-svg-icon name="close" />
    </button>
  </div>

  <ng-container [ngSwitch]="step">
    <!-- Input -->
    <ng-template [ngSwitchCase]="Step.Input">
      <form class="mt-6" [formGroup]="sendForm" (ngSubmit)="submitInput()">
        <div class="relative py-8 max-md:py-6">
          <!-- ! TEMP maxLength for testing, old: selectedWalletBalance?.wallet?.network === Network.Bitcoin ? 6 : 2 -->
          <input
            type="string"
            id="amount"
            name="amount"
            placeholder="0"
            class="mx-auto w-full bg-transparent text-center text-5xl font-semibold placeholder:text-text-tertiary focus:outline-none focus:placeholder:text-transparent max-md:text-5xl"
            formControlName="amount"
            appPositiveDecimals
            [maxLength]="16"
            [autofocus]="true"
            autocomplete="off"
            (input)="handleAmountChange($event)"
          />
          <p class="text-center text-sm font-semibold text-text-secondary">
            {{ "Send.Available_balance" | translate }}
            <span class="text-green-400">
              {{
                selectedWalletBalance?.availableAmount
                  | mfeCustom: { currency: selectedWalletBalance?.currency! }
              }}
              {{ selectedCurrencyName }}
            </span>
          </p>
          <p *ngIf="isCalculating" class="text-center text-sm font-semibold text-text-secondary">
            {{ "Swap.Calculating" | translate }}
          </p>
          <p *ngIf="amountError && !isCalculating" class="text-center text-sm font-semibold text-red-400">
            {{ amountError }}
          </p>
        </div>

        <div class="space-y-1" *ngIf="walletBalance !== null && selectedWalletBalance; else skeleton">
          <app-bullet-row
            name="{{ 'Send.Asset' | translate }}"
            rowClass="!grid-cols-[5rem_auto]"
            bulletClass="bg-yellow-500"
            class="rounded-row"
          >
            <div class="flex items-center gap-x-2">
              <app-currency-icon [currency]="walletBalance.currency" size="xs" />
              <span>{{ selectedCurrencyName }}</span>
              <span class="text-text-tertiary">{{ networkName }}</span>
            </div>
          </app-bullet-row>

          <app-bullet-row
            *ngIf="
              walletBalance === null ||
              (walletBalance && walletBalance.currency === CryptoSymbol.AbstractUsdt)
            "
            name="{{ 'Send.Network' | translate }}"
            rowClass="!grid-cols-[5rem_auto]"
            bulletClass="!bg-purple-500"
            class="rounded-row"
          >
            <button
              type="button"
              class="flex items-center justify-between"
              (click)="step = Step.NetworkSelect"
            >
              {{ networkName ? networkName : ("Send.Choose_network" | translate) }}
              <app-svg-icon name="round-next" class="h-6 w-6 text-gray-100 dark:text-slate-700" />
            </button>
          </app-bullet-row>

          <app-bullet-row
            name="{{ 'Send.To' | translate }}"
            rowClass="!grid-cols-[5rem_auto]"
            bulletClass="!bg-green-400"
            class="rounded-row"
          >
            <app-input
              id="token"
              type="text"
              name="token"
              formControlName="to"
              placeholder="{{ 'Send.Wallet_address' | translate }}"
              class="[&_input]:rounded-none [&_input]:border-none [&_input]:bg-transparent [&_input]:p-0 [&_input]:dark:bg-transparent"
              [errorMsg]="toError"
            />
          </app-bullet-row>

          <div *ngIf="isTelegramMiniApp">
            <button
              type="button"
              class="btn-primary btn-large w-full !bg-telegram"
              (click)="useTonWalletAddressAsReceiver()"
            >
              {{ "Send.Send_to_telegram" | translate }}
            </button>
          </div>
        </div>

        <ng-template #skeleton>
          <app-skeleton class="h-18 w-full" />
          <app-skeleton class="mt-1 h-18 w-full" />
          <app-skeleton
            *ngIf="walletBalance?.currency === CryptoSymbol.AbstractUsdt"
            class="mt-1 h-18 w-full"
          />
        </ng-template>

        <div
          class="mt-4 rounded-xl bg-gray-100 py-4 text-center text-sm font-semibold text-text-secondary dark:bg-slate-700"
        >
          {{ "Send.Min_send_amount" | translate }}
          <span class="text-green-500">
            {{ minSendAmount | mfeCustom }}
            {{ selectedCurrencyName }}
          </span>
        </div>

        <p class="mt-2 text-sm font-semibold text-text-secondary">
          {{ "Send.Withdrawal_fee" | translate }}:
          <span class="text-green-400">
            {{ serviceFee | mfeCustom }}
            {{ selectedCurrencyName }}
          </span>
        </p>

        <p class="mt-2 text-sm font-semibold text-text-secondary">
          {{ "Send.Sum_to_send" | translate }}:
          <span class="text-green-400">
            {{ totalAmount | mfeCustom }}
            {{ selectedCurrencyName }}
          </span>
        </p>

        <button
          type="submit"
          class="btn-primary btn-large mt-2 w-full"
          [disabled]="isCalculating || sendForm.invalid || amountError"
        >
          {{ "Send.Send" | translate }}
        </button>
      </form>
    </ng-template>

    <!-- Network select -->
    <ng-template [ngSwitchCase]="Step.NetworkSelect">
      <!-- <app-input
        type="search"
        class="mt-6 block w-full"
        placeholder="{{ 'Send.Search_asset' | translate }}"
        [(ngModel)]="networkSearch"
      /> -->

      <div class="mt-4 flex flex-col gap-y-1 overflow-y-auto">
        <button
          *ngFor="let n of networks"
          (click)="selectNetwork(n)"
          class="flex items-center rounded-2xl border border-transparent p-3 transition-colors hover:border-gray-100 dark:hover:border-slate-700"
          [ngClass]="{ 'bg-gray-50 dark:bg-slate-900': selectedWalletBalance?.wallet?.network === n }"
        >
          <img [src]="getNetworkInfo(n).icon" [alt]="getNetworkInfo(n).name" class="mr-3 h-8 w-8" />
          <p class="text-start font-semibold">
            {{ getNetworkInfo(n).name }}
            <span class="block text-xs text-text-secondary">{{ getNetworkInfo(n).currencyName }}</span>
          </p>
          <app-svg-icon
            *ngIf="selectedWalletBalance?.wallet?.network === n"
            name="check"
            class="ml-auto h-4 w-4"
          />
        </button>
      </div>
    </ng-template>

    <!-- Confirmation -->
    <ng-template [ngSwitchCase]="Step.Confirm">
      <div class="mt-6">
        <app-bullet-row name="{{ 'Send.Asset' | translate }}" class="bordered-row">
          <div class="flex items-center gap-x-2">
            <app-currency-icon [currency]="selectedWalletBalance?.currency" size="xs" />
            <p class="text-sm font-semibold">
              {{ selectedCurrencyName }}
              <span class="text-text-secondary">
                {{ networkName }}
              </span>
            </p>
          </div>
        </app-bullet-row>
        <app-bullet-row name="{{ 'Send.Network' | translate }}" class="bordered-row">
          <div class="flex items-center gap-x-2">
            <img
              [src]="getNetworkInfo(selectedWalletBalance?.wallet?.network!).icon"
              [alt]="networkName"
              class="h-8 w-8"
            />
            <p class="text-sm font-semibold">
              {{ networkName }}
            </p>
          </div>
        </app-bullet-row>
        <app-bullet-row
          name="{{ 'Send.Amount' | translate }}"
          bulletClass="!bg-yellow-500"
          class="bordered-row"
        >
          <div class="text-sm font-semibold">
            {{ sendForm.controls["amount"].value | mfeCustom: { currency: selectedWalletBalance?.currency } }}
            {{ selectedCurrencyName }}
          </div>
        </app-bullet-row>
        <app-bullet-row
          name="{{ 'Send.Address' | translate }}"
          bulletClass="!bg-green-400"
          class="bordered-row"
        >
          <div class="flex items-center justify-between overflow-hidden">
            <p class="break-all text-sm font-semibold">{{ sendForm.controls["to"].value }}</p>
            <app-copy-btn [value]="sendForm.controls['to'].value" />
          </div>
        </app-bullet-row>
        <app-bullet-row
          name="{{ 'Send.Commission' | translate }}"
          bulletClass="!bg-purple-500"
          class="bordered-row"
        >
          <div class="text-sm font-semibold">
            {{ serviceFee | mfeCustom: { currency: selectedWalletBalance?.currency! } }}
            {{ selectedCurrencyName }}
          </div>
        </app-bullet-row>
      </div>

      <p class="mt-4 text-sm font-semibold text-text-secondary">
        {{ "Send.Total_to_send" | translate }}:
        <span class="text-green-400 dark:text-green-200">
          {{ totalAmount | mfeCustom }} {{ selectedCurrencyName }}
        </span>
      </p>

      <p *ngIf="businessError" class="mt-2 text-center text-sm text-red-400">{{ businessError }}</p>

      <button class="btn-primary btn-large mt-4 w-full" (click)="onConfirm()" [disabled]="isPending">
        {{ "Common.Confirm" | translate }}
      </button>
    </ng-template>

    <!-- Success -->
    <ng-template [ngSwitchCase]="Step.Success">
      <app-status-mark variant="success" icon="check" class="mt-6 block" />
      <div class="mt-10 text-center font-bold">
        <p class="text-5xl">
          {{ sendForm.controls["amount"].value | mfeCustom: { currency: selectedWalletBalance?.currency } }}
          {{ selectedCurrencyName }}
        </p>
        <p class="mt-1 break-all text-green-500 dark:text-green-200">
          {{ "Send.Successful_send" | translate }} {{ sendForm.controls["to"].value }}
        </p>
      </div>
      <a
        [routerLink]="['/', RouteConstants.depositary, RouteConstants.transactions]"
        class="btn-primary btn-large mt-6 w-full text-center"
        (click)="onClose()"
      >
        <span class="max-md:hidden">{{ "Send.View_transaction_history" | translate }}</span>
        <span class="md:hidden">{{ "Send.View_transactions" | translate }}</span>
      </a>
    </ng-template>
  </ng-container>
</div>
