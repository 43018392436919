import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventTypeConstants } from "./common/constants/event-type.constants";
import { EventData } from "./common/models/event-data";
import { TelegramMiniAppHelper } from "./common/utils/telegram-mini-app-helper.util";
import { EnterPinModalComponent } from "./components/enter-pin-modal/enter-pin-modal.component";
import { EnvService } from "./services/env.service";
import { EventBusService } from "./services/event-bus.service";
import { LocalStorageService } from "./services/local-storage.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  public showFullscreenLoader = false;

  private isTelegramMiniApp = TelegramMiniAppHelper.isMiniApp();
  public shouldRenderTelegramMiniAppPlaceholder = false;

  private isPinModalOpen = false;

  constructor(
    private readonly _update: SwUpdate,
    private readonly _envService: EnvService,
    private readonly _localStorage: LocalStorageService,
    private readonly _eventBusService: EventBusService,
    private readonly _router: Router,
    private readonly _modalService: NgbModal
  ) {
    if (this._update.isEnabled && this._envService.isProduction) {
      this._update.versionUpdates.subscribe(evt => {
        switch (evt.type) {
          case "VERSION_READY":
            this._update.activateUpdate().then(() => {
              document.location.reload();
            });
            break;
        }
      });
    }

    this._eventBusService.handle(EventTypeConstants.ToggleFullscreenLoader, (show: boolean) => {
      this.showFullscreenLoader = show;
      if (show) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    });

    this._eventBusService.handle(EventTypeConstants.Logout, () => {
      this._modalService.dismissAll();
    });

    // Check if the pin modal is opened
    this._modalService.activeInstances.subscribe(instances => {
      this.isPinModalOpen = instances.some(
        instance => instance.componentInstance instanceof EnterPinModalComponent
      );
    });

    this._eventBusService.handle(EventTypeConstants.ShowEnterPinModal, () => {
      if (!this.isPinModalOpen) {
        this._modalService.open(EnterPinModalComponent);
      }
    });
  }

  async ngOnInit() {
    const isUserAgentIncludesTelegramData = navigator.userAgent.toLowerCase().includes("telegram");
    const isUserAgentIncludesIPhoneData = navigator.userAgent.toLowerCase().includes("iphone");
    const isOpenedOnDesktop = !isUserAgentIncludesTelegramData && !isUserAgentIncludesIPhoneData;
    this.shouldRenderTelegramMiniAppPlaceholder = this.isTelegramMiniApp && isOpenedOnDesktop;

    const language = new URLSearchParams(window.location.search).get("language");
    if (language) {
      await this._localStorage.saveLanguage(language);
      this._eventBusService.dispatch(new EventData(EventTypeConstants.ChangeLanguage, language));
    }
    if (TelegramMiniAppHelper.isMiniApp()) {
      const startAppParam = TelegramMiniAppHelper.getStartAppParam();
      if (!startAppParam) {
        return;
      }
      const kycVerificationLink = window.atob(startAppParam);
      this._router.navigateByUrl(kycVerificationLink);
    }
  }

  onMyFrameLoad(): void {
    console.log("Shop domain frame loaded");
  }
}
