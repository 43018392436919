import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { startOfDay, subDays } from "date-fns";
import { BalanceDto } from "src/app/common/DTO/balances/balance.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ReceiveModalComponent } from "src/app/components/receive-modal/receive-modal.component";
import { SwapModalComponent } from "src/app/components/swap-modal/swap-modal.component";
import { BalancesService } from "src/app/services/balances.service";
import { RateHistoriesService } from "src/app/services/rate-histories.service";
import { RatesService } from "src/app/services/rates.service";

@Component({
  templateUrl: "./assets.component.html",
  styleUrls: ["./assets.component.css"],
})
export class AssetsComponent implements OnInit {
  public rates: RateDto[] = [];
  public balances: BalanceDto[] = [];
  private rateHistory: { currency: CryptoSymbol; usdValue: number }[] = [];

  public isLoading: boolean = false;

  public mobileBalanceCurrency: CryptoSymbol = CryptoSymbol.AbstractUsdt;
  public readonly mobileCurrencyOptions = [
    {
      value: CryptoSymbol.AbstractUsdt,
      label: "Wallet.In_usdt",
    },
    {
      value: CryptoSymbol.Uzs,
      label: "Wallet.In_uzs",
    },
  ];

  constructor(
    private readonly _ratesService: RatesService,
    private readonly _balancesService: BalancesService,
    private readonly _modalService: NgbModal,
    private readonly _rateHistoriesService: RateHistoriesService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.rates = (await this._ratesService.getRates()).params ?? [];
    this.balances = (await this._balancesService.getBalances()).params ?? [];
    await this.loadAllRatesHistory();
    this.isLoading = false;
  }

  public get mobileCurrencyOptionsNames() {
    return this.mobileCurrencyOptions.map(x => x.label);
  }

  public get selectedMobileBalanceCurrency() {
    return this.mobileCurrencyOptions.find(x => x.value === this.mobileBalanceCurrency)?.label;
  }

  public onMobileCurrencyChange(currency: string) {
    this.mobileBalanceCurrency =
      this.mobileCurrencyOptions.find(x => x.label === currency)?.value ?? CryptoSymbol.AbstractUsdt;
  }

  public getRatePercentChange(currency: CryptoSymbol): number {
    // First rate from history
    const firstRate = this.rateHistory.find(item => item.currency === currency);
    // Last actual rate
    const lastRate = this.rates.find(item => item.symbol === currency);

    if (!lastRate || !firstRate) {
      return 0;
    }

    const firstRateValue = firstRate.usdValue;
    const lastRateValue = lastRate.usd;

    if (firstRateValue === 0) {
      return 0;
    }

    const percentChange = ((lastRateValue - firstRateValue) / firstRateValue) * 100;
    return percentChange;
  }

  public openReceiveModal(preselectedBalance?: BalanceDto) {
    const modalRef = this._modalService.open(ReceiveModalComponent);
    if (preselectedBalance) {
      modalRef.componentInstance.walletBalance = preselectedBalance;
    }
  }

  public openSwapModal(preselectedBalance?: BalanceDto) {
    const modalRef = this._modalService.open(SwapModalComponent, {
      modalDialogClass: "modal-dialog_full-content",
    });
    if (preselectedBalance) {
      modalRef.componentInstance.walletBalance = preselectedBalance;
    }
  }

  private async loadAllRatesHistory() {
    const currencies = this.balances.map(balance => balance.currency);
    const toDate = startOfDay(new Date());
    // Last 1 day
    const fromDate = subDays(toDate, 1);

    const rateHistories = await Promise.all(
      currencies.map(currency =>
        this._rateHistoriesService.getRateHistory(currency, fromDate.toISOString(), toDate.toISOString())
      )
    );

    for (let i = 0; i < rateHistories.length; i++) {
      const rateHistory = rateHistories[i];
      const currency = currencies[i];
      const usdValue = rateHistory.params?.[0]?.usdValue ?? 0;
      this.rateHistory.push({ currency, usdValue });
    }
  }
}
