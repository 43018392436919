<div class="relative md:w-[32rem]">
  <div class="flex items-center justify-between p-3">
    <h3 class="title">{{ "Pincode.Enter_pincode" | translate }}</h3>
    <button class="btn-flat h-6 w-6" (click)="onClose()">
      <app-svg-icon name="close" class="block" />
    </button>
  </div>

  <div class="mt-4 space-y-8 max-md:mt-3 max-md:space-y-6">
    <p class="text-center text-text-secondary">{{ "Pincode.Enter_pincode_description" | translate }}</p>

    <app-otp-input
      [digits]="pinCodeLength"
      [(ngModel)]="pinCode"
      (otpComplete)="onSubmit()"
      [errorMsg]="businessError"
      [initialTimerValue]="null"
      class="mx-auto block"
    />

    <div class="space-y-4">
      <button class="btn-primary btn-large w-full" (click)="onSubmit()" [disabled]="isPending">
        {{ "Pincode.Enter" | translate }}
      </button>
      <button class="btn-text mx-auto w-fit" (click)="onEnterWithCred()" [disabled]="isPending">
        {{ "Pincode.Enter_with_cred" | translate }}
      </button>
    </div>
  </div>
</div>
