<section class="grid grid-rows-1">
  <div class="flex items-center gap-x-2">
    <app-back-button [link]="'/admin/users/' + userId"></app-back-button>
    <h4 class="text-base font-medium text-gray-900">
      {{ "Admin.Users.User" | translate }}:
      <span class="uppercase">{{ userData.kyc?.firstName }} {{ userData.kyc?.lastName }}</span>
    </h4>
  </div>

  <h3 class="half-title mt-2.5">{{ "Transaction.All_transactions" | translate }}</h3>

  <app-tabs
    [tabs]="currencyNames"
    (onSelect)="onSelectCurrency($event)"
    variant="panels"
    class="my-6 block overflow-x-auto overflow-y-hidden border-b border-gray-100 [&_button]:uppercase"
  />

  <div
    class="grid grid-cols-[17%_14%_25%_12%_auto] gap-x-12 rounded-main bg-gray-100 px-6 py-3 text-xs font-semibold text-text-secondary dark:bg-slate-700"
  >
    <span>{{ "Transaction.Type" | translate }}</span>
    <span>{{ "Transaction.Sum" | translate }}</span>
    <span>Id</span>
    <span>{{ "Transaction.Date" | translate }}</span>
    <span class="text-end">{{ "Transaction.Status" | translate }}</span>
  </div>

  <ng-container *ngIf="!isLoading; else skeleton">
    <ng-container *ngIf="hasTxs; else noTrans">
      <div class="mt-3 grid grid-rows-1">
        <app-transaction-list-item
          *ngFor="let item of transactions"
          [txDto]="item"
          [rates]="rates"
          [fullSize]="true"
          class="block border-t border-gray-100 dark:border-slate-700 max-md:border-none"
        />

        <!-- <ng-template #bcTxs>
          <app-transaction-list-item
            *ngFor="let item of txs"
            [txDto]="item"
            class="border-t border-gray-100 first:border-none dark:border-slate-700"
          />
        </ng-template> -->
      </div>

      <ngb-pagination
        [collectionSize]="totalCount"
        [(page)]="page"
        [pageSize]="pageSize"
        [maxSize]="5"
        (pageChange)="onPageChange($event)"
        class="mt-3"
      />
    </ng-container>

    <ng-template #noTrans>
      <div class="mt-3 text-center text-base">{{ "Transaction.No_transactions" | translate }}</div>
    </ng-template>
  </ng-container>

  <ng-template #skeleton>
    <app-transaction-list-item-skeleton [fullSize]="true" />
    <app-transaction-list-item-skeleton [fullSize]="true" />
    <app-transaction-list-item-skeleton [fullSize]="true" />
  </ng-template>
</section>
