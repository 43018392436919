import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { PagedListDto } from "src/app/common/DTO/paged-list.dto";
import { TxDto } from "src/app/common/DTO/txs";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { TxDirection } from "src/app/common/enums/tx-direction.enum";
import { TxErrorCode } from "src/app/common/enums/tx-error-code.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { EnvService } from "src/app/services/env.service";

type AllTxsQuery = {
  size: number;
  page: number;
  from?: string;
  to?: string;
  statuses?: TxStatus[];
  types?: TxType[];
  currencies?: CryptoSymbol[];
  txDirections?: TxDirection[];
  userId?: number;
};

@Injectable({
  providedIn: "root",
})
export class TxService {
  private readonly _baseReqOpts: { headers: HttpHeaders } = {
    headers: new HttpHeaders(Constants.JsonContentTypeHeader),
  };

  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService
  ) {}

  public async getAllMy(query: AllTxsQuery): Promise<HttpResultDto<TxErrorCode, PagedListDto<TxDto> | null>> {
    const { size, page, from, to, statuses, types, currencies, txDirections } = query;
    let params = `?size=${size}&index=${page}`;
    if (from) {
      params += `&from=${from}`;
    }
    if (to) {
      params += `&to=${to}`;
    }
    if (statuses && statuses.length > 0) {
      statuses.forEach(status => {
        params += `&statuses=${status}`;
      });
    }
    if (types && types.length > 0) {
      types.forEach(type => {
        params += `&types=${type}`;
      });
    }
    if (currencies && currencies.length > 0) {
      currencies.forEach(currency => {
        params += `&currencies=${currency}`;
      });
    }
    if (txDirections && txDirections.length > 0) {
      txDirections.forEach(direction => {
        params += `&txDirections=${direction}`;
      });
    }
    const uri = `${this._envService.serverUrl}${UrnConstants.TransactionsAllMy}${params}`;

    const result = new HttpResultDto<TxErrorCode, PagedListDto<TxDto> | null>(false);
    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<PagedListDto<TxDto>>;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getById(id: number): Promise<HttpResultDto<TxErrorCode, TxDto | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.Transactions}/${id}`;
    const result = new HttpResultDto<TxErrorCode, TxDto | null>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<TxDto>;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getAllTxs(
    query: AllTxsQuery
  ): Promise<HttpResultDto<TxErrorCode, PagedListDto<TxDto> | null>> {
    const { size, page, from, to, statuses, types, currencies, txDirections, userId } = query;
    let params = `?size=${size}&index=${page}`;
    if (userId) {
      params += `&userId=${userId}`;
    }
    if (from) {
      params += `&from=${from}`;
    }
    if (to) {
      params += `&to=${to}`;
    }
    if (statuses && statuses.length > 0) {
      statuses.forEach(status => {
        params += `&statuses=${status}`;
      });
    }
    if (types && types.length > 0) {
      types.forEach(type => {
        params += `&types=${type}`;
      });
    }
    if (currencies && currencies.length > 0) {
      currencies.forEach(currency => {
        params += `&currencies=${currency}`;
      });
    }
    if (txDirections && txDirections.length > 0) {
      txDirections.forEach(direction => {
        params += `&txDirections=${direction}`;
      });
    }
    const uri = `${this._envService.serverUrl}${UrnConstants.TransactionsAll}${params}`;
    const result = new HttpResultDto<TxErrorCode, PagedListDto<TxDto> | null>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<PagedListDto<TxDto>>;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async verdictWaitingTx(txId: number, accept: boolean): Promise<HttpResultDto<TxErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.Transactions}/${txId}/${accept}`;
    const result = new HttpResultDto<TxErrorCode, null>(false);

    try {
      (await firstValueFrom(this._http.post(uri, null))) as ApiResponseDto<null>;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async revertWithdraw(txId: number): Promise<HttpResultDto<TxErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.TransactionsWithdrawRevert}/${txId}`;
    const result = new HttpResultDto<TxErrorCode, null>(false);

    try {
      (await firstValueFrom(this._http.post(uri, null))) as ApiResponseDto<null>;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): TxErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return TxErrorCode.InternalError;
      case Constants.Unauthorized:
        return TxErrorCode.Unauthorized;
      case "Wallet not found":
        return TxErrorCode.WalletNotFound;
      case "Tx not found":
        return TxErrorCode.TxNotFound;
      default:
        return TxErrorCode.InternalError;
    }
  }
}
