import { TxDto } from "src/app/common/DTO/txs/tx.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { Network } from "src/app/common/enums/network.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { Transaction } from "src/app/common/models/transaction";

export class TxParserUtil {
  public static mapToTransaction(tx: TxDto): Transaction {
    const mappedItem = new Transaction();
    mappedItem.token = CryptoToken.Usdt;
    mappedItem.amount = tx.amount.toString();
    mappedItem.to = tx.to;
    mappedItem.from = tx.from;
    mappedItem.createdAt = new Date(tx.createdAt);
    mappedItem.status = TxStatus.OnConfirm;
    mappedItem.type = TxType.Chain;
    mappedItem.id = tx.id.toString();
    return mappedItem;
  }

  public static getTxStatus(status?: TxStatus): string {
    if (!status) {
      return "";
    }

    switch (status) {
      case TxStatus.Approved:
        return "Transaction.Status_approved";
      case TxStatus.Error:
        return "Transaction.Status_error";
      case TxStatus.Processing:
        return "Transaction.Status_processing";
      case TxStatus.WaitingApproveByBlockchain:
        return "Transaction.Status_network_pending";
      case TxStatus.OnConfirm:
        return "Transaction.Status_on_confirm";
      default:
        return "";
    }
  }

  public static getFormattedDate(date: Date): string {
    return date.toLocaleString("ru-RU").split(",")[0];
  }

  public static getCryptoSymbol(token: CryptoToken): CryptoSymbol {
    switch (token) {
      case CryptoToken.Usdt:
        return CryptoSymbol.Usdt;
      case CryptoToken.PolygonUsdt:
        return CryptoSymbol.PolygonUsdt;
      case CryptoToken.TonUsdt:
        return CryptoSymbol.TonUsdt;
      case CryptoToken.Not:
        return CryptoSymbol.Not;
      case CryptoToken.Bitcoin:
        return CryptoSymbol.Bitcoin;
      case CryptoToken.Trx:
        return CryptoSymbol.Trx;
      case CryptoToken.Matic:
        return CryptoSymbol.Matic;
      case CryptoToken.Ton:
        return CryptoSymbol.Ton;
      default:
        return CryptoSymbol.AbstractUsdt;
    }
  }

  public static getTxNetwork(currency: CryptoSymbol): Network {
    switch (currency) {
      case CryptoSymbol.Usdt:
      case CryptoSymbol.Trx:
        return Network.Tron;
      case CryptoSymbol.PolygonUsdt:
      case CryptoSymbol.Matic:
        return Network.Polygon;
      case CryptoSymbol.TonUsdt:
      case CryptoSymbol.Ton:
      case CryptoSymbol.Not:
        return Network.Ton;
      case CryptoSymbol.Bitcoin:
        return Network.Bitcoin;
      default:
        return Network.Tron;
    }
  }
}
