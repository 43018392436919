import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ChartConfiguration } from "chart.js";
import { endOfYesterday, format, isSameMonth, subDays } from "date-fns";
import { ru } from "date-fns/locale";
import { BaseChartDirective } from "ng2-charts";
import { Subject, takeUntil } from "rxjs";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { EventData } from "src/app/common/models/event-data";
import { MfeCustomPipe } from "src/app/common/pipes/mfe-custom.pipe";
import { EventBusService } from "src/app/services/event-bus.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ScreenSizeService } from "src/app/services/screen-size.service";
import { UserTotalBalanceHistoriesService } from "src/app/services/user-total-balance-histories.service";

interface ChartPeriod {
  key: "week" | "month" | "3_months" | "6_months" | "year";
  label: string;
  days: number;
}

@Component({
  selector: "app-balance-chart",
  templateUrl: "./balance-chart.component.html",
  styleUrls: ["./balance-chart.component.css"],
})
export class BalanceChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() totalUzsBalance: number = 0;
  @Input() totalUsdBalance: number | null = null;

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  public CryptoSymbol = CryptoSymbol;
  public isBalanceVisible = true;
  public isChartLoading = true;
  public chartPeriodLabels: string[] = [];
  public isMobile = false;

  // Chart configuration
  public chartData: ChartConfiguration["data"] = {
    datasets: [
      {
        data: [],
        borderColor: "#0BA859",
        pointBackgroundColor: "#0BA859",
        pointBorderColor: "#f4f4f4",
        pointHoverBackgroundColor: "#0BA859",
        pointHoverBorderColor: "#767E7A",
        fill: "origin",
      },
    ],
    labels: [],
  };

  public chartOptions: ChartConfiguration["options"] = {
    elements: {
      line: {
        tension: 0.4,
        borderWidth: 1,
      },
      point: {
        hitRadius: 10,
      },
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
          padding: 8,
          color: "#767E7A",
          callback: (value: any, index: number) => {
            if (this.isMobile) {
              return this.manageTickLabels(index);
            } else {
              return format(new Date(this.chartData?.labels?.[index] as string), "dd.MM.yyyy");
            }
          },
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context: any) => {
            return "$" + new MfeCustomPipe().transform(context.parsed.y, { fiat: true });
          },
          title: (context: any) => {
            const formattedDate = format(new Date(context[0].label), "dd.MM.yyyy");
            return formattedDate;
          },
        },
      },
    },
    responsive: true,
    aspectRatio: 4,
  };

  private startDate: Date | null = null;
  private endDate: Date | null = null;
  private readonly periods: ChartPeriod[] = [
    { key: "week", label: "Wallet.Period_week", days: 6 },
    { key: "month", label: "Wallet.Period_1_month", days: 30 },
    { key: "3_months", label: "Wallet.Period_3_months", days: 90 },
    { key: "6_months", label: "Wallet.Period_6_months", days: 180 },
    { key: "year", label: "Wallet.Period_1_year", days: 365 },
  ];
  private destroy$ = new Subject<void>();

  public currentPeriod: ChartPeriod = this.periods[0];

  public readonly chartMinPoints = 3;

  constructor(
    private _screenSizeService: ScreenSizeService,
    private _eventBusService: EventBusService,
    private _localStorage: LocalStorageService,
    private _userTotalBalanceHistoriesService: UserTotalBalanceHistoriesService
  ) {}

  ngOnInit(): void {
    this.chartPeriodLabels = this.periods.map(period => period.label);
    this.setupEventListeners();
  }

  ngAfterViewInit(): void {
    this.applyChartGradient();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["totalUsdBalance"]) {
      this.totalUsdBalance = changes["totalUsdBalance"].currentValue;
      if (this.totalUsdBalance !== null) {
        this.initializeChart();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onChangePeriod(period: string): void {
    const periodItem = this.periods.find(p => p.label === period);
    if (periodItem) {
      this.currentPeriod = periodItem;
      this.initializeChart();
    }
  }

  public get balancePercentChange(): number {
    const data = this.chartData.datasets[0].data;
    if (data.length < 2) return 0;

    const firstValue = Number(data[0]);
    const lastValue = Number(data[data.length - 1]);

    if (firstValue === lastValue || firstValue === 0) return 0;

    const change = lastValue - firstValue;
    const percentChange = (change / firstValue) * 100;

    return percentChange;
  }

  public get shouldShowChart(): boolean {
    return this.totalUsdBalance !== null && this.chartData.datasets[0].data.length >= this.chartMinPoints;
  }

  public async toggleBalanceVisibility(): Promise<void> {
    const isVisible = !this.isBalanceVisible;
    await this._localStorage.saveBalanceVisibility(isVisible);
    this._eventBusService.dispatch(new EventData(EventTypeConstants.ToggleBalanceVisibility, isVisible));
  }

  private setupEventListeners(): void {
    this._screenSizeService.isMobile$.pipe(takeUntil(this.destroy$)).subscribe(isMobile => {
      this.isMobile = isMobile;
      this.chartOptions!.aspectRatio = isMobile ? 2 : 4;
      this.chart?.render();
    });

    this._eventBusService.handle(EventTypeConstants.ToggleBalanceVisibility, (isVisible: boolean) => {
      this.isBalanceVisible = isVisible;
    });
  }

  private initializeChart(): void {
    this.updateDateRange();
    this.getBalanceHistory();
  }

  private updateDateRange(): void {
    this.endDate = endOfYesterday();
    this.startDate = subDays(this.endDate, this.currentPeriod.days);
  }

  private applyChartGradient(): void {
    if (this.chart) {
      const grad = this.createChartGradient(this.chart);
      this.chartData.datasets[0].backgroundColor = grad;
      this.chart.render();
    }
  }

  private createChartGradient(chart: BaseChartDirective) {
    const ctx = chart?.chart?.ctx;
    const gradient = ctx?.createLinearGradient(0, 0, 0, 200);
    gradient?.addColorStop(0, "rgba(11, 168, 89, 0.2)");
    gradient?.addColorStop(1, "rgba(11, 168, 89, 0.02)");
    return gradient;
  }

  private async getBalanceHistory(): Promise<void> {
    this.isChartLoading = true;
    const startDate = this.startDate?.toISOString() ?? "";
    const endDate = this.endDate?.toISOString() ?? "";

    const res = await this._userTotalBalanceHistoriesService.getUserBalanceHistory(startDate, endDate);

    if (res.params && res.params.length > 0) {
      this.chartData.labels = res.params.map(item => new Date(item.date).toISOString());
      this.chartData.datasets[0].data = res.params.map(item => item.amount);

      this.chartData.labels?.push(new Date().toISOString());
      this.chartData.datasets[0].data?.push(this.totalUsdBalance);

      this.chart?.update();
    }

    this.isChartLoading = false;
  }

  private formatTicksByPeriod(label: string) {
    const date = new Date(label);
    const { key } = this.currentPeriod;

    switch (key) {
      case "week":
        return format(date, "dd.MM");
      case "month":
        return format(date, "dd.MM.yyyy");
      case "3_months":
      case "6_months":
      case "year":
        return format(date, "MMM", { locale: ru }).toUpperCase();
      default:
        return format(date, "dd.MM.yyyy");
    }
  }

  private manageTickLabels(index: number) {
    const labels = this.chartData?.labels;
    if (!labels?.length) return null;

    const label = labels[index] as string;

    const firstDatesOfMonth = this.getFirstDatesOfEachMonth(labels);

    const visibleLabels = this.getVisibleLabelsForPeriod(labels, firstDatesOfMonth);

    return visibleLabels.includes(label) ? this.formatTicksByPeriod(label) : null;
  }

  private getFirstDatesOfEachMonth(labels: any[]): string[] {
    return labels.filter((date, index, arr) => {
      if (index === 0) return true;
      const currentDate = new Date(date as string);
      const prevDate = new Date(arr[index - 1] as string);
      return !isSameMonth(currentDate, prevDate);
    }) as string[];
  }

  private getVisibleLabelsForPeriod(labels: any[], firstDatesOfMonth: string[]): string[] {
    const { key } = this.currentPeriod;

    switch (key) {
      case "week":
        // Show all labels for weekly view
        return labels as string[];

      case "month":
        // Show first, last and two in between for monthly view
        const oneQuarter = Math.round(labels.length / 4);
        return [labels[0], labels[oneQuarter], labels[oneQuarter * 2], labels[labels.length - 1]] as string[];

      case "3_months":
        // Show first day of each month for 3 months period
        return firstDatesOfMonth;

      case "6_months":
        // Show first, third and last month for 6 months period
        return [firstDatesOfMonth[0], firstDatesOfMonth[2], firstDatesOfMonth[firstDatesOfMonth.length - 1]];

      case "year":
        // Show quarterly months for yearly view
        return [
          firstDatesOfMonth[0],
          firstDatesOfMonth[3],
          firstDatesOfMonth[7],
          firstDatesOfMonth[firstDatesOfMonth.length - 1],
        ];

      default:
        return labels as string[];
    }
  }
}
