<!-- Full size version -->
<div
  *ngIf="fullSize"
  class="grid grid-cols-[17%_14%_25%_12%_auto] items-center gap-x-12 px-6 py-3 text-base font-semibold max-md:flex max-md:flex-wrap max-md:justify-between max-md:gap-x-1 max-md:gap-y-6 max-md:rounded-xl max-md:border-2 max-md:border-gray-100 max-md:p-3 dark:max-md:border-slate-700"
>
  <div class="flex items-center gap-x-4 max-md:max-w-[50%] max-md:gap-x-3">
    <div
      class="flex h-10 w-10 shrink-0 items-center justify-center rounded-main max-md:h-12"
      [ngClass]="{
        'bg-yellow-100': txDto.direction === TxDirection.Swap,
        'bg-purple-100': txDto.direction === TxDirection.Receiving,
        'bg-green-100': txDto.direction === TxDirection.Sending
      }"
    >
      <img
        [src]="
          txDto.direction === TxDirection.Swap
            ? 'assets/icons/swap-arrow.svg'
            : txDto.direction === TxDirection.Receiving
              ? 'assets/icons/receive-arrow.svg'
              : 'assets/icons/send-arrow.svg'
        "
        alt=""
        class="h-5 w-5"
      />
    </div>
    <div class="overflow-hidden">
      {{ txDestination }}
      <span
        *ngIf="txDto.direction !== TxDirection.Swap"
        [title]="otherWalletAddress"
        class="mt-1 block text-sm text-text-secondary"
      >
        {{ formatAddress(otherWalletAddress) }}
      </span>
    </div>
  </div>

  <div class="max-md:text-end">
    <p
      class="whitespace-nowrap"
      [title]="balanceChange"
      [ngClass]="{
        'text-green-500 dark:text-green-200':
          txDto.direction === TxDirection.Receiving || txDto.direction === TxDirection.Swap,
        'text-red-500 dark:text-red-200': txDto.direction === TxDirection.Sending
      }"
    >
      {{ formatAmount(balanceChange, txDto.direction === TxDirection.Swap ? "+" : "") }}
      {{ txDto.currency | currencyName }}
    </p>
    <p
      *ngIf="txDto.direction !== TxDirection.Swap"
      class="whitespace-nowrap text-sm text-text-secondary"
      [title]="usdBalanceChange"
    >
      ${{ usdBalanceChange }} USD
    </p>
    <p
      *ngIf="txDto.direction === TxDirection.Swap"
      class="whitespace-nowrap text-sm text-red-500 dark:text-red-200"
    >
      -{{ txDto.fromAmount | mfeCustom: { currency: txDto.fromCurrency } }}
      {{ txDto.fromCurrency | currencyName }}
    </p>
  </div>

  <div class="flex items-center gap-x-4 overflow-hidden max-md:w-full max-md:justify-between max-md:gap-x-3">
    <span class="truncate" [title]="txDto.hash || txDto.id">{{ txDto.hash || txDto.id }}</span>

    <a
      *ngIf="shouldShowLinkToTx"
      target="_blank"
      [href]="txLink"
      rel="noopener noreferrer"
      class="h-6 w-6 shrink-0 text-text-secondary hover:text-text-primary dark:hover:text-white"
    >
      <app-svg-icon name="actions/link" class="h-full w-full" />
    </a>

    <app-copy-btn [value]="txDto.hash || txDto.id.toString()" />
  </div>

  <div class="whitespace-pre text-sm max-md:font-normal max-md:text-text-secondary">
    {{ txDate | customDate: "full" }}
  </div>

  <div class="text-end text-sm" [ngClass]="statusColor">{{ txStatus }}</div>
</div>

<!-- Short version -->
<div *ngIf="!fullSize" class="flex items-center justify-between gap-x-4 px-3 py-4 max-md:px-2">
  <div class="flex items-center gap-x-4 overflow-hidden max-md:gap-x-3">
    <div
      class="flex h-12 w-10 shrink-0 items-center justify-center rounded-main"
      [ngClass]="{
        'bg-yellow-100': txDto.direction === TxDirection.Swap,
        'bg-purple-100': txDto.direction === TxDirection.Receiving,
        'bg-green-100': txDto.direction === TxDirection.Sending
      }"
    >
      <img
        [src]="
          txDto.direction === TxDirection.Swap
            ? 'assets/icons/swap-arrow.svg'
            : txDto.direction === TxDirection.Receiving
              ? 'assets/icons/receive-arrow.svg'
              : 'assets/icons/send-arrow.svg'
        "
        alt=""
        class="h-5 w-5"
      />
    </div>
    <div class="overflow-hidden">
      <p class="truncate text-base font-semibold max-md:text-sm">
        {{ txDestination
        }}<span *ngIf="txDto.direction !== TxDirection.Swap" [title]="otherWalletAddress"
          >: {{ formatAddress(otherWalletAddress) }}</span
        >
      </p>
      <p class="text-sm font-semibold text-text-secondary max-md:mt-1 max-md:text-xs">
        {{ txDto.createdAt | customDate: "full" }}
      </p>
    </div>
  </div>

  <div class="shrink-0 text-end">
    <p class="text-base font-semibold">
      <span
        *ngIf="isBalanceVisible"
        [title]="balanceChange"
        class="max-md:text-xs"
        [ngClass]="{
          'text-green-500 dark:text-green-200':
            txDto.direction === TxDirection.Receiving || txDto.direction === TxDirection.Swap,
          'text-red-500 dark:text-red-200': txDto.direction === TxDirection.Sending
        }"
      >
        {{ formatAmount(balanceChange, txDto.direction === TxDirection.Swap ? "+" : "") }}
        {{ txDto.currency | currencyName }}
      </span>
      <span *ngIf="!isBalanceVisible">***</span>
    </p>
    <p class="text-sm font-semibold max-md:mt-1 max-md:text-xs" [ngClass]="statusColor">{{ txStatus }}</p>
  </div>
</div>
