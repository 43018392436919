import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { TxDto } from "src/app/common/DTO/txs";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { RatesService } from "src/app/services/rates.service";
import { TxService } from "src/app/services/tx.service";
import { UserService } from "src/app/services/user.service";

@Component({
  templateUrl: "./user-transactions.component.html",
  styleUrls: ["./user-transactions.component.css"],
})
export class UserTransactionsComponent implements OnInit {
  public userId: number = 0;
  public userData: UserDto;

  public currencyTab: CryptoSymbol = CryptoSymbol.AbstractUsdt;
  public transactions: TxDto[] = [];
  public page = 1;
  public pageSize: number = 10;
  public totalCount: number = 0;
  public isLoading: boolean = true;

  public rates: RateDto[] = [];

  public buySellTxs: CryptoTxDto[] = [];
  public buySellPage: number = 1;

  private readonly currenciesList = [
    CryptoSymbol.AbstractUsdt,
    CryptoSymbol.Trx,
    CryptoSymbol.Matic,
    CryptoSymbol.Ton,
    CryptoSymbol.Not,
    CryptoSymbol.Bitcoin,
  ];

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _userService: UserService,
    private readonly _ratesService: RatesService,
    private readonly _txService: TxService
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.userId = params["id"];
    });
    this.userData = new UserDto();
  }

  async ngOnInit(): Promise<void> {
    await this.requestUserData();
    await this.loadRates();
    await this.loadTxs();
    this.isLoading = false;
  }

  public get currencyNames(): string[] {
    return this.currenciesList.map(c => getCurrencyName(c));
  }

  public onSelectCurrency(tabNumber: number) {
    const currency = this.currenciesList[tabNumber];
    if (currency) {
      this.currencyTab = currency;
      this.loadTxs();
    }
  }

  public get hasTxs() {
    return this.transactions.length > 0;
  }

  public onPageChange(page: number) {
    this.page = page;
    this.loadTxs();
  }

  private async requestUserData() {
    const res = await this._userService.getUserById(this.userId);
    this.userData = res.params!;
  }

  private async loadRates() {
    const res = await this._ratesService.getRates();
    this.rates = res.params ?? [];
  }

  private async loadTxs() {
    this.isLoading = true;
    const res = await this._txService.getAllTxs({
      size: this.pageSize,
      page: this.page,
      userId: this.userId,
      currencies: [this.currencyTab],
    });
    this.transactions = res.params?.items ?? [];
    this.totalCount = res.params?.totalCount ?? 0;
    this.isLoading = false;
  }
}
