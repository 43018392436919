<div class="relative mx-auto md:w-[28rem]">
  <!-- Header -->
  <div class="flex items-center max-md:py-3">
    <button *ngIf="step === Step.NetworkSelect" class="btn-flat mr-3" (click)="step = Step.Qr">
      <app-svg-icon name="actions/back" class="h-8 w-8" />
    </button>
    <h2 class="title">
      <ng-template [ngIf]="step === Step.Qr">{{ "Receive.Receive" | translate }}</ng-template>
      <ng-template [ngIf]="step === Step.NetworkSelect">{{ "Send.Network_select" | translate }}</ng-template>
    </h2>
    <button class="btn-flat ml-auto h-6 w-6" (click)="closeModal()">
      <app-svg-icon name="close" />
    </button>
  </div>

  <ng-container [ngSwitch]="step">
    <!-- QR Code -->
    <ng-template [ngSwitchCase]="Step.Qr">
      <div class="mt-6 flex justify-center p-6">
        <img
          *ngIf="qrBase64; else qrSkeleton"
          class="mx-auto block w-[11.25rem] dark:invert"
          [src]="qrBase64"
          alt="QR Code"
        />
        <ng-template #qrSkeleton>
          <app-skeleton class="mx-auto block aspect-square w-[11.25rem]" />
        </ng-template>
      </div>

      <div
        class="mt-1 space-y-1"
        *ngIf="walletBalance !== null && selectedWalletBalance; else walletAddressSkeleton"
      >
        <div
          class="grid grid-cols-[5rem_auto] items-center gap-x-6 rounded-xl border border-gray-100 px-4 py-5 text-sm font-semibold dark:border-slate-700"
        >
          <div class="flex items-center gap-x-2 text-text-secondary">
            <span class="h-3 w-3 rounded-[0.25rem] bg-yellow-500"></span>
            {{ "Receive.Asset" | translate }}
          </div>
          <div class="flex items-center gap-x-2">
            <app-currency-icon [currency]="walletBalance.currency" size="xs" />
            <span>{{ getCurrencyName(walletBalance.currency) }}</span>
            <span class="text-slate-200">{{ networkName }}</span>
          </div>
        </div>

        <div
          *ngIf="
            walletBalance === null || (walletBalance && walletBalance.currency === CryptoSymbol.AbstractUsdt)
          "
          class="grid grid-cols-[5rem_auto] items-center gap-x-6 rounded-xl border border-gray-100 px-4 py-5 text-sm font-semibold dark:border-slate-700"
          (click)="step = Step.NetworkSelect"
        >
          <div class="flex items-center gap-x-2 text-text-secondary">
            <span class="h-3 w-3 rounded-[0.25rem] bg-purple-500"></span>
            {{ "Receive.Network" | translate }}
          </div>
          <button class="flex items-center justify-between" (click)="step = Step.NetworkSelect">
            {{ networkName }}
            <app-svg-icon name="round-next" class="h-6 w-6 text-gray-100 dark:text-slate-700" />
          </button>
        </div>

        <div
          class="grid grid-cols-[5rem_auto] items-center gap-x-6 rounded-xl border border-gray-100 px-4 py-5 text-sm font-semibold dark:border-slate-700"
        >
          <div class="flex items-center gap-x-2 text-text-secondary">
            <span class="h-3 w-3 rounded-[0.25rem] bg-green-400"></span>
            {{ "Receive.Address" | translate }}
          </div>
          <div class="flex w-full items-center justify-between gap-x-2 overflow-hidden">
            <span class="break-all">{{ walletAddress }}</span>
            <app-copy-btn [value]="walletAddress" />
          </div>
        </div>
      </div>

      <ng-template #walletAddressSkeleton>
        <app-skeleton class="h-18 w-full" />
        <app-skeleton class="mt-1 h-18 w-full" />
        <app-skeleton
          *ngIf="walletBalance?.currency === CryptoSymbol.AbstractUsdt"
          class="mt-1 h-18 w-full"
        />
      </ng-template>

      <div
        class="mt-1 rounded-xl bg-gray-100 py-4 text-center text-sm font-semibold text-text-secondary dark:bg-slate-700"
      >
        {{ "Receive.Deposit_limit" | translate }}
        <span class="text-green-500">
          {{ getDepositLimitAmount(walletBalance?.currency!) | mfeCustom }}
          {{ walletBalance?.currency! | currencyName }}
        </span>
      </div>

      <button class="btn-primary btn-medium mt-4 w-full py-4" (click)="copyWalletAddress(walletAddress)">
        {{ "Receive.Copy_address" | translate }} {{ networkName }}
      </button>

      <ng-container *ngIf="isTelegramMiniApp && isTonNetwork">
        <div class="my-6 flex items-center gap-x-4">
          <div class="h-px flex-1 bg-slate-200 dark:bg-white"></div>
          <span class="font-semibold text-text-secondary dark:text-white">{{
            "Receive.Or" | translate
          }}</span>
          <div class="h-px flex-1 bg-slate-200 dark:bg-white"></div>
        </div>

        <form class="flex flex-col gap-y-2" [formGroup]="receiveForm" (ngSubmit)="receiveFromTonWallet()">
          <app-input
            [title]="'Send.Amount' | translate"
            type="number"
            name="amount"
            id="amount"
            placeholder="100"
            formControlName="amount"
            appPositiveDecimals
            [errorMsg]="amountError"
          />
          <button
            type="submit"
            [disabled]="receiveForm.invalid || !!amountError"
            class="flex items-center justify-center gap-x-4 rounded-full bg-white px-5 py-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
            <span class="whitespace-pre-line text-center text-[#0A98FD]">
              {{ "Receive.Via_telegram" | translate }}
            </span>
            <img src="assets/icons/telegram-wallet.jpg" alt="" class="h-6 w-6 rounded-md" />
          </button>
        </form>
      </ng-container>
    </ng-template>

    <!-- Network select -->
    <ng-template [ngSwitchCase]="Step.NetworkSelect">
      <!-- <app-input
        type="search"
        class="mt-6 block w-full"
        placeholder="{{ 'Send.Search_asset' | translate }}"
        [(ngModel)]="networkSearch"
      /> -->

      <div class="mt-4 flex flex-col gap-y-1 overflow-y-auto">
        <button
          *ngFor="let n of networks"
          (click)="selectNetwork(n)"
          class="flex items-center rounded-2xl border border-transparent p-3 transition-colors hover:border-gray-100 dark:hover:border-slate-700"
          [ngClass]="{ 'bg-gray-50 dark:bg-slate-900': selectedWalletBalance?.wallet?.network === n }"
        >
          <img [src]="getNetworkInfo(n).icon" [alt]="getNetworkInfo(n).name" class="mr-3 h-8 w-8" />
          <p class="text-start font-semibold">
            {{ getNetworkInfo(n).name }}
            <span class="block text-xs text-text-secondary">{{ getNetworkInfo(n).currencyName }}</span>
          </p>
          <app-svg-icon
            *ngIf="selectedWalletBalance?.wallet?.network === n"
            name="check"
            class="ml-auto h-4 w-4"
          />
        </button>
      </div>
    </ng-template>
  </ng-container>
</div>
