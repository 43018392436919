<div class="otp-input-container">
  <label class="">
    <p *ngIf="title">{{ title }}</p>

    <div
      class="grid items-center gap-3 max-md:gap-x-1"
      [ngStyle]="{
        'grid-template-columns': 'repeat(' + digits + ', minmax(0, 1fr))'
      }"
    >
      <input
        *ngFor="let digitControl of otpDigits; let i = index"
        [id]="'otp-input-' + i"
        type="text"
        inputmode="numeric"
        maxlength="1"
        class="block min-h-[5rem] rounded-lg border border-transparent bg-gray-100 p-2 text-center text-2xl font-bold text-gray-900 focus:border-green-400 focus:outline-none dark:bg-slate-700 dark:text-white max-md:min-h-[4rem] max-md:p-1 max-md:text-base"
        [formControl]="digitControl"
        [appAutofocus]="i === 0 && autofocus"
        autocomplete="off"
        (keydown)="onKeyDown($event, i)"
        (paste)="onPaste($event)"
      />
    </div>

    <span *ngIf="errorMsg != null" class="customInput__error">
      {{ errorMsg }}
    </span>

    <!-- Time counter -->
    <button
      *ngIf="initialTimerValue"
      type="button"
      [disabled]="timeCounter > 0"
      class="mx-auto mt-8 block text-center text-sm font-bold text-text-secondary hover:text-green-400 disabled:text-black/40 dark:text-text-secondary"
      (click)="onResendClick()"
    >
      <span>
        {{ "Login.Send_new_code" | translate }} <span *ngIf="timeCounter > 0">({{ timeCounter }}с)</span>
      </span>
    </button>

    <ng-container *ngIf="comment.length">
      <span class="block text-sm text-text-pale" *ngFor="let c of comment">
        {{ c }}
      </span>
    </ng-container>
  </label>
</div>
