<div
  class="max-md:my-2 max-md:rounded-2xl max-md:bg-gray-25 max-md:p-4 max-md:dark:bg-slate-800 md:absolute md:top-8"
>
  <h1 class="title">{{ "Bank.Cards" | translate }}</h1>
</div>

<div class="rounded-2xl bg-gray-25 p-6 dark:bg-slate-800 max-md:p-3">
  <div class="flex flex-wrap items-center justify-between">
    <h2 class="title max-md:px-1 max-md:text-lg">{{ "Bank.Title" | translate }}</h2>
    <ng-container *ngIf="canAddCard">
      <button class="btn-primary btn-medium max-md:mt-6 max-md:w-full" (click)="openAddBankCardModal()">
        {{ "Bank.Add_card" | translate }}
      </button>
    </ng-container>
  </div>

  <section
    *ngIf="cardList.length > 0; else noCardsTemplate"
    class="mt-6 grid grid-cols-3 gap-6 max-md:grid-cols-1 max-md:gap-4"
  >
    <app-bank-card
      *ngFor="let card of cardList; let i = index"
      [card]="card"
      [index]="i"
      [showActions]="true"
      (onDelete)="openDeleteCardModal(card.id)"
      (onSelect)="openCardDetailsModal(card, cardDetailsModal)"
      class="cursor-pointer max-md:mx-auto max-md:w-full max-md:max-w-[20rem] [&>div]:h-full"
    />
  </section>

  <ng-template #noCardsTemplate>
    <div class="mt-6 flex flex-col items-center justify-center space-y-4 max-md:space-y-3">
      <p class="font-semibold text-text-secondary max-md:text-sm">{{ "Bank.No_cards" | translate }}</p>
      <button
        class="btn-primary btn-medium max-md:w-full"
        (click)="openAddBankCardModal()"
        *ngIf="verificationStatus === VerificationStatus.Verified; else notVerifiedAddCard"
      >
        {{ "Bank.Add_card" | translate }}
      </button>
      <ng-template #notVerifiedAddCard>
        <div
          class="btn-primary btn-medium cursor-default grayscale"
          [ngbTooltip]="'Profile.Add_card_verification_required' | translate"
          tooltipClass="custom-tooltip bg-text-pale"
        >
          {{ "Bank.Add_card" | translate }}
          <app-svg-icon name="add-circle" class="ml-3 h-6 w-6" />
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>

<ng-template let-modal #cardDetailsModal>
  <div class="flex items-center justify-between p-3">
    <h3 class="title">
      {{ "Bank.Card_details" | translate }}
    </h3>
    <button (click)="modal.dismiss()" class="btn-flat">
      <app-svg-icon name="close" class="h-6 w-6" />
    </button>
  </div>

  <app-bank-card
    [card]="selectedCard!"
    [index]="getSelectedCardIndex()"
    class="mx-auto mt-8 block max-w-[19.25rem] max-md:mt-6"
  />

  <button
    class="btn-primary-danger btn-large mt-8 w-full max-md:mt-6"
    (click)="modal.dismiss(); openDeleteCardModal(selectedCard?.id!)"
  >
    {{ "Bank.Delete_card" | translate }}
  </button>
</ng-template>
