<div ngbDropdown #dateDropdown="ngbDropdown" class="relative" container="body" placement="bottom-end top-end">
  <div
    ngbDropdownToggle
    class="dropdown-base-toggle w-full cursor-pointer"
    [ngClass]="{ 'px-2 py-1.5': selectedDates }"
    #toggle
  >
    <div
      *ngIf="selectedDates; else pl"
      class="flex items-center gap-x-2 rounded-lg bg-gray-100 px-2 py-1 text-sm font-medium text-text-secondary dark:bg-slate-700"
    >
      {{ selectedDates }}
      <button class="w-4 hover:scale-110" (click)="onResetDates($event)">
        <app-svg-icon name="actions/close" class="block" />
      </button>
    </div>
    <ng-template #pl>{{ placeholder }}</ng-template>

    <app-svg-icon name="chevron" class="block w-3" />
  </div>

  <div ngbDropdownMenu [matchWidth]="toggle" class="min-w-[17rem] space-y-3 p-4">
    <ngb-datepicker
      #datePicker
      navigation="select"
      [displayMonths]="1"
      [contentTemplate]="calendar"
      [dayTemplate]="calendarDay"
      outsideDays="visible"
      [weekdays]="false"
      [maxDate]="maxDate"
      datepickerClass="ngb-datepicker"
      class="mx-auto block w-fit"
      (dateSelect)="onDateSelection($event)"
    />
    <ng-container *ngIf="withActionButtons">
      <button class="btn-primary btn-medium w-full" (click)="onSelectDates(); dateDropdown.close()">
        {{ selectedDates ? "Выбрать " + selectedDates : "Выбрать дату" }}
      </button>
      <button class="btn-primary-dark btn-medium w-full" (click)="onResetDates(); dateDropdown.close()">
        Сбросить
      </button>
    </ng-container>
  </div>
</div>

<ng-template #calendar let-dp>
  <div class="calendar">
    <!-- <div class="mb-3 flex items-center justify-between">
      <button class="btn-flat" (click)="navigateToDate(dp, -1)">
        <app-svg-icon name="actions/calendar-slide-left" class="h-6 w-6" />
      </button>
      <span class="text-center font-semibold">
        {{ dp.i18n.getMonthShortName(dp.state.months[0].month) }} {{ dp.state.months[0].year }}
      </span>
      <button class="btn-flat rotate-180" (click)="navigateToDate(dp, 1)">
        <app-svg-icon name="actions/calendar-slide-left" class="h-6 w-6" />
      </button>
    </div> -->

    <div class="flex">
      <div *ngFor="let weekday of weekdays" class="flex-1 p-1 text-center">
        <span class="text-xs font-semibold capitalize text-text-secondary">{{ weekday }}</span>
      </div>
    </div>

    <ngb-datepicker-month [month]="dp.state.months[0]" class="calendar-month" />
  </div>
</ng-template>

<ng-template
  #calendarDay
  let-date
  let-currentMonth="currentMonth"
  let-selected="selected"
  let-disabled="disabled"
  let-focused="focused"
>
  <div
    class="calendar-day"
    [class.focused]="focused"
    [class.selected]="selected"
    [class.opacity-30]="date.month !== currentMonth"
    [class.text-muted]="disabled"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    [ngClass]="{
      '!rounded-l-xl !rounded-r-none': Array.isArray(value) ? date === value[0] : date === value,
      '!rounded-l-none !rounded-r-xl': Array.isArray(value) ? date === value[1] : date === value
    }"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    <span class="calendar-day-text">{{ date.day }}</span>
  </div>
</ng-template>
