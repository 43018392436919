<div class="box h-full px-3">
  <div class="border-b border-gray-100 pb-5 dark:border-slate-700">
    <h3 class="altitle">{{ "Admin.Home.Functions" | translate }}</h3>
  </div>
  <div class="mt-5 space-y-2.5" [formGroup]="functionalityForm">
    <div class="toggle-wrapper">
      <p>{{ "Admin.Home.Buy" | translate }}</p>
      <app-switch [disabled]="!hasInteractionPermissions" formControlName="canBuy" />
    </div>
    <div class="toggle-wrapper">
      <p>{{ "Admin.Home.Sell" | translate }}</p>
      <app-switch [disabled]="!hasInteractionPermissions" formControlName="canSell" />
    </div>
    <div class="toggle-wrapper">
      <p>{{ "Admin.Home.Send" | translate }}</p>
      <app-switch [disabled]="!hasInteractionPermissions" formControlName="canSend" />
    </div>
  </div>
</div>
