<div class="my-2 flex items-center gap-x-3 rounded-2xl bg-gray-25 p-4 dark:bg-slate-800">
  <a routerLink="/depositary" class="">
    <app-svg-icon name="actions/back" class="h-6 w-6 text-text-secondary" />
  </a>
  <h1 class="title">{{ currency | currencyName }}</h1>
</div>

<div class="flex flex-col items-center rounded-2xl bg-gray-25 px-6 py-4 dark:bg-slate-800">
  <app-currency-icon [currency]="currency" size="lg" />

  <div class="mt-4 text-center">
    <p class="text-2xl font-semibold">
      {{ currentBalance?.availableAmount | mfeCustom }} {{ currency | currencyName }}
    </p>
    <p class="mt-1 font-semibold text-text-secondary">
      {{ getUsdValue(currentBalance?.availableAmount ?? 0) }} USD
    </p>
  </div>

  <div class="mt-6 grid w-full grid-cols-2 gap-x-4">
    <button class="btn-primary btn-small" (click)="openReceiveModal(currentBalance)">
      <span>{{ "Receive.Receive" | translate }}</span>
    </button>
    <button class="btn-primary btn-small" (click)="openSendModal(currentBalance)">
      <span>{{ "Send.Send" | translate }}</span>
    </button>
  </div>
</div>

<div class="mt-2">
  <app-latest-transactions [txCount]="7" [currency]="currency"></app-latest-transactions>
</div>
