import { Component, Input } from "@angular/core";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { BalanceDto } from "src/app/common/DTO/balances/balance.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";

@Component({
  selector: "app-available-assets",
  templateUrl: "./available-assets.component.html",
  styleUrls: ["./available-assets.component.css"],
})
export class AvailableAssetsComponent {
  @Input() balances: BalanceDto[] = [];
  @Input() rates: RateDto[] = [];

  public readonly periodOptions = [
    { label: "Assets.Time_week", days: 7 },
    { label: "Assets.Time_month", days: 30 },
    // { label: "Assets.Time_year", days: 365 },
  ];
  public selectedPeriod = this.periodOptions[0];

  public RouteConstants = RouteConstants;

  constructor() {}

  public getRateByCurrency(currency: CryptoSymbol): RateDto | null {
    return this.rates?.find(rate => rate.symbol === currency) ?? null;
  }

  public get periodOptionsNames() {
    return this.periodOptions.map(option => option.label);
  }

  public onPeriodChange(name: string) {
    this.selectedPeriod = this.periodOptions.find(option => option.label === name) ?? this.periodOptions[0];
  }
}
