import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { MinBalanceForWithdrawDto } from "src/app/common/DTO/min-balance-for-withdraws/min-balance-for-withdraw.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { MinBalanceForWithdrawsErrorCode } from "src/app/common/enums/min-balance-error-code.enum";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class MinBalanceForWithdrawsService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async getMinBalanceForWithdraws() {
    const uri = `${this._envService.serverUrl}${UrnConstants.MinBalanceForWithdrawsAll}`;
    const result = new HttpResultDto<MinBalanceForWithdrawsErrorCode, MinBalanceForWithdrawDto[]>(false);

    try {
      const response = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<
        MinBalanceForWithdrawDto[]
      >;
      result.params = response.data;
    } catch (e) {
      result.withError = true;

      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async updateMinBalanceForWithdraws(currency: CryptoSymbol, amount: number) {
    const params = `?Currency=${currency}&Amount=${amount}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.MinBalanceForWithdraws}${params}`;
    const result = new HttpResultDto<MinBalanceForWithdrawsErrorCode, void>(false);

    try {
      await firstValueFrom(this._http.put(uri, null, this._baseReqOpts));
    } catch (e) {
      result.withError = true;

      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): MinBalanceForWithdrawsErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return MinBalanceForWithdrawsErrorCode.InternalError;
      case Constants.Unauthorized:
        return MinBalanceForWithdrawsErrorCode.Unauthorized;
      case "Fee can't be greater or equal to min balance to withdraw":
        return MinBalanceForWithdrawsErrorCode.FeeGreaterThanMinBalance;
      default:
        return MinBalanceForWithdrawsErrorCode.InternalError;
    }
  }
}
