<div [class.variant-buttons]="variant === 'buttons'" [class.variant-panels]="variant === 'panels'">
  <button
    *ngFor="let tab of translatedTabs; index as i"
    [class.active]="currentTabNumber === i"
    (click)="onTabSelect(i)"
    type="button"
  >
    {{ tab }}
  </button>
</div>
