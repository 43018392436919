import { Component, Input, OnInit } from "@angular/core";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { TxDto } from "src/app/common/DTO/txs";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { EventBusService } from "src/app/services/event-bus.service";
import { RatesService } from "src/app/services/rates.service";
import { TxService } from "src/app/services/tx.service";

@Component({
  selector: "app-latest-transactions",
  templateUrl: "./latest-transactions.component.html",
  styleUrls: ["./latest-transactions.component.css"],
})
export class LatestTransactionsComponent implements OnInit {
  @Input() txCount: number = 7;
  @Input() currency?: CryptoSymbol;

  public wallets: WalletDto[] = [];
  public rates: RateDto[] = [];
  public transactions: TxDto[] = [];
  public page = 1;
  public totalCount = 0;
  public isTxsLoading = true;
  public isBalanceVisible = true;
  public RouteConstants = RouteConstants;

  public isLoadingMore: boolean = false;
  public hasMoreTransactions: boolean = true;

  constructor(
    private readonly _ratesService: RatesService,
    private readonly _txService: TxService,
    private readonly _eventBusService: EventBusService
  ) {
    this._eventBusService.handle(EventTypeConstants.ToggleBalanceVisibility, (isVisible: boolean) => {
      this.isBalanceVisible = isVisible;
    });
  }

  async ngOnInit(): Promise<void> {
    this.rates = (await this._ratesService.getRates()).params ?? [];
    await this.loadTransactions();
  }

  public async loadMoreTransactions(): Promise<void> {
    if (this.isLoadingMore || !this.hasMoreTransactions) {
      return;
    }

    this.isLoadingMore = true;
    this.page++;
    await this.fetchTransactions(true);
    this.isLoadingMore = false;
  }

  private async loadTransactions(): Promise<void> {
    this.isTxsLoading = true;
    this.page = 1;
    await this.fetchTransactions(false);
    this.isTxsLoading = false;
  }

  private async fetchTransactions(isLoadMore: boolean): Promise<void> {
    const res = await this._txService.getAllMy({
      page: this.page,
      size: this.txCount,
      currencies: this.currency ? [this.currency] : undefined,
    });

    if (!res.withError && res.params) {
      if (isLoadMore) {
        const newTransactions = res.params.items ?? [];
        this.transactions = [...this.transactions, ...newTransactions];
      } else {
        this.transactions = res.params.items ?? [];
      }

      this.totalCount = res.params.totalCount ?? 0;
      this.hasMoreTransactions = this.transactions.length < this.totalCount;
    }
  }
}
