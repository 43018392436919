import { HttpBackend, HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule, isDevMode } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AngularSvgIconModule } from "angular-svg-icon";
import { RecaptchaModule } from "ng-recaptcha";
import { NgChartsModule } from "ng2-charts";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxMaskModule } from "ngx-mask";
import { AppRoutingModule } from "src/app/app-routing.module";
import { AppComponent } from "src/app/app.component";
import { LanguageConstants } from "src/app/common/constants/language.constants";
import { OnlyNumbersDirective } from "src/app/common/directives/only-numbers.directive";
import { tokenInterceptorProviders } from "src/app/common/interceptors/token.interceptor";
import { CustomDatePipe } from "src/app/common/pipes/custom-date.pipe";
import { MfeCustomPipe } from "src/app/common/pipes/mfe-custom.pipe";
import { PhoneDisplayerPipe } from "src/app/common/pipes/phone-displayer.pipe";
import { AdminSidebarComponent } from "src/app/components/_admin/admin-sidebar/admin-sidebar.component";
import { CommissionBlockComponent } from "src/app/components/_admin/commission-block/commission-block.component";
import { LimitsBlockComponent } from "src/app/components/_admin/limits-block/limits-block.component";
import { RateBlockComponent } from "src/app/components/_admin/rate-block/rate-block.component";
import { ModalModule } from "src/app/components/_modal";
import { BackButtonComponent } from "src/app/components/_shared/back-button/back-button.component";
import { CopyBtnComponent } from "src/app/components/_shared/copy-btn/copy-btn.component";
import { DropdownComponent } from "src/app/components/_shared/dropdown/dropdown.component";
import { InputComponent } from "src/app/components/_shared/input/input.component";
import { LoaderComponent } from "src/app/components/_shared/loader/loader.component";
import { SelectComponent } from "src/app/components/_shared/select/select.component";
import { SkeletonComponent } from "src/app/components/_shared/skeleton/skeleton.component";
import { SwitchComponent } from "src/app/components/_shared/switch/switch.component";
import { TabsComponent } from "src/app/components/_shared/tabs/tabs.component";
import { ToastComponent } from "src/app/components/_shared/toast/toast.component";
import { BalanceCardComponent } from "src/app/components/balance-card/balance-card.component";
import { CryptoTransactionListItemComponent } from "src/app/components/crypto-transaction-list-item/crypto-transaction-list-item.component";
import { FooterComponent } from "src/app/components/footer/footer.component";
import { HeaderComponent } from "src/app/components/header/header.component";
import { LanguageSelectorComponent } from "src/app/components/language-selector/language-selector.component";
import { StatusWindowComponent } from "src/app/components/status-window/status-window.component";
import { StringSwapperComponent } from "src/app/components/string-swapper/string-swapper.component";
import { TransactionListItemSkeletonComponent } from "src/app/components/transaction-list-item-skeleton/transaction-list-item-skeleton.component";
import { TransactionListItemComponent } from "src/app/components/transaction-list-item/transaction-list-item.component";
import { AdminLayoutComponent } from "src/app/layouts/admin-layout/admin-layout.component";
import { BaseAuthFormComponent } from "src/app/layouts/base-auth-form/base-auth-form.component";
import { ClientLayoutComponent } from "src/app/layouts/client-layout/client-layout.component";
import { AdminAdminsComponent } from "src/app/pages/admin/admin-admins/admin-admins.component";
import { AdminHomeComponent } from "src/app/pages/admin/admin-home/admin-home.component";
import { AdminKycComponent } from "src/app/pages/admin/admin-kyc/admin-kyc.component";
import { KycRecordComponent } from "src/app/pages/admin/admin-kyc/kyc-record/kyc-record.component";
import { AdminLoginComponent } from "src/app/pages/admin/admin-login/admin-login.component";
import { AdminTransactionsComponent } from "src/app/pages/admin/admin-transactions/admin-transactions.component";
import { AdminUsersComponent } from "src/app/pages/admin/admin-users/admin-users.component";
import { UserPageComponent } from "src/app/pages/admin/admin-users/user-page/user-page.component";
import { UserTransactionsComponent } from "src/app/pages/admin/admin-users/user-transactions/user-transactions.component";
import { AdminComponent } from "src/app/pages/admin/admin.component";
import { KycComponent } from "src/app/pages/auth/kyc/kyc.component";
import { MyidComponent } from "src/app/pages/auth/myid/myid.component";
import { ResetPasswordComponent } from "src/app/pages/auth/reset-password/reset-password.component";
import { KycVerificationComponent } from "src/app/pages/kyc-verification/kyc-verification.component";
import { LandingComponent } from "src/app/pages/landing/landing.component";
import { MyidRedirectComponent } from "src/app/pages/myid-redirect/myid-redirect.component";
import { ProfileComponent } from "src/app/pages/profile/profile.component";
import { VerificationComponent } from "src/app/pages/verification/verification.component";
import { TransactionPageComponent } from "src/app/pages/wallet/transaction-page/transaction-page.component";
import { TransactionsComponent } from "src/app/pages/wallet/transactions/transactions.component";
import { WalletComponent } from "src/app/pages/wallet/wallet.component";
import { RecaptchaProviders } from "./common/constants/recaptcha-providers.constants";
import { AutofocusDirective } from "./common/directives/autofocus.directive";
import { DateInputMaskDirective } from "./common/directives/date-input-mask.directive";
import { MatchDropdownWidthDirective } from "./common/directives/match-dropdown-width.directive";
import { NoScrollInputDirective } from "./common/directives/no-input-scroll.directive";
import { PositiveDecimalsDirective } from "./common/directives/positive-decimals.directive";
import { PositiveNumbersDirective } from "./common/directives/positive-numbers.directive";
import { CurrencyNamePipe } from "./common/pipes/currency-name.pipe";
import { SafePipe } from "./common/pipes/safe.pipe";
import {
  CustomNgbDateParserFormatter,
  CustomNgbDatepickerI18n,
} from "./common/providers/ngb-custom-date-formatter.provider";
import { ControlPanelBlockComponent } from "./components/_admin/control-panel-block/control-panel-block.component";
import { DeactivateUserModalComponent } from "./components/_admin/deactivate-user-modal/deactivate-user-modal.component";
import { DeleteUserKycModalComponent } from "./components/_admin/delete-user-kyc-modal/delete-user-kyc-modal.component";
import { MinBalanceBlockComponent } from "./components/_admin/min-balance-block/min-balance-block.component";
import { MultiplierBlockComponent } from "./components/_admin/multiplier-block/multiplier-block.component";
import { RolesBlockComponent } from "./components/_admin/roles-block/roles-block.component";
import { ScheduleBlockComponent } from "./components/_admin/schedule-block/schedule-block.component";
import { TronEnergyBlockComponent } from "./components/_admin/tron-energy-block/tron-energy-block.component";
import { BulletRowComponent } from "./components/_shared/bullet-row/bullet-row.component";
import { CurrencyIconComponent } from "./components/_shared/currency-icon/currency-icon.component";
import { DatepickerComponent } from "./components/_shared/datepicker/datepicker.component";
import { FractionalNumberComponent } from "./components/_shared/fractional-number/fractional-number.component";
import { OtpInputComponent } from "./components/_shared/otp-input/otp-input.component";
import { StatusMarkComponent } from "./components/_shared/status-mark/status-mark.component";
import { StepperComponent } from "./components/_shared/stepper/stepper.component";
import { SvgIconComponent } from "./components/_shared/svg-icon/svg-icon.component";
import { AddBankCardModalComponent } from "./components/add-bank-card-modal/add-bank-card-modal.component";
import { BankCardComponent } from "./components/bank-card/bank-card.component";
import { BuySellModalComponent } from "./components/buy-sell-modal/buy-sell-modal.component";
import { ClientSidebarComponent } from "./components/client-sidebar/client-sidebar.component";
import { MenuLinkButtonComponent } from "./components/client-sidebar/menu-link-button/menu-link-button.component";
import { CreatePinModalComponent } from "./components/create-pin-modal/create-pin-modal.component";
import { CalculatorTokenButtonComponent } from "./components/currency-calculator/calculator-token-button/calculator-token-button.component";
import { CurrencyCalculatorComponent } from "./components/currency-calculator/currency-calculator.component";
import { DeleteBankCardModalComponent } from "./components/delete-bank-card-modal/delete-bank-card-modal.component";
import { CurrencyDropdownComponent } from "./components/dropdowns/currency-dropdown/currency-dropdown.component";
import { NetworkDropdownComponent } from "./components/dropdowns/network-dropdown/network-dropdown.component";
import { EnterPinModalComponent } from "./components/enter-pin-modal/enter-pin-modal.component";
import { FaqAccordionComponent } from "./components/faq-accordion/faq-accordion.component";
import { LogoComponent } from "./components/header/logo/logo.component";
import { LandingCommissionComponent } from "./components/landing-commission/landing-commission.component";
import { MyidModalComponent } from "./components/myid-modal/myid-modal.component";
import { PasswordCheckerComponent } from "./components/password-checker/password-checker.component";
import { ReceiveModalComponent } from "./components/receive-modal/receive-modal.component";
import { ResetPasswordModalComponent } from "./components/reset-password-modal/reset-password-modal.component";
import { SelectAvatarModalComponent } from "./components/select-avatar-modal/select-avatar-modal.component";
import { SendModalComponent } from "./components/send-modal/send-modal.component";
import { SwapModalComponent } from "./components/swap-modal/swap-modal.component";
import { ThemeSelectorComponent } from "./components/theme-selector/theme-selector.component";
import { TokenGrowthComponent } from "./components/token-growth/token-growth.component";
import { ChangePasswordModalComponent } from "./components/user-settings-modal/change-password-modal/change-password-modal.component";
import { ChangePincodeComponent } from "./components/user-settings-modal/change-pincode/change-pincode.component";
import { DeleteAccountComponent } from "./components/user-settings-modal/delete-account/delete-account.component";
import { UserSettingsModalComponent } from "./components/user-settings-modal/user-settings-modal.component";
import { AssetChartItemComponent } from "./components/wallet/available-assets/asset-chart-item/asset-chart-item.component";
import { AvailableAssetsComponent } from "./components/wallet/available-assets/available-assets.component";
import { BalanceChartComponent } from "./components/wallet/balance-chart/balance-chart.component";
import { BalanceRowComponent } from "./components/wallet/balance-row/balance-row.component";
import { LatestTransactionsComponent } from "./components/wallet/latest-transactions/latest-transactions.component";
import { EventListenerModule } from "./event-listener-module/event-listener.module";
import { AdminAmlComponent } from "./pages/admin/admin-aml/admin-aml.component";
import { ReportsComponent } from "./pages/admin/admin-reports/reports.component";
import { AdminRevenueComponent } from "./pages/admin/admin-revenue/admin-revenue.component";
import { SecurityComponent } from "./pages/admin/admin-security/security.component";
import { AssetPageComponent } from "./pages/assets/asset-page/asset-page.component";
import { AssetsComponent } from "./pages/assets/assets.component";
import { LogoutComponent } from "./pages/auth/logout/logout.component";
import { BlackListComponent } from "./pages/black-list/black-list.component";
import { BuySellComponent } from "./pages/buy-sell/buy-sell.component";
import { CalculatorComponent } from "./pages/calculator/calculator.component";
import { CardsComponent } from "./pages/cards/cards.component";
import { CommissionsComponent } from "./pages/commissions/commissions.component";
import { DepositaryComponent } from "./pages/depositary/depositary.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { CarouselIndicatorComponent } from "./pages/landing/carousel-indicator/carousel-indicator.component";
import { CarouselSlideComponent } from "./pages/landing/carousel-slide/carousel-slide.component";
import { SignInModalComponent } from "./pages/landing/modals/sign-in-modal/sign-in-modal.component";
import { SignUpModalComponent } from "./pages/landing/modals/sign-up-modal/sign-up-modal.component";
import { NewMyIdVerificationComponent } from "./pages/new-my-id-verification/new-my-id-verification.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { TmaToMyIdRedirectComponent } from "./pages/tma-to-my-id-redirect/tma-to-my-id-redirect.component";

export function HttpLoaderFactory(handler: HttpBackend) {
  const http = new HttpClient(handler);
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BaseAuthFormComponent,
    KycComponent,
    ProfileComponent,
    DropdownComponent,
    WalletComponent,
    ClientLayoutComponent,
    CopyBtnComponent,
    TransactionListItemComponent,
    BalanceCardComponent,
    BackButtonComponent,
    TransactionsComponent,
    TransactionPageComponent,
    LoaderComponent,
    InputComponent,
    StringSwapperComponent,
    SelectComponent,
    StatusWindowComponent,
    MfeCustomPipe,
    ToastComponent,
    AdminLoginComponent,
    AdminComponent,
    AdminSidebarComponent,
    AdminKycComponent,
    KycRecordComponent,
    AdminHomeComponent,
    AdminLayoutComponent,
    FooterComponent,
    RateBlockComponent,
    CommissionBlockComponent,
    LimitsBlockComponent,
    SwitchComponent,
    AdminTransactionsComponent,
    AdminUsersComponent,
    UserPageComponent,
    AdminAdminsComponent,
    LanguageSelectorComponent,
    UserTransactionsComponent,
    CustomDatePipe,
    PhoneDisplayerPipe,
    TabsComponent,
    SkeletonComponent,
    TransactionListItemSkeletonComponent,
    LandingComponent,
    VerificationComponent,
    MyidComponent,
    KycVerificationComponent,
    MyidRedirectComponent,
    CryptoTransactionListItemComponent,
    ResetPasswordComponent,
    OnlyNumbersDirective,
    CardsComponent,
    LandingCommissionComponent,
    ReportsComponent,
    SecurityComponent,
    SelectAvatarModalComponent,
    BlackListComponent,
    TokenGrowthComponent,
    CurrencyCalculatorComponent,
    CurrencyIconComponent,
    CalculatorTokenButtonComponent,
    FaqAccordionComponent,
    PageNotFoundComponent,
    ResetPasswordModalComponent,
    PasswordCheckerComponent,
    ChangePasswordModalComponent,
    MyidModalComponent,
    AutofocusDirective,
    ClientSidebarComponent,
    SvgIconComponent,
    AddBankCardModalComponent,
    StepperComponent,
    ReceiveModalComponent,
    SendModalComponent,
    CommissionsComponent,
    CalculatorComponent,
    FaqComponent,
    BuySellComponent,
    BuySellModalComponent,
    BankCardComponent,
    NoScrollInputDirective,
    PositiveNumbersDirective,
    SafePipe,
    MenuLinkButtonComponent,
    DepositaryComponent,
    PositiveDecimalsDirective,
    LogoutComponent,
    MultiplierBlockComponent,
    TmaToMyIdRedirectComponent,
    NewMyIdVerificationComponent,
    CarouselIndicatorComponent,
    CarouselSlideComponent,
    CurrencyDropdownComponent,
    BalanceRowComponent,
    SignInModalComponent,
    SignUpModalComponent,
    DeleteBankCardModalComponent,
    UserSettingsModalComponent,
    NetworkDropdownComponent,
    SwapModalComponent,
    ThemeSelectorComponent,
    LogoComponent,
    DateInputMaskDirective,
    ControlPanelBlockComponent,
    DeleteUserKycModalComponent,
    DeactivateUserModalComponent,
    BalanceChartComponent,
    AvailableAssetsComponent,
    LatestTransactionsComponent,
    FractionalNumberComponent,
    RolesBlockComponent,
    BulletRowComponent,
    MatchDropdownWidthDirective,
    DatepickerComponent,
    AssetsComponent,
    CurrencyNamePipe,
    OtpInputComponent,
    StatusMarkComponent,
    AssetChartItemComponent,
    DeleteAccountComponent,
    ScheduleBlockComponent,
    MinBalanceBlockComponent,
    AssetPageComponent,
    AdminAmlComponent,
    TronEnergyBlockComponent,
    AdminRevenueComponent,
    ChangePincodeComponent,
    EnterPinModalComponent,
    CreatePinModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
      defaultLanguage: LanguageConstants.RUSSIAN,
    }),
    NgxMaskModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    RecaptchaModule,
    BrowserAnimationsModule,
    EventListenerModule,
    NgChartsModule,
    InfiniteScrollModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    tokenInterceptorProviders,
    RecaptchaProviders,
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomNgbDatepickerI18n },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
