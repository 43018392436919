<form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()" class="flex flex-col gap-y-4">
  <app-input
    type="password"
    [title]="'Password_change.Old_password' | translate"
    [placeholder]="'Password_change.Enter_old_password' | translate"
    formControlName="oldPsw"
    id="oldPsw"
    [autocomplete]="'off'"
    [errorMsg]="getFieldHasErrors('oldPsw')"
  />
  <app-input
    type="text"
    [title]="'Password_change.New_password' | translate"
    [placeholder]="'Password_change.Enter_new_password' | translate"
    formControlName="newPsw"
    id="newPsw"
    [autocomplete]="'off'"
    [errorMsg]="getFieldHasErrors('newPsw')"
  />
  <app-password-checker
    *ngIf="getFieldHasErrors('newPsw')"
    [password]="changePasswordForm.get('newPsw')?.value"
    [confirmPassword]="changePasswordForm.get('pswRepeat')?.value"
  />

  <app-input
    type="text"
    [title]="'Password_change.Repeat_new_password' | translate"
    [placeholder]="'Password_change.Enter_new_password' | translate"
    formControlName="pswRepeat"
    id="pswRepeat"
    [autocomplete]="'off'"
    [errorMsg]="getFieldHasErrors('pswRepeat')"
  />

  <p *ngIf="businessError" class="text-sm font-medium text-invalid-color">{{ businessError }}</p>

  <button
    type="submit"
    class="btn-primary btn-large w-full"
    [disabled]="changePasswordForm.invalid || isPending"
  >
    {{ "Password_change.Change_password" | translate }}
  </button>
</form>

<ng-template #successModal let-modal>
  <div class="space-y-8">
    <div class="flex items-center justify-between p-3">
      <h3 class="title">{{ "Password_change.Success" | translate }}</h3>
      <button class="btn-flat h-6 w-6" (click)="modal.dismiss()">
        <app-svg-icon name="close" class="block" />
      </button>
    </div>
    <div>
      <app-status-mark variant="success" icon="check" class="mx-auto block" />
      <p class="mt-4 text-center text-xl font-semibold text-green-500 dark:text-green-200">
        {{ "Password_change.Password_changed" | translate }}
      </p>
    </div>
    <button class="btn-primary-dark btn-large w-full" (click)="modal.dismiss()">
      {{ "Password_change.Back" | translate }}
    </button>
  </div>
</ng-template>
