import { Component, Input, SimpleChanges, ViewChild } from "@angular/core";
import { ChartConfiguration } from "chart.js";
import { format, subDays } from "date-fns";
import { BaseChartDirective } from "ng2-charts";
import { BalanceDto } from "src/app/common/DTO/balances/balance.dto";
import { RateHistoryDto } from "src/app/common/DTO/rate-history";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { getCurrencyNetwork } from "src/app/common/utils/currency-network-helper.util";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { RateHistoriesService } from "src/app/services/rate-histories.service";
import { ThemeService } from "src/app/services/theme.service";

@Component({
  selector: "app-asset-chart-item",
  templateUrl: "./asset-chart-item.component.html",
  styleUrls: ["./asset-chart-item.component.css"],
})
export class AssetChartItemComponent {
  @Input() balance: BalanceDto = new BalanceDto();
  @Input() currentRate: RateDto | null = null;
  @Input() period: number = 7;

  public isLoading = true;
  public CryptoSymbol = CryptoSymbol;
  public theme: "light" | "dark" = "light";

  private rateHistory: RateHistoryDto[] = [];

  public chartData: ChartConfiguration["data"] = {
    datasets: [
      {
        data: [],
        borderColor: "#047a57",
        backgroundColor: "#047a57",
      },
    ],
    labels: [],
  };

  public chartOptions: ChartConfiguration["options"] = {
    elements: {
      line: {
        borderWidth: 1,
      },
      point: {
        radius: 0,
        hitRadius: 10,
      },
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    aspectRatio: 3,
  };

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  constructor(
    private _rateHistoriesService: RateHistoriesService,
    private _themeService: ThemeService
  ) {
    this._themeService.theme$.subscribe(theme => {
      this.theme = theme;
      this.setChartColor();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["period"]) {
      this.loadRateHistory();
    }
  }

  public getCurrencyNetworkName(currency: CryptoSymbol) {
    const network = getCurrencyNetwork(currency);
    return getNetworkName(network);
  }

  public get ratePercentChange() {
    const data = this.chartData.datasets[0].data;

    if (data.length === 0) {
      return 0;
    }

    const lastRate = data[data.length - 1];
    const firstRate = data[0];

    const lastRateValue = Number(lastRate ?? 0);
    const firstRateValue = Number(firstRate ?? 0);

    if (firstRateValue === 0) {
      return 0;
    }

    const percentChange = ((lastRateValue - firstRateValue) / firstRateValue) * 100;

    return percentChange;
  }

  private async loadRateHistory() {
    this.isLoading = true;

    const toDate = new Date();
    const fromDate = subDays(toDate, this.period);

    const toDateStr = toDate.toISOString();
    const fromDateStr = fromDate.toISOString();

    const res = await this._rateHistoriesService.getRateHistory(
      this.balance.currency,
      fromDateStr,
      toDateStr
    );
    if (res.withError) {
      this.isLoading = false;
      return;
    }
    this.rateHistory = res.params ?? [];
    this.updateChartData();
    this.isLoading = false;
  }

  private updateChartData(): void {
    if (this.rateHistory.length === 0) {
      return;
    }

    const values = this.rateHistory.map((item, idx) => {
      // If the last item, return the current rate
      if (idx === this.rateHistory.length - 1) {
        return this.currentRate?.usd ?? 0;
      }
      return item.usdValue;
    });
    const labels = this.rateHistory.map(item => {
      const date = new Date(item.date);
      return format(date, "MMM d");
    });

    this.chartData.datasets[0].data = values;
    this.chartData.labels = labels;

    if (this.chart) {
      this.chart.update();
    }

    this.setChartColor();
  }

  private setChartColor() {
    const isPositiveTrend = this.ratePercentChange >= 0;
    let chartColor = "";

    if (isPositiveTrend) {
      chartColor = this.theme === "dark" ? "#77c7af" : "#047a57";
    } else {
      chartColor = this.theme === "dark" ? "#f29494" : "#c73a3a";
    }

    this.chartData.datasets[0].borderColor = chartColor;
    this.chartData.datasets[0].backgroundColor = chartColor;

    if (this.chart) {
      this.chart.update();
    }
  }
}
