import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { UserErrorCode } from "src/app/common/enums/user-error-code.enum";
import { EventData } from "src/app/common/models/event-data";
import { EventBusService } from "src/app/services/event-bus.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ToastService } from "src/app/services/toast.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-delete-account",
  templateUrl: "./delete-account.component.html",
  styleUrls: ["./delete-account.component.css"],
})
export class DeleteAccountComponent {
  @Input() userId: number | null = null;

  @Output() onSuccess = new EventEmitter<void>();
  @Output() onBack = new EventEmitter<void>();

  @ViewChild("successModal") successModal!: TemplateRef<any>;

  deleteAccountForm: FormGroup;
  isPending = false;

  constructor(
    private _translateService: TranslateService,
    private _modalService: NgbModal,
    private _userService: UserService,
    private _toastService: ToastService,
    private _eventBusService: EventBusService,
    private _localStorage: LocalStorageService,
    private _routes: Router
  ) {
    this.deleteAccountForm = new FormGroup({
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(ValidateConstants.MinPswLength),
        Validators.maxLength(ValidateConstants.MaxPswLength),
      ]),
    });
  }

  async onDeleteAccount() {
    if (this.deleteAccountForm.invalid || !this.userId) {
      return;
    }

    this.isPending = true;
    const res = await this._userService.deleteMe();
    this.isPending = false;

    if (res.withError) {
      if (res.errorCode === UserErrorCode.UserNotFound) {
        this._toastService.error(this._translateService.instant("Delete_account.User_not_found"));
      } else {
        this._toastService.error(this._translateService.instant("Common.Unknown_error"));
      }
    } else {
      this.openSuccessModal();
    }
  }

  getFieldHasErrors(fieldName: string) {
    const field = this.deleteAccountForm.get(fieldName);

    if (field?.value === null) {
      return null;
    }

    if (field?.invalid) {
      return this._translateService.instant("Login.Wrong_password");
    } else {
      return null;
    }
  }

  openSuccessModal() {
    const modal = this._modalService.open(this.successModal, {
      backdropClass: "!bg-transparent",
    });
    this.onSuccess.emit();

    // Send event when modal is closed
    modal.result.then(
      () => {
        this.onBack.emit();
      },
      () => {
        this.onBack.emit();
      }
    );
  }

  async onExit() {
    this._eventBusService.dispatch(new EventData(EventTypeConstants.Logout));
    await this._localStorage.clearTokens();
    await this._localStorage.removeUserData();
    this._routes.navigateByUrl("/");
  }
}
