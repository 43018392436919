import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Constants } from "../common/constants/constants";
import { UrnConstants } from "../common/constants/urn.constants";
import { ApiResponseDto } from "../common/DTO/api-response.dto";
import { BalanceDto } from "../common/DTO/balances/balance.dto";
import { HttpResultDto } from "../common/DTO/http-result.dto";
import { BalancesErrorCode } from "../common/enums/balances-error-code.enum";
import { ErrorParserUtil } from "../common/utils/error-parser.util";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class BalancesService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _envService: EnvService
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async getBalances() {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetBalances}`;
    const result = new HttpResultDto<BalancesErrorCode, BalanceDto[] | null>(false);

    try {
      const response = (await firstValueFrom(this._httpClient.get(uri))) as ApiResponseDto<BalanceDto[]>;
      result.params = response.data;
    } catch (e) {
      result.withError = true;

      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): BalancesErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return BalancesErrorCode.InternalError;
      case Constants.Unauthorized:
        return BalancesErrorCode.Unauthorized;
      default:
        return BalancesErrorCode.InternalError;
    }
  }
}
