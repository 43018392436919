export enum WalletErrorCode {
  Unauthorized = 1,
  InternalError = 2,
  BlockchainError = 3,
  SendLimitReached = 4,
  SendDisabled = 5,
  InvalidAddress = 6,
  NotEnoughCurrency = 7,
  LessThanMinSendAmount = 8,
}
