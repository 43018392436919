<div class="max-w-xl px-15 py-7">
  <h2 class="title-modal">
    {{ "Admin.Users.Delete_confirmation_title" | translate: { user: userFullName } }}
  </h2>

  <div class="mt-5 grid grid-cols-2 gap-x-4">
    <button class="btn-primary-dark btn-medium" (click)="onClose()" [disabled]="isPending">
      {{ "Common.No" | translate }}
    </button>
    <button class="btn-primary-danger btn-medium" (click)="onDelete()" [disabled]="isPending">
      {{ "Common.Yes" | translate }}
    </button>
  </div>
</div>
