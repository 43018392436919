<div class="relative mx-auto flex aspect-square w-[10rem] items-center justify-center">
  <ng-container *ngIf="variant === 'success'">
    <span class="absolute inset-0 rounded-full bg-green-500/25 dark:bg-green-200/25"></span>
    <span class="absolute inset-4 rounded-full bg-green-500/50 dark:bg-green-200/50"></span>
    <span class="absolute inset-8 rounded-full bg-green-500 dark:bg-green-200"></span>
  </ng-container>

  <ng-container *ngIf="variant === 'error'">
    <span class="absolute inset-0 rounded-full bg-red-500/25 dark:bg-red-200/25"></span>
    <span class="absolute inset-4 rounded-full bg-red-500/50 dark:bg-red-200/50"></span>
    <span class="absolute inset-8 rounded-full bg-red-500 dark:bg-red-200"></span>
  </ng-container>

  <ng-container *ngIf="variant === 'warning'">
    <span class="dark:bg-yellow-200/25 absolute inset-0 rounded-full bg-yellow-500/25"></span>
    <span class="dark:bg-yellow-200/50 absolute inset-4 rounded-full bg-yellow-500/50"></span>
    <span class="dark:bg-yellow-200 absolute inset-8 rounded-full bg-yellow-500"></span>
  </ng-container>

  <app-svg-icon [name]="icon" class="z-10 w-12 text-white" />
</div>
