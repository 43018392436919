import { animate, AUTO_STYLE, state, style, transition, trigger } from "@angular/animations";
import { Component } from "@angular/core";

@Component({
  templateUrl: "./admin-revenue.component.html",
  styleUrls: ["./admin-revenue.component.css"],
  animations: [
    trigger("openClose", [
      state("true", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state("false", style({ height: "0", visibility: "hidden" })),
      transition("false => true", animate("200ms")),
      transition("true => false", animate("200ms")),
    ]),
  ],
})
export class AdminRevenueComponent {
  public isAssetsVisible = true;
  public isIncomeVisible = true;

  constructor() {}

  public toggleAssetsVisibility() {
    this.isAssetsVisible = !this.isAssetsVisible;
  }

  public toggleIncomeVisibility() {
    this.isIncomeVisible = !this.isIncomeVisible;
  }
}
