import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { DepositLimitDto } from "src/app/common/DTO/deposit-limit";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { DepositLimitsErrorCode } from "src/app/common/enums/deposit-limit-error-code.enum";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class DepositLimitsService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async getDepositLimits() {
    const uri = `${this._envService.serverUrl}${UrnConstants.DepositLimitsAll}`;
    const result = new HttpResultDto<DepositLimitsErrorCode, DepositLimitDto[]>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri, this._baseReqOpts))) as ApiResponseDto<
        DepositLimitDto[]
      >;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async updateDepositLimit(amount: number, currency: CryptoSymbol) {
    const params = `?Amount=${amount}&Currency=${currency}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.DepositLimits}${params}`;
    const result = new HttpResultDto<DepositLimitsErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.put(uri, null, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): DepositLimitsErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return DepositLimitsErrorCode.InternalError;
      case Constants.Unauthorized:
        return DepositLimitsErrorCode.Unauthorized;
      default:
        return DepositLimitsErrorCode.InternalError;
    }
  }
}
