<div class="absolute top-8">
  <h1 class="title">
    {{ "Admin.Users.Title" | translate }} <span class="ml-2 text-slate-400">{{ totalCount }}</span>
  </h1>
</div>

<section class="rounded-2xl bg-gray-25 py-6 dark:bg-slate-800">
  <div class="flex items-center justify-between px-6">
    <app-dropdown
      [items]="userIdentificationFilterNames"
      [value]="selectedIdentificationFilterName"
      (onSelect)="onIdentificationFilterSelect($event)"
      [widthAsToggle]="false"
      class="block w-[17rem]"
    >
      <button class="dropdown-base-toggle w-full">
        {{ userFilterNameByIdentificationOption(selectedIdentificationFilter) }}
        <app-svg-icon name="chevron" class="block w-3" />
      </button>
    </app-dropdown>

    <app-input
      type="search"
      name="userSearch"
      id="userSearch"
      placeholder="{{ 'Admin.Users.Search' | translate }}"
      class="block w-[17rem] [&_input]:py-2.5"
      [(ngModel)]="search"
      (ngModelChange)="$search.next($event)"
    />
  </div>

  <!-- <div class="grid grid-cols-3">
    <div>
      <app-dropdown [items]="userActivationFilterNames" (onSelect)="onActivationFilterSelect($event)">
        <button class="flex items-center gap-2">
          <p class="flex w-[12rem] justify-items-start">
            {{ "Admin.Users.Activity" | translate }}:
            {{ userFilterNameByActivationOption(selectedActivationFilter) }}
          </p>
          <img src="assets/icons/arrow.svg" alt="lang-arrow" class="w-3" />
        </button>
      </app-dropdown>
    </div>
  </div> -->

  <div
    class="mt-6"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="loadMoreUsers()"
  >
    <table class="adminTable">
      <thead>
        <tr>
          <th scope="col">{{ "Admin.Users.Phone_number" | translate }}</th>
          <th scope="col">{{ "Admin.Users.Status" | translate }}</th>
          <th scope="col">{{ "Admin.Users.Balance" | translate }}</th>
          <!-- <th scope="col">{{ "Admin.Users.Activity" | translate }}</th> -->
          <th scope="col">{{ "Admin.Users.Registration_date" | translate }}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let user of users"
          [ngClass]="{ 'pointer-events-none opacity-60': isUserActionsInProgress }"
        >
          <td>{{ user.phoneNumber | phoneDisplayer }}</td>
          <td>{{ renderStatus(user) | translate }}</td>
          <td>{{ getUsdtBalanceByUser(user) | mfeCustom: { fiat: true } }} USDT</td>
          <!-- <td
            [ngClass]="{
              'text-green-500 dark:text-green-200': user.isActive,
              'text-red-500 dark:text-red-200': !user.isActive
            }"
          >
            {{ isActiveCaption(user) }}
          </td> -->
          <td class="whitespace-pre">
            {{ user.createdAt | customDate: "full" }}
          </td>

          <td class="flex items-center gap-x-2">
            <div ngbDropdown class="dropdown">
              <button
                ngbDropdownToggle
                class="btn-flat h-6 w-6 rounded bg-gray-300 text-text-primary disabled:cursor-not-allowed disabled:opacity-50 dark:bg-slate-500 dark:text-white"
                [disabled]="!hasInteractionPermissions"
              >
                <app-svg-icon name="actions/menu-dots" class="block h-4 w-4" />
              </button>
              <div ngbDropdownMenu class="space-y-3 !bg-gray-100 py-2 dark:!bg-slate-700">
                <button
                  ngbDropdownItem
                  (click)="handleDownloadReport(user)"
                  [disabled]="!hasInteractionPermissions || !user.kyc"
                >
                  <app-svg-icon name="admin/download" class="aspect-square w-5" />
                  <span>{{ "Admin.Users.Download_user_data" | translate }}</span>
                </button>
                <button
                  ngbDropdownItem
                  (click)="openDeleteKycConfirmation(user)"
                  [disabled]="!hasInteractionPermissions || !user.kyc || !user.isActive"
                >
                  <app-svg-icon name="trash-bin" class="aspect-square w-5" />
                  <span>{{ "Admin.Users.Delete_identification" | translate }}</span>
                </button>
                <button
                  ngbDropdownItem
                  (click)="openDeactivateUserConfirmation(user)"
                  [disabled]="!hasInteractionPermissions || !user.isActive"
                >
                  <app-svg-icon name="admin/close-circle" class="aspect-square w-5" />
                  <span>{{ "Admin.Users.Deactivate_account" | translate }}</span>
                </button>
                <button
                  ngbDropdownItem
                  (click)="handleBlockUnblock(user)"
                  [disabled]="!hasInteractionPermissions"
                >
                  <app-svg-icon name="admin/lock" class="aspect-square w-5" />
                  <span>
                    {{
                      user.blackList ? ("Admin.Users.Unblock" | translate) : ("Admin.Users.Block" | translate)
                    }}
                  </span>
                </button>
              </div>
            </div>

            <a
              class="btn-flat h-6 w-6 rounded bg-gray-300 text-text-primary dark:bg-slate-500 dark:text-white"
              [routerLink]="user.id.toString()"
              [title]="'Admin.Users.Open_user_page' | translate"
            >
              <app-svg-icon name="actions/back" class="block h-4 w-4 rotate-180" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
