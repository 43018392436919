<table class="adminTable">
  <thead>
    <tr>
      <th scope="col">{{ "Admin.Roles.Name" | translate }}</th>
      <th scope="col" *ngFor="let permission of permissionsList">{{ permission.name | translate }}</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let role of roles">
      <td>{{ role.name }}</td>
      <td *ngFor="let permission of permissionsList">
        <app-switch
          [ngModel]="getSwitchIsChecked(role.id, permission.value)"
          (ngModelChange)="handleSwitchChange($event, role.id, permission.value)"
          [disabled]="!canPermissionBeChanged(role, permission.value)"
        />
      </td>
      <td class="flex justify-end">
        <button
          class="flex h-6 w-6 items-center justify-center rounded bg-gray-300 text-white hover:text-red-400 disabled:cursor-not-allowed disabled:opacity-50 dark:bg-slate-400"
          title="{{ 'Admin.Roles.Delete_role' | translate }}"
          (click)="handleDeleteRole(role.id)"
          [disabled]="!hasInteractionPermissions"
        >
          <app-svg-icon name="admin/trash" class="block h-4 w-4" />
        </button>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #addRoleModal let-modal>
  <div class="flex items-center justify-between p-3">
    <h4 class="title">{{ "Admin.Roles.Add_role" | translate }}</h4>
    <button class="btn-flat" (click)="modal.dismiss()">
      <app-svg-icon name="close" class="block h-6 w-6" />
    </button>
  </div>
  <form class="mt-6 flex flex-col gap-y-4" [formGroup]="addRoleForm" (ngSubmit)="onSubmitAddRole()">
    <app-input type="text" [title]="'Название'" placeholder="Админ" id="addRoleName" formControlName="name" />
    <div class="space-y-6">
      <div
        class="flex items-center justify-between font-medium"
        *ngFor="let permission of addPermissionsList"
      >
        {{ permission.name | translate }}
        <app-switch
          [ngModel]="getSwitchOnAddIsChecked(permission.value)"
          (ngModelChange)="onChangeSwitchOnAdd(permission.value)"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
    </div>
    <button type="submit" class="btn-primary btn-large mt-2" [disabled]="addRoleForm.invalid || isPending">
      {{ "Common.Add" | translate }}
    </button>
  </form>
</ng-template>
