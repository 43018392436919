import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserResolver } from "src/app/common/resolvers/user.resolver";
import { CreatePinModalComponent } from "src/app/components/create-pin-modal/create-pin-modal.component";

@Component({
  templateUrl: "./depositary.component.html",
  styleUrls: ["./depositary.component.css"],
})
export class DepositaryComponent {
  constructor(
    private _modalService: NgbModal,
    private _userResolver: UserResolver
  ) {}

  public async ngOnInit(): Promise<void> {
    const user = await this._userResolver.resolve();

    if (user && !user.havePinCode) {
      this.openCreatePinModal();
    }
  }

  public openCreatePinModal() {
    this._modalService.open(CreatePinModalComponent);
  }
}
