<div class="absolute top-8">
  <h1 class="title font-semibold">{{ "Admin.Security.Terrorists.Title" | translate }}</h1>
</div>

<section class="rounded-2xl bg-gray-25 py-6 dark:bg-slate-800">
  <div class="flex items-center justify-end px-6">
    <div class="flex items-center gap-x-5">
      <input type="file" #fileInput style="display: none" (change)="onFileSelected($event)" />
      <button class="btn-flat" (click)="fileInput.click()" [disabled]="!hasInteractionPermissions">
        <app-svg-icon name="admin/download" class="w-5" />
        {{ "Admin.Security.Terrorists.Import" | translate }}
      </button>

      <button class="btn-flat" (click)="downloadTerroristsCsv()" [disabled]="!hasInteractionPermissions">
        <app-svg-icon name="export-icon" class="w-5" />
        {{ "Admin.Security.Terrorists.Download" | translate }}
      </button>

      <button *ngIf="!showSearch" class="btn-flat" (click)="toggleSearch()">
        <app-svg-icon name="scan-icon" class="w-5" />
        {{ "Admin.Security.Terrorists.Scan" | translate }}
      </button>
      <app-input
        type="search"
        placeholder="Поиск"
        [(ngModel)]="searchTerrorist"
        (ngModelChange)="$searchTerrorist.next($event)"
        [class.hidden]="!showSearch"
        class="[&_input]:py-2"
      />

      <button
        class="btn-primary btn-small"
        (click)="openAddTerrorModal()"
        [disabled]="!hasInteractionPermissions"
      >
        {{ "Admin.Security.Terrorists.Add_terrorist" | translate }}
      </button>
    </div>
  </div>

  <div class="mt-6">
    <table class="adminTable">
      <thead>
        <tr>
          <th scope="col">{{ "Admin.Security.Terrorists.External_id" | translate }}</th>
          <th scope="col">{{ "Admin.Security.Terrorists.Name" | translate }}</th>
          <th scope="col">{{ "Admin.Security.Terrorists.PinFL" | translate }}</th>
          <th scope="col">{{ "Admin.Security.Terrorists.Add_date" | translate }}</th>
          <th scope="col">{{ "Admin.Security.Terrorists.Passport" | translate }}</th>
          <!-- <th scope="col">{{ "Admin.Security.Terrorists.Deleted" | translate }}</th> -->
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody *ngIf="!isLoading; else loader">
        <tr *ngFor="let terrorist of terroristList">
          <td>{{ terrorist.externalId }}</td>
          <td>{{ terrorist.name + " " + terrorist.surname }}</td>
          <td>{{ terrorist.pinfl }}</td>
          <td class="whitespace-nowrap">{{ terrorist.createdAt | customDate: "full" }}</td>
          <td>{{ terrorist.passportSerial + " " + terrorist.passportNumber }}</td>
          <!-- <td>
            {{ terrorist.isDeleted ? ("Common.Yes" | translate) : ("Common.No" | translate) }}
          </td> -->
          <td class="flex items-center justify-end">
            <button
              class="flex h-6 w-6 items-center justify-center rounded bg-gray-300 text-white hover:text-red-400 disabled:cursor-not-allowed disabled:opacity-50 dark:bg-slate-400"
              (click)="openDeleteTerrorModal(terrorist)"
              [disabled]="!hasInteractionPermissions"
            >
              <app-svg-icon name="admin/trash" class="block h-4 w-4" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <ngb-pagination
      [collectionSize]="totalCount"
      [(page)]="page"
      [pageSize]="pageSize"
      [maxSize]="5"
      (pageChange)="onPageChange($event)"
      class="mt-6 block"
    />
  </div>
</section>

<ng-template #loader>
  <td class="w-full space-y-1" colspan="6">
    <app-skeleton *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" class="h-14 w-full"></app-skeleton>
  </td>
</ng-template>

<ng-template #addTerroristModal>
  <div class="flex items-center justify-between p-3">
    <h4 class="title">{{ "Admin.Security.Terrorists.Add_terrorist" | translate }}</h4>
    <button class="btn-flat" (click)="closeAddTerrorModal()">
      <app-svg-icon name="close" class="block h-6 w-6" />
    </button>
  </div>

  <form class="mt-6 flex flex-col gap-y-4" [formGroup]="addTerroristForm" (ngSubmit)="onAddTerroristClick()">
    <app-input
      [title]="'Admin.Security.Terrorists.External_id' | translate"
      [placeholder]="'Admin.Security.Terrorists.External_id' | translate"
      type="number"
      formControlName="externalId"
      id="externalId"
      [errorMsg]="getAddFormErrorMessage('externalId')"
    />
    <app-input
      [title]="'Admin.Security.Terrorists.Name' | translate"
      [placeholder]="'Admin.Security.Terrorists.Name' | translate"
      type="text"
      formControlName="name"
      id="terroristName"
      [errorMsg]="getAddFormErrorMessage('name')"
    />
    <app-input
      [title]="'Admin.Security.Terrorists.Last_name' | translate"
      [placeholder]="'Admin.Security.Terrorists.Last_name' | translate"
      type="text"
      formControlName="surname"
      id="terroristSurname"
      [errorMsg]="getAddFormErrorMessage('surname')"
    />
    <app-input
      [title]="'Admin.Security.Terrorists.Terror_pinFL' | translate"
      [placeholder]="'Admin.Security.Terrorists.Terror_pinFL' | translate"
      type="text"
      formControlName="pinFl"
      id="terroristPinFl"
      [errorMsg]="getAddFormErrorMessage('pinFl')"
    />
    <app-input
      [title]="'Admin.Security.Terrorists.Passport_serial' | translate"
      [placeholder]="'Admin.Security.Terrorists.Passport_serial' | translate"
      type="text"
      formControlName="passportSerial"
      id="terroristPassportSerial"
      [errorMsg]="getAddFormErrorMessage('passportSerial')"
    />
    <app-input
      [title]="'Admin.Security.Terrorists.Passport_number' | translate"
      [placeholder]="'Admin.Security.Terrorists.Passport_number' | translate"
      type="number"
      formControlName="passportNumber"
      id="terroristPassportNumber"
      [errorMsg]="getAddFormErrorMessage('passportNumber')"
    />

    <button
      type="submit"
      class="btn-primary btn-large mt-2"
      [disabled]="addTerroristForm.invalid || isPending"
    >
      {{ "Common.Add" | translate }}
    </button>
  </form>
</ng-template>

<ng-template #deleteTerroristModal>
  <div class="flex items-center justify-between p-3">
    <h4 class="title">{{ "Admin.Security.Terrorists.Delete_terrorist" | translate }}</h4>
    <button class="btn-flat" (click)="closeDeleteTerrorModal()">
      <app-svg-icon name="close" class="block h-6 w-6" />
    </button>
  </div>
  <div class="mt-6 flex flex-col gap-y-4">
    <app-status-mark variant="error" icon="admin/trash" />
    <p class="text-center text-xl text-text-secondary">
      {{ terroristToDelete?.name + " " + terroristToDelete?.surname }} <br />
      {{ "Admin.Security.Terrorists.Add_date" | translate }}
      {{ terroristToDelete?.createdAt! | customDate: "full" }}
    </p>
    <div
      class="w-full rounded-2xl border border-gray-100 bg-yellow-100 p-4 text-sm font-semibold text-yellow-500"
    >
      {{ "Admin.Security.Terrorists.Delete_description" | translate }}
    </div>
  </div>
  <button
    class="btn-primary-danger btn-large mt-8 w-full"
    (click)="onDeleteTerroristClick()"
    [disabled]="isPending"
  >
    {{ "Common.Delete" | translate }}
  </button>
</ng-template>

<!-- <jw-modal [id]="ModalConstants.AdminGetScannedTerrors" [bodyWidth]="'w-[44rem]'">
  <span class="text-xl">{{ "Admin.Security.Terrorists.Scanned_terrors_title" | translate }}</span>
  <table class="adminTable mb-3">
    <thead>
      <tr>
        <th scope="col">{{ "Admin.Security.Terrorists.User_fullname" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.Terror_fullname" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.User_pinFL" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.Terror_pinFL" | translate }}</th>
        <th scope="col">{{ "Admin.Security.Terrorists.Block" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Profile" | translate }}</th>
      </tr>
    </thead>
    <tbody *ngIf="scannedTerrorItems?.length; else noTerrors">
      <tr *ngFor="let scannedTerror of scannedTerrorItems">
        <td>{{ scannedTerror.kycFullName }}</td>
        <td>{{ scannedTerror.terroristFullName }}</td>
        <td>{{ scannedTerror.kycPinFL }}</td>
        <td>{{ scannedTerror.terroristPinFL }}</td>
        <td>
          <app-switch
            [(ngModel)]="switchValues[scannedTerror.userId]"
            (onSwitch)="onSwitchChange(scannedTerror.userId)"
          ></app-switch>
        </td>
        <td>
          <a
            class="h-6 w-6"
            [routerLink]="'/admin/users/' + scannedTerror.userId"
            [title]="'Admin.Users.Open_user_page' | translate"
            target="_blank"
          >
            <img src="assets/icons/link.svg" alt="Accept" />
          </a>
        </td>
      </tr>
    </tbody>
    <ng-template #noTerrors>
      <tr>
        <td colspan="7" class="text-center">
          {{ "Admin.Security.Terrorists.Terror_not_found" | translate }}
        </td>
      </tr>
    </ng-template>
  </table>

  <ngb-pagination
    [collectionSize]="totalCount"
    [(page)]="page"
    [pageSize]="pageSize"
    [maxSize]="3"
    [boundaryLinks]="true"
    (pageChange)="requestScannedTerrors()"
  >
  </ngb-pagination>
</jw-modal> -->
