import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-carousel-indicator",
  templateUrl: "./carousel-indicator.component.html",
  styleUrls: ["./carousel-indicator.component.css"],
})
export class CarouselIndicatorComponent {
  @Input() slideIds: number[] = [];
  @Input() activeSlideId?: number = undefined;
  @Input() class?: string = undefined;

  @Output() select = new EventEmitter<number>();

  handleSelect(slideId: number) {
    this.select.emit(slideId);
  }
}
