import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AdminLoginDto } from "src/app/common/DTO/auth/admin-login.dto";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { AuthErrorCode } from "src/app/common/enums/auth-error-code.enum";
import { AuthService } from "src/app/services/auth.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { UserService } from "src/app/services/user.service";

@Component({
  templateUrl: "./admin-login.component.html",
  styleUrls: ["./admin-login.component.css"],
})
export class AdminLoginComponent {
  private readonly _dto: AdminLoginDto;
  public authError: string = "";
  public isPending = false;

  public adminForm = new FormGroup({
    login: new FormControl("", [Validators.required]),
    psw: new FormControl("", [
      Validators.required,
      Validators.minLength(ValidateConstants.MinPswLength),
      // Validators.maxLength(ValidateConstants.MaxPswLength),
    ]),
  });

  constructor(
    private readonly _authService: AuthService,
    private readonly _router: Router,
    private readonly _translateService: TranslateService,
    private readonly _localStorage: LocalStorageService,
    private readonly _userService: UserService
  ) {
    this._dto = new AdminLoginDto();
  }

  public get _login() {
    return this.adminForm.get("login");
  }

  public get _psw() {
    return this.adminForm.get("psw");
  }

  public async handleLogin() {
    this.authError = "";
    this._dto.login = this._login!.value as string;
    this._dto.psw = this._psw!.value as string;

    this.isPending = true;
    const response = await this._authService.loginAdmin(this._dto);
    this.isPending = false;

    if (!response.withError) {
      await this._localStorage.saveTokens(response.params!);
      const getMeRes = await this._userService.getMe();
      if (getMeRes.withError) {
        await this._localStorage.clearTokens();
        this.authError = "Что-то пошло не так";
        return;
      }

      await this._localStorage.saveUserData(getMeRes.params!);
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminHome}`);
      return;
    }

    if (response.errorCode == AuthErrorCode.IncorrectPhoneOrPsw) {
      this.authError = this._translateService.instant("Login.Wrong_login_or_pass");
      return;
    }

    this.authError = this._translateService.instant("Common.Unknown_error");
  }
}
