import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { PagedListDto } from "src/app/common/DTO/paged-list.dto";
import { CreateTerroristDto, ScannedTerrorDto, TerroristDto } from "src/app/common/DTO/terrorists";
import { TerroristErrorCode } from "src/app/common/enums/terrorist-error-code.enum";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class TerroristService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService
  ) {}

  public async getAllTerrorists(
    index: number,
    size: number,
    searchStr?: string
  ): Promise<HttpResultDto<TerroristErrorCode, PagedListDto<TerroristDto> | null>> {
    const pageParams = `?size=${size}&index=${index}`;
    const searchParams = searchStr ? `&searchStr=${searchStr}` : "";
    const uri = `${this._envService.serverUrl}${UrnConstants.TerroristAll}${pageParams}${searchParams}`;
    const result = new HttpResultDto<TerroristErrorCode, PagedListDto<TerroristDto> | null>(false);

    try {
      const response = (await firstValueFrom(
        this._http.get(uri)
      )) as ApiResponseDto<PagedListDto<TerroristDto> | null>;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async addTerrorist(dto: CreateTerroristDto): Promise<HttpResultDto<TerroristErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.Terrorist}`;
    const result = new HttpResultDto<TerroristErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.post(uri, dto));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async deleteTerrorist(id: number): Promise<HttpResultDto<TerroristErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.Terrorist}/${id}`;
    const result = new HttpResultDto<TerroristErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.delete(uri));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async downloadCsvFile(): Promise<any> {
    const uri = `${this._envService.serverUrl}${UrnConstants.DownloadTerroristsCsv}`;
    this._http
      .get(uri, {
        responseType: "blob",
      })
      .subscribe({
        next: file => {
          return file;
        },
        error: error => {
          return error;
        },
      });
  }

  public async uploadCsvFile(formData: FormData): Promise<HttpResultDto<TerroristErrorCode, number>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.UploadTerroristsCsv}`;
    const result = new HttpResultDto<TerroristErrorCode, number>(false);

    try {
      const response = (await firstValueFrom(this._http.post(uri, formData))) as ApiResponseDto<number>;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async scanTerrorsWithKyc(
    size: number,
    page: number
  ): Promise<HttpResultDto<TerroristErrorCode, PagedListDto<ScannedTerrorDto> | null>> {
    const params = `?size=${size}&index=${page}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.ScanTerrorists}${params}`;
    const result = new HttpResultDto<TerroristErrorCode, PagedListDto<ScannedTerrorDto> | null>(false);

    try {
      const response = (await firstValueFrom(
        this._http.get(uri)
      )) as ApiResponseDto<PagedListDto<ScannedTerrorDto> | null>;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): TerroristErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return TerroristErrorCode.InternalError;
      case Constants.Unauthorized:
        return TerroristErrorCode.Unauthorized;
      case "Terrorist already exist":
        return TerroristErrorCode.AlreadyExist;
      default:
        return TerroristErrorCode.InternalError;
    }
  }
}
