<div class="space-y-2 rounded-2xl bg-gray-100 p-3 dark:bg-slate-700">
  <h4 class="text-xs font-semibold">{{ "Password_change.Password_must_include" | translate }}:</h4>

  <ul class="mt-2 list-none space-y-2">
    <li
      *ngFor="let r of passwordRequirements"
      class="flex items-center gap-x-1"
      [ngClass]="
        getIsPasswordReqValid(r.pattern)
          ? 'text-green-500 line-through dark:text-green-200'
          : 'text-red-500 dark:text-red-200'
      "
    >
      <svg
        *ngIf="getIsPasswordReqValid(r.pattern)"
        class="h-6 w-6"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.2225 15.7237L6.66699 12.1682L7.91144 10.9237L10.2225 13.2348L16.0892 7.36816L17.3337 8.61261L10.2225 15.7237Z"
          fill="currentColor"
        />
      </svg>

      <div
        *ngIf="!getIsPasswordReqValid(r.pattern)"
        class="flex h-6 w-6 shrink-0 items-center justify-center"
      >
        <span class="h-1 w-1 rounded-full bg-red-500 dark:bg-red-200"></span>
      </div>

      <span class="text-sm">{{ r.text | translate }}</span>
    </li>
  </ul>
</div>
