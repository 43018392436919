<app-header />

<div class="container mt-20 flex items-center gap-12 max-md:mt-12 max-md:flex-wrap max-md:gap-8">
  <div class="w-[38rem] shrink-0 max-md:w-[18rem]">
    <img src="assets/images/not-found.png" alt="404" class="aspect-square" />
  </div>

  <div class="max-md:px-8">
    <h2 class="text-[5rem] font-semibold max-md:text-5xl">
      {{ "Not_found.Oops" | translate }}
    </h2>
    <p class="text-[2rem] font-semibold leading-normal text-text-secondary max-md:mt-4 max-md:text-lg">
      {{ "Not_found.Page_not_found" | translate }}
    </p>
    <a routerLink="/" class="btn-primary btn-medium mt-10 w-fit">{{ "Not_found.Main_link" | translate }}</a>
  </div>
</div>
