import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { ThemeService } from "src/app/services/theme.service";

type Settings = "phone" | "password" | "pin" | "email" | "delete";
type SettingsTab = {
  id: Settings;
  title: string;
  icon: string;
};

@Component({
  selector: "app-user-settings-modal",
  templateUrl: "./user-settings-modal.component.html",
  styleUrls: ["./user-settings-modal.component.css"],
})
export class UserSettingsModalComponent {
  @Input() user: UserDto | null = null;

  public SETTINGS_TABS: SettingsTab[] = [
    // { id: "phone", title: "Settings.Phone", icon: "settings/phone" },
    { id: "password", title: "Settings.Password", icon: "settings/lock" },
    { id: "delete", title: "Settings.Delete_account", icon: "settings/delete-account" },
    { id: "pin", title: "Settings.Pin", icon: "settings/pin" },
    // { id: "pin", title: "Settings.Pin", icon: "settings/pin" },
    // { id: "email", title: "Settings.Email", icon: "settings/email" },
  ];
  public currentTab = this.SETTINGS_TABS[0];

  public hideWindow = false;
  public mode: "dark" | "light" = "light";

  constructor(
    private _modalRef: NgbActiveModal,
    private _themeService: ThemeService
  ) {
    this._themeService.theme$.subscribe((theme: "dark" | "light") => {
      this.mode = theme;
    });
  }

  public onClose() {
    this._modalRef.close();
  }

  public onTabSelect(tab: SettingsTab) {
    this.currentTab = tab;
  }
}
