import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";

export function getCurrencyName(currency?: CryptoSymbol) {
  switch (currency) {
    case CryptoSymbol.Usdt:
      return "USDT";
    case CryptoSymbol.Trx:
      return "TRX";
    case CryptoSymbol.Matic:
      return "MATIC";
    case CryptoSymbol.PolygonUsdt:
      return "USDT";
    case CryptoSymbol.Uzs:
      return "UZS";
    case CryptoSymbol.Tiyins:
      return "Tiyins";
    case CryptoSymbol.Ton:
      return "TON";
    case CryptoSymbol.Not:
      return "NOT";
    case CryptoSymbol.TonUsdt:
      return "USDT";
    case CryptoSymbol.Bitcoin:
      return "BTC";
    case CryptoSymbol.AbstractUsdt:
      return "USDT";
    default:
      return "-";
  }
}
