<aside class="sticky top-6 flex h-[calc(100vh-1.5rem)] flex-col p-6 pt-0">
  <app-logo [theme]="theme" class="px-4 py-3" />

  <nav class="mt-6 flex flex-col gap-1">
    <a
      *ngFor="let link of sidebarLinks"
      [routerLink]="link.link"
      class="flex items-center rounded-xl border border-transparent px-4 py-3 text-text-secondary transition-colors hover:border-gray-100 hover:text-text-primary dark:hover:border-slate-700 dark:hover:bg-slate-700 dark:hover:text-white"
      routerLinkActive="bg-gray-100 !text-text-primary dark:bg-slate-700 dark:!text-white"
    >
      <app-svg-icon [name]="link.icon" class="h-6 w-6 shrink-0" />
      <span class="ml-4 overflow-hidden whitespace-nowrap text-base font-semibold">{{
        link.title | translate
      }}</span>
    </a>
    <!-- <a class="btn btn_big btn_pale" routerLink="/admin/kyc" routerLinkActive="btn_active"> KYC </a> -->
  </nav>

  <hr class="mb-6 mt-auto border-gray-100 dark:border-slate-700" />

  <app-theme-selector class="mx-auto w-full" />

  <div class="mt-6 space-y-1">
    <p class="text-lg font-medium">{{ user.phoneNumber }}</p>
    <p class="text-sm font-medium text-text-secondary">{{ userRoleName }}</p>
  </div>

  <button
    class="mt-2 flex items-center gap-x-4 rounded-xl bg-gray-100 px-4 py-3 font-semibold text-text-primary hover:text-red-400 dark:bg-slate-700 dark:text-white dark:hover:text-red-500"
    (click)="onLogout()"
  >
    <app-svg-icon name="menu/exit" class="h-6 w-6 shrink-0" />
    {{ "Header.Logout" | translate }}
  </button>
</aside>
