<ng-container *ngIf="!isLarge; else large">
  <footer
    class="flex items-center gap-x-6 bg-gray-900 px-11.5 py-6 text-sm text-red-25/60 dark:bg-slate-800 max-md:items-start max-md:px-4 max-md:py-3"
  >
    <img src="assets/images/parent-logo-dark.svg" alt="" class="w-16" />
    <span>{{ "Footer.License_1" | translate }}</span>
    <span>{{ "Footer.License_2" | translate }}</span>
  </footer>
</ng-container>

<ng-template #large>
  <footer class="line-gradient-border border-t bg-landingBg">
    <div
      class="flex flex-col gap-y-6 px-4 pb-8 pt-5 md:grid md:grid-cols-4 md:items-start md:gap-y-0 md:px-12 md:pb-15 md:pt-10"
    >
      <div class="flex justify-between gap-y-2 text-sm text-text-secondary/50 md:flex-col md:justify-normal">
        <img src="assets/images/logo-dark.svg" alt="" class="w-32" />
        <div class="space-y-2">
          <span class="block">&copy;Paynet Crypto {{ currentYear }}</span>
          <a href="#" class="block text-end underline md:text-start">{{
            "Footer.Public_offer" | translate
          }}</a>
        </div>
      </div>

      <div class="space-y-4">
        <h3 class="font-medium text-text-secondary/50">{{ "Footer.Contacts" | translate }}</h3>
        <div class="space-y-2 text-text-secondary">
          <a href="#" class="flex w-fit items-center gap-x-2">
            <img src="assets/icons/contacts/phone.svg" alt="" class="h-6 w-6.5" />
            <span>+998 71 2023777</span>
          </a>
          <a href="#" class="flex w-fit items-center gap-x-2">
            <img src="assets/icons/contacts/mail.svg" alt="" class="h-6 w-6.5" />
            <span>info&#64;paynetcrypto.uz</span>
          </a>
          <a href="#" class="flex w-fit items-center gap-x-2">
            <img src="assets/icons/contacts/telegram.svg" alt="" class="h-6 w-6.5" />
            <span>&#64;paynet_crypto_bot</span>
          </a>
        </div>
      </div>

      <div class="space-y-4">
        <h3 class="font-medium text-text-secondary/50">{{ "Footer.Address" | translate }}</h3>
        <span class="flex items-start gap-x-2 text-text-secondary">
          <img src="assets/icons/contacts/location.svg" alt="" class="h-6 w-6.5" />
          <span class="whitespace-pre-line">
            {{ "Footer.Location" | translate }}
          </span>
        </span>
      </div>

      <div class="space-y-4">
        <h3 class="font-medium text-text-secondary/50">{{ "Footer.Socials" | translate }}</h3>
        <div class="flex gap-x-3">
          <a href="#">
            <app-svg-icon
              name="socials/youtube"
              class="block h-9.5 w-9.5 text-text-secondary hover:text-white"
            />
          </a>
          <a href="#">
            <app-svg-icon
              name="socials/facebook"
              class="block h-9.5 w-9.5 text-text-secondary hover:text-white"
            />
          </a>
          <a href="#">
            <app-svg-icon
              name="socials/instagram"
              class="block h-9.5 w-9.5 text-text-secondary hover:text-white"
            />
          </a>
          <a href="#">
            <app-svg-icon
              name="socials/telegram"
              class="block h-9.5 w-9.5 text-text-secondary hover:text-white"
            />
          </a>
          <a href="#">
            <app-svg-icon
              name="socials/linkedin"
              class="block h-9.5 w-9.5 text-text-secondary hover:text-white"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-y-3 px-4 py-6 text-sm text-text-secondary md:flex-row md:gap-x-6 md:px-12">
      <span>{{ "Footer.License_1" | translate }}</span>
      <span>{{ "Footer.License_2" | translate }}</span>
    </div>
  </footer>
</ng-template>
