import { Component, Input } from "@angular/core";

@Component({
  selector: "app-svg-icon",
  templateUrl: "./svg-icon.component.html",
  styleUrls: ["./svg-icon.component.css"],
})
export class SvgIconComponent {
  @Input() public name?: string;

  public get iconPath(): string {
    return `assets/icons/${this.name}.svg`;
  }
}
