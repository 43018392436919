import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { UpdateTronEnergyPanelDto } from "src/app/common/DTO/tron-energy-panel";
import { ToastService } from "src/app/services/toast.service";
import { TronEnergyPanelService } from "src/app/services/tron-energy-panel.service";

@Component({
  selector: "app-tron-energy-block",
  templateUrl: "./tron-energy-block.component.html",
  styleUrls: ["./tron-energy-block.component.css"],
})
export class TronEnergyBlockComponent implements OnInit {
  public tronEnergyForm: FormGroup;
  public isEditing = false;
  public isPending = false;

  constructor(
    private readonly _tronEnergyPanelService: TronEnergyPanelService,
    private readonly _translateService: TranslateService,
    private readonly _toastService: ToastService
  ) {
    this.tronEnergyForm = new FormGroup({
      masterEnergyCount: new FormControl(0, [Validators.required, Validators.min(0)]),
      userEnergyCount: new FormControl(0, [Validators.required, Validators.min(0)]),
    });
  }

  public async ngOnInit() {
    await this.getTronEnergyPanel();
  }

  public get energyCount() {
    return {
      masterEnergyCount: this.tronEnergyForm.get("masterEnergyCount")?.value,
      userEnergyCount: this.tronEnergyForm.get("userEnergyCount")?.value,
    };
  }

  public async toggleEditing() {
    if (this.isEditing) {
      if (this.tronEnergyForm.invalid) return;

      await this.updateTronEnergyPanel();
      this.isEditing = false;
    } else {
      this.isEditing = true;
    }
  }

  public getFieldError(field: string) {
    const formField = this.tronEnergyForm.get(field);

    if (formField?.touched === false) {
      return null;
    }

    if (formField?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (formField?.hasError("min")) {
      return this._translateService.instant("Admin.Home.Min_value_error", {
        value: formField?.errors?.["min"]?.min,
      });
    }
    return null;
  }

  private async getTronEnergyPanel() {
    const result = await this._tronEnergyPanelService.getTronEnergyPanel();
    if (result.params) {
      this.tronEnergyForm.patchValue(result.params);
    }
  }

  private async updateTronEnergyPanel() {
    const dto: UpdateTronEnergyPanelDto = {
      masterEnergyCount: this.energyCount.masterEnergyCount,
      userEnergyCount: this.energyCount.userEnergyCount,
    };

    this.isPending = true;
    const result = await this._tronEnergyPanelService.updateTronEnergyPanel(dto);
    this.isPending = false;

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.error(errorMsg);
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.success(successMsg);
      this.getTronEnergyPanel();
    }
  }
}
