import { Injectable } from "@angular/core";
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomNgbDateAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = "-";

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = ".";

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? this.pad(date.day) + this.DELIMITER + this.pad(date.month) + this.DELIMITER + date.year
      : "";
  }

  private pad(value: number): string {
    return value < 10 ? "0" + value : value.toString();
  }
}

// TODO: move to translations
const I18N_VALUES = {
  ru: {
    weekdays: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    weekLabel: "неделя",
  },
  uz: {
    weekdays: ["Душанба", "Сешанба", "Чоршанба", "Пайшанба", "Жума", "Шанба", "Якшанба"],
    months: [
      "Январ",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июн",
      "Июл",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    weekLabel: "неделя",
  },
};

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomNgbDatepickerI18n extends NgbDatepickerI18n {
  private _i18n = {
    language: this._translate.currentLang,
  };
  constructor(private _translate: TranslateService) {
    super();
    this._i18n.language = this._translate.currentLang || "ru";
    this._translate.onLangChange.subscribe((event: any) => {
      this._i18n.language = event.lang;
    });
  }

  getWeekdayLabel(weekday: number): string {
    const languageData = I18N_VALUES[this._i18n.language as keyof typeof I18N_VALUES];
    return languageData?.weekdays[weekday - 1];
  }
  override getWeekLabel(): string {
    const languageData = I18N_VALUES[this._i18n.language as keyof typeof I18N_VALUES];
    return languageData?.weekLabel;
  }
  getMonthShortName(month: number): string {
    const languageData = I18N_VALUES[this._i18n.language as keyof typeof I18N_VALUES];
    return languageData?.months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
