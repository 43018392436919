import { Component, EventEmitter, Input, Output } from "@angular/core";

interface LandingSlide {
  id: number;
  imgSrc: string;
  title: {
    common: string;
    highlighted: string;
  };
}

@Component({
  selector: "app-carousel-slide",
  templateUrl: "./carousel-slide.component.html",
  styleUrls: ["./carousel-slide.component.css"],
})
export class CarouselSlideComponent {
  @Input() slide?: LandingSlide = undefined;

  @Output() buttonClick = new EventEmitter<void>();

  handleButtonClick() {
    this.buttonClick.emit();
  }
}
