<header
  class="flex items-center"
  [ngClass]="{
    'justify-between p-4 md:px-11.5 md:py-5': fullSize,
    'line-gradient-border border-b !bg-transparent dark:!bg-transparent': transparent,
    'justify-end !bg-transparent px-10 py-6 max-md:justify-between max-md:rounded-2xl max-md:!bg-gray-25 max-md:p-4 max-md:dark:!bg-slate-800':
      !fullSize
  }"
>
  <ng-container *ngIf="fullSize || isMobile">
    <a *ngIf="isAuth; else plainLogo" [routerLink]="'/' + RouteConstants.depositary">
      <app-logo [theme]="mode" [transparent]="transparent" />
    </a>
    <ng-template #plainLogo>
      <app-logo [theme]="mode" [transparent]="transparent" />
    </ng-template>
  </ng-container>

  <div
    *ngIf="withAuthState && isAuth; else unauthorizedNavigation"
    class="flex items-center gap-x-6 max-md:gap-x-4"
  >
    <app-language-selector [size]="isMobile ? 'sm' : 'md'" />

    <!-- <app-svg-icon name="profile-light" class="block h-12 w-12 max-md:hidden" /> -->
    <button class="h-6 w-6 md:hidden" (click)="openMobileMenuModal(mobileMenuModal)">
      <app-svg-icon name="menu/menu" />
    </button>
  </div>

  <ng-template #unauthorizedNavigation>
    <div class="flex items-center gap-x-3">
      <app-language-selector [size]="isMobile ? 'sm' : 'md'" />

      <button
        class="btn-primary btn-medium max-md:px-4 max-md:py-2 max-md:text-sm md:px-16"
        (click)="openSignInModal()"
      >
        {{ "Login.Enter" | translate }}
      </button>
    </div>
  </ng-template>
</header>

<ng-template #mobileMenuModal let-modal>
  <div class="flex h-full flex-col">
    <div class="flex items-center justify-between gap-x-4 rounded-2xl bg-white p-4 dark:bg-slate-800">
      <app-logo [theme]="mode" />
      <app-language-selector size="sm" class="ml-auto" />
      <button (click)="modal.close()" class="h-6 w-6">
        <app-svg-icon name="menu/menu" class="block" />
      </button>
    </div>

    <div class="mb-auto mt-4 flex flex-col gap-y-2">
      <a
        *ngFor="let item of mobileMenuItems"
        [routerLink]="item.link"
        class="flex items-center gap-x-4 rounded-xl px-4 py-3 font-semibold"
        [ngClass]="{
          'bg-gray-100 text-text-primary dark:bg-slate-700 dark:text-white': getIsCurrentMobileRoute(
            item.link
          ),
          'text-text-secondary': !getIsCurrentMobileRoute(item.link)
        }"
        (click)="modal.close()"
      >
        <app-svg-icon [name]="item.icon" class="h-6 w-6" />
        {{ item.title | translate }}
      </a>

      <hr class="border-gray-100 dark:border-slate-700" />

      <button
        class="flex items-center gap-x-4 rounded-xl px-4 py-3 font-semibold text-text-secondary"
        (click)="openSettingsModal(modal)"
      >
        <app-svg-icon name="menu/settings" class="h-6 w-6" />
        {{ "Header.Settings" | translate }}
      </button>
    </div>

    <ng-container *ngIf="isTelegramMiniApp">
      <div
        *ngIf="tonWalletAddress; else connectWalletButton"
        class="flex items-center gap-x-4 rounded-xl bg-gray-100 px-4 py-3 text-text-secondary dark:bg-slate-700"
      >
        <app-svg-icon name="telegram-wallet" class="h-6 w-6" />
        <div class="flex flex-1 items-center gap-x-2">
          <span class="text-black dark:text-white">{{ tonWalletAddressShort }}</span>
          <app-copy-btn [value]="userFriendlyTonWalletAddress" />
        </div>
        <button
          class="rounded-xl bg-red-55 px-4 py-3 text-xs font-medium text-red-500 dark:bg-red-50"
          (click)="openContentInModal(disconnectWalletConfirmationModal)"
        >
          Disconnect
        </button>
      </div>

      <ng-template #connectWalletButton>
        <button
          class="mb-6 flex items-center justify-center gap-x-4 rounded-xl bg-telegram px-4 py-3 text-black"
          (click)="handleTonWalletConnect()"
        >
          <app-svg-icon name="telegram-wallet" class="h-6 w-6" />
          <span class="font-bold">{{ "Header.Telegram_wallet" | translate }}</span>
        </button>
      </ng-template>
    </ng-container>

    <hr class="mb-6 border-gray-100 dark:border-slate-700" />
    <app-theme-selector class="mx-auto w-full" />
    <button
      class="mt-1 flex w-full items-center gap-x-4 rounded-xl bg-gray-100 px-4 py-3 font-semibold dark:bg-slate-700"
      (click)="exit(); modal.close()"
    >
      <app-svg-icon name="menu/exit" class="h-6 w-6" />
      {{ "Header.Logout" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #disconnectWalletConfirmationModal let-modal>
  <div class="mx-auto flex w-[18.75rem] flex-col items-center justify-center space-y-6 py-7 max-md:p-0">
    <h4 class="text-2xl font-semibold">{{ "Header.Disconnect_wallet" | translate }}</h4>
    <p class="text-center">{{ "Header.Disconnect_wallet_confirmation" | translate }}</p>

    <div class="grid w-full grid-cols-2 gap-x-4">
      <button class="btn-primary-danger btn-medium" (click)="handleTonWalletDisconnect(modal)">
        {{ "Common.Yes" | translate }}
      </button>
      <button class="btn-primary-dark btn-medium" (click)="modal.close()">
        {{ "Common.No" | translate }}
      </button>
    </div>
  </div>
</ng-template>
