import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AddCommissionDto } from "src/app/common/DTO/commission/add-commission.dto";
import { CommissionDto } from "src/app/common/DTO/commission/commission.dto";
import { SwapCommissionDto, UpdateSwapCommissionDto } from "src/app/common/DTO/swap-commissions";
import { WithdrawCommissionDto } from "src/app/common/DTO/withdraw-commissions/withdraw-commission.dto";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { CommissionService } from "src/app/services/commission.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { SwapCommissionsService } from "src/app/services/swap-commissions.service";
import { ToastService } from "src/app/services/toast.service";
import { WithdrawCommissionsService } from "src/app/services/withdraw-commissions.service";

@Component({
  selector: "app-commission-block",
  templateUrl: "./commission-block.component.html",
  styleUrls: ["./commission-block.component.css"],
})
export class CommissionBlockComponent implements OnInit {
  @Input() public selectedCurrency: CryptoSymbol = CryptoSymbol.Trx;

  public isFormPending = false;
  public form: FormGroup;
  public hasInteractionPermissions = false;

  private allCommissions: CommissionDto[] = [];
  private withdrawCommissions: WithdrawCommissionDto[] = [];
  private swapCommissions: SwapCommissionDto[] = [];

  public editWithdrawForm: FormGroup;
  public editWithdrawId: number | null = null;
  public isEditWithdrawPending = false;

  public commissionToDelete: CommissionDto | null = null;

  public editSwapForm: FormGroup;
  public isEditSwapPending = false;

  public CryptoSymbol = CryptoSymbol;

  constructor(
    private _localStorage: LocalStorageService,
    private _modalService: NgbModal,
    private _commissionService: CommissionService,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    private _withdrawCommissionsService: WithdrawCommissionsService,
    private _swapCommissionsService: SwapCommissionsService
  ) {
    this.form = new FormGroup({
      maxAmount: new FormControl(null, [Validators.required]),
      percent: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
    });

    this.editWithdrawForm = new FormGroup({
      percent: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      internalPercent: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
    });

    this.editSwapForm = new FormGroup({
      percent: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      usdtPercent: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.CommissionInteraction
    );
    await this.requestCommissions();
    await this.requestWithdrawCommissions();
    await this.requestSwapCommissions();
  }

  public get currencyName() {
    return getCurrencyName(this.selectedCurrency);
  }

  public get sellCommissions() {
    let targetCurrency: CryptoSymbol;
    if (
      this.selectedCurrency === CryptoSymbol.Usdt ||
      this.selectedCurrency === CryptoSymbol.PolygonUsdt ||
      this.selectedCurrency === CryptoSymbol.TonUsdt
    ) {
      targetCurrency = CryptoSymbol.AbstractUsdt;
    } else {
      targetCurrency = this.selectedCurrency;
    }
    return this.allCommissions.filter(x => x.currency === targetCurrency);
  }

  public getWithdrawCommissions(currency: CryptoSymbol) {
    return this.withdrawCommissions.filter(x => x.currency === currency);
  }

  public getSwapCommissions(currency: CryptoSymbol) {
    return this.swapCommissions.filter(x => x.currency === currency);
  }

  public get amountError() {
    const amount = this.form.get("maxAmount");

    if (amount?.value == null) {
      return null;
    }

    if (amount?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    const alreadyHasAmount = this.sellCommissions.some(x => x.maxAmount == +amount?.value);

    if (alreadyHasAmount) {
      return this._translateService.instant("Admin.Home.Already_has_commission");
    }
  }

  public get percentError() {
    const percent = this.form.get("percent");

    if (percent?.value == null) {
      return null;
    }

    if (percent?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (percent?.hasError("min") || percent?.hasError("max")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
  }

  public openCreateModal(currency: CryptoSymbol, content: any) {
    this.selectedCurrency = currency;
    this._modalService.open(content);
  }

  public openDeleteModal(item: CommissionDto, content: any) {
    this.commissionToDelete = item;
    this._modalService.open(content);
  }

  public handleCloseModal() {
    this._modalService.dismissAll();
  }

  public async onCreateCommissionSubmit() {
    this.isFormPending = true;
    await this.createCommission();

    const successMsg = this._translateService.instant("Common.Updated");
    this._toastService.success(successMsg);
    this.isFormPending = false;
    this.form.reset();
    this.handleCloseModal();
  }

  public async deleteCommission(id: number) {
    const result = await this._commissionService.deleteCommission(id);

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.error(errorMsg);
      return;
    }

    const successMsg = this._translateService.instant("Common.Updated");
    this._toastService.success(successMsg);
    this.commissionToDelete = null;
    this.requestCommissions();
    this.handleCloseModal();
  }

  public editWithdrawError(field: string) {
    const control = this.editWithdrawForm.get(field);

    if (control?.value == null) {
      return null;
    }

    if (control?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (control?.hasError("min") || control?.hasError("max")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
  }

  public openEditWithdrawCommissionModal(id: number, currency: CryptoSymbol, content: any) {
    this.editWithdrawId = id;
    const currentCommission = this.getWithdrawCommissions(currency)[0];
    this.editWithdrawForm.patchValue({
      percent: currentCommission.amount,
      internalPercent: currentCommission.internalAmount,
    });
    this._modalService.open(content);
  }

  public async onEditWithdrawSubmit() {
    if (!this.editWithdrawId) return;

    this.isEditWithdrawPending = true;
    const percent = this.editWithdrawForm.get("percent")?.value;
    const internalPercent = this.editWithdrawForm.get("internalPercent")?.value;

    await this.editWithdrawCommission(this.editWithdrawId, percent, internalPercent);

    this.isEditWithdrawPending = false;
    this.editWithdrawForm.reset();
    this.editWithdrawId = null;
    this.handleCloseModal();
  }

  public getEditSwapFieldError(field: string) {
    const value = this.editSwapForm.get(field);

    if (value?.value == null) {
      return null;
    }

    if (value?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (value?.hasError("min") || value?.hasError("max")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
  }

  public openEditSwapCommissionModal(dto: UpdateSwapCommissionDto, content: any) {
    this.editSwapForm.patchValue({ percent: dto.percent, usdtPercent: dto.usdtPercent });
    this._modalService.open(content);
  }

  public async onEditSwapSubmit() {
    if (this.editSwapForm.invalid) {
      return;
    }

    const percent = this.editSwapForm.get("percent")?.value;
    const usdtPercent = this.editSwapForm.get("usdtPercent")?.value;
    const dto: UpdateSwapCommissionDto = {
      percent: percent,
      usdtPercent: usdtPercent,
      currency: this.selectedCurrency,
    };

    await this.editSwapCommission(dto);

    this.isEditSwapPending = false;
    this.editSwapForm.reset();
    this.handleCloseModal();
  }

  private async requestCommissions() {
    const result = await this._commissionService.getAllCommission();
    if (result.withError) {
      return;
    }
    this.allCommissions = result.params ?? [];
  }

  private async createCommission() {
    const newCommission = new AddCommissionDto();
    if (
      this.selectedCurrency === CryptoSymbol.Usdt ||
      this.selectedCurrency === CryptoSymbol.TonUsdt ||
      this.selectedCurrency === CryptoSymbol.PolygonUsdt
    ) {
      newCommission.currency = CryptoSymbol.AbstractUsdt;
    } else {
      newCommission.currency = this.selectedCurrency;
    }
    newCommission.maxAmount = this.form.get("maxAmount")?.value;
    newCommission.percent = this.form.get("percent")?.value;

    const result = await this._commissionService.addCommission(newCommission);

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.error(errorMsg);
      return;
    }

    this.requestCommissions();
  }

  private async requestWithdrawCommissions() {
    const result = await this._withdrawCommissionsService.getAllWithdrawCommissions();
    if (result.withError) {
      return;
    }
    this.withdrawCommissions = result.params ?? [];
  }

  private async editWithdrawCommission(id: number, percent: number, internalPercent: number) {
    const result = await this._withdrawCommissionsService.updateWithdrawCommission(
      id,
      percent,
      internalPercent
    );

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.error(errorMsg);
      return;
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.success(successMsg);
    }

    await this.requestWithdrawCommissions();
  }

  private async requestSwapCommissions() {
    const result = await this._swapCommissionsService.getAllSwapCommissions();
    if (result.withError) {
      return;
    }
    this.swapCommissions = result.params ?? [];
  }

  private async editSwapCommission(dto: UpdateSwapCommissionDto) {
    const result = await this._swapCommissionsService.updateSwapCommission(dto);

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.error(errorMsg);
      return;
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.success(successMsg);
    }

    await this.requestSwapCommissions();
  }
}
