<div class="jw-modal">
  <div [class]="bodyClass">
    <button class="jw-modal-close-btn" (click)="close()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.6 20L4 18.4L10.4 12L4 5.6L5.6 4L12 10.4L18.4 4L20 5.6L13.6 12L20 18.4L18.4 20L12 13.6L5.6 20Z"
          fill="currentColor"
        />
      </svg>
    </button>
    <ng-content></ng-content>
  </div>
</div>
<div class="jw-modal-background"></div>
