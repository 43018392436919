<form [formGroup]="deleteAccountForm" (ngSubmit)="onDeleteAccount()">
  <app-input
    type="password"
    formControlName="password"
    [title]="'Delete_account.Password' | translate"
    [placeholder]="'Delete_account.Enter_password' | translate"
    [errorMsg]="getFieldHasErrors('password')"
  />
  <button
    type="submit"
    class="btn-primary-danger btn-large mt-4 w-full"
    [disabled]="deleteAccountForm.invalid || isPending"
  >
    {{ "Delete_account.Delete_account" | translate }}
  </button>
</form>

<ng-template #successModal let-modal>
  <div class="space-y-8">
    <div class="flex items-center justify-between p-3">
      <h3 class="title">{{ "Delete_account.Confirmation" | translate }}</h3>
      <button class="btn-flat h-6 w-6" (click)="modal.dismiss()">
        <app-svg-icon name="close" class="block" />
      </button>
    </div>
    <div>
      <app-status-mark variant="error" icon="trash-bin" class="mx-auto block" />
      <p class="mt-4 text-center text-xl font-semibold text-red-500 dark:text-red-200">
        {{ "Delete_account.Account_deleted" | translate }}
      </p>
    </div>
    <button class="btn-primary-dark btn-large w-full" (click)="onExit()">
      {{ "Delete_account.Exit" | translate }}
    </button>
  </div>
</ng-template>
