<app-header></app-header>

<main
  class="flex items-start gap-x-2 px-4 py-5 max-md:bg-gray-25 max-md:dark:bg-slate-900 md:px-11.5 md:pb-18 md:pt-12"
>
  <section
    *ngIf="step !== 'success' && step !== 'error'"
    class="flex h-[40.75rem] flex-1 flex-col rounded-2xl border border-[#D9D9D9] bg-[#FBFBFC] px-4 py-6 dark:border-none dark:bg-slate-800 md:px-9"
  >
    <h1 class="mb-2 text-lg font-semibold md:text-1.5xl">
      {{ "New_my_id_verification.Common.Title" | translate }}
    </h1>
    <p class="mb-6 dark:text-slate-200">
      {{ "New_my_id_verification.Common.Subtitle" | translate }}
    </p>

    <ng-container *ngIf="step === 'form'">
      <div
        class="flex items-center gap-8 rounded-2xl bg-[#F6F6F6] p-4 dark:bg-slate-900 max-md:flex-col max-md:gap-6 md:w-fit"
      >
        <form
          [formGroup]="formGroup"
          class="flex w-full flex-col gap-y-8 md:w-120"
          (submit)="handleFormSubmit()"
        >
          <div class="flex flex-col gap-y-4">
            <app-input
              formControlName="passport"
              [title]="'New_my_id_verification.Form.Passport' | translate"
              placeholder="__ ______"
              mask="SS 0000000"
              class="[&_input]:uppercase"
            />
            <app-input
              formControlName="birthDate"
              [title]="'New_my_id_verification.Form.Birth_date' | translate"
              placeholder="dd.mm.yyyy"
              mask="00.00.0000"
              [dropSpecialCharacters]="false"
            />
          </div>

          <button type="submit" class="btn-primary btn-medium" [disabled]="formGroup.invalid">
            {{ "Common.Next" | translate }}
          </button>
        </form>

        <div
          class="flex aspect-square w-[16.875rem] items-center justify-center rounded-full bg-gray-50 dark:bg-slate-800 max-md:block max-md:aspect-auto max-md:h-auto max-md:w-auto max-md:bg-transparent max-md:dark:bg-transparent"
        >
          <img
            [src]="theme === 'dark' ? 'assets/images/myid-logo-3-dark.png' : 'assets/images/myid-logo-3.png'"
            class="w-32"
          />
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 'photo'">
      <div
        *ngIf="isCameraAccessPrompting"
        class="mb-6 flex grid-cols-[1fr_auto_1fr] items-center gap-x-2 rounded-main bg-[#FFEC39]/50 p-2 dark:bg-gold-400 md:grid"
      >
        <!-- <app-svg-icon name="danger-square" class="h-12 w-12"></app-svg-icon> -->
        <div></div>
        <p class="text-base font-medium dark:text-black md:text-center">
          {{ "New_my_id_verification.Embedded_flow.Camera_prompt" | translate }}
        </p>
      </div>

      <div class="-mt-8 flex flex-1 flex-col items-center justify-center gap-y-3">
        <div *ngIf="!isCameraAccessPrompting" class="p-2" [class]="{ 'opacity-0': !errorMessage }">
          <p class="text-center text-lg font-semibold text-[#EB5757]">{{ errorMessage || "empty" }}</p>
        </div>

        <div class="max-md:w-full md:relative">
          <iframe
            #myIdIframe
            id="myid_iframe"
            [src]="myIdUrl | safe"
            allow="camera;fullscreen"
            [frameBorder]="0"
            class="fixed inset-0 h-screen w-screen border-[#D9D9D9] md:static md:aspect-video md:h-auto md:w-[50rem] md:rounded-2xl md:border-2"
            [class]="{
              'opacity-0': isCameraAccessPrompting,
              'border-[#EB5757]': !!errorMessage,
              'border-[#D9D9D9]': !errorMessage
            }"
            (load)="handleWindowResize()"
          ></iframe>

          <div
            *ngIf="isCameraAccessPrompting"
            class="inset-0 flex items-center justify-center rounded-full bg-stroke-color dark:bg-slate-900 max-md:bg-transparent max-md:dark:bg-transparent md:absolute"
          >
            <img
              [src]="
                theme === 'dark' ? 'assets/images/myid-logo-3-dark.png' : 'assets/images/myid-logo-3.png'
              "
              class="w-32"
            />
          </div>
        </div>

        <img
          *ngIf="!isCameraAccessPrompting"
          [src]="theme === 'dark' ? 'assets/images/myid-logo-3-dark.png' : 'assets/images/myid-logo-3.png'"
          class="w-32"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="step === 'qr-code'">
      <div class="mb-6 items-center rounded-main bg-[#FFEC39]/50 px-2 py-3 dark:bg-gold-400">
        <p class="text-center text-base font-medium dark:text-black">
          {{ "New_my_id_verification.Redirect_flow.Qr_prompt" | translate }}
        </p>
      </div>
      <div class="flex flex-1 flex-col items-center justify-center gap-y-2.5">
        <div class="flex h-56 w-56 items-center justify-center rounded-2xl bg-stroke-color">
          <a *ngIf="!isTelegramMiniApp" [href]="myIdUrl" target="_blank" class="btn-primary btn-medium">
            Proceed
          </a>
          <button *ngIf="isTelegramMiniApp" class="btn-primary btn-medium" (click)="redirectToMyId()">
            Proceed
          </button>
        </div>
        <img
          [src]="theme === 'dark' ? 'assets/images/myid-logo-3-dark.png' : 'assets/images/myid-logo-3.png'"
          class="w-32"
        />
      </div>
    </ng-container>
  </section>

  <section
    *ngIf="step === 'success'"
    class="flex h-[40.75rem] flex-1 flex-col items-center rounded-2xl border border-[#D9D9D9] bg-[#FBFBFC] px-4 py-6 dark:border-none dark:bg-slate-800 md:px-9"
  >
    <h1 class="mb-2 text-1.5xl font-semibold text-[#0BA859]">
      {{ "New_my_id_verification.Success.Title" | translate }}
    </h1>
    <p class="mb-3 p-2 text-center text-lg dark:text-slate-200">
      {{ "New_my_id_verification.Success.Welcome_text" | translate }}
      <br />
      <!-- <span class="font-medium">[user first and last names]</span> -->
    </p>

    <img src="assets/images/success-check-mark.png" class="mb-3 h-56 w-56" />
    <img
      [src]="theme === 'dark' ? 'assets/images/myid-logo-3-dark.png' : 'assets/images/myid-logo-3.png'"
      class="mb-3 w-32"
    />

    <a [routerLink]="'/' + walletRoute" class="btn-primary btn-medium">{{
      "New_my_id_verification.Success.Go_to_wallet" | translate
    }}</a>
  </section>

  <section
    *ngIf="step === 'error'"
    class="h-[40.75rem] flex-1 rounded-2xl border border-[#D9D9D9] bg-[#FBFBFC] px-4 py-6 dark:border-none dark:bg-slate-800 md:px-9"
  >
    <h1 class="mb-6 text-1.5xl font-semibold text-[#505561] dark:text-white">
      {{ "New_my_id_verification.Error.Title" | translate }}
    </h1>

    <div class="mb-6 flex items-center gap-x-2 rounded-main bg-[#E72A2A]/50 p-2">
      <!-- <app-svg-icon name="error-square" class="h-12 w-12"></app-svg-icon> -->
      <p class="text-center text-lg font-semibold text-text-secondary">
        {{ errorMessage || "Debug message: Unknown error" }}
      </p>
    </div>

    <div
      class="mb-2 rounded-xl bg-input-bg p-6 text-[#505561] dark:border dark:border-slate-700 dark:bg-slate-800 dark:text-slate-200 max-md:pb-20 md:hidden md:p-10"
    >
      <h2 class="mb-3 font-bold dark:text-white">{{ "New_my_id_verification.Advices.Title" | translate }}</h2>
      <hr class="mb-2.5 h-px bg-[#505561] dark:bg-slate-400" />
      <div class="flex flex-col gap-y-4">
        <div *ngFor="let advice of adviseList" class="flex items-center gap-x-2">
          <app-svg-icon name="stars" class="h-6 w-6" />
          <span>{{ advice | translate }}</span>
        </div>
      </div>
    </div>

    <button class="btn-primary btn-medium max-md:w-full" (click)="restartVerification()">
      {{ "New_my_id_verification.Error.Retry" | translate }}
    </button>
  </section>

  <section
    *ngIf="!isCameraAccessPrompting"
    class="basis-[30rem] rounded-xl border border-[#D9D9D9] bg-[#FBFBFC] p-10 text-[#505561] dark:border-none dark:bg-slate-800 dark:text-slate-200 max-md:hidden"
    [class]="{ 'opacity-0': step === 'success' }"
  >
    <h2 class="mb-3 font-bold dark:text-white">{{ "New_my_id_verification.Advices.Title" | translate }}</h2>
    <hr class="mb-2.5 h-px bg-[#505561] dark:bg-slate-400" />
    <div class="flex flex-col gap-y-4">
      <div *ngFor="let advice of adviseList" class="flex items-center gap-x-2">
        <app-svg-icon name="stars" class="h-6 w-6" />
        <span>{{ advice | translate }}</span>
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>
