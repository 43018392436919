import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { UpdatePinCodeDto } from "src/app/common/DTO/users/update-pin-code.dto";
import { ToastService } from "src/app/services/toast.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-create-pin-modal",
  templateUrl: "./create-pin-modal.component.html",
  styleUrls: ["./create-pin-modal.component.css"],
})
export class CreatePinModalComponent {
  public pinCode: string = "";
  public businessError: string | null = null;
  public isPending: boolean = false;
  public readonly pinCodeLength = ValidateConstants.PinCodeLength;

  constructor(
    private _modalRef: NgbActiveModal,
    private _userService: UserService,
    private _toastService: ToastService,
    private _translateService: TranslateService
  ) {}

  public onClose() {
    this._modalRef.dismiss();
  }

  public async onSetPinCode() {
    if (this.pinCode.length !== this.pinCodeLength) {
      return;
    }

    const dto: UpdatePinCodeDto = {
      newPinCode: this.pinCode,
    };

    this.isPending = true;
    const response = await this._userService.updatePinCode(dto);
    this.isPending = false;

    if (response.withError) {
      this.businessError = this._translateService.instant("Common.Unknown_error");
      return;
    }

    const successMsg = this._translateService.instant("Pincode.Pincode_set");
    this._toastService.success(successMsg);
    this.onClose();
  }
}
