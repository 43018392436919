<div
  class="max-md:my-2 max-md:rounded-2xl max-md:bg-gray-25 max-md:p-4 max-md:dark:bg-slate-800 md:absolute md:top-8"
>
  <h1 class="title">{{ "Transaction.Transaction_history" | translate }}</h1>
</div>

<div class="rounded-2xl bg-gray-25 py-6 dark:bg-slate-800 max-md:py-4">
  <!-- Desktop filters -->
  <div class="mb-6 grid grid-cols-[1fr_1fr_1fr_auto] items-center gap-x-4 px-6 max-md:hidden">
    <app-dropdown
      [items]="currencyNames"
      [value]="selectedCurrencyNames"
      (onSelect)="onSelectCurrency($event); handleFilterChange()"
    >
      <div class="dropdown-base-toggle w-full gap-x-2" [ngClass]="{ 'px-2 py-1.5': currencies.length > 0 }">
        <div
          *ngIf="currencies.length > 0; else currencyPlaceholder"
          class="flex flex-wrap items-center gap-1"
        >
          <div
            *ngFor="let currency of currencies"
            class="flex items-center gap-x-2 rounded-lg bg-gray-100 px-2 py-1 text-sm font-medium text-text-secondary dark:bg-slate-700"
          >
            {{ currency | currencyName }}
            <button class="btn-flat" (click)="removeCurrency(currency, $event); handleFilterChange()">
              <app-svg-icon name="actions/close" class="block w-3" />
            </button>
          </div>
        </div>

        <ng-template #currencyPlaceholder>
          {{ "Transaction.Asset" | translate }}
        </ng-template>

        <app-svg-icon name="chevron" class="block w-3 shrink-0" />
      </div>
    </app-dropdown>

    <app-dropdown
      [items]="trxDirectionFilterNames"
      [value]="selectedDirectionFilterNames"
      (onSelect)="onSelectDirection($event); handleFilterChange()"
    >
      <div class="dropdown-base-toggle w-full gap-x-2" [ngClass]="{ 'px-2 py-1.5': directions.length > 0 }">
        <div
          *ngIf="directions.length > 0; else directionPlaceholder"
          class="flex flex-wrap items-center gap-1"
        >
          <div
            *ngFor="let direction of directions"
            class="flex items-center gap-x-2 rounded-lg bg-gray-100 px-2 py-1 text-sm font-medium text-text-secondary dark:bg-slate-700"
          >
            {{ getDirectionName(direction) | translate }}
            <button class="btn-flat" (click)="removeDirection(direction, $event); handleFilterChange()">
              <app-svg-icon name="actions/close" class="block w-3" />
            </button>
          </div>
        </div>

        <ng-template #directionPlaceholder>
          {{ "Transaction.Type" | translate }}
        </ng-template>

        <app-svg-icon name="chevron" class="block w-3 shrink-0" />
      </div>
    </app-dropdown>

    <app-datepicker
      [value]="[startDate, endDate]"
      [range]="true"
      [withActionButtons]="true"
      (onSelect)="onSelectDates($event); handleFilterChange()"
      (onReset)="resetDates(); handleFilterChange()"
    />

    <button
      class="btn-primary-danger px-4 py-2.5"
      [disabled]="isLoading || !appliedFiltersCount"
      (click)="resetFilters()"
    >
      {{ "Common.Reset" | translate }}
    </button>
  </div>

  <!-- Mobile filters -->
  <div class="flex items-center gap-x-2 px-3 md:hidden">
    <button
      class="btn-primary-danger grow px-4 py-2.5"
      [disabled]="isLoading || !appliedFiltersCount"
      (click)="resetFilters()"
    >
      {{ "Common.Reset_filters" | translate }}
    </button>
    <button
      (click)="openModal(mobileDatePicker)"
      class="relative flex aspect-square w-11 items-center justify-center rounded-xl bg-gray-100 dark:bg-slate-700"
    >
      <app-svg-icon name="filters" class="block w-6 text-black dark:text-white" />
      <span
        *ngIf="appliedFiltersCount"
        class="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-green-400 text-[0.625rem] leading-none text-white"
      >
        {{ appliedFiltersCount }}
      </span>
    </button>
  </div>

  <section class="mt-6 max-md:px-3">
    <div
      class="grid grid-cols-[17%_14%_25%_12%_auto] gap-x-12 px-6 py-3 text-xs font-semibold text-text-secondary max-md:hidden"
    >
      <span>{{ "Transaction.Type" | translate }}</span>
      <span>{{ "Transaction.Sum" | translate }}</span>
      <span>Id</span>
      <span>{{ "Transaction.Date" | translate }}</span>
      <span class="text-end">{{ "Transaction.Status" | translate }}</span>
    </div>

    <ng-container *ngIf="!isLoading; else txSkeleton">
      <ng-container *ngIf="transactions.length > 0; else noTransactions">
        <div
          class="max-md:space-y-6"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          (scrolled)="loadMoreTransactions()"
        >
          <app-transaction-list-item
            *ngFor="let item of transactions"
            [txDto]="item"
            [fullSize]="true"
            [rates]="rates"
            class="block border-t border-gray-100 dark:border-slate-700 max-md:border-none"
          />

          <ng-container *ngIf="isLoadingMore">
            <app-transaction-list-item-skeleton
              [fullSize]="true"
              class="block border-t border-gray-100 dark:border-slate-700"
            />
          </ng-container>
        </div>
      </ng-container>

      <ng-template #noTransactions>
        <div class="text-center text-sm font-semibold text-text-secondary">
          {{ "Transaction.No_transactions" | translate }}
        </div>
      </ng-template>
    </ng-container>

    <ng-template #txSkeleton>
      <div class="max-md:space-y-6">
        <app-transaction-list-item-skeleton [fullSize]="true" />
        <app-transaction-list-item-skeleton
          [fullSize]="true"
          class="block border-t border-gray-100 dark:border-slate-700"
        />
      </div>
    </ng-template>
  </section>
</div>

<ng-template #mobileDatePicker let-modal>
  <section class="flex flex-col space-y-4">
    <div class="flex items-center justify-between">
      <h3 class="title">{{ "Common.Filters" | translate }}</h3>
      <button class="btn-flat" (click)="modal.close()">
        <app-svg-icon name="actions/close" class="block w-6" />
      </button>
    </div>

    <app-dropdown
      [items]="currencyNames"
      [value]="selectedCurrencyNames"
      (onSelect)="onSelectCurrency($event)"
    >
      <div class="dropdown-base-toggle w-full gap-x-2" [ngClass]="{ 'px-2 py-1.5': currencies.length > 0 }">
        <div
          *ngIf="currencies.length > 0; else currencyPlaceholder"
          class="flex flex-wrap items-center gap-1"
        >
          <div
            *ngFor="let currency of currencies"
            class="flex items-center gap-x-2 rounded-lg bg-gray-100 px-2 py-1 text-sm font-medium text-text-secondary dark:bg-slate-700"
          >
            {{ currency | currencyName }}
            <button class="btn-flat" (click)="removeCurrency(currency, $event)">
              <app-svg-icon name="actions/close" class="block w-3" />
            </button>
          </div>
        </div>

        <ng-template #currencyPlaceholder>
          {{ "Transaction.Asset" | translate }}
        </ng-template>

        <app-svg-icon name="chevron" class="block w-3 shrink-0" />
      </div>
    </app-dropdown>

    <app-dropdown
      [items]="trxDirectionFilterNames"
      [value]="selectedDirectionFilterNames"
      (onSelect)="onSelectDirection($event)"
    >
      <div class="dropdown-base-toggle w-full gap-x-2" [ngClass]="{ 'px-2 py-1.5': directions.length > 0 }">
        <div
          *ngIf="directions.length > 0; else directionPlaceholder"
          class="flex flex-wrap items-center gap-1"
        >
          <div
            *ngFor="let direction of directions"
            class="flex items-center gap-x-2 rounded-lg bg-gray-100 px-2 py-1 text-sm font-medium text-text-secondary dark:bg-slate-700"
          >
            {{ getDirectionName(direction) | translate }}
            <button class="btn-flat" (click)="removeDirection(direction, $event)">
              <app-svg-icon name="actions/close" class="block w-3" />
            </button>
          </div>
        </div>

        <ng-template #directionPlaceholder>
          {{ "Transaction.Type" | translate }}
        </ng-template>

        <app-svg-icon name="chevron" class="block w-3 shrink-0" />
      </div>
    </app-dropdown>

    <app-datepicker
      [range]="false"
      [withActionButtons]="false"
      [placeholder]="'Transaction.Date_from' | translate"
      [value]="startDate"
      (onSelect)="onSelectDates($event, 'start')"
      (onReset)="resetDates('start')"
    />

    <app-datepicker
      [range]="false"
      [withActionButtons]="false"
      [placeholder]="'Transaction.Date_to' | translate"
      [value]="endDate"
      (onSelect)="onSelectDates($event, 'end')"
      (onReset)="resetDates('end')"
    />

    <button (click)="closeModalAndApplyFilters(modal)" class="btn-primary btn-medium">
      {{ "Common.Apply" | translate }}
    </button>
    <button (click)="closeModalAndResetFilters(modal)" class="btn-primary-danger btn-medium">
      {{ "Common.Reset_all_filters" | translate }}
    </button>
  </section>
</ng-template>
