import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Address, fromNano } from "@ton/ton";
import { firstValueFrom } from "rxjs";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { Network } from "src/app/common/enums/network.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { Transaction } from "src/app/common/models/transaction";
import { NotMasterAddress } from "../common/constants/not.constant";
import { TonUsdtMasterAddress } from "../common/constants/ton-usdt.constant";
import { TonApiEventListDto } from "../common/DTO/tonapi/tonapi-event-list.dto";
import { TonApiEventDto } from "../common/DTO/tonapi/tonapi-event.dto";
import { WalletTransactionList } from "../common/models/wallet-transaction-list";
import { EnvService } from "./env.service";

type TonToken = CryptoToken.TonUsdt | CryptoToken.Not;

@Injectable({
  providedIn: "root",
})
export class TonService {
  private readonly txPerPage = 10;

  private readonly jettonMasterAddresses: Record<TonToken, string> = {
    [CryptoToken.TonUsdt]: TonUsdtMasterAddress,
    [CryptoToken.Not]: NotMasterAddress,
  };

  constructor(
    private readonly _http: HttpClient,
    private readonly _env: EnvService
  ) {}

  public async getTonTransactions(
    wallet: string,
    beforeLt: number | null = null,
    startDate?: number,
    endDate?: number
  ) {
    const result = new WalletTransactionList();
    result.fingerPrint = beforeLt?.toString?.() ?? null;

    let response = await this.fetchTonTransactions(
      wallet,
      beforeLt,
      this.txPerPage,
      false,
      startDate,
      endDate
    );
    if (!response) {
      return result;
    }

    // If the rate limit reached, try again with the API key
    if (response.events.length === 0) {
      response = await this.fetchTonTransactions(wallet, beforeLt, this.txPerPage, true, startDate, endDate);
    }
    if (!response) {
      result.fingerPrint = null;
      return result;
    }

    for (const tx of response.events) {
      const dto = new TonApiEventDto();
      Object.assign(dto, tx);
      const parsed = this.parseRawTx(dto, wallet);
      result.items.push(parsed);
    }
    if (response.next_from) {
      result.fingerPrint = response.next_from.toString();
    }

    return result;
  }

  public async getUsdtTransactions(
    wallet: string,
    beforeLt: number | null = null,
    startDate?: number,
    endDate?: number
  ) {
    const result = new WalletTransactionList();
    result.fingerPrint = beforeLt?.toString?.() ?? null;

    let response = await this.fetchJettonTransactions(
      wallet,
      CryptoToken.TonUsdt,
      beforeLt,
      this.txPerPage,
      false,
      startDate,
      endDate
    );
    if (!response) {
      return result;
    }

    // If the rate limit reached, try again with the API key
    if (response.events.length === 0) {
      response = await this.fetchJettonTransactions(
        wallet,
        CryptoToken.TonUsdt,
        beforeLt,
        this.txPerPage,
        true,
        startDate,
        endDate
      );
    }
    if (!response) {
      result.fingerPrint = null;
      return result;
    }

    for (const tx of response.events) {
      const dto = new TonApiEventDto();
      Object.assign(dto, tx);
      const parsed = this.parseJettonTx(dto, wallet, CryptoToken.TonUsdt);
      result.items.push(parsed);
    }
    if (response.next_from) {
      result.fingerPrint = response.next_from.toString();
    }

    return result;
  }

  public async getNotTransactions(
    wallet: string,
    beforeLt: number | null = null,
    startDate?: number,
    endDate?: number
  ) {
    const result = new WalletTransactionList();
    result.fingerPrint = beforeLt?.toString?.() ?? null;

    let response = await this.fetchJettonTransactions(
      wallet,
      CryptoToken.Not,
      beforeLt,
      this.txPerPage,
      false,
      startDate,
      endDate
    );
    if (!response) {
      return result;
    }

    // If the rate limit reached, try again with the API key
    if (response.events.length === 0) {
      response = await this.fetchJettonTransactions(
        wallet,
        CryptoToken.Not,
        beforeLt,
        this.txPerPage,
        true,
        startDate,
        endDate
      );
    }
    if (!response) {
      result.fingerPrint = null;
      return result;
    }

    for (const tx of response.events) {
      const dto = new TonApiEventDto();
      Object.assign(dto, tx);
      const parsed = this.parseJettonTx(dto, wallet, CryptoToken.Not);
      result.items.push(parsed);
    }
    if (response.next_from) {
      result.fingerPrint = response.next_from.toString();
    }

    return result;
  }

  public async estimateTonTransferCost(): Promise<number> {
    return 0.0055;
  }

  private async fetchAllTransactions(
    wallet: string,
    beforeLt: number | null = null,
    offset = 10,
    withApiKey = false,
    startDate?: number,
    endDate?: number
  ) {
    const urlSearchParams = new URLSearchParams({
      limit: String(offset),
    });
    if (beforeLt !== null) {
      urlSearchParams.append("before_lt", beforeLt.toString());
    }
    if (startDate !== undefined) {
      urlSearchParams.append("start_date", startDate.toString());
    }
    if (endDate !== undefined) {
      urlSearchParams.append("end_date", endDate.toString());
    }

    const uri = `${this._env.tonApiUrl}/accounts/${wallet}/events?${urlSearchParams.toString()}`;

    const headers: { [key: string]: string | string[] } = {};

    if (withApiKey) {
      headers["Authorization"] = `Bearer ${this._env.tonApiKey}`;
    }

    try {
      return (await firstValueFrom(this._http.get(uri))) as TonApiEventListDto;
    } catch (e) {
      return null;
    }
  }

  private async fetchTonTransactions(
    wallet: string,
    beforeLt: number | null = null,
    offset = 10,
    withApiKey = false,
    startDate?: number,
    endDate?: number
  ) {
    const eventList = new TonApiEventListDto();
    eventList.events = [];

    const fetchAndExtractTonTransactions = async (
      innerWallet: string,
      innerBeforeLt: number | null = null,
      innerOffset = 10,
      innerWithApiKey = false,
      innerStartDate?: number,
      innerEndDate?: number
    ) => {
      const allEventList = await this.fetchAllTransactions(
        innerWallet,
        innerBeforeLt,
        innerOffset,
        innerWithApiKey,
        innerStartDate,
        innerEndDate
      );
      if (allEventList === null || allEventList.events.length === 0) {
        return;
      }
      allEventList.events.forEach(event => {
        if (event.actions.length === 0 || event.actions[0].type !== "TonTransfer") {
          return;
        }
        if (eventList.events.length < innerOffset) {
          eventList.events.push(event);
        }
      });
      eventList.next_from = allEventList.next_from;
      if (eventList.events.length < innerOffset) {
        await fetchAndExtractTonTransactions(
          innerWallet,
          eventList.next_from,
          innerOffset,
          innerWithApiKey,
          innerStartDate,
          innerEndDate
        );
      }
    };

    await fetchAndExtractTonTransactions(wallet, beforeLt, offset, withApiKey, startDate, endDate);
    return eventList;
  }

  private async fetchJettonTransactions(
    wallet: string,
    token: TonToken,
    beforeLt: number | null = null,
    offset = 10,
    withApiKey = false,
    startDate?: number,
    endDate?: number
  ) {
    const jettonMasterAddress = Address.parse(this.jettonMasterAddresses[token]);

    const eventList = new TonApiEventListDto();
    eventList.events = [];

    const fetchAndExtractJettonTransactions = async (
      innerWallet: string,
      innerBeforeLt: number | null = null,
      innerOffset = 10,
      innerWithApiKey = false,
      innerStartDate?: number,
      innerEndDate?: number
    ) => {
      const allEventList = await this.fetchAllTransactions(
        innerWallet,
        innerBeforeLt,
        innerOffset,
        innerWithApiKey,
        innerStartDate,
        innerEndDate
      );
      if (allEventList === null || allEventList.events.length === 0) {
        return;
      }
      allEventList.events.forEach(event => {
        if (event.actions.length === 0 || event.actions[0].type !== "JettonTransfer") {
          return;
        }
        const jettonAddress = Address.parse(event.actions[0].JettonTransfer.jetton.address);
        if (!jettonAddress.equals(jettonMasterAddress)) {
          return;
        }
        if (eventList.events.length < innerOffset) {
          eventList.events.push(event);
        }
      });
      eventList.next_from = allEventList.next_from;
      if (eventList.events.length < innerOffset) {
        await fetchAndExtractJettonTransactions(
          innerWallet,
          eventList.next_from,
          innerOffset,
          innerWithApiKey,
          innerStartDate,
          innerEndDate
        );
      }
    };

    await fetchAndExtractJettonTransactions(wallet, beforeLt, offset, withApiKey, startDate, endDate);
    return eventList;
  }

  private parseRawTx(tx: TonApiEventDto, userWallet: string): Transaction {
    const txDto = new Transaction();

    const action = tx?.actions?.[0];
    if (!action) {
      return txDto;
    }

    const fromAddress = Address.parseRaw(action.TonTransfer.sender.address).toString();
    const toAddress = Address.parseRaw(action.TonTransfer.recipient.address).toString();

    txDto.from = fromAddress;
    txDto.to = toAddress;
    txDto.hash = tx.event_id;
    txDto.createdAt = new Date(tx.timestamp * 1000);
    txDto.timestamp = tx.timestamp * 1000;
    txDto.fee = 0;
    txDto.id = tx.event_id;
    // txDto.type = fromAddress.toLowerCase() === userWallet.toLowerCase() ? TxType.Out : TxType.In;
    txDto.type = TxType.Chain;
    txDto.network = Network.Ton;
    txDto.amount = fromNano(action.TonTransfer.amount);
    txDto.status = TxStatus.Approved;
    txDto.token = CryptoToken.Ton;
    // txDto.isCommission = tx.methodId === "0xa9059cbb";
    txDto.isCommission = false;

    return txDto;
  }

  private parseJettonTx(tx: TonApiEventDto, userWallet: string, token: CryptoToken): Transaction {
    const txDto = new Transaction();

    const action = tx?.actions?.[0];
    if (!action) {
      return txDto;
    }

    const fromAddress = Address.parseRaw(action.JettonTransfer.sender.address).toString();
    const toAddress = Address.parseRaw(action.JettonTransfer.recipient.address).toString();
    const rawAmount = Number(action.JettonTransfer.amount);
    const decimals = action.JettonTransfer.jetton.decimals;
    const amount = rawAmount * 10 ** (9 - decimals);

    txDto.from = fromAddress;
    txDto.to = toAddress;
    txDto.hash = tx.event_id;
    txDto.createdAt = new Date(tx.timestamp * 1000);
    txDto.timestamp = tx.timestamp * 1000;
    txDto.fee = 0;
    txDto.id = tx.event_id;
    // txDto.type = fromAddress.toLowerCase() === userWallet.toLowerCase() ? TxType.Out : TxType.In;
    txDto.type = TxType.Chain;
    txDto.network = Network.Ton;
    txDto.amount = fromNano(amount);
    txDto.status = TxStatus.Approved;
    txDto.token = token;

    return txDto;
  }
}
