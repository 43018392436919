import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { debounceTime, distinctUntilChanged, Subject } from "rxjs";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { CreateTerroristDto, TerroristDto } from "src/app/common/DTO/terrorists";
import { ScannedTerrorDto } from "src/app/common/DTO/terrorists/scanned-terror.dto";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { TerroristErrorCode } from "src/app/common/enums/terrorist-error-code.enum";
import { BlackListService } from "src/app/services/black-list.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ReportsService } from "src/app/services/reports.service";
import { TerroristService } from "src/app/services/terrorist.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  templateUrl: "./security.component.html",
  styleUrls: ["./security.component.css"],
})
export class SecurityComponent implements OnInit {
  @ViewChild("addTerroristModal") addTerroristModal!: TemplateRef<any>;
  @ViewChild("deleteTerroristModal") deleteTerroristModal!: TemplateRef<any>;

  public addTerroristForm: FormGroup;
  public hasInteractionPermissions: boolean = false;
  public isLoading: boolean = true;

  public terroristList: TerroristDto[] = [];
  public page = 1;
  public pageSize = 10;
  public totalCount = 0;

  public terroristToDelete: TerroristDto | null = null;
  public isPending: boolean = false;
  public showSearch: boolean = false;
  public searchTerrorist: string = "";
  public $searchTerrorist = new Subject<string>();

  public switchValues: { [userId: number]: boolean } = {};

  public scannedTerrorItems?: ScannedTerrorDto[] | null;

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _modalService: NgbModal,
    private readonly _translateService: TranslateService,
    private readonly _terroristService: TerroristService,
    private readonly _toastService: ToastService,
    private readonly _reportService: ReportsService,
    private readonly _blackListService: BlackListService
  ) {
    this.addTerroristForm = new FormGroup({
      externalId: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      surname: new FormControl(null, [Validators.required]),
      pinFl: new FormControl(null, [
        Validators.required,
        Validators.minLength(ValidateConstants.PinflLength),
        Validators.maxLength(ValidateConstants.PinflLength),
      ]),
      passportSerial: new FormControl(null, [Validators.required]),
      passportNumber: new FormControl(null, [Validators.required]),
    });
  }

  async ngOnInit(): Promise<void> {
    // TODO: check if it is correct
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.UserInteraction
    );
    await this.getTerrorists(this.page, this.pageSize);

    this.$searchTerrorist.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async search => {
      await this.getTerrorists(this.page, this.pageSize, search);
    });
  }

  public onPageChange(page: number) {
    this.page = page;
    this.getTerrorists(this.page, this.pageSize);
  }

  public toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  public openAddTerrorModal() {
    this._modalService.open(this.addTerroristModal).result.then(
      () => {
        this.closeAddTerrorModal();
      },
      () => {
        this.closeAddTerrorModal();
      }
    );
  }

  public closeAddTerrorModal() {
    this._modalService.dismissAll();
    this.addTerroristForm.reset();
    this.addTerroristForm.markAsUntouched();
  }

  public handleDownloadTerroristReportButtonClick() {
    this._reportService.getTerroristsDataReport();
  }

  public async downloadTerroristsCsv() {
    try {
      const csv = await this._terroristService.downloadCsvFile();
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "terrorists.csv";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      this._toastService.error(this._translateService.instant("Common.Unknown_error"));
    }
  }

  public async onAddTerroristClick() {
    if (this.addTerroristForm.invalid) {
      return;
    }

    const newTerror: CreateTerroristDto = {
      externalId: this.addTerroristForm.get("externalId")?.value,
      name: this.addTerroristForm.get("name")?.value.trim(),
      pinFl: this.addTerroristForm.get("pinFl")?.value.trim(),
      surname: this.addTerroristForm.get("surname")?.value.trim(),
      passportNumber: this.addTerroristForm.get("passportNumber")?.value.trim(),
      passportSerial: this.addTerroristForm.get("passportSerial")?.value.trim(),
    };

    this.isPending = true;
    const res = await this._terroristService.addTerrorist(newTerror);
    this.isPending = false;

    if (res.withError) {
      switch (res.errorCode) {
        case TerroristErrorCode.AlreadyExist:
          this._toastService.error(
            this._translateService.instant("Admin.Security.Terrorists.Terror_already_exist")
          );
          break;
        default:
          this._toastService.error(this._translateService.instant("Common.Unknown_error"));
          break;
      }
      return;
    }

    const successMessage = this._translateService.instant("Admin.Security.Terrorists.Success_add");
    this._toastService.success(successMessage);
    this.closeAddTerrorModal();
    this.page = 1;
    this.getTerrorists(this.page, this.pageSize);
  }

  public async onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (!file) return;

    this.isPending = true;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const response = await this._terroristService.uploadCsvFile(formData);

    if (response.withError) {
      this._toastService.show(this._translateService.instant("Admin.Security.Terrorists.Fail_import"));
      event.target.value = "";
      this.isPending = false;
      return;
    }

    if (response.params != null && response.params < 1) {
      this._toastService.show(this._translateService.instant("Admin.Security.Terrorists.No_record_saved"));
    } else {
      this._toastService.show(this._translateService.instant("Admin.Security.Terrorists.Success_import"));
    }

    event.target.value = "";
    this.isPending = false;
  }

  public getAddFormErrorMessage(control: string) {
    const field = this.addTerroristForm.get(control);

    if (!field?.touched) {
      return null;
    }

    if (field?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (control === "pinFl" && (field?.hasError("minlength") || field?.hasError("maxlength"))) {
      return this._translateService.instant("Admin.Security.Terrorists.PinFL_error");
    }

    return null;
  }

  public async onSwitchChange(userId: number) {
    const switchValue = this.switchValues[userId];

    if (switchValue) {
      await this.blockUser(userId);
    } else {
      await this.unblockUser(userId);
    }
  }

  public openDeleteTerrorModal(terr: TerroristDto) {
    this.terroristToDelete = terr;
    this._modalService.open(this.deleteTerroristModal).result.then(
      () => {
        this.closeDeleteTerrorModal();
      },
      () => {
        this.closeDeleteTerrorModal();
      }
    );
  }

  public closeDeleteTerrorModal() {
    this._modalService.dismissAll();
    this.terroristToDelete = null;
  }

  public async onDeleteTerroristClick() {
    if (this.terroristToDelete == null) {
      return;
    }
    this.isPending = true;
    await this.deleteTerrorist(this.terroristToDelete.id);
    this.isPending = false;
    this.terroristToDelete = null;
    this.closeDeleteTerrorModal();
    this.page = 1;
    await this.getTerrorists(this.page, this.pageSize);
  }

  private async blockUser(userId: number) {
    await this._blackListService.blockUser(userId, false);
  }

  private async unblockUser(userId: number) {
    await this._blackListService.unblockUser(userId);
  }

  private async deleteTerrorist(userId: number) {
    const res = await this._terroristService.deleteTerrorist(userId);
    if (res.withError) {
      this._toastService.error(this._translateService.instant("Common.Unknown_error"));
      return;
    }

    this._toastService.success(this._translateService.instant("Common.Updated"));
  }

  private async getTerrorists(page: number, size: number, search?: string) {
    this.isLoading = true;
    const res = await this._terroristService.getAllTerrorists(page, size, search);
    this.isLoading = false;

    if (res.withError) {
      return;
    }
    this.terroristList = res.params?.items ?? [];
    this.totalCount = res.params?.totalCount!;
  }
}
