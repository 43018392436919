<div class="box px-3">
  <div class="border-b border-gray-100 pb-5 dark:border-slate-700">
    <h3 class="altitle">{{ "Admin.Home.Rate" | translate }} {{ currencyName }}</h3>
  </div>

  <div class="admin-value-row mt-6">
    <span class="text-sm font-medium text-text-secondary">
      1 {{ currencyName }}: {{ rate?.usd | mfeCustom: { fiat: true } }} USD
    </span>
  </div>
</div>
