<div class="absolute top-8">
  <h1 class="title">{{ "Admin.Revenue.Title" | translate }}</h1>
</div>

<div class="mt-6 grid grid-cols-2 gap-x-6">
  <div class="box flex h-fit flex-col">
    <h3 class="altitle">{{ "Admin.Revenue.Swap_income" | translate }}</h3>
    <div class="my-6 text-5xl font-semibold">{{ 0 | mfeCustom }} USDT</div>
    <div class="space-y-4" [@openClose]="isAssetsVisible">
      <div class="admin-value-row-wrapper">
        <p>Bitcoin</p>
        <div class="admin-value-row">{{ 0 | mfeCustom }}</div>
      </div>
    </div>
    <button
      class="btn-primary btn-large"
      [ngClass]="{ 'mt-2': !isAssetsVisible, 'mt-8': isAssetsVisible }"
      (click)="toggleAssetsVisibility()"
    >
      {{
        isAssetsVisible
          ? ("Admin.Revenue.Hide_assets" | translate)
          : ("Admin.Revenue.Show_assets" | translate)
      }}
    </button>
  </div>

  <div class="box flex h-fit flex-col">
    <h3 class="altitle">{{ "Admin.Revenue.Withdraw_income" | translate }}</h3>
    <div class="my-6 text-5xl font-semibold">{{ 0 | mfeCustom }} USDT</div>
    <div class="space-y-4" [@openClose]="isIncomeVisible">
      <div class="admin-value-row-wrapper">
        <p>Bitcoin</p>
        <div class="admin-value-row">{{ 0 | mfeCustom }}</div>
      </div>
    </div>
    <button
      class="btn-primary btn-large"
      [ngClass]="{ 'mt-2': !isIncomeVisible, 'mt-8': isIncomeVisible }"
      (click)="toggleIncomeVisibility()"
    >
      {{
        isIncomeVisible
          ? ("Admin.Revenue.Hide_income" | translate)
          : ("Admin.Revenue.Show_income" | translate)
      }}
    </button>
  </div>
</div>
