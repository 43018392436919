import { Component, Input, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ControlPanelSettingsDto } from "src/app/common/DTO/control-panel/control-panel-settings.dto";
import { AdminPermission } from "src/app/common/enums/admin-permission.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ControlPanelService } from "src/app/services/control-panel.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-control-panel-block",
  templateUrl: "./control-panel-block.component.html",
  styleUrls: ["./control-panel-block.component.css"],
})
export class ControlPanelBlockComponent {
  @Input() public selectedCurrency: CryptoSymbol = CryptoSymbol.Trx;

  public hasInteractionPermissions: boolean = false;
  public functionalityForm: FormGroup;

  private controlPanel: ControlPanelSettingsDto[] = [];

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _controlPanelService: ControlPanelService,
    private readonly _toastService: ToastService,
    private readonly _translateService: TranslateService
  ) {
    this.functionalityForm = new FormGroup({
      currency: new FormControl(null),
      canBuy: new FormControl(false),
      canSell: new FormControl(false),
      canSend: new FormControl(false),
    });
  }

  async ngOnInit(): Promise<void> {
    this.hasInteractionPermissions = await this._localStorage.adminHasPermission(
      AdminPermission.PlatformInteraction
    );

    await this.getFunctionality();
    this.getFunctionalityByCurrency(this.selectedCurrency);

    this.functionalityForm.valueChanges.subscribe(async () => {
      await this.updateFunctionality();
      await this.getFunctionality();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedCurrency"]) {
      this.selectedCurrency = changes["selectedCurrency"].currentValue;
      this.getFunctionalityByCurrency(this.selectedCurrency);
    }
  }

  public getFunctionalityByCurrency(currency: CryptoSymbol) {
    const found = this.controlPanel.find(x => x.currency === currency);
    if (!found) {
      return;
    }
    this.functionalityForm.setValue(
      {
        currency: currency,
        canBuy: found?.canBuy,
        canSell: found?.canSell,
        canSend: found?.canSend,
      },
      { emitEvent: false }
    );
  }

  private async getFunctionality() {
    const res = await this._controlPanelService.getControlPanel();
    if (res.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.error(errorMsg);
      return;
    }

    this.controlPanel = res.params!;
  }

  private async updateFunctionality() {
    const form = this.functionalityForm;

    if (!form) {
      return;
    }

    const dto: ControlPanelSettingsDto = {
      currency: form.value.currency,
      canBuy: form.value.canBuy,
      canSell: form.value.canSell,
      canSend: form.value.canSend,
    };

    const res = await this._controlPanelService.updateControlPanel(dto);
    if (res.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.error(errorMsg);
      return;
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.success(successMsg);
    }
  }
}
