<div class="absolute top-8">
  <h1 class="title">{{ "Admin.Trans.Title" | translate }}</h1>
</div>

<section class="rounded-2xl bg-gray-25 py-6 dark:bg-slate-800">
  <div class="flex items-center gap-x-4 px-6 py-1">
    <app-dropdown [items]="tabNames" (onSelect)="onTabChange($event)" class="block w-[17rem]">
      <button class="dropdown-base-toggle w-full">
        <span class="text-sm font-bold text-text-secondary">{{ currentTabLabel | translate }}</span>
        <app-svg-icon name="chevron" class="h-4 w-4"></app-svg-icon>
      </button>
    </app-dropdown>

    <app-datepicker
      [value]="[startDate, endDate]"
      [range]="true"
      [withActionButtons]="true"
      (onSelect)="onSelectDates($event); handleFilterChange()"
      (onReset)="resetDates(); handleFilterChange()"
      class="block w-[17rem]"
    />

    <button
      class="btn-primary-danger ml-auto px-4 py-2.5"
      (click)="resetFilters()"
      [disabled]="!appliedFiltersCount"
    >
      {{ "Common.Reset" | translate }}
    </button>
  </div>

  <div class="mt-6 flex flex-col space-y-8">
    <div
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="loadMoreTransactions()"
    >
      <table class="adminTable">
        <thead>
          <tr>
            <th scope="col">{{ "Admin.Trans.Sum" | translate }}</th>
            <th scope="col">{{ "Admin.Trans.Date" | translate }}</th>
            <th scope="col">{{ "Admin.Trans.Type" | translate }}</th>
            <th scope="col">{{ "Admin.Trans.Sender_address" | translate }}</th>
            <!-- <th scope="col">{{ "Admin.Trans.Receiver_number" | translate }}</th> -->
            <th scope="col">{{ "Admin.Trans.Receiver_address" | translate }}</th>
            <th scope="col">{{ "Admin.Trans.Status" | translate }}</th>
            <th *ngIf="transactionsHasActions" scope="col">{{ "Admin.Trans.Options" | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="sendTxs.length > 0; else noSendTxs">
          <tr *ngFor="let item of sendTxs">
            <td>
              <span>
                {{ item.amount | mfeCustom: { currency: item.currency } }} {{ item.currency | currencyName }}
              </span>
              <span class="mt-1 block text-text-secondary">
                {{ getAmountInUsd(item.amount, item.currency) }} USD
              </span>
            </td>
            <td class="whitespace-nowrap">{{ item.createdAt | customDate: "full" }}</td>
            <td>
              {{
                getTxType(item.type)
                  | translate
                    : {
                        fromCurrency: item.fromCurrency | currencyName,
                        toCurrency: item.currency | currencyName
                      }
              }}
            </td>
            <td>
              <div *ngIf="item.from; else noFrom" class="max-w-[10rem] overflow-hidden" [title]="item.from">
                <span class="truncate">{{ item.from }}</span>
                <app-copy-btn [value]="item.from"></app-copy-btn>
              </div>
              <ng-template #noFrom>
                <span>-</span>
              </ng-template>
            </td>
            <!-- TODO Add phone number -->
            <!-- <td>-</td> -->
            <td>
              <div *ngIf="item.to; else noTo" class="max-w-[10rem] overflow-hidden" [title]="item.to">
                <span class="truncate">{{ item.to }}</span>
                <app-copy-btn [value]="item.to"></app-copy-btn>
              </div>
              <ng-template #noTo>
                <span>-</span>
              </ng-template>
            </td>
            <td>
              <span
                [ngClass]="{
                  'text-green-500 dark:text-green-200': item.status === TxStatus.Approved,
                  'text-red-500 dark:text-red-200': item.status === TxStatus.Error,
                  'text-yellow-500':
                    item.status === TxStatus.OnConfirm ||
                    item.status === TxStatus.Processing ||
                    item.status === TxStatus.WaitingApproveByBlockchain
                }"
              >
                {{ getTxStatus(item.status) | translate }}
              </span>
            </td>
            <td *ngIf="transactionsHasActions">
              <div class="flex items-center gap-x-2">
                <button
                  class="btn-flat h-6 w-6 rounded bg-green-100 text-green-500 disabled:opacity-50"
                  [title]="'Common.Approve' | translate"
                  (click)="handleVerdictSendTx(item.id, true)"
                  [disabled]="isVerdictPending"
                >
                  <app-svg-icon name="check" class="h-4 w-4"></app-svg-icon>
                </button>
                <button
                  class="btn-flat h-6 w-6 rounded bg-red-100 text-red-500 disabled:opacity-50"
                  [title]="'Common.Cancel' | translate"
                  (click)="handleVerdictSendTx(item.id, false)"
                  [disabled]="isVerdictPending"
                >
                  <app-svg-icon name="close" class="h-4 w-4"></app-svg-icon>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <ng-template #noSendTxs>
          <tr>
            <td colspan="8" class="text-center text-xs text-text-secondary">
              {{ "Transaction.No_transactions" | translate }}
            </td>
          </tr>
        </ng-template>
      </table>
    </div>
  </div>
</section>

<!-- <section class="mt-8">
  <div class="flex items-center justify-between border-b border-gray-100">
    <app-tabs variant="panels" [tabs]="['Admin.Trans.Buy_sell']" />
  </div>
  <h4 class="mt-2.5 text-sm font-medium text-slate-400 dark:text-slate-200">
    {{ "Admin.Trans.Buy_sell_description" | translate }}
  </h4>

  <div class="mt-6 flex flex-col space-y-8">
    <table class="adminTable">
      <thead>
        <tr>
          <th scope="col">{{ "Admin.Trans.Sum" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Date" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Type" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Wallet" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Phone_number" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Status" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Card_number" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Options" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Revert" | translate }}</th>
          <th scope="col">{{ "Admin.Trans.Compliance" | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="cryptoTxs.length > 0; else noCryptoTxs">
        <tr *ngFor="let item of cryptoTxs">
          <td>
            {{
              getCryptoCurrencyValue(item.toAmount, item.toCurrency)
                | mfeCustom: { currency: item.toCurrency }
            }}
            {{ getCryptoCurrencyName(item.toCurrency) }}
          </td>
          <td>{{ item.createdAt | customDate: "full" }}</td>
          <td>
            <span>{{ getCryptoTxType(item.type) }}</span>
          </td>
          <td>
            <a [href]="getAddressOnExplorer(item)" target="_blank" class="link"
              >{{ item.walletPublicKey.slice(0, 5) }}...</a
            >
          </td>
          <td>
            <a [routerLink]="'/admin/users/' + item.userId" class="link" target="_blank">{{
              item.userPhoneNumber | phoneDisplayer
            }}</a>
          </td>
          <td *ngIf="item.cryptoTxHash">
            <a [href]="getTransactionOnExplorer(item)" target="_blank" class="link">{{
              getCryptoTxStatus(item.internalStatus)
            }}</a>
          </td>
          <td *ngIf="!item.cryptoTxHash">{{ getCryptoTxStatus(item.internalStatus) }}</td>
          <td>
            <span>{{ item.maskedCardNumber }}</span>
          </td>
          <td>
            <div *ngIf="isCryptoVerdictEnabled(item.internalStatus)">
              <button
                class="h-6 w-6"
                [title]="'Common.Approve' | translate"
                (click)="handleVerdictCryptoTx(item.id, true)"
                [disabled]="isCryptoVerdictPending || !hasInteractionPermissions"
              >
                <img src="assets/icons/accept.svg" alt="Accept" />
              </button>
              <button
                class="h-6 w-6"
                [title]="'Common.Cancel' | translate"
                (click)="handleVerdictCryptoTx(item.id, false)"
                [disabled]="isCryptoVerdictPending || !hasInteractionPermissions"
              >
                <img src="assets/icons/decline.svg" alt="Decline" />
              </button>
            </div>
          </td>
          <td>
            <button
              *ngIf="item.canRevertWithdraw"
              class="h-6 w-6"
              [title]="'Admin.Trans.Revert' | translate"
              (click)="handleRevertWithdrawTx(item.id)"
              [disabled]="!hasInteractionPermissions"
            >
              <img src="assets/icons/revert_deposit.svg" alt="Revert" />
            </button>
          </td>
          <td>
            <a
              *ngIf="item.complianceRiskScore && item.complianceReportLink"
              [href]="item.complianceReportLink"
              target="_blank"
              class="link"
            >
              {{ item.complianceRiskScore * 100 }}%
            </a>
            <span *ngIf="!item.complianceRiskScore">0%</span>
          </td>
        </tr>
      </tbody>
      <ng-template #noCryptoTxs>
        <tr>
          <td colspan="10" class="text-center">{{ "Transaction.No_transactions" | translate }}</td>
        </tr>
      </ng-template>
    </table>

    <ngb-pagination
      [collectionSize]="cryptoTotalCount"
      [(page)]="cryptoPage"
      [pageSize]="pageSize"
      [maxSize]="5"
      (pageChange)="requestCryptoTxs()"
    >
    </ngb-pagination>
  </div>
</section> -->
