import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AssetsSDK, createApi } from "@ton-community/assets-sdk";
import { Address, toNano } from "@ton/ton";
import { CHAIN } from "@tonconnect/ui";
import { generate } from "lean-qr";
import { toSvgDataURL } from "lean-qr/extras/svg";
import { NotMasterAddress } from "src/app/common/constants/not.constant";
import { TonUsdtMasterAddress } from "src/app/common/constants/ton-usdt.constant";
import { BalanceDto } from "src/app/common/DTO/balances/balance.dto";
import { DepositLimitDto } from "src/app/common/DTO/deposit-limit/deposit-limit.dto";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { Network } from "src/app/common/enums/network.enum";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { getNetworkInfo } from "src/app/common/utils/network-info-helper";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { TelegramMiniAppHelper } from "src/app/common/utils/telegram-mini-app-helper.util";
import { TonAddressUtils } from "src/app/common/utils/ton-address.utils";
import { BalancesService } from "src/app/services/balances.service";
import { DepositLimitsService } from "src/app/services/deposit-limits.service";
import { EnvService } from "src/app/services/env.service";
import { ToastService } from "src/app/services/toast.service";
import { TonConnectSender } from "src/app/services/ton-connect-sender.service";
import { TonConnectService } from "src/app/services/ton-connect.service";
import { WalletService } from "src/app/services/wallet.service";

enum Step {
  Qr,
  NetworkSelect,
}

@Component({
  selector: "app-receive-modal",
  templateUrl: "./receive-modal.component.html",
  styleUrls: ["./receive-modal.component.css"],
})
export class ReceiveModalComponent implements OnInit, OnDestroy {
  @Input() walletBalance: BalanceDto | null = null;

  step: Step = Step.Qr;
  Step = Step;
  internalError = false;
  wallets: WalletDto[] = [];
  balances: BalanceDto[] = [];
  depositLimits: DepositLimitDto[] = [];
  qrBase64 = "";
  selectedWalletBalance = this.walletBalance;
  selectedCurrency = this.selectedWalletBalance?.currency;
  Network = Network;
  CryptoSymbol = CryptoSymbol;
  isTelegramMiniApp = TelegramMiniAppHelper.isMiniApp();
  isTonWalletConnected = false;
  networkFee = 0;
  networkSearch = "";
  isLoadingDepositLimit = false;

  receiveForm: FormGroup;

  private cleanUpFunctionsList: (VoidFunction | undefined)[] = [];

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _walletService: WalletService,
    private readonly _tonConnectService: TonConnectService,
    private readonly _translateService: TranslateService,
    private readonly _envService: EnvService,
    private readonly _balancesService: BalancesService,
    private readonly _toastService: ToastService,
    private readonly _depositLimitsService: DepositLimitsService
  ) {
    this.receiveForm = new FormGroup({
      amount: new FormControl(null, [Validators.required, Validators.min(Number.MIN_VALUE)]),
    });
  }

  async ngOnInit() {
    const wallets = await this._walletService.getMy();
    this.wallets = wallets?.params || [];
    const balances = await this._balancesService.getBalances();
    this.balances = balances?.params || [];

    // If we have a preselected balance, we need to select the correct balance
    if (this.walletBalance) {
      if (this.walletBalance.currency === CryptoSymbol.AbstractUsdt) {
        this.selectedWalletBalance = this.balances.find(b => b.currency === CryptoSymbol.Trx)!;
      } else {
        this.selectedWalletBalance = this.walletBalance;
      }
      this.selectedCurrency = this.selectedWalletBalance?.currency;
    }

    // Set default balance if we don't have a preselected balance
    if (this.selectedWalletBalance === null) {
      this.selectedWalletBalance = this.balances.find(
        b => b.wallet?.network === Network.Tron && b.currency === CryptoSymbol.Trx
      )!;
      this.selectedCurrency = this.selectedWalletBalance?.currency;
    }

    this.generateQr(this.walletAddress);
    await this.getDepositLimit();

    this.isTonWalletConnected = this._tonConnectService?.tonConnectUi?.connected;
    const tonStatusUnsubscribe = this._tonConnectService?.tonConnectUi?.onStatusChange?.(wallet => {
      if (wallet?.account?.address) {
        this.isTonWalletConnected = true;
      }
    });
    this.cleanUpFunctionsList.push(tonStatusUnsubscribe);
  }

  ngOnDestroy() {
    this.cleanUpFunctionsList.map(cleanUp => cleanUp?.());
  }

  closeModal(): void {
    this._activeModal.close();
  }

  get networkName(): string {
    if (this.selectedCurrency === CryptoSymbol.AbstractUsdt) {
      return getNetworkName(Network.Tron);
    }
    return getNetworkName(this.selectedWalletBalance?.wallet?.network!);
  }

  get isTonNetwork() {
    return this.selectedWalletBalance?.wallet?.network === Network.Ton;
  }

  get networks() {
    let nets = this.wallets.map(w => w.network);
    if (this.walletBalance?.currency === CryptoSymbol.AbstractUsdt) {
      nets = nets.filter(n => n !== Network.Bitcoin);
    }
    if (this.networkSearch) {
      nets = nets.filter(n => getNetworkName(n).toLowerCase().includes(this.networkSearch.toLowerCase()));
    }
    return nets;
  }

  get currencies(): CryptoSymbol[] {
    const currencies = this.balances
      .filter(balance => balance.wallet?.address === this.selectedWalletBalance?.wallet?.address)
      .map(balance => balance.currency);

    const networkToUsdtMap = new Map<Network, CryptoSymbol>([
      [Network.Tron, CryptoSymbol.Usdt],
      [Network.Ton, CryptoSymbol.TonUsdt],
      [Network.Polygon, CryptoSymbol.PolygonUsdt],
    ]);

    const selectedNetwork = this.selectedWalletBalance?.wallet?.network;
    if (selectedNetwork && networkToUsdtMap.has(selectedNetwork)) {
      currencies.push(networkToUsdtMap.get(selectedNetwork)!);
    }

    return currencies;
  }

  get amountError(): string | null {
    const amount = this.receiveForm.controls["amount"];

    if (amount.value === null) {
      return null;
    }

    if (amount?.hasError("required")) {
      return this._translateService.instant("Send.Amount_required_error");
    }

    if (amount?.hasError("min")) {
      return this._translateService.instant("Send.Amount_min_error");
    }

    if (amount?.hasError("max")) {
      return this._translateService.instant("Send.Amount_insuf_error");
    }

    return null;
  }

  public get walletAddress(): string {
    const address = this.selectedWalletBalance?.wallet?.address ?? "";

    if (this.isTonNetwork) {
      return TonAddressUtils.bounceableToNonBounceable(address);
    }

    return address;
  }

  getCurrencyName = getCurrencyName;
  getNetworkInfo = getNetworkInfo;

  selectNetwork(network: Network): void {
    const balance = this.balances.find(b => b.wallet?.network === network) ?? null;
    this.selectedWalletBalance = balance;
    this.selectedCurrency = balance?.currency;
    this.generateQr(this.walletAddress);
    this.step = Step.Qr;
  }

  private generateQr(address: string) {
    const code = generate(address);
    this.qrBase64 = toSvgDataURL(code);
  }

  selectWalletBalance(walletBalance: BalanceDto) {
    this.selectedWalletBalance = walletBalance;
  }

  selectWalletBalanceByCurrency(currency: CryptoSymbol) {
    this.selectedCurrency = currency;

    const currencyMapping: any = {
      [CryptoSymbol.Usdt]: CryptoSymbol.Trx,
      [CryptoSymbol.TonUsdt]: CryptoSymbol.Ton,
      [CryptoSymbol.PolygonUsdt]: CryptoSymbol.Matic,
    };

    const targetBalanceCurrency = currencyMapping[currency] || currency;
    this.selectedWalletBalance = this.balances.find(b => b.currency === targetBalanceCurrency) ?? null;
  }

  copyWalletAddress(value: string) {
    const tempEl = document.createElement("textarea");
    document.body.appendChild(tempEl);
    tempEl.value = value;
    tempEl.select();
    document.execCommand("copy", false);
    tempEl.remove();
    this._toastService.success(this._translateService.instant("Common.Copied"));
  }

  getDepositLimitAmount(currency: CryptoSymbol) {
    let targetCurrency: CryptoSymbol;

    if (currency === CryptoSymbol.AbstractUsdt) {
      switch (this.selectedWalletBalance?.wallet?.network) {
        case Network.Tron: {
          targetCurrency = CryptoSymbol.Usdt;
          break;
        }
        case Network.Ton: {
          targetCurrency = CryptoSymbol.TonUsdt;
          break;
        }
        case Network.Polygon: {
          targetCurrency = CryptoSymbol.PolygonUsdt;
          break;
        }
        default: {
          break;
        }
      }
    } else {
      targetCurrency = currency;
    }

    const limit = this.depositLimits?.find(x => x.currency === targetCurrency);
    return limit?.amount;
  }

  async receiveFromTonWallet() {
    if (!this.receiveForm.valid) {
      console.log("amount is invalid");
      return;
    }
    try {
      if (!this._tonConnectService?.tonConnectUi?.connected) {
        console.log("wallet is not connected");
        await this._tonConnectService.openTelegramWallet();
        return;
      }
      const amount = this.receiveForm.controls["amount"].value;
      switch (this.selectedWalletBalance?.currency) {
        case CryptoSymbol.Ton: {
          await this.receiveTonCoins(amount);
          break;
        }
        case CryptoSymbol.TonUsdt: {
          await this.receiveTonJettons(amount, "usdt");
          break;
        }
        case CryptoSymbol.Not: {
          await this.receiveTonJettons(amount, "not");
          break;
        }
        default: {
          throw new Error("Cannot receive unsupported currency");
        }
      }
    } catch (error) {
      console.error("ton transaction error:", error);
    }
  }

  private async receiveTonCoins(amount: string | number) {
    await this._tonConnectService?.tonConnectUi?.sendTransaction?.({
      network: this._envService.isProduction ? CHAIN.MAINNET : CHAIN.TESTNET,
      validUntil: Math.floor(Date.now() / 1000) + 180, // 180 sec
      messages: [
        {
          address: this.selectedWalletBalance?.wallet?.address ?? "",
          amount: toNano(amount).toString(),
        },
      ],
    });
  }

  private async receiveTonJettons(amount: string | number, jettonType: "usdt" | "not") {
    const tonAssetsSdkApi = await createApi(this._envService.isProduction ? "mainnet" : "testnet");
    const tonAssetsSender = new TonConnectSender(this._tonConnectService.tonConnectUi);
    const tonAssetsSdk = AssetsSDK.create({ api: tonAssetsSdkApi, sender: tonAssetsSender });

    const jettonAddress = jettonType === "usdt" ? TonUsdtMasterAddress : NotMasterAddress;

    const jettonWallet = tonAssetsSdk.openJettonWallet(Address.parse(jettonAddress));
    const receiver = Address.parse(this.selectedWalletBalance?.wallet?.address ?? "");

    await jettonWallet.send(tonAssetsSender, receiver, toNano(amount));
  }

  private async getDepositLimit() {
    this.isLoadingDepositLimit = true;
    const response = await this._depositLimitsService.getDepositLimits();
    this.depositLimits = response?.params || [];
    this.isLoadingDepositLimit = false;
  }
}
