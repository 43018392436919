<div class="box flex items-start justify-between" (click)="handleClick()">
  <div>
    <h5 class="text-base font-medium">{{ txDestination }}</h5>
    <div class="flex items-center gap-2 text-sm font-light max-md:text-xs">
      <span>{{ txDto.createdAt | customDate: "full" }}</span>
    </div>
    <div class="flex items-center text-sm md:hidden" *ngIf="txDto.cryptoTxHash != ''">
      <span class="mr-2 font-light lowercase">{{ "Transaction.Hash" | translate }}:</span>
      <a
        target="_blank"
        [href]="'https://tronscan.io/#/transaction/' + txDto.cryptoTxHash"
        class="max-w-[6.75rem] truncate"
      >
        {{ txDto.cryptoTxHash }}
      </a>
      <app-copy-btn [value]="txDto.cryptoTxHash" />
    </div>
    <div class="mt-2 max-md:mt-0 max-md:max-w-[14.5rem]">
      <div class="flex items-center text-sm max-md:text-xs">
        <span class="mr-2 font-light">{{ "Crypto_transaction.Bank" | translate }}:</span>
        <span>{{ bankingStatus }}</span>
      </div>
      <div class="flex items-end text-sm">
        <span class="mr-2 font-light">{{ "Crypto_transaction.Blockchain" | translate }}:</span>
        <span class="truncate">{{ internalStatus }}</span>
      </div>
    </div>
  </div>

  <div class="text-right">
    <div class="text-xl font-medium">
      {{ getCurrencyValue(txDto.toAmount, txDto.toCurrency) | mfeCustom: { currency: txDto.toCurrency } }}
      {{ getCurrencyName(txDto.toCurrency) }}
    </div>
    <div class="text-sm">
      {{
        getCurrencyValue(txDto.fromAmount, txDto.fromCurrency) | mfeCustom: { currency: txDto.fromCurrency }
      }}
      {{ getCurrencyName(txDto.fromCurrency) }}
    </div>
    <div class="flex items-center text-sm max-md:hidden" *ngIf="txDto.cryptoTxHash != ''">
      <span class="mr-2 font-light lowercase">{{ "Transaction.Hash" | translate }}:</span>
      <a
        target="_blank"
        [href]="'https://tronscan.io/#/transaction/' + txDto.cryptoTxHash"
        class="max-w-[9.375rem] truncate"
      >
        {{ txDto.cryptoTxHash }}
      </a>
      <app-copy-btn [value]="txDto.cryptoTxHash" />
    </div>
  </div>
</div>
