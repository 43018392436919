import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { CreateRoleDto, RoleDto, UpdateRoleDto } from "src/app/common/DTO/roles";
import { RolesErrorCode } from "src/app/common/enums/roles-error-code.enum";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class RolesService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async getAllRoles() {
    const uri = `${this._envService.serverUrl}${UrnConstants.RolesAll}`;
    const result = new HttpResultDto<RolesErrorCode, RoleDto[]>(false);

    try {
      const response = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<RoleDto[]>;
      result.params = response.data;
    } catch (e) {
      result.withError = true;
      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async createRole(dto: CreateRoleDto) {
    const uri = `${this._envService.serverUrl}${UrnConstants.Roles}`;
    const result = new HttpResultDto<RolesErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.post(uri, dto));
    } catch (e) {
      result.withError = true;
      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async updateRole(dto: UpdateRoleDto) {
    const uri = `${this._envService.serverUrl}${UrnConstants.Roles}`;
    const result = new HttpResultDto<RolesErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.put(uri, dto));
    } catch (e) {
      result.withError = true;
      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async deleteRole(roleId: number) {
    const uri = `${this._envService.serverUrl}${UrnConstants.Roles}`;
    const result = new HttpResultDto<RolesErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.delete(uri, { body: { roleId } }));
    } catch (e) {
      result.withError = true;
      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): RolesErrorCode {
    if (error.includes("The specified condition was not met")) {
      return RolesErrorCode.NoPermissions;
    }
    switch (error) {
      case Constants.InternalServerError:
        return RolesErrorCode.InternalError;
      case Constants.Unauthorized:
        return RolesErrorCode.Unauthorized;
      default:
        return RolesErrorCode.InternalError;
    }
  }
}
