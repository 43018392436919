import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Injectable({ providedIn: "root" })
export class UserGuard {
  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _router: Router
  ) {}

  public async canActivate() {
    const data = await this._localStorage.getUserData();
    if (data == null) {
      return false;
    }

    if (data.role.isAdmin) {
      this._router.navigate([`${RouteConstants.admin}/${RouteConstants.adminHome}`]);
      return false;
    }

    return true;
  }
}
