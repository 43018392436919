<div class="absolute top-8">
  <h1 class="title font-semibold">{{ "Admin.Reports.Title" | translate }}</h1>
</div>

<section class="mt-6 space-y-6">
  <div class="box flex items-center justify-between p-6">
    <h4 class="altitle">{{ "Admin.Reports.Create_report" | translate }}</h4>
    <app-datepicker
      [value]="[fromDate, toDate]"
      [range]="true"
      [withActionButtons]="false"
      (onSelect)="onSelectDates($event)"
      class="ml-auto mr-6 block w-[17rem]"
    />
    <button
      class="btn-primary btn-small"
      (click)="handleExportClick()"
      [disabled]="!hasInteractionPermissions"
    >
      {{ "Admin.Reports.Export" | translate }}
    </button>
  </div>

  <div class="grid grid-cols-2 gap-x-6">
    <div class="box">
      <h4 class="altitle">{{ "Admin.Reports.Accounting_reports" | translate }}</h4>

      <div class="mt-5 border-t border-gray-100 pt-5 dark:border-gray-700">
        <div class="flex items-center gap-2 rounded-2xl bg-gray-100 px-4 py-2.5 dark:bg-slate-700">
          <div class="text-sm font-medium">
            <span>{{ "Admin.Reports.Sell_buy_report" | translate }}:</span>
            <span class="block text-text-secondary">{{ dateRange }}</span>
          </div>
          <button
            *ngIf="showDownloadButton"
            class="ml-auto w-8"
            (click)="handleDownloadReports('accounting')"
            [disabled]="!hasInteractionPermissions"
          >
            <app-svg-icon name="receive" />
          </button>
        </div>
      </div>
    </div>

    <div class="box">
      <h4 class="altitle">{{ "Admin.Reports.User_reports" | translate }}</h4>

      <div class="mt-5 space-y-5 border-t border-gray-100 pt-5 dark:border-gray-700">
        <div class="flex items-center gap-2 rounded-2xl bg-gray-100 px-4 py-2.5 dark:bg-slate-700">
          <div class="text-sm font-medium">
            <span>{{ "Admin.Reports.Users_registration_report" | translate }}:</span>
            <span class="block text-text-secondary">
              {{ dateRange }}
            </span>
          </div>
          <button
            *ngIf="showDownloadButton"
            class="ml-auto w-8"
            (click)="handleDownloadReports('registered-users')"
            [disabled]="!hasInteractionPermissions"
          >
            <app-svg-icon name="receive" />
          </button>
        </div>

        <div class="flex items-center gap-2 rounded-2xl bg-gray-100 px-4 py-2.5 dark:bg-slate-700">
          <div class="text-sm font-medium">
            <span>{{ "Admin.Reports.Users_personal_data_report" | translate }}</span>
          </div>
          <button
            class="ml-auto w-8"
            (click)="handleDownloadUsersData()"
            [disabled]="!hasInteractionPermissions"
          >
            <app-svg-icon name="receive" />
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
