<section class="overflow-hidden rounded-2xl bg-gray-25 dark:bg-slate-800">
  <div class="px-6 pt-6" [ngClass]="{ 'pb-6': !shouldShowChart }">
    <div class="flex items-center justify-between">
      <p class="flex items-center gap-x-2.5 text-xl font-semibold max-md:text-lg">
        {{ "Wallet.Balance" | translate }}
        <button class="btn-flat w-6" (click)="toggleBalanceVisibility()">
          <app-svg-icon [name]="isBalanceVisible ? 'eye-on' : 'eye-off'" />
        </button>
      </p>

      <app-dropdown
        *ngIf="shouldShowChart"
        [items]="chartPeriodLabels"
        [value]="currentPeriod.label"
        (onSelect)="onChangePeriod($event)"
      >
        <button class="dropdown-base-toggle gap-x-2">
          <p>{{ currentPeriod.label | translate }}</p>
          <app-svg-icon name="chevron" class="block h-4 w-4" />
        </button>
      </app-dropdown>
    </div>

    <div
      *ngIf="totalUsdBalance !== null; else balanceSkeleton"
      class="flex items-end gap-x-2 max-md:mt-4 max-md:flex-col max-md:items-start"
    >
      <div class="text-6xl font-semibold max-md:text-[2.5rem]">
        <app-fractional-number
          *ngIf="isBalanceVisible; else hiddenBalance"
          [value]="totalUsdBalance | mfeCustom: { fiat: true }"
        />
        <ng-template #hiddenBalance>***</ng-template>
        USD
      </div>
      <div
        class="flex items-center gap-x-1 text-xl font-semibold"
        [ngClass]="{ 'text-green-400': balancePercentChange > 0, 'text-red-500': balancePercentChange < 0 }"
      >
        <app-svg-icon
          *ngIf="balancePercentChange !== 0"
          name="ticker-up"
          class="w-6"
          [ngClass]="{ 'rotate-180': balancePercentChange < 0 }"
        />
        {{ balancePercentChange | number: "1.0-2" }}%
      </div>
    </div>
    <ng-template #balanceSkeleton>
      <app-skeleton class="h-16 w-96 max-md:mt-4 max-md:w-full" />
    </ng-template>
  </div>

  <div
    class="relative mb-1 mt-8 max-h-[13.25rem] w-full pb-[calc(100%/4)] max-md:max-h-[17.5rem] max-md:pb-[calc(100%/2)]"
    [ngClass]="{ hidden: !shouldShowChart || isChartLoading }"
  >
    <canvas
      baseChart
      type="line"
      [data]="chartData"
      [options]="chartOptions"
      class="absolute left-0 top-0 h-full w-full"
    ></canvas>
  </div>

  <app-loader
    *ngIf="isChartLoading"
    class="mx-6 mb-4 mt-2 block h-[16.375rem] w-fill max-md:h-[12.25rem] [&>*]:h-full"
  />
</section>
