import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { SwapCommissionDto, UpdateSwapCommissionDto } from "src/app/common/DTO/swap-commissions";
import { SwapCommissionsErrorCode } from "src/app/common/enums/swap-commissions-error-code.enum";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class SwapCommissionsService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async getAllSwapCommissions() {
    const uri = `${this._envService.serverUrl}${UrnConstants.SwapCommissionsAll}`;
    const result = new HttpResultDto<SwapCommissionsErrorCode, SwapCommissionDto[]>(false);

    try {
      const response = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<SwapCommissionDto[]>;
      result.params = response.data;
    } catch (e) {
      result.withError = true;

      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async updateSwapCommission(dto: UpdateSwapCommissionDto) {
    const uri = `${this._envService.serverUrl}${UrnConstants.SwapCommissionsUpdate}`;
    const result = new HttpResultDto<SwapCommissionsErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.put(uri, dto));
    } catch (e) {
      result.withError = true;

      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): SwapCommissionsErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return SwapCommissionsErrorCode.InternalError;
      case Constants.Unauthorized:
        return SwapCommissionsErrorCode.Unauthorized;
      default:
        return SwapCommissionsErrorCode.InternalError;
    }
  }
}
