export class UrnConstants {
  public static readonly SignupUrn = "auth/signup";
  public static readonly ApprovePhoneUrn = "auth/approve";
  public static readonly SendConfirmCodeUrn = "auth/code/send";
  public static readonly LoginUrn = "auth/login";
  public static readonly RefreshTokenUrn = "auth/refresh";
  public static readonly LogoutUrn = "auth/logout";
  public static readonly LoginAdminUrn = "auth/login/admin";
  public static readonly AdminsUrn = "auth/admins";
  public static readonly ChangePasswordUrn = "auth/psw";
  public static readonly SendPasswordResetCodeUrn = "auth/password-reset/code";
  public static readonly ResetPasswordUrn = "auth/password-reset";
  public static readonly CheckPhoneIsExistsUrn = "auth/phone-number/is-exists";
  public static readonly ValidateLoginUrn = "auth/login/validate";
  public static readonly GetMe = "users/me";
  public static readonly GetAllUsers = "users/all";
  public static readonly UsersUrn = "users";
  public static readonly DeleteKyc = "users/kyc";
  public static readonly GetAdmins = "users/admins";
  public static readonly ChangePinCode = "users/PinCode";
  public static readonly AddKyc = "kyc";
  public static readonly AllKyc = "kyc/all";
  public static readonly GetKycById = "kyc";
  public static readonly VerdictKyc = "kyc/verdict";
  public static readonly getVerificationSessionResult = "kyc/session/kyc";
  public static readonly StartVerificationSession = "kyc/session/start";
  public static readonly CancelVerificationSession = "kyc/session/cancel";
  public static readonly GetMyWallet = "wallets/my";
  public static readonly SendUsdt = "wallets/send-usdt";
  public static readonly SendTrx = "wallets/send-trx";
  public static readonly Send = "wallets/send";
  public static readonly Swap = "wallets/swap";
  public static readonly CheckWalletExists = "wallets/CheckExists";
  public static readonly GetMyCards = "cards";
  public static readonly DeleteCard = "cards";
  public static readonly AddCard = "cards";
  public static readonly RequestCardVerificationCode = "cards/request";
  public static readonly VerifyCard = "cards/verify";
  public static readonly Terrorist = "terrorists";
  public static readonly TerroristAll = "terrorists/all";
  public static readonly DownloadTerroristsCsv = "terrorists/csv";
  public static readonly ScanTerrorists = "terrorists/scan";
  public static readonly UploadTerroristsCsv = "terrorists/import-csv";
  public static readonly GetBalances = "balances/all";
  public static readonly NetworkFees = "NetworkFees";
  public static readonly NetworkFeesAll = "NetworkFees/All";
  public static readonly MinBalanceForWithdraws = "MinBalanceForWithdraws";
  public static readonly MinBalanceForWithdrawsAll = "MinBalanceForWithdraws/All";

  public static readonly Transactions = "transactions";
  public static readonly TransactionsAll = "transactions/all";
  public static readonly TransactionsAllMy = "transactions/all/my";
  public static readonly TransactionsWithdrawRevert = "transactions/Withdraw-Revert";

  public static readonly UploadAvatar = "users/avatar";
  public static readonly UpdateUserRiskLevel = "users/risk-level";

  public static readonly BlackLists = "black-lists";
  public static readonly BlackListsUnblock = "unblock";

  public static readonly ControlPanel = "control-panel";
  public static readonly ControlPanelAll = "control-panel/all";

  public static readonly CommissionAll = "commission/all";
  public static readonly Commission = "commission";

  public static readonly LimitPanel = "limit-panel";
  public static readonly LimitPanelAll = "limit-panel/all";

  public static readonly GetRates = "rates";

  public static readonly RateHistoriesAll = "RateHistories/All";

  public static readonly GeneralPanel = "general-panel";

  public static readonly BuyCrypto = "crypto/buy";
  public static readonly SellCrypto = "crypto/sell";
  public static readonly CryptoTxs = "crypto/transactions";
  public static readonly MyCryptoTxs = "crypto/transactions/my";

  public static readonly WithdrawCommissions = "withdraw-commissions";
  public static readonly WithdrawCommissionsAll = "withdraw-commissions/all";

  public static readonly SwapCommissions = "SwapCommissions";
  public static readonly SwapCommissionsAll = "SwapCommissions/All";
  public static readonly SwapCommissionsUpdate = "SwapCommissions/Update";

  public static readonly Roles = "roles";
  public static readonly RolesAll = "roles/all";

  public static readonly DepositLimits = "DepositLimits";
  public static readonly DepositLimitsAll = "DepositLimits/All";

  public static readonly UserTotalBalanceHistories = "UserTotalBalanceHistories";

  public static readonly SystemSchedule = "SystemSchedule";
  public static readonly SystemScheduleAll = "SystemSchedule/All";

  public static readonly DepositControlPanels = "DepositControlPanels";

  public static readonly RequestOnDepositsAll = "RequestOnDeposits/All";
  public static readonly RequestOnDepositsVerdict = "RequestOnDeposits/Verdict";

  public static readonly TronEnergyPanel = "TronEnergyPanel";

  public static readonly StaticFolder = "files/";

  /* TronScan Urns */
  public static readonly GetAccountAssets = "account/tokens";
  public static readonly GetTxInfo = "transaction-info";

  /* TronGrid Urns */
  public static readonly GetAccountTxs = "v1/accounts/";
  public static readonly GetTronChainParameters = "wallet/getchainparameters";

  public static readonly GenerateMyIdQrPayload = "kyc/generate/payload";
  public static readonly VerifyKyc = "kyc/Register/2cebfd7c-7168-4f41-8b86-664af355245e";

  public static readonly GetBuySellCryptoByPeriodReport = "reports/buy-sell-crypto-report";
  public static readonly GetUsersRegisteredByPeriodReport = "reports/users-registered-by-period-report";
  public static readonly GetUserPersonalDataReport = "reports/user-personal-data-report";
  public static readonly GetUsersPersonalDataReport = "reports/users-personal-data-report";
  public static readonly GetTerroristsDataReport = "reports/terrorists-data-report";
}
