import { Component, Input } from "@angular/core";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";

@Component({
  selector: "app-token-growth",
  templateUrl: "./token-growth.component.html",
  styleUrls: ["./token-growth.component.css"],
})
export class TokenGrowthComponent {
  @Input() tokenRate: RateDto | null = null;
}
